import axios from 'axios';
import moment from 'moment';
import { sessionService } from 'redux-react-session';
import { resetAuthReducer, setAuthenticatedUser, setSessionSettings } from '../reduxStore/actions/authActions';
import {setCompanyData, setCompanyOrgChartData, setGroupOrgChartData, setOnBoardingComplete} from '../reduxStore/actions/flowActions';
import { actions_ErrorsModalClose, actions_ErrorsModalOpen } from '../reduxStore/actions/modalsActions';
import { store } from '../reduxStore/store';
import { URLS } from './API_CONSTANTS';
import {normalizeUserData} from "./user.service";

const objectHas = (config, prop) => Object.prototype.hasOwnProperty.call(config, prop) == true;
const dispatch = fn => store.dispatch(fn);

//setting instance of axios with interceptors for all api calls
export const axiosInstance = axios.create({ updateProfileAtSucces: false });
const isHandlerEnabled = (config = {}) => {
    return !(objectHas(config, 'handlerEnabled') && !config.handlerEnabled);
};
const getAuthenticatedUser = (config = {}) => {
    return objectHas(config, 'retrieveAuthenticatedUser') && config?.retrieveAuthenticatedUser;
};

const isModalOutputEnabled = (config = {}) => {
    return objectHas(config, 'withModalOutput') && config?.withModalOutput;
};
const forwardAllErrors = (config = {}) => {
    return objectHas(config, 'forwardAllErrors') && config?.forwardAllErrors;
};
const refreshOrgChart = (config = {}) => {
    return objectHas(config, 'refreshOrgChart') && config?.refreshOrgChart;
};
const refreshGroupChart = (config = {}) => {
    return objectHas(config, 'refreshGroupChart') && config?.refreshGroupChart;
};
const noRetryWithToken = (config = {}) => {
    return objectHas(config, 'noTokenRetrival') && config?.noTokenRetrival;
};
//request interceptor
const requestHandler = (requestConfig) => {
    //  console.log('isHandlerEnabled(response.config): ', isHandlerEnabled(request.config));
    if (isHandlerEnabled(requestConfig)) {
        // Modify request here by changing headers or payload
        /*
				console.log(">>API Request -", requestConfig?.method, '-@-', requestConfig?.url, requestConfig?.data != undefined && requestConfig?.data);
		*/
        // const { last_credentials: { access_token, refresh_token } } = store.getState().appReducer;
        // const token = access_token?.length > 0 ? access_token : store.getState().userReducer.credentials.access_token;
        // if (requestConfig?.headers?.Authorization) requestConfig.headers.Authorization = token ? `Bearer ${token}` : '';
        return requestConfig;
    }

    return requestConfig;
};

axiosInstance.interceptors.request.use(request => requestHandler(request));
//response interceptor
axiosInstance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
);
const successHandler = (response) => {
    if (isHandlerEnabled(response.config)) {
        // Handle responses
        if ([200, 201].includes(response.status)) {
            if (getAuthenticatedUser(response?.config)) {
                const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

                if (response?.data?.access_token && response?.data?.expires_in && response?.data?.refresh_token) {
                    const sessionSettings = {
                        rememberMe: response?.config?.rememberMe,
                        //  user_id: userData?.email,
                        expirationDate: moment()
                            .add(+response.data.expires_in * 1000, 'seconds')
                            .format('YYYY-MM-DD hh:mm:ss:ssss'),
                        last_access_token: response?.data?.access_token,
                        last_expires_in: response?.data?.expires_in,
                        last_refresh_token: response?.data?.refresh_token,
                    };

                    dispatch(setSessionSettings(sessionSettings));
                    const currentSession = { ...store.getState.sessionReducer, ...sessionSettings };

                    sessionService.saveSession(currentSession).then(() => {});
                }
                const token = response?.data?.access_token ? response?.data?.access_token : last_access_token;
                const getAuthenticatedUserConfig = { ...URLS.users.getAuthenticatedUser(token), handlerEnabled: true };

                return axiosInstance(getAuthenticatedUserConfig)
                    .then((res) => {
                        if (res?.status === 200 && res?.data) {
                            const user_info = normalizeUserData(res?.data?.data);

                            sessionService.saveUser({ ...user_info });
                            dispatch(setAuthenticatedUser({ ...user_info }));
                            const getCompanyDetailsConfig = {
                                ...URLS.company.getCompanyDetails(token, user_info?.company_id),
                                handlerEnabled: true,
                            };

                            return axiosInstance(getCompanyDetailsConfig)
                                .then((re) => {
                                    if (re?.status === 200 && re?.data?.data) {
                                        dispatch(setCompanyData(re?.data?.data));
                                        if (re?.data?.data?.onboarding_status === true) {
                                            dispatch(setOnBoardingComplete(true));
                                        }

                                        return {
                                            ...response,
                                            data: {
                                                ...response.data,
                                                is_admin: store.getState().authReducer.authenticatedUser.is_admin,
                                                onboarding_status: re?.data?.data?.onboarding_status,
                                            },
                                        };
                                    } else {
                                        return response;
                                    }
                                })
                                .catch((auto_inner_2_error) => {
                                    console.error(auto_inner_2_error);

                                    return Promise.reject(auto_inner_2_error);
                                });
                        }
                    })
                    .catch((auto_inner_1_error) => {
                        console.error(auto_inner_1_error);

                        return Promise.reject(auto_inner_1_error);
                    });
            }
            if (refreshOrgChart(response.config)) {
                const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
                const token = response?.data?.access_token ? response?.data?.access_token : last_access_token;
                const config = {
                    ...URLS.positions.getPositionsOrgChart(token),
                    handlerEnabled: true,
                };

                axiosInstance(config)
                    .then((response) => {
                        if (response?.status == 200 && response?.data && response?.data?.data) {
                            store.dispatch(setCompanyOrgChartData(response?.data?.data));

                            return response;
                        }
                    })
                    .catch((error) => {
                        console.error(error);

                        return Promise.reject(error);
                    });
            }

            if (refreshGroupChart(response.config)) {
                const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
                const selectedGroup = store.getState().flowReducer?.selectedGroup ?? null;
                const group_id = selectedGroup?.value ?? null;
                const group_name = selectedGroup?.label ?? null;

                const token = response?.data?.access_token ? response?.data?.access_token : last_access_token;
                const config = {
                    ...URLS.groups.getGroupOrgChart(token, group_id),
                    handlerEnabled: true,
                };

                axiosInstance(config)
                    .then((response) => {
                        if (response?.status == 200 && response?.data && response?.data?.data) {
                            let chart = {
                                "id": group_id,
                                "name": group_name,
                                "is_department_root": false,
                                "division": null,
                                "children": [
                                    ...response?.data?.data || [], // Merge data
                                    ...response?.data?.non_assigned || [], // Merge non_assigned
                                ],
                            };

                            //Same code is copied in axiosInstance
                            store.dispatch(setGroupOrgChartData(chart));

                            return response;
                        }
                    })
                    .catch((error) => {
                        console.error(error);

                        return Promise.reject(error);
                    });
            }
        }
    }

    return response;
};
const errorHandler = (error) => {
    if (isHandlerEnabled(error.config)) {
        /*  */
    }
    const originalRequest = error.config;

    if (noRetryWithToken(error.config)) {
        return Promise.reject(error.response?.data);
    }

    const isNotLogin = error?.config?.url?.indexOf('/api/auth/register') === -1 && error?.config?.url?.indexOf('/api/auth/login') === -1;

    const {
        sessionSettings: { last_refresh_token },
    } = store.getState().authReducer;

    if (error?.response?.status === 401 && !originalRequest._retry && isNotLogin && last_refresh_token) {
        originalRequest._retry = true;
        const data = new FormData();

        data.append('refresh_token', last_refresh_token);
        const config_REF_TOK = {
            ...URLS.auth.refreshToken(last_refresh_token),
            data: data,
        };

        return axios(config_REF_TOK) //.post(URL + value, data, { headers: { ...header } })
            .then((res) => {
                if (res?.data) {
                    const sessionSettings = {
                        rememberMe: store.getState()?.authReducer?.sessionSettings?.rememberMe,
                        expirationDate: moment()
                            .add(+res.data.expires_in * 1000, 'seconds')
                            .format('YYYY-MM-DD hh:mm:ss:ssss'),
                        last_access_token: res?.data?.access_token,
                        last_expires_in: res?.data?.expires_in,
                        last_refresh_token: res?.data?.refresh_token,
                    };

                    dispatch(setSessionSettings(sessionSettings));
                    const currentSession = { ...store.getState.sessionReducer, ...sessionSettings };

                    sessionService
                        .saveSession(currentSession)
                        .then(() => {
                            const authenticatedUser = store.getState().authReducer.authenticatedUser;

                            sessionService
                                .saveUser(authenticatedUser)
                                .then(() => {
                                    // 2) Change Authorization header
                                    originalRequest.headers = {
                                        ...originalRequest.headers,
                                        Authorization: 'Bearer ' + res.data.access_token,
                                    };

                                    // 3) return originalRequest object with Axios.
                                    return axios(originalRequest);
                                })
                                .catch((err) => {
                                    console.log('  sessionService.saveUser error ', err);
                                });
                        })
                        .catch((_err) => {
                            console.log('sessionService.saveUser catch error ', _err);
                            axios(originalRequest);
                        });
                }
            })
            .catch((error) => {
                sessionService.deleteSession().then(() => sessionService.deleteUser());
                dispatch(resetAuthReducer());
                let err = { status: error?.response?.status, data: error?.response?.data || error };
                let fwdErr = '';

                if (error.response?.data?.message) {
                    const c = error.response?.data?.message;

                    fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
                    err = { status: error?.response?.status, data: fwdErr };
                }

                return Promise.reject(err);
                // return axios(originalRequest);
            });
    }

    let err = { status: error?.response?.status, data: error?.response?.data, request_uuid: error?.response?.data?.request_uuid  };
    let fwdErr = '';

    switch (error?.response?.status && error?.response?.status) {
        case 401:
        case 500:
            if (error.response?.data?.message) {
                const c = error.response?.data?.message;

                fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n ' + c : fwdErr;
            }
            err = { status: error?.response?.status, data: fwdErr };
            break;
        case 403:
        case 422:
            if (error.response?.data?.errors?.position_id?.length > 0) {
                const c = error.response?.data?.errors?.position_id[0];

                fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
            }
            if (error.response?.data?.errors?.founding_date?.length > 0) {
                const c = error.response?.data?.errors?.founding_date[0];

                fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
            }
            if (error.response?.data?.errors?.misc_info?.length > 0) {
                const c = error.response?.data?.errors?.misc_info[0];

                fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
            }

            if (error.response?.data?.errors?.email?.length > 0) {
                const c = error.response?.data?.errors?.email[0];

                fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
            }
            if (error.response?.data?.errors?.password?.length > 0) {
                fwdErr = error.response?.data?.errors;
            }
            if (error.response?.data?.errors?.invitation_id?.length > 0) {
                const c = error.response?.data?.errors?.invitation_id[0];

                fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
            }
            if (error.response?.data?.errors?.name?.length > 0) {
                const c = error.response?.data?.errors?.name[0];

                fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
            }
            if (error.response?.data?.errors?.old_password?.length > 0) {
                const c = error.response?.data?.errors?.old_password[0];

                fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
            }
            if (error.response?.data?.errors?.logo?.length > 0) {
                const c = error.response?.data?.errors?.logo[0];

                fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
            }
            if (error.response?.data?.errors?.photo?.length > 0) {
                const c = error.response?.data?.errors?.photo[0];

                fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
            }

            if (error.response?.data?.message || error.response?.data?.data?.message) {
                const c = error.response?.data?.message ?? error.response?.message ?? error.response?.data?.data?.message;

                if (isNotLogin && typeof fwdErr == 'string') {
                    fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
                }
            }

            if (error.response?.data?.message && error.response?.data.errors && forwardAllErrors(error.config) == true) {
                fwdErr = error.response?.data;
                err = { status: error?.response?.status, data: fwdErr };
            }
            if (forwardAllErrors(error.config) == false) {
                err = { status: error?.response?.status, data: fwdErr?.trim() };
            }

            break;
        case 404:
            if (error.response?.data) {
                const c = error.response?.data?.message ?? error.response?.message ?? error.response.data.data.message;

                if (isNotLogin) {
                    fwdErr = c !== fwdErr.split('\n')[fwdErr.split('\n').length - 1] ? fwdErr + '\n' + c : fwdErr;
                    err = { status: error?.response?.status, data: fwdErr };
                }
            }
            if (error.response?.data?.message && error.response?.data.errors && forwardAllErrors(error.config) == true) {
                fwdErr = error.response?.data;
                err = { status: error?.response?.status, data: fwdErr };
            }
            if (forwardAllErrors(error.config) == false) {
                err = { status: error?.response?.status, data: fwdErr?.trim() };
            }
            break;
        default: {
            err = { status: error?.response?.status, data: error?.toString(), request_uuid: error?.response?.data?.request_uuid };
        }
    }

    if (forwardAllErrors(error.config) == true) {
        err = { status: error?.response?.status, data: error?.response?.data, request_uuid: error?.response?.data?.request_uuid  };
    }
    if (!isNotLogin && typeof err?.data === 'string' && err?.data?.indexOf('\n') == -1) {
        err = { status: error?.response?.status, data: error?.response?.data?.message ?? error.message, request_uuid: error?.response?.data?.request_uuid  };
    }
    if (isModalOutputEnabled(error.config) === true) {
        const options = {
            headingCentered: true,
            heading: 'ERROR' + error?.response?.status + ' on ' + error?.config?.url,
            subheading: JSON.stringify(error.response?.data),
            subheadingCentered: true,
            onClose: () => {},
            firstBtnText: 'close',
            onFirstButtonClick: () => {
                store.dispatch(actions_ErrorsModalClose());
            },
            secondBtnText: 'retry',
            onSecondButtonClick: () => {},
        };

        store.dispatch(actions_ErrorsModalOpen({ isOpen: true, options: options }));
    }

    return Promise.reject(err);
};
