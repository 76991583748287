import {
    ADD_NEW_STATUS,
    ADD_NEW_TASK,
    DELETE_TASK,
    SET_MY_GROUPS,
    SET_SELECTED_GROUP,
    SET_COMPANY_DATA,
    SET_COMPANY_DEPARTMENTS,
    SET_COMPANY_ORG_CHART_DATA,
    SET_GROUP_ORG_CHART_DATA,
    SET_COMPANY_POSITIONS_ARRAY,
    SET_COMPANY_PROCESSES,
    SET_COMPLETED_TASKS,
    SET_DUPLICATE_POSITION_INFO,
    SET_INVITE_COLLEAGUES_LIST,
    SET_ONBOARDING_COMPLETED,
    SET_POSITION_INFO,
    SET_SELECTED_PROCESS,
    SET_SELECTED_PROCESS_STEP,
    RESET_SELECTED_PROCESS_STEP,
    SET_SELECTED_PROCESS_STEP_OPTIONS,
    SET_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT,
    REMOVE_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT,
    SET_SERVER_FEATURE_NOT_READY_YET,
    SET_TASKS,
    SET_UNASSIGNED_USSERS_ARRAY,
    SET_USERS_ARRAY,
    SET_NEW_COMPONENT_BLUEPRINT,
    SET_SELECTED_COMPONENT_BLUEPRINT,
    DELETE_USER,
    ACTIVATE_USER,
    UPDATE_USER,
    DELETE_COMPONENT_BLUEPRINT,
    SET_BEGUN_PROCESS_TASK,
    SET_BLUEPRINT_COMPONENTS,
    CHANGE_TASK_PROCESS,
    RESET_FLOW_REDUCER,
    SET_COMPONENTS,
    EDIT_TASK,
    SET_COMPANY_MENU_SYNC,
    SET_ACCOUNT_MENU_SYNC, SET_GROUP_SYNC,
} from './types';

export const setInviteColleguesList = arrayColleguesList => dispatch =>
    dispatch({
        type: SET_INVITE_COLLEAGUES_LIST,
        payload: arrayColleguesList,
    });
export const setCompanyData = companyDataObj => dispatch => dispatch({ type: SET_COMPANY_DATA, payload: companyDataObj });
export const syncCompanyMenuList = () => dispatch => dispatch({ type: SET_COMPANY_MENU_SYNC });
export const syncAccountMenuList = () => dispatch => dispatch({ type: SET_ACCOUNT_MENU_SYNC });
export const syncGroupsList = () => dispatch => dispatch({ type: SET_GROUP_SYNC });

export const setMyGroups = myGroupsArray => dispatch =>
    dispatch({
        type: SET_MY_GROUPS,
        payload: myGroupsArray,
    });
export const setCompanyDepartments = companyDepartmentsArray => dispatch =>
    dispatch({
        type: SET_COMPANY_DEPARTMENTS,
        payload: companyDepartmentsArray,
    });
export const setCompanyPositionsArray = posiArray => dispatch =>
    dispatch({
        type: SET_COMPANY_POSITIONS_ARRAY,
        payload: posiArray,
    });
export const setPositionInfo = positionInfo => dispatch => dispatch({ type: SET_POSITION_INFO, payload: positionInfo });
export const setDuplicatePositionInfo = duplicatePositionInfo => dispatch =>
    dispatch({
        type: SET_DUPLICATE_POSITION_INFO,
        payload: duplicatePositionInfo,
    });

export const setCompanyOrgChartData = orgChartData => dispatch =>
    dispatch({
        type: SET_COMPANY_ORG_CHART_DATA,
        payload: orgChartData,
    });
export const setSelectedGroup = selectedGroup => dispatch =>
    dispatch({
        type: SET_SELECTED_GROUP,
        payload: selectedGroup,
    });
export const setGroupOrgChartData = orgChartData => dispatch =>
    dispatch({
        type: SET_GROUP_ORG_CHART_DATA,
        payload: orgChartData,
    });

export const setOnBoardingComplete = onBoardingCompleted => dispatch =>
    dispatch({
        type: SET_ONBOARDING_COMPLETED,
        payload: onBoardingCompleted,
    });

export const setCompanyProcesses = (companyProcessesArray, page) => dispatch =>
    dispatch({
        type: SET_COMPANY_PROCESSES,
        payload: companyProcessesArray,
        page: page,
    });
export const setComapanyPinnedProcesses = companyPinnedProcessesArray => dispatch =>
    dispatch({
        type: SET_COMPANY_PROCESSES,
        payload: companyPinnedProcessesArray,
    });
export const setSelectedProcess = selectedProcess => dispatch =>
    dispatch({
        type: SET_SELECTED_PROCESS,
        payload: selectedProcess,
    });
export const setSelectedProStep = selectedProStep => dispatch =>
    dispatch({
        type: SET_SELECTED_PROCESS_STEP,
        payload: selectedProStep,
    });
export const setSelectedProStepOptions = selectedProStepOption => dispatch =>
    dispatch({
        type: SET_SELECTED_PROCESS_STEP_OPTIONS,
        payload: selectedProStepOption,
    });
export const setSelectedProStepCompBP = selectedProStepCompBP => dispatch =>
    dispatch({
        type: SET_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT,
        payload: selectedProStepCompBP,
    });
export const removeSelectedProStepCompBP = removeSelectedProStepCompBP_id => dispatch =>
    dispatch({
        type: REMOVE_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT,
        payload: removeSelectedProStepCompBP_id,
    });

export const resetSelectedProStep = () => dispatch =>
    dispatch({
        type: RESET_SELECTED_PROCESS_STEP,
    });

export const setProcessTask = (task, isResumed) => dispatch =>
    dispatch({
        type: SET_BEGUN_PROCESS_TASK,
        payload: task,
        is_resumed: isResumed,
    });
export const handleChangeTaskProcess = process => dispatch => dispatch({ type: CHANGE_TASK_PROCESS, payload: process });

export const setTasks = tasksArray => dispatch => dispatch({ type: SET_TASKS, payload: tasksArray });
export const editTaskElement = task => dispatch => dispatch({ type: EDIT_TASK, payload: task });

export const taskDelete = taskToDelete => (dispatch) => {
    dispatch({
        type: DELETE_TASK,
        payload: taskToDelete,
    });
};
export const addNewTask = taskData => dispatch => dispatch({ type: ADD_NEW_TASK, payload: taskData });
export const addNewStatus = statusData => dispatch =>
    dispatch({
        type: ADD_NEW_STATUS,
        payload: statusData,
    });
export const setCompletedTasks = (completedTasksArray, page) => dispatch =>
    dispatch({
        type: SET_COMPLETED_TASKS,
        payload: completedTasksArray,
        page: page,
    });

export const setBlueprintComponents = (compArray, page) => dispatch =>
    dispatch({
        type: SET_BLUEPRINT_COMPONENTS,
        payload: compArray,
        page: page,
    });

export const setNewComponentBlueprint = newComponentBlueprint => dispatch =>
    dispatch({
        type: SET_NEW_COMPONENT_BLUEPRINT,
        payload: newComponentBlueprint,
    });

export const componentBlueprintDelete = componentToDelete => (dispatch) => {
    dispatch({
        type: DELETE_COMPONENT_BLUEPRINT,
        payload: componentToDelete,
    });
};

export const setSelectedComponentBlueprint = selectedComponentBlueprint => dispatch =>
    dispatch({
        type: SET_SELECTED_COMPONENT_BLUEPRINT,
        payload: selectedComponentBlueprint,
    });

export const setUsersArray = usersArray => dispatch =>
    dispatch({
        type: SET_USERS_ARRAY,
        payload: usersArray,
    });

export const deleteUser = user => dispatch =>
    dispatch({
        type: DELETE_USER,
        payload: user,
    });

export const activateUser = user => dispatch =>
    dispatch({
        type: ACTIVATE_USER,
        payload: user,
    });

export const updateUser = user => dispatch =>
    dispatch({
        type: UPDATE_USER,
        payload: user,
    });

export const setUnassignedUsersArray = unassignedUssersArray => dispatch =>
    dispatch({
        type: SET_UNASSIGNED_USSERS_ARRAY,
        payload: unassignedUssersArray,
    });
export const setServerFeatureNotReadyYet = value => dispatch =>
    dispatch({
        type: SET_SERVER_FEATURE_NOT_READY_YET,
        payload: value,
    });

export const resetFlowReducer = () => dispatch => dispatch({ type: RESET_FLOW_REDUCER });

export const setComponents = (compArray, page) => dispatch =>
    dispatch({
        type: SET_COMPONENTS,
        payload: compArray,
        page: page,
    });
