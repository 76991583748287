import { store } from '../reduxStore/store';
import { URLS } from './API_CONSTANTS';
import { axiosInstance } from './axiosInstance';
import { Observable } from 'rxjs';

export const uploadImage = (file) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = new FormData();

    data.append('image', file);

    const config = {
        ...URLS.images.uploadImage(last_access_token),
        data: data,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 201 && response?.data) {
                    subscriber.next(response?.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const deleteImage = (id) => {
	const {
		sessionSettings: { last_access_token },
	} = store.getState().authReducer;

	const config = {
		...URLS.images.deleteImage(last_access_token, id),
		handlerEnabled: true,
	};

	return new Observable(subscriber =>
		axiosInstance(config)
			.then((response) => {
				if (response?.status === 200) {
					subscriber.next(response?.data);
					subscriber.complete();
				}
			})
			.catch((error) => {
				subscriber.error(error);
			})
	);
};
