import { SET_AUTHENTICATED_USER, SET_LAST_KNOWN_ROUTE, SET_SESSION_SETTINGS, RESET_AUTH_REDUCER } from './types';

export const setSessionSettings = (settings) => {
    return (dispatch, _getState) => dispatch({ type: SET_SESSION_SETTINGS, payload: settings });
};
export const setAuthenticatedUser = (authenticatedUser) => {
    return (dispatch, _getState) => dispatch({ type: SET_AUTHENTICATED_USER, payload: authenticatedUser });
};
export const setLastKnownRoute = lastKnownRoute => (dispatch, _getState) => dispatch({ type: SET_LAST_KNOWN_ROUTE, payload: lastKnownRoute });

export const resetAuthReducer = () => (dispatch, _getState) => dispatch({ type: RESET_AUTH_REDUCER });
