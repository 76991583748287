import { useCallback, useEffect, useRef, useState } from 'react';
import { isSafari } from 'react-device-detect';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { connect, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Element } from 'react-scroll';
import roundArrow from '../../../assets/images/chart/roundArrow.svg';
import { TheRoleInformationContext, useRoleInformationContext } from '../../../context/appRender.context';
import { setPositionInfo } from '../../../reduxStore/actions/flowActions';
import { tutorialModalOpen } from '../../../reduxStore/actions/modalsActions';
import { PDFDownloadLink } from '@react-pdf/renderer';
import RolePDF from './RolePDF';
import {
    addingUserToPosition,
    duplicateSpecificPosition,
    getCompanyAllDepartmentManagerialPositions,
    getCompanyDepartments, getPositionById,
    removeUserFromPosition,
    sharingUserToPosition,
    unsharingUserFromPosition,
    updatePosition,
} from '../../../services/company.service';
import {
    Button,
    FieldWrapper,
    ModalR,
    SelectWithCheckboxesAndNestedItems,
    RenderDinamicTextInput,
    RenderPositionsPeople,
    RenderSharedResourcesForPosition,
    RenderTitle,
    SelectWithSearch,
    TutorialModal,
    CustomCheckbox,
    usePermissionContext,
} from '../../common';
import cls from './RoleInformation.module.scss';
import '../RoleInformationFiles/SplineSans-Regular-normal';
import {
    CheckIcon, deleteButtonIcon, duplicateIcon,
    rightArrow, shredder, successLarge, downloadIcon,
    leftArrow, tooltipPurple, closeIcon, closeIconPurple, questionMarkDark,
}
    from '../../../assets/images/new-icons';
import MessageContainer from '../../SideMenuEntries/ProcessesComps/MessageContainer/MessageContainer';
import { FieldWrapper_v2, RenderTooltip } from '../../common/TwoStatesFieldWrapper/FieldWrapper';

import TextEditor from '../../common/TextEditor/TextEditor';
import {
    accountability, consequencesFailure, consequencesSuccess,
    department, desiredResult,
    isManagerial,
    newPositionName,
    parentPosition, peopleInPosition, positionStandards, resorces,
    roleName, sharedResources, strategicWork,
    subpositions, tacticalWork,
} from "./tooltips";
import SharePositionModal from "./SharePositionModal";
import DeletePositionComponent from "./DeletePositionComponent";
import SuccessModal from "../../common/SucessModal/SuccessModal";
import StyledInput from "../../common/UI/StyledInput/StyledInput";
import { getAllUsers } from "../../../services/user.service";
import { first } from 'rxjs';
import {isEmptyValue, isNotEmptyValue} from "../../../shared/utility";
import { plusIconPurple } from '../../../assets/images/new-icons';
import { Can } from '../../common';
import { Permissions } from '../../common';
import RoleEditors from './RoleEditors';

const R = require('ramda');

const RoleInformation = ({
    positionInfo, onSetPositionInfo, companyDepartments,
    companyUsersArray, onTutorialModalOpen,
}) => {
    const fieldWrapProps = { useContext: useRoleInformationContext };
    const navigate = useNavigate();
    const location = useLocation();
    const { positionId } = useParams();
    const [isManagerialPosition, setIsManagerialPosition] = useState(positionInfo?.is_managerial ? 1 : 0);
    const [canInviteColleaguesIntoTheirTeam, setCanInviteColleaguesIntoTheirTeam] = useState(positionInfo?.can_invite_colleagues_into_their_team ? 1 : 0);
    const [canRemoveColleaguesFromTheirTeam, setCanRemoveColleaguesFromTheirTeam] = useState(positionInfo?.can_remove_colleagues_from_their_team ? 1 : 0);
    const [usersToAddOnPosition, setUsersToAddOnPosition] = useState([]);
    const [usersToShareOnPosition, setUsersToShareOnPosition] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDeleteResult, setShowDeleteResult] = useState({ visible: false, message: '' });
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);
    const [showIsShareOpen, setShowIsShareOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(false);
    const [selectedSharingPosition, setSelectedSharingPosition] = useState(false);
    const [sharingError, setSharingError] = useState('');
    const [hasBeenShared, setHasBeenShared] = useState(false);
    const [sharingLoading, setSharingLoading] = useState(false);
    const [multiselectItems, setMultiselectItems] = useState([]);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingChangePosition, setLoadingChangePosition] = useState(false);
    const [isGMDirectDep, setIsGMDirectDep] = useState(false);
    const mainContainerRef = useRef();
    const printingAreaRef = useRef();
    const [parentPositions, setParentPositions] = useState([]);
    const [mainErrorMessage, setMainErrorMessage] = useState('');
    const { companyData } = useSelector(state => state.flowReducer);
    const [isPositionUpdated, setIsPositionUpdated] = useState(false);
    const [disableParentSelection, setDisableParentSelection] = useState(false);
    const [nameError, setNameError] = useState('');
    const retreiveDepartmentsFromServer = () => {
        getCompanyDepartments().subscribe();
    };
    const [canEditPosition, setCanEditPosition] = useState(false);
    const [isManagedByManager, setIsManagedByManager] = useState(false);
    const { isAuthorized } = usePermissionContext();

    useEffect( () => {
        isAuthorized(null, [Permissions.CREATE_POSITION, Permissions.UPDATE_POSITION]).then(e => setCanEditPosition(e || isManagedByManager));
    }, [isAuthorized, isManagedByManager]);

    useEffect(() => {
        getPositionById(positionId)
            .pipe(first())
            .subscribe({
                next : (data)=>{
                    retrieveParentPositions(data.department_id);
                    setIsPositionUpdated(true);
                    setIsManagedByManager(data.is_managed_by_current_user);
                },
            });
        getAllUsers().subscribe({  });
        retreiveDepartmentsFromServer();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (location?.state?.isGMDirectDep == true) {
            setIsGMDirectDep(true);
        }
    }, [location]);

    const retrieveParentPositions = (department_id) => {
        setDisableParentSelection(true);
        getCompanyAllDepartmentManagerialPositions().subscribe({
            next: (data) => {
                let items = [];

                data?.forEach((i) => {
                    if (i.managers != undefined)
                        i.managers.forEach((ii) => {
                            items = [
                                ...items,
                                {
                                    label: ii.name,
                                    value: ii.id,
                                    department_name: i.name,
                                    department_id: i.id,
                                    icon: <img src={roundArrow} alt=">" style={{ width: '20px', height: '20px' }} />,
                                },
                            ];
                        });
                });

                setMultiselectItems(R.sortWith([R.ascend(R.prop('department_name'))])(items));

                const role = data?.find(ii => ii.id === department_id);

                if (role != undefined) {
                    const parentPos = role.managers.map(i => ({
                        label: i.name,
                        value: i.id,
                    }));

                    setParentPositions([...parentPos]);
                }
                setDisableParentSelection(false);
            },
            error: () =>{
                setDisableParentSelection(false);
            },
        });
    };

    const duplicatePositions = () => {
        setShowDuplicateModal(true);
    };
    const deleteThisPosition = () => {
        if (positionInfo?.users?.length > 0) {
            setMainErrorMessage('There are users in the position. You cannot delete a position with active');
        } else {
            setShowDeleteModal(true);
        }
    };
    const [savePressed, setSavePressed] = useState(false);
    const onSavePosition = useCallback(() => {
        setMainErrorMessage('');
        setNameError('');

        //Necessary as if user has selected department and don't select parent this position get's vanish.
        if(isNotEmptyValue(positionInfo.department_id) && isEmptyValue(positionInfo.parent_id)){
            setMainErrorMessage('Make sure to click and select a parent position');

            return;
        }
        setSavePressed(true);
        setLoadingSave(true);
        updatePosition(positionInfo.id).subscribe({
            next: () => {
                setLoadingSave(false);
                companyData.onboarding_status === false ?
                    navigate('/onboarding-chart') : navigate('/chart');
            },
            error: (e) => {
                setNameError(e.data);
                setLoadingSave(false);
            },
        });
    }, [positionInfo]);
    const DuplicatePositionComponent = (comp) => {
        const { isOpen, onClose } = comp;
        const [loading, setLoading] = useState(false);
        const [showConfirm, setShowConfirm] = useState(false);
        const [resultMessage, setResultMessage] = useState('');
        const [selectedParentPosition, setSelectedParentPosition] = useState({
            id: positionInfo.parent_position ? positionInfo.parent_position.id : '',
            label: positionInfo.parent_position ? positionInfo.parent_position.name : '',
            value: positionInfo.parent_position ? positionInfo.parent_position.name : '',
            selected: true,
        });

        const [positionNewName, setPositionNewName] = useState('');
        const [errorMessage, setErrorMessage] = useState('');

        useEffect(() => {
            setErrorMessage('');
        }, [selectedParentPosition]);


        const onDuplicatePressed = () => {
            if (!selectedParentPosition.selected || selectedParentPosition.label === '') {
                setErrorMessage('Make sure to click and select a parent position');
            } else if (selectedParentPosition.label === 'Chairman') {
                setErrorMessage('You cannot duplicate under the Chairman. Select a different parent position');
            } else if (selectedParentPosition.is_department_root === true) {
                setErrorMessage('You cannot duplicate under the departments root position. Select a different parent position');
            } else if (positionNewName.length > 0) {
                setLoading(true);
                duplicateSpecificPosition(positionInfo.id, positionNewName, selectedParentPosition.id).subscribe({
                    next: () => {
                        setLoading(false);
                        setShowConfirm(true);
                        setResultMessage('Position Has Been Successfully Duplicated.');
                    },
                    error: (error) => {
                        setLoading(false);
                        setErrorMessage(error.data.errors.name[0] ?? error.data.errors.parent_id[0]);
                    },
                });
            } else {
                setErrorMessage('Name is required');
            }
        };

        useCallback(() => {
            setLoading(false);
            setShowConfirm(false);
            onClose();
        }, [positionInfo]);

        return (
            <ModalR
                shadowedContainerStyle={{ maxWidth: '600px', minWidth: '475px', width: '475px' }}
                onClose={() => {
                    setShowConfirm(false);
                    onClose();
                }}
                isOpen={isOpen}
                backdropColor={true}
                blured={true}
            >
                <div className={cls.duplicateModalContainer}>
                    {!showConfirm ? (
                        <div className={cls.duplicatingPositionContainer}>
                            <div>
                                <img
                                    alt={""}
                                    src={questionMarkDark}
                                    style={{
                                        position: 'absolute',
                                        width: '24px',
                                        height: '24px',
                                        top: '35px',
                                        left: '40px',
                                    }}
                                />
                                <div style={{ position: 'absolute', top: '35px', right: '40px' }}>
                                    <div onClick={onClose} style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 0 }}>
                                        <img alt="" src={closeIcon} style={{ width: '24px', height: '24px' }} />
                                    </div>
                                </div>
                                <p className={cls.modalTitle}>
                                    Duplicate Position
                                </p>
                            </div>
                            {!loading && (
                                <div className={cls.duplicatingPosition_description}>
                                    <RenderTitle title={'Parent Position'}
                                        tooltipText={parentPosition} />
                                    <div className={cls.duplicatingPosition_parent}>
                                        <SelectWithCheckboxesAndNestedItems
                                            hasCloseIcon={false}
                                            noTags={true}
                                            placeholder={'Select Parent Position'}
                                            items={multiselectItems}
                                            groupedBy={'department_name'}
                                            triggerType={'chevron'}
                                            initialSelectedValues={multiselectItems?.filter(i => i.value === positionInfo.parent_id)}
                                            className={cls.peoplePositionsSelect}
                                            inputClassName={cls.peoplePositionsSelectInput}
                                            molclassname={cls.peoplePositionsSelectMenuOverlay}
                                            moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
                                            onChange={(selection) => {
                                                selection?.value &&
                                                    setSelectedParentPosition({
                                                        id: selection.value,
                                                        department_id: selection.department_id,
                                                        selected: true,
                                                    });
                                            }}
                                            onInputChange={e =>
                                                setSelectedParentPosition({
                                                    ...selectedParentPosition,
                                                    value: e,
                                                    selected: false,
                                                })
                                            }
                                        />
                                    </div>
                                    <RenderTitle title={'Name of The New Position'}
                                        tooltipText={newPositionName} />
                                    <div className={cls.duplicatingPosition_newName}>
                                        <RenderDinamicTextInput
                                            numberOfLines={1}
                                            plcHoffset={'9px'}
                                            maxLength={48}
                                            placeholder="Type here..."
                                            text={positionNewName}
                                            onTextChanged={(text) => {
                                                setErrorMessage('');
                                                setPositionNewName(text);
                                            }}
                                        />
                                    </div>
                                    {errorMessage && <MessageContainer message={errorMessage} />}
                                </div>
                            )}
                            {!loading && (
                                <div>
                                    <Button
                                        icon={<CheckIcon style={{ padding: '1px 1px', width: '24px', height: '24px' }} />}
                                        title="Complete Duplication"
                                        style={{ marginLeft: '0px', width: '100%', marginTop: '20px', height: '56px' }}
                                        titleStyle={{ textAlign: 'center', padding: '0 2px 0 0' }}
                                        clicked={onDuplicatePressed}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className={cls.duplicatingPosition_resultOperationContainer}>
                            <img src={successLarge} alt="" style={{ padding: '10px 10px', width: '194px', height: '194px' }} />
                            <div className={cls.duplicatingPosition_resultContainer}>
                                <h1 className={cls.duplicatingPosition_resultText}>{resultMessage}</h1>
                            </div>
                            <Button
                                lighter
                                title={'Close'}
                                style={{ width: '122px', height: '56px' }}
                                clicked={() => {
                                    onClose({ succes: false, message: 'User canceled.' });
                                }}
                                icon={<img alt="" src={closeIconPurple} />}
                            />
                        </div>
                    )}
                    {loading && (
                        <div className={cls.loadingContainer}>
                            <Loader type={isSafari ? 'Watch' : 'ThreeDots'}
                                color="var(--app-color-gray-dark)"
                                secondaryColor="blue" height={'70px'} width={'70px'} />
                        </div>
                    )}
                    {resultMessage && (
                        <ModalR backdropStyle={{ height: '100%' }}
                            shadowedContainerStyle={{ width: '536px', height: '368px' }}
                            onClose={() => {
                            }} position={'fixed'} scrollable={true}
                            backdropColor={true} blured={true} width={'60%'} isOpen={() => {
                            }}>
                            <div className={cls.duplicateModalContainer}>
                                <div className={cls.resultOperationWrapper}>
                                    <div className={cls.resultOperationContainer}>
                                        <img
                                            alt=""
                                            src={shredder}
                                            style={{
                                                padding: '10px 10px',
                                                marginTop: '20px',
                                                width: '120px',
                                                height: '120px',
                                            }}
                                        />
                                        <div className={cls.resultContainer}>
                                            <h1 className={cls.resultText}>{resultMessage}</h1>
                                            <Button
                                                lighter
                                                title={'Close'}
                                                style={{ marginTop: '45px', width: '122px', height: '56px' }}
                                                clicked={() => {
                                                    setResultMessage('');
                                                    onClose();
                                                }}
                                                icon={<img alt="" src={closeIconPurple} />}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalR>
                    )}
                </div>
            </ModalR>
        );
    };
    const settingUsersToAddOnPosition = val => setUsersToAddOnPosition(val);

    useEffect(() => {
        const usersOnPos = positionInfo?.users?.map(user => user.id);
        const newUsersToAdd = companyUsersArray?.filter(i => usersOnPos?.indexOf(i.profile_id) === -1);
        settingUsersToAddOnPosition(newUsersToAdd);
    }, [companyUsersArray, positionInfo]);

    const addSingleUserToPosition = useCallback(
        (selectedToAdd) => {
            setLoadingChangePosition(true);
            if (positionInfo?.id?.length > 0 && selectedToAdd?.length > 0) {
                if (positionInfo?.shared_users?.filter(i => i.id == selectedToAdd[0]?.value)?.length > 0) {
                    unsharingUserFromPosition(positionInfo?.id, [selectedToAdd]).subscribe({
                        next: () => {
                            addingUserToPosition(positionInfo?.id, selectedToAdd).subscribe({
                                next: (users) => {
                                    setLoadingChangePosition(false);
                                    onSetPositionInfo({
                                        users: [...positionInfo.users, ...users],
                                    });
                                },
                                error: (e) => {
                                    setLoadingChangePosition(false);
                                },
                            });
                        },
                        error: (e) => {
                            setLoadingChangePosition(false);
                        },
                    });
                } else {
                    addingUserToPosition(positionInfo?.id, selectedToAdd).subscribe({
                        next: (users) => {
                            setLoadingChangePosition(false);
                            onSetPositionInfo({
                                users: [...positionInfo.users, ...users],
                            });
                        },
                        error: (e) => {
                            setLoadingChangePosition(false);
                        },
                    });
                }
            }
        },
        [positionInfo]
    );

    const onAddUserOnPosition = useCallback(
        (selectedToAdd) => {
            if (selectedToAdd) {
                addSingleUserToPosition(selectedToAdd);
            }
        },
        [positionInfo]
    );

    const onRemoveUserFromPosition = useCallback(
        (selectedEmployee) => {
            if (positionInfo?.id?.length > 0 && selectedEmployee?.id?.length > 0) {
                setLoadingChangePosition(true);
                removeUserFromPosition(positionInfo?.id, selectedEmployee.id).subscribe({
                    next: () => {
                        setLoadingChangePosition(false);
                        const users = positionInfo?.users?.filter(function (item) {
                            return item.id !== selectedEmployee.id;
                        });

                        onSetPositionInfo({
                            users: [...users],
                        });
                    },
                    error: (e) => {
                        setLoadingChangePosition(false);
                    },
                });
            }
        },
        [positionInfo]
    );

    const settingUsersToShareOnPosition = val => setUsersToShareOnPosition(val);

    useEffect(() => {
        const usersOnPosIds = positionInfo?.users?.map(it => it.id);
        const sharedUsrIds = positionInfo?.shared_users?.map(it => it.id);
        const newUsersToShare = companyUsersArray?.filter(i => usersOnPosIds?.indexOf(i.profile_id) == -1 && sharedUsrIds.indexOf(i.profile_id) == -1);

        settingUsersToShareOnPosition(newUsersToShare);
    }, [companyUsersArray, positionInfo]);

    const onShareUserOnPosition = useCallback((selectedToShare, sharingPosition) => {
        if (sharingPosition.length > 0 && selectedToShare.length > 0) {
            setSharingLoading(true);
            sharingUserToPosition(sharingPosition, [selectedToShare]).subscribe({
                next: (data) => {
                    data && setHasBeenShared(true);
                    setShowIsShareOpen(false);
                    setSharingLoading(false);
                },
                error: (err) => {
                    setSharingError(err.data);
                    setSharingLoading(false);
                },
            });
        }
    });

    const onUnshareUserFromPosition = useCallback(
        (selectedEmployee) => {
            if (positionInfo?.id?.length > 0 && selectedEmployee?.id?.length > 0) {
                const subscription = unsharingUserFromPosition(positionInfo?.id, [selectedEmployee?.id]).subscribe();

                // Unsubscribe when the component unmounts.
                return () => {
                    subscription.unsubscribe();
                };
            }
        },
        [positionInfo]
    );
    const onUnshareUsersFromPosition = useCallback(
        (selectedEmployees) => {
            if (positionInfo?.id?.length > 0) {
                const ids = selectedEmployees.map(v => v.id);
                const subscription = unsharingUserFromPosition(positionInfo?.id, ids).subscribe();

                // Unsubscribe when the component unmounts.
                return () => {
                    subscription.unsubscribe();
                };
            }
        },
        [positionInfo]
    );

    const isParentPositionDisabled = () =>{
        return positionInfo?.is_protected || positionInfo?.parent_position?.is_department_root === true;
    };

    const isDepartmentHidden = () => {
        return positionInfo?.name === 'Chairman' || positionInfo?.is_department_root === true || positionInfo?.parent_position?.is_department_root === true;
    };

    useEffect(() => {
        positionInfo.department = companyDepartments.filter(({ value }) => {
            return value == positionInfo.department_id;
        })[0]?.label;
    }, [companyDepartments]);

    return (
        <TheRoleInformationContext>
            <div ref={mainContainerRef} className={cls.roleInformationWrapper}>
                <div className={cls.topCenteredHeader}>
                    <div style={{ marginLeft: '30px', display: 'flex' }}>
                        <Button clicked={() => navigate('/chart')}
                            lighter title={'Back'} icon={<img alt="" src={leftArrow} />}
                            style={{ height: '32px' }} />
                    </div>
                    <div
                        style={{
                            marginLeft: '30px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            alignContent: 'center',
                            textAlign: 'left',
                            width: '100%',
                        }}
                    >
                        <p
                            style={{
                                backgroundColor: 'transparent',
                                border: 0,
                                fontFamily: 'var(--app-text-main-font)',
                                fontSize: '24px',
                                fontWeight: 600,
                                color: 'var(--alvanda-dark-purple)',
                                textAlign: 'center',
                                maxHeight: '55px',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                            }}
                        >


                            {isPositionUpdated ?  positionInfo?.name :
                                ( <Loader type={isSafari ? 'Watch' : 'ThreeDots'}
                                    color={'var(--app-color-gray-dark)'}
                                    height={'25px'} width={'25px'}/>)}
                        </p>
                        <TutorialModal />
                        <Button
                            lighter
                            title={'Position Tutorial'}
                            style={{ marginRight: '20px', height: '32px' }}
                            icon={<img alt="Position Tutorial" src={tooltipPurple} />}
                            clicked={() => {
                                onTutorialModalOpen({
                                    isOpen: true,
                                    tutorialTitle: 'How to Define Positions',
                                    videoUrl: 'https://youtu.be/TcsCS_mH7rU',
                                    showDescription: false,
                                });
                            }}
                        />
                    </div>
                </div>
                {!isPositionUpdated ? (
                    <div className={cls.loadingContainer}>
                        <Loader type={isSafari ? 'Watch' : 'ThreeDots'}
                            color={'var(--app-color-gray-dark)'} radius={50}
                            style={{marginTop: '80px'}}/>
                    </div>
                ) : (
                    <div ref={printingAreaRef} className={cls.printableContainer}>
                        <div id={'scrollableContainer'} className={cls.scrollableContainer}>
                            <div id="printArea" className={cls.printArea}>
                                <Element name="0"></Element>
                                <p className={cls.roleInformationTitleText}>{'Position Management'}</p>
                                <div style={{ width: '50%', marginBottom: '20px' }}>
                                    <StyledInput
                                        hasError={nameError}
                                        label={'Role Name'}
                                        tooltipText={roleName}
                                        value={positionInfo.name}
                                        type="text"
                                        name="comapny-name"
                                        placeholder="Role Name"
                                        onChange={(e) => {
                                            if (e.target.value.length < 100)
                                            {
                                                onSetPositionInfo({
                                                    name: e.target.value,
                                                });
                                            }
                                        }}
                                        disabled={!canEditPosition}
                                    />
                                    {nameError && <MessageContainer message={nameError} />}
                                </div>
                                {!isGMDirectDep && (
                                    <>
                                        <div
                                            style={{
                                                width: '50%',
                                                marginBottom: '20px',
                                                display: isDepartmentHidden() ? 'none' : '',
                                            }}
                                        >
                                            <RenderTitle title={'Department'}
                                                tooltipText={department} />
                                            <SelectWithSearch
                                                triggerType={'chevron'}
                                                items={companyDepartments}
                                                defaultSelectedItem={
                                                    companyDepartments?.find(({ value }) => {
                                                        return value === positionInfo.department_id;
                                                    }) ?? {}
                                                }
                                                inputClassName={cls.currencySearchInput}
                                                clearControlsClassName={cls.currencyClearControlsClassName}
                                                molclassname={cls.currencySelectMenuOverlayList}
                                                moliclassname={cls.currencySelectMenuOverlayListItem}
                                                onChange={(e) => {
                                                    onSetPositionInfo({
                                                        department_id: e.value,
                                                        parent_id: '',
                                                        parent_position:{
                                                            id : '',
                                                            name : '',
                                                        },
                                                    });
                                                    retrieveParentPositions(e.value);
                                                }}
                                                ButtonToShow ={<div className={cls.centerButton}>
                                                    <Button
                                                        clicked={(e)=>{e.stopPropagation(); navigate('/welcome/define-departments');}}
                                                        title={'Add Department'}
                                                        icon={<img alt="" src={plusIconPurple} className={cls.addDepartmentButtonIcon_VII} />}
                                                        lighter/>
                                                </div>}
                                                menuOverlayListStyle={{ maxHeight: '200px', overflow:'auto'}}
                                            />
                                        </div>

                                        <div
                                            style={{
                                                width: '50%',
                                                marginBottom: '20px',
                                                display: positionInfo.name === 'Chairman' ? 'none' : '',
                                            }}
                                        >
                                            <RenderTitle title={'Parent Position'}
                                                tooltipText={parentPosition} />
                                            <SelectWithSearch
                                                disabled={isParentPositionDisabled() || disableParentSelection}
                                                isClearable={false}
                                                items={parentPositions.filter(e => e.label !== positionInfo.name)}
                                                triggerType={isParentPositionDisabled() ? 'disabled' : 'chevron'}
                                                defaultSelectedItem={{
                                                    label: positionInfo.parent_position?.name,
                                                    value: positionInfo.parent_position?.id,
                                                }}
                                                placeholder={disableParentSelection ? "Loading data..." : "Select parent position"}
                                                inputClassName={cls.currencySearchInput}
                                                clearControlsClassName={cls.currencyClearControlsClassName}
                                                molclassname={cls.currencySelectMenuOverlayList}
                                                moliclassname={cls.currencySelectMenuOverlayListItem}
                                                fadeOnDisable={true}
                                                onChange={(e) => {
                                                    onSetPositionInfo({
                                                        parent_id: e.value,
                                                        parent_position: {
                                                            id: e.value,
                                                            name: e.label,
                                                        },
                                                    });
                                                }}
                                            />
                                        </div>
                                        {(!positionInfo?.is_protected && !positionInfo?.parent_position?.is_department_root ) &&
                                        <div className={cls.checkboxContainer}>
                                            <div>
                                                <CustomCheckbox
                                                    disabled={positionInfo.is_protected || !canEditPosition}
                                                    label={'Is this a managerial Position?'}
                                                    onChange={(val) => {
                                                        onSetPositionInfo({ is_managerial: val});
                                                        setIsManagerialPosition(val);
                                                    }}
                                                    defaultChecked={ positionInfo?.is_managerial ? true : false  }
                                                    checkMarkStyle={{ width: "24px", height: "24px" }} /></div>
                                            <div><RenderTooltip tooltipText='Managerial Positions can have other Positions reporting to them in the Org Chart.' /></div>
                                        </div>
                                        }

                                        {!!isManagerialPosition && (
                                            <FieldWrapper id={'subPositions'} title={'What positions report directly to this position?'}
                                                tooltipText={subpositions}
                                                readOnly otherContent {...fieldWrapProps}>
                                                <div className={cls.inputField}>
                                                    {positionInfo.children?.length > 0 ? (
                                                        <p
                                                            style={{
                                                                color: 'var(--alvanda-dark-purple)',
                                                                fontFamily: 'var(--app-text-secondary-font)',
                                                            }}
                                                        >
                                                            {positionInfo.children
                                                                ?.map(item => item.name)
                                                                .join(', ')
                                                                .toString()}
                                                        </p>
                                                    ) : (
                                                        <p
                                                            style={{
                                                                color: 'var(--alvanda-dark-purple)',
                                                                fontFamily: 'var(--app-text-secondary-font)',
                                                            }}
                                                        >
                                                            {'No position'}
                                                        </p>
                                                    )}
                                                </div>
                                            </FieldWrapper>
                                        )}

                                        {(positionInfo?.is_managerial && !positionInfo?.is_protected && !positionInfo?.parent_position?.is_department_root ) &&
                                            <>
                                                <div className={cls.checkboxContainer}>
                                                    <div>
                                                        <CustomCheckbox
                                                            disabled={!canEditPosition}
                                                            label={'Can invite colleagues into their team?'}
                                                            onChange={(val) => {
                                                                onSetPositionInfo({ can_invite_colleagues_into_their_team: val});
                                                                setCanInviteColleaguesIntoTheirTeam(val);
                                                            }}
                                                            defaultChecked={ !!positionInfo?.can_invite_colleagues_into_their_team  }
                                                            checkMarkStyle={{ width: "24px", height: "24px" }} /></div>
                                                </div>
                                                <div className={cls.checkboxContainer}>
                                                    <div>
                                                        <CustomCheckbox
                                                            disabled={!canEditPosition}
                                                            label={'Can remove colleagues from their team?'}
                                                            onChange={(val) => {
                                                                onSetPositionInfo({ can_remove_colleagues_from_their_team: val});
                                                                setCanRemoveColleaguesFromTheirTeam(val);
                                                            }}
                                                            defaultChecked={ !!positionInfo?.can_remove_colleagues_from_their_team  }
                                                            checkMarkStyle={{ width: "24px", height: "24px" }} /></div>
                                                </div>
                                            </>
                                        }
                                    </>
                                )}

                                <Element name="1"></Element>
                                <RoleEditors
                                    savePressed={savePressed}
                                    setSavePressed={setSavePressed}
                                    positionInfo={positionInfo}
                                    onSetPositionInfo={onSetPositionInfo}
                                    hasPermission={canEditPosition}
                                />
                            </div>

                            <div id={'listsArea'} className={cls.printArea}>
                                <Element name="2"></Element>
                                <FieldWrapper_v2
                                    id={'peopleInThisPosition'}
                                    title={'People in this Position'}
                                    tooltipText={peopleInPosition}
                                    otherContent
                                    {...fieldWrapProps}
                                    fieldWrapperStyle={cls.peoplewrapper}
                                >
                                    <RenderPositionsPeople
                                        {...fieldWrapProps}
                                        id={'peopleInThisPosition'}
                                        setIsShareOpen={() => setShowIsShareOpen(true)}
                                        onAddUserOnPosition={onAddUserOnPosition}
                                        setShareUserPosition={setSelectedUser}
                                        loadingChangePosition={loadingChangePosition}
                                        onRemoveUserFromPosition={onRemoveUserFromPosition}
                                        usersOnPosition={positionInfo?.users}
                                        usersToAddOnPosition={usersToAddOnPosition}
                                        canEditPosition={canEditPosition}
                                    />
                                </FieldWrapper_v2>
                                <Element name="3"></Element>
                                <FieldWrapper
                                    id={'sharedresources'}
                                    title={'Shared Resources'}
                                    tooltipText={sharedResources}
                                    value={''}
                                    readOnly={true}
                                    clickParent={false}
                                    otherContent
                                    {...fieldWrapProps}
                                >
                                    <RenderSharedResourcesForPosition {...fieldWrapProps}
                                        id={'sharedresources'} sharedUsersOnPosition={positionInfo?.shared_users}
                                        usersToShareOnPosition={usersToShareOnPosition}
                                        onUnshareUserFromPosition={onUnshareUserFromPosition}
                                        onUnshareUsersFromPosition={onUnshareUsersFromPosition}

                                    />
                                </FieldWrapper>
                            </div>
                            {mainErrorMessage && <MessageContainer message={mainErrorMessage} />}
                            {canEditPosition && <div className={cls.optionsContainer}>
                                <Button icon={<img alt="" src={rightArrow} />}
                                    style={{ width: '200px', height: '56px' }}
                                    loading={loadingSave}
                                    clicked={() => onSavePosition()} title={'Save & Continue'} />
                                <div className={cls.buttonsContainer}>
                                    {positionInfo && companyData && (
                                            <PDFDownloadLink style={{ display: 'flex', textDecoration: 'none' }}
                                                document={<RolePDF positionInfo={positionInfo} companyInfo={companyData} />} fileName={`${positionInfo?.name}.pdf`}>
                                                {({ blob, url, loading, error }) => (<Button lighter title={'Download as PDF'} icon={<img alt="" src={downloadIcon} />} />)}
                                            </PDFDownloadLink>
                                        )}

                                    <Button clicked={duplicatePositions}
                                        lighter title={'Duplicate Position'}
                                        icon={<img alt="" src={duplicateIcon} />} />
                                    <Button disabled={positionInfo.is_protected} clicked={deleteThisPosition}
                                        lighter title={'Delete Position'}
                                        icon={<img alt="" src={deleteButtonIcon} />} />
                                </div>
                            </div>}
                        </div>
                    </div>)}
            </div>

            {showDeleteModal && (
                <DeletePositionComponent
                    positionInfo={positionInfo}
                    isOpen={showDeleteModal}
                    onClose={(p) => {
                        setShowDeleteModal(false);
                        if ([true, false].indexOf(p?.success) > -1) {
                            setShowDeleteResult({
                                visible: true,
                                message: p.message,
                            });
                        }
                    }}
                />
            )}
            {showDeleteResult?.visible && (
                <SuccessModal
                    icon={shredder}
                    isOpen={showDeleteResult?.visible}
                    resultsMessage={showDeleteResult?.message}
                    onClose={() => {
                        setShowDeleteResult({ visible: false, message: '' });
                        window.history.back();
                    }}
                />
            )}
            <SharePositionModal
                isOpen={showIsShareOpen}
                onClose={() => setShowIsShareOpen(false)}
                setSelectedSharingPosition={setSelectedSharingPosition}
                sharingError={sharingError}
                sharingLoading={sharingLoading}
                setSharingError={setSharingError}
                onShareUserOnPosition={() => onShareUserOnPosition(selectedUser?.id, selectedSharingPosition)}
            />
            {hasBeenShared && (
                <ModalR backdropStyle={{ height: '100%' }}
                    shadowedContainerStyle={{ width: '536px', height: '368px' }}
                    onClose={() => {
                    }} position={'fixed'} scrollable={true}
                    backdropColor={true} blured={true} width={'60%'} isOpen={() => {
                    }}>
                    <div className={cls.duplicateModalContainer}>
                        <div className={cls.resultOperationWrapper}>
                            <div className={cls.resultOperationContainer}>
                                <img
                                    alt=""
                                    src={successLarge}
                                    style={{
                                        padding: '10px 10px',
                                        marginTop: '20px',
                                        width: '120px',
                                        height: '120px',
                                    }}
                                />
                                <div className={cls.resultContainer}>
                                    <h1 className={cls.resultText}>Colleagues Have Been Successfully Shared.</h1>
                                    <Button lighter title={'Close'}
                                        style={{ marginTop: '35px', width: '122px', height: '56px' }}
                                        clicked={() => setHasBeenShared(false)} icon={<img alt="" src={closeIconPurple} />} />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalR>
            )}
            {showDuplicateModal && (
                <DuplicatePositionComponent
                    isOpen={showDuplicateModal}
                    onClose={() => {
                        setShowDuplicateModal(false);
                        window.history.back();
                    }}
                />
            )}
        </TheRoleInformationContext>
    );
};
const mapStateToProps = (store) => {
    const { positionInfo, companyDepartments, companyUsersArray } = store.flowReducer;
    const { onBoardingVisible, isOnboarding } = store.modalsReducer;

    return { positionInfo, companyDepartments, companyUsersArray, onBoardingVisible, isOnboarding };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onSetPositionInfo: field => dispatch(setPositionInfo(field)),
        onTutorialModalOpen: options => dispatch(tutorialModalOpen(options)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleInformation);
