import { useEffect, useState } from "react";
import { connect, useSelector } from 'react-redux';
import { Button, ModalR, Can, Permissions, usePermissionContext, TutorialModal, RenderTitle } from '../../common';
import cls from './CompanyCulture.module.scss';
import { setCompanyDetails } from '../../../services/company.service';
import { first } from 'rxjs/operators';
import { checkIcon, closeIconPurple, leftArrow, play, successLarge } from '../../../assets/images/new-icons';
import { tutorialModalOpen } from '../../../reduxStore/actions/modalsActions';

import TextEditor from '../TextEditor/TextEditor';
import {useNavigate} from "react-router-dom";

const CompanyCulture = (props) => {
    const { companyData } = useSelector(state => state.flowReducer);
    const [visionText, setVisionText] = useState(null);
    const [missionText, setMissionText] = useState(null);
    const [coreValues, setCoreValues] = useState(companyData?.core_values);
    const [companyStandards, setCompanyStandards] = useState(companyData?.company_standards);
    const [showSaved, setShowSaved] = useState(false);
    const [savePressed, setSavePressed] = useState(false);
    const { isAuthorized } = usePermissionContext();
    const [canUpdateCompany, setCanUpdateCompany] = useState(true);
    const navigate = useNavigate();

    useEffect( () => {
        isAuthorized(null, [Permissions.UPDATE_COMPANY]).then(updateCompany => setCanUpdateCompany(updateCompany));
    }, [isAuthorized]);

    const onSave = () => {
        setSavePressed(true);

        setCompanyDetails({
            vision_text: visionText ?? companyData.vision_text,
            mission_text: missionText ?? companyData.mission_text,
            core_values: coreValues ?? companyData.core_values,
            company_standards: companyStandards ?? companyData.company_standards,
        })
            .pipe(first())
            .subscribe({
                error: () => {
                    setShowSaved(false);
                },
                next: () => {
                    setShowSaved(true);
                },
            });
    };

    return (
        <div style={{ width: '100%', height: '100%', padding: '0' }}>
            <div className={cls.host}>
                <div className={cls.topCenteredHeader}>
                    <div style={{ marginLeft: '30px', display: 'flex' }}>
                        <Button lighter title={'Back'}
                            icon={<img alt="" src={leftArrow} />}
                            clicked={() => navigate(-1)}
                            style={{ height: '32px' }}></Button>
                        <p
                            style={{
                                backgroundColor: 'transparent',
                                border: 0,
                                fontFamily: 'var(--app-text-main-font)',
                                fontSize: '24px',
                                fontWeight: 600,
                                marginLeft: '30px',
                                color: 'var(--alvanda-dark-purple)',
                                textAlign: 'center',
                            }}
                        >
                            {`${companyData.name}'s Culture`}
                        </p>
                    </div>
                </div>

                <div className={cls.target}>
                    <div className={cls.content}>
                        <RenderTitle title={'Vision Statement'}
                            tooltipText={'An organization’s vision is a 1-2-page-long narrative describing exactly what the organization will look like 3-5 years from now.'}
                            titleClassName={cls.contentTitle} />
                        <TutorialModal hasCheckbox />
                        <TextEditor
                            savePressed={savePressed}
                            setSavePressed={setSavePressed}
                            uniqueIdentifier={"companyCulture_vision"}
                            disabled={!canUpdateCompany}
                            data={visionText ?? companyData?.vision_text}
                            onChangeCallback={setVisionText}
                        />

                        <Can permissions={[Permissions.UPDATE_COMPANY]}>
                            <Button
                                lighter
                                title={'Replay Vision Explainer Video'}
                                icon={<img alt="" src={play} />}
                                style={{ height: '32px', marginTop: '16px', marginBottom: '40px' }}
                                clicked={() => props.onTutorialModalOpen({
                                    isOpen: true,
                                    tutorialTitle: 'Vision Explainer Video',
                                    tutorialType: 'vision',
                                    showDescription: false,
                                    videoUrl: 'https://youtu.be/vvBmgPb_hVw',
                                })}
                            />
                        </Can>

                        <RenderTitle
                            title={'Mission Statement'}
                            tooltipText={'An organization’s mission is a statement in the present tense that tells the world why it exists. (Eg. Google’s mission is “to organize the world’s information and make it universally accessible and useful.”)'}
                            titleClassName={cls.contentTitle}
                        />
                        <TextEditor
                            savePressed = {savePressed}
                            setSavePressed={setSavePressed}
                            uniqueIdentifier={"companyCulture_mission"}
                            disabled={!canUpdateCompany}
                            data={missionText ?? companyData?.mission_text}
                            onChangeCallback={setMissionText}
                        />

                        <Can permissions={[Permissions.UPDATE_COMPANY]}>
                            <Button
                                lighter
                                title={'Replay Mission Explainer Video'}
                                icon={<img alt="" src={play} />}
                                style={{ height: '32px', marginTop: '16px', marginBottom: '40px' }}
                                clicked={() => props.onTutorialModalOpen({
                                    isOpen: true,
                                    tutorialTitle: 'Mission Explainer Video',
                                    tutorialType: 'mission',
                                    showDescription: false,
                                    videoUrl: 'https://youtu.be/WRHW_g0PzSw',
                                })}
                            />
                        </Can>

                        <div style={{ marginBottom: '40px' }}>
                            <RenderTitle
                                title={'Core Values'}
                                tooltipText={'These are the guiding principles by which all' +
                                    ' persons within the organization should conduct themselves. (E.g. if you have Love or ' +
                                    'Respect as a core value, you should not tolerate people taking badly ' +
                                    'about one another behind their backs)'}
                                titleClassName={cls.contentTitle}
                            />
                            <TextEditor
                                savePressed = {savePressed}
                                setSavePressed={setSavePressed}
                                uniqueIdentifier={"companyCulture_coreValues"}
                                disabled={!canUpdateCompany}
                                data={coreValues ?? companyData?.core_values}
                                onChangeCallback={setCoreValues}
                            />

                        </div>

                        <RenderTitle
                            title={'Organizational Standards'}
                            tooltipText={'These are the organization-wide standards that all ' +
                                'members must commit to. You can also call these the house rules. (E.g. Messages or calls from direct' +
                                ' managers must be replied to or returned within max 2 hours, during work hours.)'}
                            titleClassName={cls.contentTitle}
                        />
                        <TextEditor
                            savePressed = {savePressed}
                            setSavePressed={setSavePressed}
                            uniqueIdentifier={"companyCulture_standards"}
                            disabled={!canUpdateCompany}
                            data={companyStandards ?? companyData?.company_standards}
                            onChangeCallback={setCompanyStandards}
                        />

                    </div>
                    <ModalR isOpen={showSaved} shadowedContainerStyle={{ width: '536px', height: '368px' }}
                        onClose={() => setShowSaved(false)} scrollable={true} blured={true}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: '30px',
                            }}
                        >
                            <img alt={''} src={successLarge} />
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontFamily: 'var(--app-text-main-font)',
                                    fontSize: '24px',
                                    fontWeight: '600',
                                    color: 'var(--alvanda-purple)',
                                    marginBottom: '5px',
                                    textAlign: 'center',
                                }}
                            >
                                {'Company Details Successfully Saved!'}
                            </p>
                            <p
                                style={{
                                    width: '300px',
                                    fontFamily: 'var(--app-text-secondary-font)',
                                    fontSize: '16px',
                                    textAlign: 'center',
                                    marginBottom: '20px',
                                }}
                            ></p>
                            <Button clicked={() => setShowSaved(false)} lighter title={'Close'}
                                icon={<img alt="" src={closeIconPurple} />}
                                style={{ height: '56px', width: '122px' }} />
                        </div>
                    </ModalR>

                    <Can permissions={[Permissions.UPDATE_COMPANY]}>
                        <Button style={{ marginTop: '30px' }} disabled={!canUpdateCompany} icon={<img alt="" src={checkIcon} />}
                            title={'Save'} clicked={onSave} />
                    </Can>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        onTutorialModalOpen: options => dispatch(tutorialModalOpen(options)),
    };
};

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCulture);
