import classNames from 'classnames';
import cls from './StyledInput.module.scss';
import { RenderTitle } from "../../TwoStatesFieldWrapper/FieldWrapper";
import { errorInfo, ArrowBlackUp } from "../../../../assets/images/new-icons";
import {Button} from "../../index";
import { NumericFormat } from 'react-number-format';

const ArrowDownIcon = (props) => {
    return (
        <svg width="10" height="6" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 1.5L6 6L10.5 1.5" stroke={props.value > 1 ? "#0B0B28" : "#D9D9D9"}
                strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

const ArrowUpIcon = (props) => {
    return (
        <svg width="10" height="6" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 1.5L6 6L10.5 1.5" stroke={props.value < 100 ? "#0B0B28" : "#D9D9D9"}
                strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const StyledInput = ({
    showHide = false, mandatory, withValueLimit=null, onShowHide, setMinWidth = false, counterBtnsContainer,isNumber, placeholder, value, className, autoComplete, handleIncrement, handleDecrement, name, tooltipText, label, onFocus, onClick, onChange, onKeyDown, onAddNewItem, disabled = false, onBlur, type, accept, hasError, error, extraClass, showAddButton = false, showCounterButton = false, autoFocus, isPercentage, tabIndex = 0
}) => {

    const paddingRight=()=>{
        return (showAddButton || showHide) ? '65px' : showCounterButton ? '28px' : '0px';
    };

    return (
        <>
            {label && <RenderTitle hasError={hasError} title={label} required={mandatory} tooltipText={tooltipText && tooltipText} setMinWidth = {setMinWidth}/>}
            <div className={classNames(cls.inputHostContainer, className)}>
                {isPercentage ?
                    <NumericFormat
                        tabIndex={tabIndex}
                        onBlur={onBlur}
                        className={classNames(cls.inputHost, extraClass)}
                        style={{paddingRight: paddingRight()}}
                        placeholder={placeholder}
                        onChange={(e)=>{onChange(e);}}
                        value={value}
                        suffix={'%'} />
                    :isNumber ?
                        <NumericFormat
                            tabIndex={tabIndex}
                            isAllowed={withValueLimit}
                            onBlur={onBlur}
                            className={classNames(cls.inputHost, extraClass)}
                            style={{paddingRight: paddingRight()}}
                            placeholder={placeholder}
                            onChange={(e)=>{onChange(e);}}
                            value={value} />
                        :
                        <input
                            tabIndex={tabIndex}
                            accept={accept}
                            autoComplete={autoComplete}
                            is-errored={hasError ? 'error' : ''}
                            onBlur={onBlur}
                            disabled={disabled}
                            onKeyDown={onKeyDown}
                            className={classNames(cls.inputHost, extraClass)}
                            style={{paddingRight: paddingRight()}}
                            onFocus={onFocus}
                            name={name}
                            type={type}
                            onClick={onClick}
                            placeholder={placeholder}
                            onChange={onChange}
                            value={value}
                            autoFocus={autoFocus}/>}
                {showAddButton && (
                    <div className={cls.inputAddButton}>
                        <Button title={'Save'} clicked={onAddNewItem}
                            disabled={value?.trim().length === 0}
                        />
                    </div>
                )}
                {showCounterButton && (
                    <div className={classNames(cls.counterButtons, counterBtnsContainer)}>
                        <button disabled={value >= 100} type='button' onClick={handleIncrement}>{<ArrowUpIcon value={value} />}</button>
                        <button disabled={value <= 1} type='button' onClick={handleDecrement}>{<ArrowDownIcon value={value} />}</button>
                    </div>
                )}
                {showHide && (
                    <div className={cls.inputAddButton}>
                        <p onClick={onShowHide}>
                            {showHide}
                        </p>
                    </div>
                )}
            </div>
            {error && <p className={cls.error}><span><img alt="" src={errorInfo} /></span>{error}</p>}
        </>
    );
};

export default StyledInput;
