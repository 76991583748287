//auth

export const SET_SESSION_SETTINGS = 'SET_SESSION_SETTINGS';
export const SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER';
export const SET_LAST_KNOWN_ROUTE = 'SET_LAST_KNOWN_ROUTE';
export const RESET_AUTH_REDUCER = 'RESET_AUTH_REDUCER';
//flowReducer
//--temporary flag--
export const SET_SERVER_FEATURE_NOT_READY_YET = 'SET_SERVER_FEATURE_NOT_READY_YET';
//--onboarding modals and org chart--
export const SET_COMPANY_POSITIONS_ARRAY = 'SET_COMPANY_POSITIONS_ARRAY';
export const SET_COMPANY_DATA = 'SET_COMPANY_DATA';
export const SET_COMPANY_MENU_SYNC = 'SET_COMPANY_MENU_SYNC';
export const SET_ACCOUNT_MENU_SYNC = 'SET_ACCOUNT_MENU_SYNC';
export const SET_GROUP_SYNC = 'SET_GROUP_SYNC';
export const SET_INVITE_COLLEAGUES_LIST = 'SET_INVITE_COLLEAGUES_LIST';
export const SET_COMPANY_ORG_CHART_DATA = 'SET_COMPANY_ORG_CHART_DATA';
export const SET_COMPANY_DEPARTMENTS = 'SET_COMPANY_DEPARTMENTS';
export const SET_POSITION_INFO = 'SET_POSITION_INFO';
export const SET_DUPLICATE_POSITION_INFO = 'SET_DUPLICATE_POSITION_INFO';
export const SET_ONBOARDING_COMPLETED = 'SET_ONBOARDING_COMPLETED';
export const SET_PAGED_MODAL_OPEN = 'SET_PAGED_MODAL_OPEN';
export const SET_PAGED_MODAL_CLOSE = 'SET_PAGED_MODAL_CLOSE';

//--groups--
export const SET_MY_GROUPS = 'SET_MY_GROUPS';
export const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP';
export const SET_GROUP_ORG_CHART_DATA = 'SET_GROUP_ORG_CHART_DATA';

//--tasks--
export const SET_TASKS = 'SET_TASKS';
export const SET_COMPLETED_TASKS = 'SET_COMPLETED_TASKS';
export const DELETE_TASK = 'DELETE_TASK';
export const ADD_NEW_TASK = 'ADD_NEW_TASK';
export const EDIT_TASK = 'EDIT_TASK';

//--processes--
export const SET_COMPANY_PROCESSES = 'SET_COMPANY_PROCESSES';
export const SET_SELECTED_PROCESS = 'SET_SELECTED_PROCESS';
export const SET_SELECTED_PROCESS_STEP = 'SET_SELECTED_PROCESS_STEP';
export const SET_SELECTED_PROCESS_STEP_OPTIONS = 'SET_SELECTED_PROCESS_STEP_OPTIONS';
export const SET_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT = 'SET_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT';
export const REMOVE_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT = 'REMOVE_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT';
export const RESET_SELECTED_PROCESS_STEP = 'RESET_SELECTED_PROCESS_STEP';
export const SET_BEGUN_PROCESS_TASK = 'SET_BEGUN_PROCESS_TASK';
export const CHANGE_TASK_PROCESS = 'CHANGE_TASK_PROCESS';

//--components--
export const SET_COMPONENTS = 'SET_COMPONENTS';
export const DELETE_COMPONENT = 'DELETE_COMPONENT';
export const ADD_COMPONENT = 'ADD_COMPONENT';
//--blueprints--
export const SET_BLUEPRINT_COMPONENTS = 'SET_BLUEPRINT_COMPONENTS';
export const SET_NEW_COMPONENT_BLUEPRINT = 'SET_NEW_COMPONENT_BLUEPRINT';
export const DELETE_COMPONENT_BLUEPRINT = 'DELETE_COMPONENT_BLUEPRINT';
export const SET_SELECTED_COMPONENT_BLUEPRINT = 'SET_SELECTED_COMPONENT_BLUEPRINT';
//--statutes--
export const SET_STATUTES = 'SET_STATUTES';
export const ADD_NEW_STATUS = 'ADD_NEW_STATUS';
//--company--
export const SET_USERS_ARRAY = 'SET_USERS_ARRAY';
export const SET_UNASSIGNED_USSERS_ARRAY = 'SET_UNASSIGNED_USSERS_ARRAY';
//--users--
export const DELETE_USER = 'DELETE_USER';
export const ACTIVATE_USER= 'ACTIVATE_USER';
export const UPDATE_USER = 'UPDATE_USER';

export const RESET_FLOW_REDUCER = 'RESET_FLOW_REDUCER';

//modals
export const SET_IS_ONBOARDING = 'SET_IS_ONBOARDING';
export const SET_ONBOARDING_VISIBLE = 'SET_ONBOARDING_VISIBLE';
export const SET_ONBOARDING_MODALS_VISIBILITY = 'SET_ONBOARDING_MODALS_VISIBILITY';
export const SET_PREVIOUS_MODAL_NAME = '';

export const ERRORS_MODAL_OPEN = 'ERRORS_MODAL_OPEN';
export const ERRORS_MODAL_CLOSE = 'ERRORS_MODAL_CLOSE';

export const INFO_MODAL_OPEN = 'INFO_MODAL_OPEN';
export const INFO_MODAL_CLOSE = 'INFO_MODAL_CLOSE';

export const PREVIEW_CHART_MODAL_OPEN = 'PREVIEW_CHART_MODAL_OPEN';
export const PREVIEW_CHART_MODAL_CLOSE = 'PREVIEW_CHART_MODAL_CLOSE';

export const TUTORIAL_MODAL_OPEN = 'TUTORIAL_MODAL_OPEN';
export const TUTORIAL_MODAL_CLOSE = 'TUTORIAL_MODAL_CLOSE';

export const RESET_MODALS_REDUCER = 'RESET_MODALS_REDUCER';

//Lexical Text Editor
export const SET_IMAGES_LIST = 'SET_IMAGES_LIST';

//countries list
export const SET_COUNTRIES_LIST = 'SET_COUNTRIES_LIST';

export const SET_CHART_PREVIEW = 'SET_CHART_PREVIEW';
