import classNames from 'classnames';
import moment from 'moment';
import { debounce } from 'lodash';
import 'rc-datetime-picker/dist/picker.css';
import {useCallback, useEffect, useRef, useState} from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { first } from 'rxjs/operators';
import { DatePickerComp, SelectD, SelectWithSearch, UnfocusableWrapper } from '..';
import { Calendar, closeIconPurple, Tooltip, UploadIcon } from '../../../assets/images/new-icons';
import { setCompanyData } from '../../../reduxStore/actions/flowActions';
import { getCompanyDetails,
    setCompanyLogo, getAllCompanyPositionsAsArray,
    removeUserFromPosition } from '../../../services/company.service';
import { setUserOnAPosition } from '../../../services/user.service';
import { industryFields, numberOfEmployees, uncachedUrl, durationOptions } from '../../../shared/utility';
import { Permissions, usePermissionContext } from '../../common';
import cls from './CompanyDataUpdate.module.scss';
import Loader from 'react-loader-spinner';
import { isSafari } from 'react-device-detect';
import ReactTooltip from 'react-tooltip';
import DatePicker from 'react-datepicker';
import {RenderTooltip, CustomCheckbox } from '../../common';
import {pat} from "../../../context/processStep.context";
import {RenderDurationInputWithUnitEntry} from "../TwoStatesFieldWrapper/FieldWrapper";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
export const CompanyDataUpdate = (props) => {
    const { settingsContainerClass, datePickerRC, feValidation, bypassTriggerValidation, setElementsTouched, sendCompanyDetails, isOnboarding = false, restrictClick } = props;
    const { companyData, companyPositionsArray } = useSelector(state => state.flowReducer);
    const { authenticatedUser } = useSelector(state => state.authReducer);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [position, setPosition] = useState(authenticatedUser?.positions.length > 0 ? authenticatedUser?.positions[0].id : null);
    const { isAuthorized } = usePermissionContext();
    const [canUpdateCompany, setCanUpdateCompany] = useState(false);
    const debouncePayloadRef = useRef({});
    const [showConfirmationModal , setShowConfirmationModal]= useState(false);

    useEffect( () => {
        isAuthorized(null, [Permissions.UPDATE_COMPANY]).then(updateCompany => setCanUpdateCompany(updateCompany));
    }, [isAuthorized]);

    const onDrop = useCallback(
        acceptedFiles =>
            acceptedFiles.forEach((file) => {
                setLogoError('');
                dispatch(setCompanyData({ logo: '' }));

                if (file?.type?.toString().indexOf('image/') > -1 && ['jpeg', 'jpg', 'png'].indexOf(file?.type?.toString().split('/')[1]) > -1) {
                    let reader = new FileReader();

                    reader.readAsDataURL(file);
                    reader.onloadend = (_e) => {
                        const formData = new FormData();

                        formData.append('logo', file);
                        setLoading(true);
                        setCompanyLogo(file)
                            .pipe(first())
                            .subscribe({
                                complete: () => {
                                    setLoading(false);
                                },
                                error: (error) => {
                                    setLogoError(error?.data);
                                    setLoading(false);
                                },
                            });
                    };
                }
            }),
        []
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    const [logoError, setLogoError] = useState('');
    const [compantDetails_apiError, setCompanyDetails_ApiError] = useState('');

    useEffect(() => {
        getCompanyDetails().pipe(first()).subscribe();

        getAllCompanyPositionsAsArray()
            .pipe(first())
            .subscribe({
                next: () => {
                    setPosition(authenticatedUser?.positions.length > 0 ? authenticatedUser?.positions[0].id : null);
                },
            });
    }, []);

    const [fieldsErrors, setFieldErrors] = useState({
        name: '',
        display_name: '',
        founding_date: '',
        employees_no: '',
        industry: '',
    });

    const settingFieldErors = useCallback(
        (val) => {
            !bypassTriggerValidation && setFieldErrors({ ...val });
        },
        [bypassTriggerValidation]
    );

    useEffect(() => {
        settingFieldErors({ ...feValidation });
    }, [feValidation]);
    const renderDropZone = useCallback( () => {
        return canUpdateCompany ? (
            <div className={cls.logoDragContainer} app-drag-active={isDragActive ? 'true' : 'false'} {...getRootProps()}>
                <input {...getInputProps()} className={cls.logoDragInput} type="file" multiple={false} accept="*,image/png, image/jpeg, image/jpg" />
                {isDragActive ? (
                    <div className={cls.logoContentContainer}>
                        <UploadIcon />
                        <p className={cls.logoUploadText}>{'Upload Logo'}</p>
                        {loading && <Loader type={isSafari ? 'Watch' : 'ThreeDots'} color={'var(--app-color-gray-dark)'} secondaryColor="lightgray" radius={32} height={32} width={`${32}px`} />}
                    </div>
                ) : (
                    <div className={cls.logoContentContainer}>
                        {companyData?.logo?.length > 2 ? (
                            <div style={{ display: 'flex' }}>
                                <img alt="lg" src={uncachedUrl(companyData?.logo)} className={cls.logoUrlImg} />
                            </div>
                        ) : (
                            <div className={cls.logoUploadPictureContainer}>
                                <UploadIcon className={cls.logoUploadIcon} />
                                <p className={cls.logoUploadPictureText}> {'Upload Logo'}</p>
                                {loading && <Loader type={isSafari ? 'Watch' : 'ThreeDots'} color={'var(--app-color-gray-dark)'} secondaryColor="lightgray" radius={32} height={32} width={`${32}px`} />}
                            </div>
                        )}
                    </div>
                )}
            </div>
        ) : (
            <div className={cls.logoDragContainer} app-drag-active={'false'}>
                <div className={cls.logoContentContainer}>
                    {companyData?.logo?.length > 2 ? (
                        <img alt="lg" src={uncachedUrl(companyData?.logo)} className={cls.logoUrlImg} />
                    ) : (
                        <div className={cls.logoUploadPictureContainer}>
                            <UploadIcon className={cls.logoUploadIcon} />
                            <p className={cls.logoUploadPictureText}> {'Upload Picture'}</p>
                        </div>
                    )}
                </div>
            </div>
        );
    }, [companyData, loading]);

    const debounceCompany = useCallback(debounce((restrict) => {
        sendCompanyDetails && sendCompanyDetails(debouncePayloadRef.current, restrict);
    }, 1000), []);

    const companyNameChangeHandler = (e) => {
        dispatch(setCompanyData({ name: e.target.value }));
    };
    const companyNameChangeUpdater = (e) => {
        if (e.target.value.length > 4) {
            sendCompanyDetails && sendCompanyDetails({ name: e.target.value });
        }
    };

    const companyDisplayNameChangeHandler = (e) => {
        dispatch(setCompanyData({ display_name: e.target.value }));
        debouncePayloadRef.current = { display_name: e.target.value };
        debounceCompany();
    };
    const foundingDateChangeHandler = (date) => {
        dispatch(setCompanyData({ founding_date: moment(date).format('YYYY-MM-DD') }));
        setElementsTouched(c => ({ ...c, founding_date: true }));
        sendCompanyDetails && sendCompanyDetails({ founding_date: moment(date).format('YYYY-MM-DD') });
    };
    const employeesChangeHandler = (e) => {
        e?.value?.length > 0 && dispatch(setCompanyData({ employees_no: e.value })); // onSetCompanyDataField({ employees_no: e.value });
        setElementsTouched(c => ({ ...c, employees_no: numberOfEmployees.some(i => i.value === e.value) }));
        sendCompanyDetails && sendCompanyDetails({ employees_no: e.value });
    };

    const industryChangeHandler = (e) => {
        dispatch(setCompanyData({ industry: e.target.value }));
        setElementsTouched(c => ({ ...c, industry: true }));
        sendCompanyDetails && sendCompanyDetails({ industry: e.target.value });
    };

    const globalEscalationChangeHandler = (settingUpdated)=>{
        dispatch(setCompanyData({settings: {...companyData.settings, ...settingUpdated}}));
        setElementsTouched(c => ({ ...c, settings: true }));
        debouncePayloadRef.current = {settings: {...companyData.settings, ...settingUpdated}};
        debounceCompany(true);
    };

    return (
        <div className={cls.companyDataWrapper}>
            <UnfocusableWrapper className={classNames(cls.companySettingsContainer, settingsContainerClass)} /* onClickOutside={onSaveCompanyData} */>
                <p className={cls.labeledContainerLabel}>Company Information</p>
                <div>
                    <LabeledField label={'Company Name'} tooltipText={'The name that will be displayed to all your organization’s stakeholders.'} error={fieldsErrors.name}>
                        <input
                            readOnly={!canUpdateCompany}
                            type="text"
                            name="comapny-name"
                            className={cls.inputField}
                            placeholder="Company Name"
                            value={companyData?.name ?? ''}
                            onChange={companyNameChangeHandler}
                            onFocus={() => {
                                setElementsTouched?.(c => ({ ...c, name: true }));
                            }}
                            onBlur={companyNameChangeUpdater}
                        />
                    </LabeledField>
                    <div style={{ display: 'flex', gap: '24px', marginTop: '15px' }}>
                        <div style={{ flex: 1 }}>
                            <LabeledField label={'Founding Date'}
                                tooltipText={'The date on which your organization was founded.'}
                                error={fieldsErrors.founding_date}>
                                { canUpdateCompany ? (
                                    datePickerRC ? (
                                        <DatePicker
                                            nonEditable={canUpdateCompany}
                                            value={companyData.founding_date ? moment(companyData.founding_date).format('Do of MMMM YYYY') : ''}
                                            selected={companyData.founding_date ? new Date(moment(companyData.founding_date, 'YYYY-MM-DD')) : null}
                                            onChange={foundingDateChangeHandler}
                                            customInput={
                                                <div id={'custom-input'} className={cls.inputField} style={{ display: 'flex' }}>
                                                    <input readOnly={true} type="text" name="input-founding_date" className={cls.datepickerInputField} value={companyData.founding_date ? moment(companyData.founding_date).format('Do of MMMM YYYY') : ''} />
                                                    <Calendar className={cls.calendarSVG} />
                                                </div>
                                            }
                                            disabledKeyboardNavigation
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={90}
                                            maxDate={moment().toDate()}
                                        />
                                    ) : (
                                        <DatePickerComp
                                            nonEditable={canUpdateCompany}
                                            value={companyData.founding_date ? moment(companyData.founding_date).format('Do of MMMM YYYY') : ''}
                                            selected={companyData.founding_date ? new Date(moment(companyData.founding_date, 'YYYY-MM-DD')) : null}
                                            dateChangeHandler={foundingDateChangeHandler}
                                            inputContainerClassName={cls.foundingDateInputContainer}
                                            inputClassName={cls.inputField}
                                            inputStyle={{
                                                textAlign: companyData.founding_date?.length > 0 ? 'left' : 'center',
                                                border: 'none',
                                                height: 'auto',
                                            }}
                                            hasCalendarIcon
                                            placeholder={'Due Date'}
                                            disabledKeyboardNavigation
                                        />
                                    )
                                ) : (
                                    <input readOnly={true} type="text" name="input-founding_date" className={cls.inputField} value={moment(companyData.founding_date ?? moment()).format('Do of MMMM YYYY') ?? ''} />
                                )}
                            </LabeledField>
                        </div>
                        <div style={{ flex: 1 }}>
                            <LabeledField label={'Number of Colleagues'} tooltipText={'The approximate total number of colleagues and contractors the organization currently has. (This is just informational and does not affect billing.)'} error={fieldsErrors.employees_no}>
                                {canUpdateCompany ? (
                                    <SelectD
                                        title=""
                                        placeholder="Nr. of Colleagues"
                                        tmiClassName={cls.selectText}
                                        molclassname={cls.employeesMOL}
                                        className={cls.inputField}
                                        onChange={employeesChangeHandler}
                                        items={numberOfEmployees}
                                        defaultSelected={numberOfEmployees.find(i => i.value === companyData.employees_no)}
                                    />
                                ) : (
                                    <input readOnly={true} type="text" name="input-number-of-employes" className={cls.inputField} value={numberOfEmployees.find(i => i.value === companyData.employees_no)?.label ?? ''} />
                                )}
                            </LabeledField>
                        </div>
                    </div>
                    <div style={{ marginTop: '15px' }}>
                        <div style={{ flex: 1 }}>
                            <LabeledField label={'Industry'} tooltipText={'The industry that best describes the type of work your organization does.'} error={fieldsErrors.industry}>
                                {canUpdateCompany ? (
                                    <SelectWithSearch
                                        id={'industry-selection'}
                                        triggerType={'chevron'}
                                        itemType={'no-item-icons'}
                                        className={cls.inputField}
                                        inputClassName={cls.selectText}
                                        molclassname={cls.industryMOL}
                                        moliclassname={cls.industryMOLI}
                                        title=""
                                        placeholder={'Select from here...'}
                                        onChange={(val) => {
                                            industryChangeHandler({ target: { value: val.label } });
                                        }}
                                        defaultSelectedItem={industryFields?.find(i => i.label == companyData.industry)}
                                        items={industryFields}
                                    />
                                ) : (
                                    <input readOnly={true} type="text" name="input-number-of-employes" className={cls.inputField} value={industryFields?.find(i => i.label == companyData.industry)?.label ?? ''} />
                                )}
                            </LabeledField>
                        </div>
                    </div>
                    <div style={{ marginTop: '15px', display: isOnboarding === true ? '' : 'none' }}>
                        <div style={{ flex: 1 }}>
                            <LabeledField label={`Your Position, ${authenticatedUser?.first_name}`}
                                tooltipText={'Your position within the organization. (If don’t ' +
                                              'hold any of the pre-defined positions, you can create your position at a later step).'}
                                error={fieldsErrors.position}>
                                <SelectWithSearch
                                    isClearable
                                    id={'industry-selection'}
                                    triggerType={'chevron'}
                                    itemType={'no-item-icons'}
                                    className={cls.inputField}
                                    inputClassName={cls.selectText}
                                    molclassname={cls.industryMOL}
                                    moliclassname={cls.industryMOLI}
                                    title=""
                                    placeholder={'Select from here...'}
                                    onChange={(val) => {
                                        val && setPosition(val.value);
                                        val ? val.value !== '0' && setUserOnAPosition({ id: val.value }).pipe(first()).subscribe({}) : removeUserFromPosition(position).pipe(first()).subscribe({});
                                    }}
                                    defaultSelectedItem={position ? companyPositionsArray.find(pos => pos.value === position) : null}
                                    items={companyPositionsArray}
                                />
                            </LabeledField>
                        </div>
                    </div>

                    <div className={classNames(cls.globalEscalationWrapper, restrictClick && cls.restrictClick)}>
                        <LabeledField
                            label={'Global Escalation Settings'}
                            tooltipText={'Global escalation settings to send notification to manager and manager of manger'}
                            labelStyle={{padding: '5px 0px'}}
                        />
                        <div disabled={true}  className={classNames(cls.globalEscalationSettings, companyData?.settings?.is_using_global_escallation && cls.globalEscalationSettingsDisabled )}>
                            <div className={cls.checkbox}>
                                <div>
                                    <CustomCheckbox
                                        label='Escalate overdue task to manager after'
                                        onChange={(val) => {
                                            globalEscalationChangeHandler({'escalate_to_manager': val ? 12 : 0});
                                        }}
                                        defaultChecked={companyData?.settings?.escalate_to_manager ? true : false}
                                        checkMarkStyle={{ width: "24px", height: "24px",  ...(companyData?.settings?.is_using_global_escallation && {backgroundColor: '#0B0B28'}) }} // here use Global escalation fal
                                    />
                                </div>
                                <div className={cls.timeWrapper}>
                                    <div className={classNames(cls.secondSelectContainer, !companyData?.settings?.escalate_to_manager && cls.disabledField)}>
                                        <RenderDurationInputWithUnitEntry
                                            seconds={companyData?.settings?.escalate_to_manager > 0 ? companyData?.settings?.escalate_to_manager : 0}
                                            defaultSeconds={12}
                                            defaultUnit={companyData?.settings?.escalate_to_manager_unit ?? 'hours'}
                                            selectMenuOverlay={cls.smoMultiselectDuration}
                                            unitSectionClassName={cls.unitSection}
                                            unitInputClassName={cls.unitInput}
                                            onTextChanged={(duration, duration_unit) => {
                                                globalEscalationChangeHandler({
                                                    'escalate_to_manager': duration,
                                                    'escalate_to_manager_unit': duration_unit,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={cls.checkbox}>
                                <div><CustomCheckbox
                                    label='Escalate overdue task to manager of manager'
                                    onChange={(val) => {
                                        globalEscalationChangeHandler({'escalate_to_manager_of_manager': val ? 12 : 0});
                                    }}
                                    defaultChecked={companyData?.settings?.escalate_to_manager_of_manager ? true : false}
                                    checkMarkStyle={{ width: "24px", height: "24px",  ...(companyData?.settings?.is_using_global_escallation && {backgroundColor: '#0B0B28'}) }}// here use Global escalation fal
                                /></div>
                                <div className={cls.timeWrapper}>
                                    <div  className={classNames(cls.secondSelectContainer, !companyData?.settings?.escalate_to_manager_of_manager && cls.disabledField)}>
                                        <RenderDurationInputWithUnitEntry
                                            seconds={companyData?.settings?.escalate_to_manager_of_manager > 0 ? companyData?.settings?.escalate_to_manager_of_manager : 0}
                                            defaultSeconds={12}
                                            defaultUnit={companyData?.settings?.escalate_to_manager_of_manager_unit ?? 'hours'}
                                            selectMenuOverlay={cls.smoMultiselectDuration}
                                            unitSectionClassName={cls.unitSection}
                                            unitInputClassName={cls.unitInput}
                                            onTextChanged={(duration, duration_unit) => {
                                                globalEscalationChangeHandler({
                                                    'escalate_to_manager_of_manager': duration,
                                                    'escalate_to_manager_of_manager_unit': duration_unit,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={cls.checkbox}>
                                <div><CustomCheckbox
                                    label='Allow managers to change escalation settings'
                                    onChange={(val) => {
                                        globalEscalationChangeHandler({'allow_manager_change_settings': val});
                                    }}
                                    defaultChecked={companyData?.settings?.allow_manager_change_settings}
                                    checkMarkStyle={{ width: "24px", height: "24px",  ...(companyData?.settings?.is_using_global_escallation && {backgroundColor: '#0B0B28'}) }} // here use Global escalation fal
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>{compantDetails_apiError?.length > 0 && <p className={cls.errorGeneralText}>{compantDetails_apiError} </p>}</div>
                </div>
            </UnfocusableWrapper>
            <div>
                <p className={cls.labeledContainerLabel}>Company’s Logo </p>
                {renderDropZone()}
                {logoError?.length > 0 && (
                    <p className={cls.logoErrorText} style={{ width: '150px' }}>
                        {logoError}
                    </p>
                )}
            </div>
            <div style={{ textAlign: 'end', display: 'inline-block', marginTop: '50px' }}>
                {companyData?.logo?.length > 2 && (
                    <img
                        src={closeIconPurple}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setShowConfirmationModal(true);
                        }}
                    />
                )}
            </div>
            <ConfirmationModal resultsMessage="Are you sure you want to remove Company's logo?"
                isOpen={showConfirmationModal}
                isYes={()=>{
                    sendCompanyDetails({ logo: '' });
                    dispatch(setCompanyData({ logo: '' }));
                    setShowConfirmationModal(false);
                }}
                isNo={()=>{
                    setShowConfirmationModal(false);
                }
                }
            />
        </div>
    );
};

const LabeledField = (props) => {
    const { label, error, children, tooltipText, labelStyle } = props;
    const randomID = String(Math.random());

    return (
        <div className={cls.labeledFieldHost}>
            <div style={{ display: 'flex', justifyContent: 'space-between', ...labelStyle }}>
                <label className={cls.labeledFieldLabel}>{label}</label>
                <div data-tip={tooltipText} data-for={randomID}>
                    <Tooltip />
                    <ReactTooltip className={classNames(cls.showTooltip)} id={randomID} multiline effect={'float'} type={'dark'} clickable={true} place={'left'} />
                </div>
            </div>
            {children}
            {error?.length > 0 && <p className={cls.labeledFieldError}>{error}</p>}
        </div>
    );
};
