import {useSelect} from 'downshift';
import React, {ComponentProps, ForwardedRef, forwardRef, ReactNode, useEffect, useRef} from 'react';
import classNames from 'classnames';
import {ErrorMessage, Label, ToggleMenuButton, MenuOverlayListItem, MenuOverlayList} from './SelectComponents';
import {XSign} from '../../../../assets/images/icons';
import cls from './Select.module.scss';
import Loader from 'react-loader-spinner';
import {isSafari} from 'react-device-detect';
import {isEqual} from "lodash";

type Item = { value: string; label: string; icon?: SVGElement };
export type SelectProps = {
    defaultSelected: any;
    /** list of items to chose from */
    items: Item[];
    /**displayed as label above Control */
    title: string;
    placeholder?: string;
    /** sets an id to the related elements as required by aria listbox rules. Falls back to `title` if none is provided */
    id?: string;
    /** sets clearing button when selected element is present */
    isClearable: boolean;
    /** adds a "*"-Indicator to the end of the title that turns red in case of an error*/
    hasMandatoryIndicator?: boolean;
    errorMessage?: string;
    onChange?: (selectedItem?: Item | null) => void;
    triggerType?: 'none' | 'chevron' | 'downArrow' | 'eyeglass';
    toggleLeftIcon?: string;
    hasToggleIcon?: boolean;
    selectType?: 'checkboxes' | 'radio' | 'normal';
    iconValue?: boolean;
    menuButtonPlaceholder: string;
    /** prop to pass className to toggle menu input button */
    tmiClassName: string;
    /** prop to pass className to MenuOverlayList */
    molclassname: string;
    /** prop to pass className to MenuOverlayListItem */
    moliclassname: string;
    /** Props to change rotation of chevron arrow */
    closeVectorRotation?: string;
    openVectorRotation?: string;
    checkboxEmplacement?: string;
    error: boolean;
    loading: boolean;
    isDisabled: boolean;
    noDataMessage?: string;
    SelectAddItemComp?: ReactNode;
    isNotSearchable?: boolean;
} & Omit<ComponentProps<'div'>, 'onChange'>;
/**Allows **single selection** via a dropdown **withoutSearch**
 * If you look for a dropdown with Search functionallity consider looking at `SelectWithSearch`.
 * If you look for a multiple Selection Properties use 'Multiselect' or 'MultiSelectWithSearch'
 * intended to be used like this:
 * ```jsx
 * <Select
 *    title="destination"
 *    placeholder="chose a country"
 *    hasMandatoryIndicator,iconValue
 *    onChange={newCountry=>setCountry(newCountry.value)}
 *    items={[
 *      {value:'ro', label:'🇷🇴 Romania'},
 *      {value:'ch', label:'🇨🇭 Schweiz'},
 *      {value:'de', label:'🇩🇪 Deutschland'},
 *    ]}
 *  />
 * ```
 */
const Select = forwardRef<HTMLDivElement, SelectProps>((props: SelectProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
        items,
        title,
        defaultSelected,
        isClearable,
        hasToggleIcon,
        triggerType,
        id = title?.replace(/ /g, '-'),
        placeholder,
        hasMandatoryIndicator,
        errorMessage,
        checkboxEmplacement,
        selectType,
        onChange,
        className,
        iconValue,
        tmiClassName,
        closeVectorRotation,
        openVectorRotation,
        molclassname,
        moliclassname,
        isDisabled,
        isNotSearchable,
        ...rest
    } = props;

    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
        selectedItem,
        selectItem,
    } = useSelect({
        items,
        id,
        defaultSelectedItem: defaultSelected,
        ...(isNotSearchable ? {} : { itemToString: item => item?.label ?? '' }),
        onSelectedItemChange: event => onChange?.(event.selectedItem),
    });

    return (
        <div ref={ref} className={classNames(cls.host, className)} {...rest}>
            <Label {...getLabelProps()} hasMandatoryIndicator={hasMandatoryIndicator}>
                {title}
            </Label>
            <ToggleMenuButton
                className={classNames(cls.toggleMenu, tmiClassName)}
                {...getToggleButtonProps()}
                hasToggleIcon={hasToggleIcon}
                triggerType={triggerType}
                isOpen={isOpen}
                closeVectorRotation={closeVectorRotation}
                openVectorRotation={openVectorRotation}
                disabled={isDisabled}
                extraControl={
                    isClearable &&
                    selectedItem?.label && (
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                selectItem(null);
                            }}
                        >
                            <XSign className={cls.toggleMenuControlsIcons} />
                        </div>
                    )
                }
            >
                {iconValue && defaultSelected?.length > 0 ?
                    <img alt={''} style={{marginRight: 'auto', width: '18px', height: '18px'}}
                        src={defaultSelected}/> : (selectedItem?.label?.length > 0 || selectedItem?.name?.length > 0) ? (selectedItem.label || selectedItem.name) :
                        <p style={{color: "#858593"}}>{placeholder}</p>
                }
            </ToggleMenuButton>
            <MenuOverlayList {...getMenuProps()} className={classNames(cls.menuOverlayList, molclassname)}>
                {isOpen &&
                    items?.map((item, index) => (
                        <MenuOverlayListItem
                            isVisible={true}
                            className={classNames(cls.menuOverlayListItem, moliclassname)}
                            key={`${item}${index}`}
                            itemType={selectType}
                            checkboxEmplacement={checkboxEmplacement}
                            isHighlighted={highlightedIndex === index}
                            isSelected={selectedItem == item}
                            {...getItemProps({item, index})}
                            style={{
                                cursor: 'pointer',
                                color: 'var(--app-color-gray-light)',
                                backgroundColor: selectedItem == item ? 'var(--app-color-gray-lighter)' : highlightedIndex === index ? '#F1F1F1' : 'transparent',
                            }}
                        >
                            <div className={cls.itemContainer}>
                                <span className={cls.itemIcon}>{item?.icon && item.icon}</span> {item.label}
                            </div>
                        </MenuOverlayListItem>
                    ))}
            </MenuOverlayList>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
    );
});

export default Select;

export const SelectR = forwardRef<HTMLDivElement, SelectProps>((props: SelectProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
        items,
        title,
        defaultSelected,
        isClearable,
        id = title?.replace(/ /g, '-'),
        placeholder,
        hasMandatoryIndicator,
        errorMessage,
        onChange,
        className,
        iconValue,
        selectType,
        tmiClassName,
        molclassname,
        moliclassname,
        toggleLeftIcon,
        menuButtonPlaceholder,
        error,
        loading,
        noDataMessage,
        SelectAddItemComp,
        ...rest
    } = props;
    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
        selectedItem,
        selectItem,
    } = useSelect({
        items,
        id,
        initialSelectedItem: defaultSelected,
        itemToString: item => item?.label ?? '',
        onSelectedItemChange: event => onChange?.(event.selectedItem),
    });
    const selectedValuesRef = useRef();

    useEffect(() => {
        // to avoid unnecessary rendering used this logic
        if (defaultSelected && !isEqual(selectedValuesRef.current, defaultSelected)){
            selectItem(defaultSelected);
            selectedValuesRef.current = defaultSelected;
        }
    }, [defaultSelected]);

    return (
        <div ref={ref} className={classNames(cls.host, className)} {...rest}
            app-variant-has-error={error ? 'error' : ''}>
            {title && (
                <Label {...getLabelProps()} hasMandatoryIndicator={hasMandatoryIndicator}>
                    {title}
                </Label>
            )}
            <ToggleMenuButton
                className={classNames(cls.toggleMenu, tmiClassName)}
                {...getToggleButtonProps()}
                isOpen={isOpen}
                extraControl={
                    isClearable &&
                    selectedItem?.label && (
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                selectItem(null);
                            }}
                        >
                            <XSign className={cls.toggleMenuControlsIcons}/>
                        </div>
                    )
                }
            >
                {toggleLeftIcon && (
                    <div className={cls.toggleLeftIconContainer}>
                        <img alt={''} src={toggleLeftIcon}/>
                    </div>
                )}
                {iconValue && defaultSelected?.length > 0 ? (
                    <img alt={''} style={{marginRight: 'auto', width: '18px', height: '18px'}} src={defaultSelected}/>
                ) : selectedItem?.label?.length > 0 ? (
                    <p className={menuButtonPlaceholder}>{selectedItem?.label}</p>
                ) : (
                    <p className={menuButtonPlaceholder}>{placeholder}</p>
                )}
            </ToggleMenuButton>
            <MenuOverlayList style={{display: isOpen ? '' : 'none'}} {...getMenuProps()}
                className={classNames(cls.menuOverlayList, molclassname)}>
                {isOpen &&
                    (loading ? (
                        <Loader type={isSafari ? 'Watch' : 'ThreeDots'} color={'var(--app-color-gray-dark)'} secondaryColor="lightgray" radius={22}
                            height={22} width={'22px'}/>
                    ) : (<>
                        {noDataMessage ? <div className={cls.noDataStyle}>{noDataMessage}</div> : items?.map((item, index) => (
                            <MenuOverlayListItem
                                className={classNames(cls.menuOverlayListItem, moliclassname)}
                                key={`${item}${index}`}
                                /*simple={true}*/
                                isVisible={true}
                                itemType={selectType}
                                isHighlighted={highlightedIndex === index}
                                isSelected={JSON.stringify(selectedItem) === JSON.stringify(item)}
                                {...getItemProps({item, index})}
                                style={{
                                    cursor: 'pointer',
                                    color: 'var(--app-color-gray-light)',
                                    backgroundColor: selectedItem == item ? '#F2F2F2' : highlightedIndex === index ? '#F2F2F2' : 'transparent',
                                }}
                            >
                                <div className={cls.itemContainer}>
                                    {item?.icon && <span
                                        className={cls.itemIcon}>{item?.icon && item.icon}</span>} {item.label}
                                </div>
                            </MenuOverlayListItem>
                        ))}
                    </>
                    ))
                }
                {SelectAddItemComp && (
                    <div style={{ position: 'sticky', bottom: 0, backgroundColor: 'white' }}>
                        {React.cloneElement(SelectAddItemComp, { isOpen: isOpen })}
                    </div>
                )}
            </MenuOverlayList>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
    );
});
