import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { closeIcon } from '../../../assets/images/new-icons';
import { setCompanyData } from '../../../reduxStore/actions/flowActions';
import { ModalR } from '../../common';
import CompanyOrganisationalChart from '../CompanyOrganisationalChart/CompanyOrganisationalChart';
import cls from './XII_PreviewOrganisationalChart.module.scss';
const PanZoomComponent = React.lazy(() => import('../../common/PanZoomComponent/PanZoomComponent'));

const PreviewOrganisationalChart = (props) => {
    const { closeButtonAction, onCancel } = props;
    const [showTutorial, setShowTutorial] = useState(false);

    return props.modalVisible ? (
        <>
            <ModalR
                modalWrapperStyle={props?.modalWrapperStyle}
                shadowedContainerStyle={props.shadowedContainerStyle}
                scrollable={true}
                backdropStyle={{ height: props.backdropStyle ? props.backdropStyle : '1000px' }}
                isOpen={props.modalVisible}
                modalClosed={() => props.onCancel()}
                blured={props.blured}
            >
                <div className={cls.closeContainer}>
                    <div onClick={onCancel} className={cls.iconContainer}>
                        <img src={closeIcon} className={cls.img} alt="close" />
                    </div>
                </div>

                <div className={cls.mainContainer}>
                    <div className={cls.innerContainer}>
                        <div className={cls.titleContainer}>
                            <p>
                                Organizational
                                <br />
                                Chart Preview
                            </p>
                        </div>
                        <div className={cls.transformWrapper}>
                            <PanZoomComponent zoomStep={1} controlsContainerClass={cls.panZoomControlsContainer} panZoomProps={{ autoCenterZoomLevel: 0.65, className: cls.panZoomComponent, style: { cursor: 'grab' } }}>
                                <CompanyOrganisationalChart preview />
                            </PanZoomComponent>
                        </div>
                        <div className={cls.footerWrapper}>
                            <div
                                className={cls.editChartButtonContainer}
                                onClick={() => {
                                    onCancel();
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </ModalR>
            {showTutorial && (
                <div
                    onClick={() => {
                        setShowTutorial(false);
                    }}
                    style={{
                        zIndex: 500,
                        cursor: 'grab',
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        bottom: '0',
                        left: '0',
                        backgroundColor: '#4a4a4a75',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <ReactPlayer width="648px" height="354px"
                        url={'https://www.youtube.com/watch?v=PLAij3pKzjg'} style={{ minHeight: '550px', borderRadius: '16px', overflow: 'hidden' }} />
                </div>
            )}
        </>
    ) : null;
};

const mapStateToProps = (store) => {
    const { inviteColleguesList, companyData } = store.flowReducer;
    const { isOnboarding } = store.modalsReducer;

    return { inviteColleguesList, companyData, isOnboarding };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onSetCompanyDataField: field => dispatch(setCompanyData(field)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewOrganisationalChart);
