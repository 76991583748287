import { useCallback, useEffect, useRef, useState } from 'react';
import ResizeObserver from 'react-resize-observer';
import './CustomCheckbox.scss';
import { Checkmark } from '../../../../assets/images/new-icons';
import { RenderTooltip } from '../../TwoStatesFieldWrapper/FieldWrapper';

const CustomCheckbox = (props) => {
    const { defaultChecked, label, onChange, tooltipText, proportioned, checkIconStyle,
        mainContainerStyle, checkMarkStyle, checkboxMarkContainerStyle, labelStyle, disabled = false, ...rest } = props;
    const [checked, setChecked] = useState(defaultChecked);
    const [labelHeight, setLabelHeight] = useState(32);
    const [resizedParHeight, setResizedParHeight] = useState(32);
    const labelRef = useRef();
    const settingLabelHeight = val => setLabelHeight(val);

    useEffect(() => {
        if (labelRef.current.clientHeight < 28) {
            settingLabelHeight(32);
        }
        if (labelRef.current.clientHeight < 51) {
            settingLabelHeight(labelRef.current.clientHeight);
        }
    }, [resizedParHeight]);

    useEffect(() => {
        if ([true, false, undefined].indexOf(!!defaultChecked) > -1 && defaultChecked != checked) {
            setChecked(!!defaultChecked);
        }
    }, [defaultChecked]);

    const clickHandler = (e) => {
        // Check if the click occurred on an anchor inside the label
        const isLinkClicked = e?.target?.tagName?.toLowerCase() === 'a';

        if (!isLinkClicked) {
            // If it's not a link, proceed with checkbox action
            e.preventDefault();
            e.stopPropagation();
            if (!disabled) {
                setChecked(c => !c);
                if (onChange) onChange(!checked);
            }
        }
    };

    const containerClicked = (e) => {
        clickHandler(e);
    };

    const labelClicked = (e) => {
        clickHandler(e);
    };

    const bgColor = disabled ? 'var(--app-color-gray-light)' : checked ? checkMarkStyle.backgroundColor ?? 'var(--alvanda-purple)' : 'white';

    return (
        <div style={{position: "relative"}}>
            {disabled && <div className='disbaled-container'/>}
            <div className={'checkboxMainContainer'}
                style={{ ...mainContainerStyle, overflow: 'visible' }} onClick={e => containerClicked(e)} {...rest}>
                <div
                    data-event={props.dataEvent}
                    data-tip={props.dataTip}
                    className={'checkMarkContainer'}
                    style={{
                        ...checkMarkStyle,
                        backgroundColor: checked ? checkMarkStyle.backgroundColor ?? 'var(--alvanda-purple)' : 'white',
                        height: proportioned ? labelHeight : checkMarkStyle?.height ?? '32px',
                        width: proportioned ? labelHeight : checkMarkStyle?.width ?? '32px',
                        minWidth: proportioned ? labelHeight : checkMarkStyle?.width ?? '32px',
                        border: checked ? '1px solid var(--alvanda-purple)' : '1px solid var(--app-color-gray-lighter)',
                        ...checkboxMarkContainerStyle,
                    }}
                >
                    {checked && <Checkmark style={checkIconStyle} />}
                </div>
                <div className={'labelContainer'} onClick={e => labelClicked(e)}>
                    <ResizeObserver
                        onResize={(rect) => {
                            setResizedParHeight(rect.height);
                        }}
                    />
                    <p ref={labelRef} className={'label'} style={{ ...labelStyle }}>
                        {label}
                    </p>
                </div>
                {tooltipText?.length > 0 && <RenderTooltip tooltipText={tooltipText} />}
            </div>
        </div>
    );
};

export default CustomCheckbox;
