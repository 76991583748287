import { Observable, forkJoin } from 'rxjs';
import { editTaskElement, setCompletedTasks, setProcessTask, setTasks, taskDelete } from '../reduxStore/actions/flowActions';
import { store } from '../reduxStore/store';
import { URLS } from './API_CONSTANTS';
import { axiosInstance } from './axiosInstance';
export const getAllTasks = (userId) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.tasks.getAllTasks(last_access_token, userId),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data && response?.data?.data) {
                    const tasks = response?.data?.data;

                    store.dispatch(setTasks(tasks));
                    subscriber.next(tasks);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const getTaskById = (taskId, step_id) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    let config = {
        ...URLS.tasks.fetchTaskById(last_access_token, taskId),
        handlerEnabled: true,
    };

    if (step_id) {
        config.params = { step_id };
    }

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data && response?.data?.data) {
                    const tasks = response?.data?.data;

                    subscriber.next(tasks);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const getTasksByIds = (taskIds) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const observables = taskIds.map((taskId) => {
        const config = {
            ...URLS.tasks.fetchTaskById(last_access_token, taskId),
            handlerEnabled: true,
        };

        return new Observable((subscriber) => {
            axiosInstance(config)
                .then((response) => {
                    if (response?.status === 200 && response?.data && response?.data?.data) {
                        const tasks = response?.data?.data;

                        subscriber.next(tasks);
                        subscriber.complete();
                    }
                })
                .catch((error) => {
                    subscriber.error(error);
                });
        });
    });

    return new Observable((subscriber) => {
        forkJoin(observables)
            .subscribe({
                next: (responses) => {
                    subscriber.next(responses);
                    subscriber.complete();
                },
                error: (error) => {
                    subscriber.error(error);
                },
            });
    });
};


export const handoverTask = (taskData, taskId) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = new FormData();

    data.append('description', taskData.description);
    data.append('due_date', taskData.due_date);
    data.append('assignee_id', taskData.assignee_id);
    data.append('procedure_pathway_id', taskData.procedure_pathway_id);
    const config = {
        ...URLS.tasks.handoverTask(last_access_token, taskId),
        data: data,
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data && response?.data?.data) {
                    subscriber.next();
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const getAllCompletedTasks = (page, userId) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.tasks.getAllCompletedTasks(last_access_token, page, userId),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data && response?.data?.data) {
                    const tasks = response?.data;

                    store.dispatch(setCompletedTasks(tasks, page));
                    subscriber.next(tasks);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const getAllCompletedTasksPaginated = (page, userId, per_page, sort = { type: "completed_at", order: "desc" }) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.tasks.getAllCompletedTasks(last_access_token, page, userId, per_page, sort),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data) {
                    subscriber.next(response?.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const deleteTask = (taskId) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = new FormData();
    const defaultConfig = { ...URLS.tasks.deleteTask(last_access_token, taskId) };
    const config = {
        ...defaultConfig,
        headers: { ...defaultConfig.headers },
        data: data,
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data) {
                    store.dispatch(taskDelete(taskId));
                    subscriber.next(response.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const createTask = (taskData) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const config = {
        ...URLS.tasks.createTask(last_access_token),
        data: taskData,
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data && response?.data?.data) {
                    const task = response?.data?.data;

                    store.dispatch(setProcessTask(task, false));
                    subscriber.next(task);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const startTask = (taskId) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.tasks.startTaskById(last_access_token, taskId),
        data: taskId,
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data && response?.data?.data) {
                    const task = response?.data?.data;

                    store.dispatch(setProcessTask(task, true));
                    subscriber.next();
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const pauseTask = (taskId, step_id) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.tasks.pauseTaskById(last_access_token, taskId),
        data: {
            step_id,
        },
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data && response?.data?.data) {
                    const time_log = response?.data?.data;

                    subscriber.next(time_log);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const resumeTask = (taskId, step_id) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.tasks.resumeTaskById(last_access_token, taskId),
        data: {
            step_id,
        },
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data && response?.data?.data) {
                    const time_log = response?.data?.data;

                    subscriber.next(time_log);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const attachComponentsToTask = (task_id, procedure_step_id, component_id, is_additional = false) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const config = {
        ...URLS.tasks.attachComponentsToTask(last_access_token, task_id),
        data: {id: component_id, is_additional, procedure_step_id}, // By default is_additional is true if not sent in BE
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data && response?.data?.data) {
                    const data = response?.data?.data;

                    subscriber.next(data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const detachComponentsToTask = (task_id, components) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const config = {
        ...URLS.tasks.detachComponentsToTask(last_access_token, task_id),
        data: {components: components},
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data && response?.data?.data) {
                    const data = response?.data?.data;

                    subscriber.next(data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const completeTask = (taskId, step_id) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.tasks.endTaskById(last_access_token, taskId),
        data: {
            step_id,
        },
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {

                store.dispatch(taskDelete(taskId));
                subscriber.next();
                subscriber.complete();
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const editTask = (taskData, taskId) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const config = {
        ...URLS.tasks.editTask(last_access_token, taskId),
        data: taskData,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data && response?.data?.data) {
                    const pos = response?.data?.data;

                    store.dispatch(taskDelete(taskId));
                    store.dispatch(editTaskElement(pos));
                    subscriber.next(pos);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const getTaskStepsComponent = (taskId, processId, stepId) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = new FormData();

    stepId && data.append('step_id', stepId);
    processId && data.append('process_id', processId);

    const config = {
        data: data,
        ...URLS.tasks.getStepComponents(last_access_token, taskId),
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data) {
                    subscriber.next(response.data.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const getTaskInheritedComponents = (taskId, processId) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = new FormData();

    data.append('process_id', processId);

    const config = {
        data: data,
        ...URLS.tasks.getInheritedComponents(last_access_token, taskId),
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data) {
                    subscriber.next(response.data.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
