import { ComponentProps, forwardRef } from 'react';
import { ui } from '../../../../assets/images/icons/index';

export type TagProps = {
	/** shows an **x** close indicator at the end of if `true` */
	hasCloseButton?: boolean;
} & ComponentProps<'div'>;

const Tag = forwardRef<HTMLDivElement, TagProps>((props, ref) => {
    const { children, style = {}, className = '', hasCloseButton, ...rest } = props;

    return (
        <div
            ref={ref}
            tabIndex={hasCloseButton ? 0 : undefined}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '10px 8px 10px 8px',
                fontSize: '15px',
                lineHeight: '1.25em',
                boxShadow: '0 3px 10px 0 #0000001a',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                borderRadius: '5px',
                ...style,
            }}
            className={className || 'tag-container'}
            {...rest}
        >
            {children}
            {hasCloseButton && <img alt="" src={ui} style={{ marginLeft: '10px', width: '12px', height: '12px' }} />}
        </div>
    );
});

export default Tag;
