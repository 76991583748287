import { memo, useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import './chartStyles.scss';
import cls from './III_NonManagerials.module.scss';

const NonManagerialRenders = memo((props) => {
    const nmg_mainCont_III_Ref = useRef();
    const [colDim] = useState({ colWidth: 0, colHeight: 0 });
    const { onContNMan_III_ChildChange, nmPositions: data, showPositionInfo } = props;
    const {chartPreview} = useSelector(store => store.modalsReducer);
    useEffect(() => {
        if (onContNMan_III_ChildChange) {
            onContNMan_III_ChildChange({
                width: nmg_mainCont_III_Ref.current?.clientWidth,
                // height: colContainerRef.current?.clientHeight,
                offsetLeft: nmg_mainCont_III_Ref.current?.offsetLeft,
                // offsetParent: colContainerRef.current?.offsetParent,
            });
        }
    }, [colDim]);
    if (data?.children?.length == 0) return null;

    return (
        <div ref={nmg_mainCont_III_Ref} className={cls.host}>
            {data?.children?.length > 0 ? (
                <div className={cls.target}>
                    {data?.children.map((i, idx) => {
                        return (
                            <div
                                className={cls.cardContainerBase}
                                app-last-item={idx == data?.children?.length - 1 ? 'last' : ''}
                                onClick={() => {
                                    showPositionInfo(i.id);
                                }}
                                key={i + idx}
                                app-org-chart-preview={chartPreview === true ? 'true' : 'false'}
                            >
                                <p>
                                    <span className={cls.companyInfoTitle}>{i.name}</span>
                                    <span>{i.sub_name}</span>
                                </p>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div style={{position: 'relative', marginBottom: '10px', minHeight: '250px' }} />
            )}
        </div>
    );
});

const mapStateToProps = (store) => {
    const { inviteColleguesList } = store.flowReducer;

    return { inviteColleguesList };
};

export default connect(mapStateToProps)(NonManagerialRenders);
