import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import ResizeObserver from 'react-resize-observer';
import { useNavigate } from 'react-router-dom';
import './chartStyles.scss';
import cls from './III_Managerials.module.scss';
import III_NonManagerials from './III_NonManagerials';
import II_Managerials from './II_Managerials';
import { useSelector } from 'react-redux';

const III_Managerials = memo((props) => {
    const navigate = useNavigate();
    const mainCont_III_Ref = useRef();
    const labelCont_III_Ref = useRef();
    const {chartPreview} = useSelector(store => store.modalsReducer);
    const { data, showPositionInfo, onContMan_III_ChildChange, positioning, firstElement, firstElementCenter, lastElement, lastElementCenter, ...rest } = props;
    const chCont_III_Ref = useRef();

    const [mg_III_Ch, setMg_III_Ch] = useState([...(data?.children?.filter(i => i.is_managerial == 1 || i.is_managerial == true) ?? [])]);
    const [nonMg_III_Ch, setNonMg_III_Ch] = useState([...(data?.children?.filter(i => i.is_managerial == 0 || i.is_managerial == false) ?? [])]);
    const [colDim, setColDim] = useState({ colWidth: 0, colHeight: 0 });
    const [nmanCont_III_Dim, setNManCont_III_Dim] = useState([]);

    useEffect(() => {
        if (onContMan_III_ChildChange) {
            onContMan_III_ChildChange({
                width: mainCont_III_Ref.current?.clientWidth,
                offsetLeft: mainCont_III_Ref.current?.offsetLeft,
            });
        }
        if (lastElement && lastElementCenter) {
            lastElementCenter(+mainCont_III_Ref.current?.clientWidth / 2 + 10);
        }
        if (firstElement && firstElementCenter) {
            firstElementCenter(+mainCont_III_Ref.current?.clientWidth / 2 + 10);
        }
    }, [colDim, nmanCont_III_Dim]);
    const settingState = (type, value) => {
        switch (type) {
            case 'setManagerialCh':
                setMg_III_Ch(value);
                break;
            case 'setNonManagerialCh':
                setNonMg_III_Ch(value);
                break;
            default:
                return;
        }
    };

    useEffect(() => {
        const managerials = [...(data?.children?.filter(i => i.is_managerial == 1 || i.is_managerial == true) || [])];
        const nonManagerials = [...(data?.children.filter(i => i.is_managerial == 0 || i.is_managerial == false) ?? [])];

        settingState('setManagerialCh', managerials);
        settingState('setNonManagerialCh', nonManagerials);
    }, [data?.children]);
    const detChildPos = useCallback(
        (idx) => {
            let total = nonMg_III_Ch.length + mg_III_Ch.length;

            if (total >=3){
                if (idx === 0 && nonMg_III_Ch.length === 0) return 'left';
                if (idx === mg_III_Ch.length - 1) return 'right';
                if (idx === 0 && mg_III_Ch.length > 1 && nonMg_III_Ch.length > 0) return 'left';
                else return 'center';
            }
            if (total === 1) return 'center';
            if (idx === 0 && nonMg_III_Ch.length === 0) return 'left';
            else return 'right';
        },
        [nonMg_III_Ch, mg_III_Ch]
    );

    return (
        <div key={props.id} className={cls.host} {...rest} ref={mainCont_III_Ref} app-iii-item-position={positioning}>
            <ResizeObserver
                onResize={(rect) => {
                    setColDim(c => ({ ...c, colWidth: rect.width, colHeight: rect.height }));
                }}
                onPosition={(rect) => {
                    setColDim(c => ({ ...c, offsetLeft: rect.left, offsetTop: rect.top }));
                }}
            />
            <div className={cls.target} ref={labelCont_III_Ref}>
                <div
                    className={cls.cardContainerBase}
                    onClick={() => {
                        showPositionInfo(data.id);
                    }}
                >
                    <p>
                        <span className={cls.companyInfoTitle}>{data.name}</span>
                        <span>{data.sub_name}</span>
                    </p>
                </div>
            </div>
            <div
                className={cls.childrenContainer}
                ref={chCont_III_Ref}
                app-both-children-types={nonMg_III_Ch?.length > 0 && mg_III_Ch?.length > 0 ? 'true' : 'false'}
                app-managerials-only={nonMg_III_Ch?.length == 0 && mg_III_Ch?.length > 1 ? 'true' : 'false'}
                app-non-managerials-only={nonMg_III_Ch?.length > 0 && mg_III_Ch?.length == 0 ? 'true' : 'false'}
                app-only-one-managerial={nonMg_III_Ch?.length == 0 && mg_III_Ch?.length == 1 ? 'true' : 'false'}
            >
                {nonMg_III_Ch?.length > 0 && (
                    <III_NonManagerials
                        key={data.name}
                        nmPositions={{ children: nonMg_III_Ch }}
                        onlyNMPos={mg_III_Ch?.length == 0}
                        showPositionInfo={(positionId) => {
                            !chartPreview && navigate({ pathname: `/role/${positionId}`});
                        }}
                        onContNMan_III_ChildChange={({ width, _height, offsetLeft, _offsetParent }) => {
                            setNManCont_III_Dim({ id: 'nManCont_III_Dim', width: width, offsetLeft: offsetLeft });
                        }}
                        parentsDims={{
                            width: labelCont_III_Ref?.current?.clientWidth,
                            height: labelCont_III_Ref?.current?.clientHeight,
                            offsetLeft: labelCont_III_Ref?.current?.offsetLeft,
                            offsetTop: labelCont_III_Ref?.current?.offsetTop,
                        }}
                    />
                )}
                <div className={cls.II_Managerials_Children_Container}
                    app-both-siblings-types={nonMg_III_Ch?.length > 0 && mg_III_Ch?.length > 1 ? 'true' : 'false'}
                >
                    {mg_III_Ch?.length > 0 &&
                        mg_III_Ch?.map((i, idx) => {
                            return (
                                <II_Managerials
                                    key={i.id}
                                    data={i}
                                    render_index={idx}
                                    // depLabelsDims={{
                                    //   width: labelCont_III_Ref?.current?.clientWidth,
                                    //   offsetLeft: labelCont_III_Ref?.current?.offsetLeft,
                                    // }}
                                    showPositionInfo={(positionId) => {
                                        !chartPreview && navigate({ pathname: `/role/${positionId}`});}}
                                    positioning={detChildPos(idx)}
                                    lastElement={idx == mg_III_Ch?.length - 1}
                                    lastElementCenter={(lastElem) => {
                                        chCont_III_Ref.current.style.setProperty('--app-iii-right-offset', `${lastElem}px`);
                                    }}
                                    firstElement={idx == 0}
                                    firstElementCenter={(lastElem) => {
                                        chCont_III_Ref.current.style.setProperty('--app-iii-left-offset', `${lastElem}px`);
                                    }}
                                />
                            );
                        })
                    }
                </div>

            </div>
        </div>
    );
});

export default III_Managerials;
