import moment from 'moment';
import { sessionService } from 'redux-react-session';
import { Observable } from 'rxjs';
import { resetAuthReducer, setSessionSettings } from '../reduxStore/actions/authActions';
import { resetFlowReducer } from '../reduxStore/actions/flowActions';
import { resetModalsReducer } from '../reduxStore/actions/modalsActions';
import { store } from '../reduxStore/store';
import { URLS } from './API_CONSTANTS';
import { axiosInstance } from './axiosInstance';

const dispatch = fn => store.dispatch(fn);

export const userRegistration = (userData) => {
    const data = new FormData();

    data.append('email', userData?.email);
    data.append('password', userData?.password);
    data.append('password_confirmation', userData?.password);
    data.append('invitation_id', userData?.invitation_id);
    data.append('timezone', userData?.timezone);

    const config = {
        ...URLS.auth.register,
        data: data,
        handlerEnabled: true,
        rememberMe: true,
        retrieveAuthenticatedUser: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                try {
                    if (response?.status == 200 && response?.data) {
                        const sessionSettings = {
                            rememberMe: userData?.rememberMe,
                            //  user_id: userData?.email,
                            expirationDate: moment()
                                .add(+response.data.expires_in * 1000, 'seconds')
                                .format('YYYY-MM-DD hh:mm:ss:ssss'),
                            last_access_token: response?.data?.access_token,
                            last_expires_in: response?.data?.expires_in,
                            last_refresh_token: response?.data?.refresh_token,
                        };

                        dispatch(setSessionSettings(sessionSettings));
                        const actualSesion = store?.getState()?.sessionReducer ?? {};

                        sessionService?.saveSession?.({ ...actualSesion, ...sessionSettings }).then(() => {
                            try {
                                const authenticatedUser = store?.getState()?.authReducer?.authenticatedUser;

                                sessionService?.saveUser?.(authenticatedUser).then(() => {
                                    subscriber.next(response.data);
                                    subscriber.complete();
                                });
                            } catch (ex) {
                                /*  */
                            }
                        });

                    }
                } catch (ex) {
                    console.log('ex is: ', ex);
                    subscriber.error(ex);
                }
            })
            .catch(error => subscriber.error(error))
    );
};
export const forgetPasswordRequest = (email) => {
    const data = new FormData();

    data.append('email', email);
    const config = {
        ...URLS.auth.forgotPassword,
        data: data,
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data) {
                    subscriber.next(response?.data);
                    subscriber.complete();
                }
            })
            .catch(error => subscriber.error(error))
    );
};
export const resetPassword = (email, password, confirmPassword, token) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = new FormData();

    data.append('token', token ?? last_access_token);
    data.append('email', email);
    data.append('password', password);
    data.append('password_confirmation', confirmPassword);

    const config = {
        ...URLS.auth.resetPassword,
        data: data,
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data) {
                    subscriber.next(response?.data);
                    subscriber.complete();
                }
            })
            .catch(error => subscriber.error(error))
    );
};
export const userLogin = (userData) => {
    const data = new FormData();

    data.append('email', userData?.email);
    data.append('password', userData?.password);
    data.append('remember', userData.rememberMe);

    const config = {
        ...URLS.auth.login,
        data: data,
        headers: { ...URLS.auth.login.headers },
        handlerEnabled: true,
        rememberMe: userData.rememberMe,
        retrieveAuthenticatedUser: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                try {
                    if (response?.status == 200 && response?.data) {
                        const sessionSettings = {
                            rememberMe: userData?.rememberMe,
                            //  user_id: userData?.email,
                            expirationDate: moment()
                                .add(+response.data.expires_in * 1000, 'seconds')
                                .format('YYYY-MM-DD hh:mm:ss:ssss'),
                            last_access_token: response?.data?.access_token,
                            last_expires_in: response?.data?.expires_in,
                            last_refresh_token: response?.data?.refresh_token,
                        };

                        dispatch(setSessionSettings(sessionSettings));
                        const actualSesion = store?.getState()?.sessionReducer ?? {};

                        sessionService?.saveSession?.({ ...actualSesion, ...sessionSettings }).then(() => {
                            try {
                                const authenticatedUser = store?.getState()?.authReducer?.authenticatedUser;

                                sessionService?.saveUser?.(authenticatedUser).then(() => {
                                    subscriber.next(response.data);
                                    subscriber.complete();
                                });
                            } catch (ex) {
                                /*  */
                            }
                        });

                    }
                } catch (ex) {
                    console.log('ex is: ', ex);
                    subscriber.error(ex);
                }
            })
            .catch(error => subscriber.error(error))
    );
};
export const userLogout = () => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.auth.logout(last_access_token),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                const sessionSett = {
                    expirationDate: moment(),
                    last_access_token: '',
                    last_refresh_token: response?.data?.refresh_token,
                };

                dispatch(setSessionSettings(sessionSett));
                sessionService.deleteSession().then(() => sessionService.deleteUser());
                dispatch(resetAuthReducer());
                dispatch(resetFlowReducer());
                dispatch(resetModalsReducer());
                subscriber.next(response.data);
                subscriber.complete();
            })
            .catch((error) => {
                subscriber.error(error);
                sessionService.deleteSession().then(() => sessionService.deleteUser());
                const sessionSett = {
                    expirationDate: moment(),
                    last_access_token: '',
                    last_refresh_token: '',
                };

                dispatch(setSessionSettings(sessionSett));
                dispatch(resetAuthReducer());
                dispatch(resetFlowReducer());
                dispatch(resetModalsReducer());
            })
    );
};

export const checkAuthTimeout = expirationTime => (_dispatch) => {
    setTimeout(() => {
        userLogout();
    }, expirationTime * 1000);
};
export const authCheckState = () => (_dispatch) => {
    const token = localStorage.getItem('token');

    if (!token) {
        userLogout();
    } else {
        const expirationDate = new Date(localStorage.getItem('expirationDate'));

        if (expirationDate <= new Date()) {
            userLogout();
        }
    }
};
export const gettingInvitationByHash = (hash) => {
    const config = {
        ...URLS.auth.getInvitationByHash(hash),
        handlerEnabled: true,
        rememberMe: true,
        noTokenRetrival: true,
    };
    let observable = new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status == 200 && response?.data) {
                    subscriber.next(response?.data?.data);
                    subscriber.complete();
                }
            })
            .catch(error => subscriber.error(error))
    );

    return observable;
};
export const gettingInvitationById = (invitationId) => {
    const config = {
        ...URLS.auth.getInvitationById(invitationId),
        handlerEnabled: true,
        rememberMe: true,
    };
    let observable = new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status == 200 && response?.data) {
                    const sessionSettings = {
                        rememberMe: true,
                        //  user_id: userData?.email,
                        expirationDate: moment()
                            .add(+response.data.expires_in * 1000, 'seconds')
                            .format('YYYY-MM-DD hh:mm:ss:ssss'),
                        last_access_token: response?.data?.access_token,
                        last_expires_in: response?.data?.expires_in,
                        last_refresh_token: response?.data?.refresh_token,
                    };

                    dispatch(setSessionSettings(sessionSettings));
                    const currentSession = { ...store.getState.sessionReducer, ...sessionSettings };

                    sessionService?.saveSession?.(currentSession).then(() => {
                        const authenticatedUser = store.getState().authReducer.authenticatedUser;

                        sessionService?.saveUser?.(authenticatedUser).then(() => {
                            //  dispatch(setLastUsedCredentials(response.data));
                            //  dispatch(setLatestLogin({ email: userData.email, password: userData.password }));
                            subscriber.next(response.data);
                            subscriber.complete();
                        });
                    });
                }
            })
            .catch(error => subscriber.error(error))
    );

    return observable;
};
export const verify2FA = (code, uuid) => {
    const data = {
        request_uuid: uuid,
        one_time_password: code,
    };

    const config = {
        ...URLS.auth.verify2FA,
        data: data,
        headers: { ...URLS.auth.verify2FA.headers },
        handlerEnabled: true,
        retrieveAuthenticatedUser: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                try {
                    if (response?.status == 200 && response?.data) {
                        const sessionSettings = {
                            expirationDate: moment()
                                .add(+response.data.expires_in * 1000, 'seconds')
                                .format('YYYY-MM-DD hh:mm:ss:ssss'),
                            last_access_token: response?.data?.access_token,
                            last_expires_in: response?.data?.expires_in,
                            last_refresh_token: response?.data?.refresh_token,
                        };

                        dispatch(setSessionSettings(sessionSettings));
                        const actualSesion = store?.getState()?.sessionReducer ?? {};

                        sessionService?.saveSession?.({ ...actualSesion, ...sessionSettings }).then(() => {
                            try {
                                const authenticatedUser = store?.getState()?.authReducer?.authenticatedUser;

                                sessionService?.saveUser?.(authenticatedUser).then(() => {
                                    subscriber.next(response.data);
                                    subscriber.complete();
                                });
                            } catch (ex) {
                                /*  */
                            }
                        });

                    }
                } catch (ex) {
                    console.log('ex is: ', ex);
                    subscriber.error(ex);
                }
            })
            .catch(error => subscriber.error(error))
    );
};
export const getBarCode = () => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.auth.getBarCode(last_access_token),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                subscriber.next(response.data);
                subscriber.complete();
            })
            .catch(error => subscriber.error(error))
    );
};

export const enable2FA = (secretCode) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const config = {
        ...URLS.auth.enable2FA(last_access_token, secretCode),
        handlerEnabled: true,
        data: {secret: secretCode},
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                subscriber.next(response.data);
                subscriber.complete();
            })
            .catch(error => subscriber.error(error))
    );
};

export const disable2FA = (password) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const config = {
        ...URLS.auth.disable2FA(last_access_token, password),
        handlerEnabled: true,
        data: {password: password},
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                subscriber.next(response.data);
                subscriber.complete();
            })
            .catch(error => subscriber.error(error))
    );
};
export const get2FADetails = () => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.auth.get2FADetails(last_access_token),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                subscriber.next(response.data);
                subscriber.complete();
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
