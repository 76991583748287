import { createStateContext } from 'react-use';

export type TheRoleInformationContextData = { activeElement: string };
export const [useRoleInformationContext, TheRoleInformationContext] = createStateContext<TheRoleInformationContextData>({ activeElement: '' });

export type OLContextData = { activeElement: string };
export const [useOLContext, OLContext] = createStateContext<OLContextData>({ activeElement: '' });

export type TheListItemsRenderContext = { activeElement: string; items: string[] };
export const [useListItemsRenderedContext, TheListItemsRenderContext] = createStateContext<TheListItemsRenderContext>({ activeElement: '', items: [] });
