import React, { useEffect, useState } from 'react';
import cls from './Checkbox.module.scss';

const Checkbox = (props) => {
    const { defaultChecked, onChange, label } = props;
    const [checked, setChecked] = useState(defaultChecked);
    const settingChecked = val => setChecked(val);

    useEffect(() => {
        if (defaultChecked !== checked) {
            settingChecked(defaultChecked);
        }
    }, [defaultChecked]);
    const onChangedState = () => {
        setChecked(c => !c);
        if (onChange) onChange({ target: { checked: !checked } });
    };

    return (
        <div className={cls.checkbox}>
            <div>
                <input checked={checked} onChange={onChange} name="checkbox" type="checkbox" />
                <p className={cls.commitText}>{label}</p>
            </div>
        </div>
    );
};

export default Checkbox;
