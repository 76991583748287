import React, {useEffect, useState, useMemo} from 'react';
import {Routes, Navigate, Route, useNavigate} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {setOnBoardingVisible} from './appFiles/reduxStore/actions/modalsActions';
import {OnboardingModals} from './appFiles/components/OnboardingEntries';
import { Can, CoreRoles, Permissions, ErrorsModal, InfoModal, ModalR, usePermissionContext } from "./appFiles/components/common";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { strategicRoadmapEnabled } from "./appFiles/services/API_CONSTANTS";


const ChangePassword = React.lazy(() => import('./appFiles/components/Auth/ChangePassword'));
const MiscPage = React.lazy(() => import('./appFiles/components/AdminCenter/AdminCenterComponents/MiscPage/MiscPage'));
const InvitedColleagues = React.lazy(() => import("./appFiles/components/OnboardingEntries/OnboardingModals/InvitedColleagues"));
const InviteColleaguesByEmail = React.lazy(() => import("./appFiles/components/OnboardingEntries/OnboardingModals/InviteColleaguesByEmail"));
const InviteColleaguesByFile = React.lazy(() => import("./appFiles/components/OnboardingEntries/OnboardingModals/InviteColleaguesByFile"));
const VideoCategories = React.lazy(() => import("./appFiles/components/AdminCenter/AdminCenterComponents/VideoCategories/VideoCategories"));
const ComponentContext = React.lazy(() => import('./appFiles/context/components.context'));
const WelcomeTo = React.lazy(() => import("./appFiles/components/OnboardingEntries/OnboardingModals/WelcomeTo"));
const BasicCompanyInformations = React.lazy(() => import("./appFiles/components/OnboardingEntries/OnboardingModals/BasicCompanyInformations"));
const CompanyCulture = React.lazy(() => import("./appFiles/components/common/CompanyCulture/CompanyCulture"));
const CompanyMission = React.lazy(() => import("./appFiles/components/OnboardingEntries/OnboardingModals/CompanyMission"));
const CompanyVision = React.lazy(() => import("./appFiles/components/OnboardingEntries/OnboardingModals/CompanyVision"));
const DefineDepartments = React.lazy(() => import("./appFiles/components/OnboardingEntries/OnboardingModals/DefineDepartments"));
const AddNewPosition = React.lazy(() => import("./appFiles/components/OnboardingEntries/OnboardingModals/AddNewPosition"));
const InfoTask = React.lazy(() => import("./appFiles/components/SideMenuEntries/TaskComps/InfoTask/InfoTask"));
const MobileDisclaimer = React.lazy(() => import('./appFiles/components/OthersGlobals/Disclaimer/MobileDisclaimer'));
const TrialEnd = React.lazy(() => import("./appFiles/components/SideMenuEntries/BillingInfo/TrialEnd"));
const Layout = React.lazy(() => import("./appFiles/hoc/Layout/Layout"));
const AddUserCompany = React.lazy(() => import("./appFiles/components/MultiCompany/AddUserCompany/AddUserCompany"));
const EditGroupCompany = React.lazy(() => import("./appFiles/components/MultiCompany/GroupAdminCenter/AdminCenterComponents/EditGroupCompany/EditGroupCompany"));

const Login = React.lazy(() => import('./appFiles/components/Auth/Login'));
const ResetPassword = React.lazy(() => import('./appFiles/components/Auth/ResetPassword'));
const AcceptInvitation = React.lazy(() => import('./appFiles/components/Auth/AcceptInvitation'));
const Dashboard = React.lazy(() => import('./appFiles/components/SideMenuEntries/Dashboard'));
const Tasks = React.lazy(() => import('./appFiles/components/SideMenuEntries/Tasks'));
const Processes = React.lazy(() => import('./appFiles/components/SideMenuEntries/Processes'));
const ProcessesFlows = React.lazy(() => import('./appFiles/components/SideMenuEntries/ProcessesFlows/ProcessesFlows'));
const Components = React.lazy(() => import('./appFiles/components/SideMenuEntries/Components/Components'));
const ComponentPage = React.lazy(() => import('./appFiles/components/SideMenuEntries/ComponentsComps/ComponentInfo'));
const Company = React.lazy(() => import('./appFiles/components/SideMenuEntries/Company/Company'));
const CompanyDetails = React.lazy(()=>import('./appFiles/components/SideMenuEntries/CompanyDetails/CompanyDetails'));
const StrategicRoadmap = React.lazy(() => import('./appFiles/components/SideMenuEntries/StrategicRoadmap/StrategicRoadmap'));
const GuidingDocuments = React.lazy(() => import('./appFiles/components/SideMenuEntries/GuidingDocuments/GuidingDocuments'));
const Flows = React.lazy(() => import('./appFiles/components/SideMenuEntries/Flows/Flows'));
const BluePrints = React.lazy(() => import('./appFiles/components/SideMenuEntries/Blueprints/Blueprints'));
const VideoTutorials = React.lazy(() => import('./appFiles/components/OthersGlobals/HelpCenter/VideoTutorials'));
const Documentation = React.lazy(() => import('./appFiles/components/OthersGlobals/HelpCenter/Documentation'));
const Tracker = React.lazy(() => import('./appFiles/components/OthersGlobals/Tracker'));
const PageNotFound = React.lazy(() => import('./appFiles/components/OthersGlobals/PageNotFound'));
const StrategicInitiativeDetails = React.lazy(() => import('./appFiles/components/SideMenuEntries/StrategicRoadmap/StrategicInitiativeDetails/StrategicInitiativeDetails'));
const EmptyPage = React.lazy(() => import('./appFiles/components/OthersGlobals/EmptyPage'));
const EditGroupChart = React.lazy(() => import('./appFiles/components/MultiCompany/GroupChart/EditGroupChart'));
const AddGroupCompany = React.lazy(() => import('./appFiles/components/MultiCompany/GroupChart/AddGroupCompany/AddGroupCompany'));
const DefineDivisions = React.lazy(() => import('./appFiles/components/MultiCompany/Division/DefineDivisions'));
const GroupAdminCenter = React.lazy(() => import('./appFiles/components/MultiCompany/GroupAdminCenter/GroupAdminCenter'));
const GroupAdminCompany = React.lazy(() => import('./appFiles/components/MultiCompany/GroupAdminCenter/AdminCenterComponents/GroupAdminCompany/GroupAdminCompany'));
const EditOrganisationalChart = React.lazy(() =>
    import('./appFiles/components/OnboardingEntries/CompanyOrganisationalChart/EditComOrgChart'));
const OnBoardingOrganisationalChart = React.lazy(() =>
    import('./appFiles/components/OnboardingEntries/CompanyOrganisationalChart/ComOrgChartOnBoarding'));
const RoleInformation = React.lazy(() => import('./appFiles/components/OnboardingEntries/RoleInformation/RoleInformation'));
const HelpCenter = React.lazy(() => import('./appFiles/components/OthersGlobals/HelpCenter/HelpCenter'));
const AccountSettings = React.lazy(() => import('./appFiles/components/SideMenuEntries/AccountSettings/AccountSettings'));
const MyProfile = React.lazy(() => import('./appFiles/components/TopBar/MyProfile/MyProfile'));
const Notifications = React.lazy(() => import('./appFiles/components/TopBar/Notifications/Notifications'));
const ComponentPageWrapper = React.lazy(()=> import('./appFiles/components/SideMenuEntries/BlueprintsComps/ComponentsTab/ComponentsPage'));
const ProcessInformation = React.lazy(() => import('./appFiles/components/SideMenuEntries/BlueprintsComps/ProcessesTab/ProcessInfo'));
const CompanySettings = React.lazy(() => import('./appFiles/components/TopBar/CompanySettings/CompanySettings'));
const BillingInfo = React.lazy(() => import('./appFiles/components/SideMenuEntries/BillingInfo/BillingInfo'));
const TrialInfo = React.lazy(() => import('./appFiles/components/SideMenuEntries/BillingInfo/TrialInfo'));
const Success = React.lazy(() => import('./appFiles/components/SideMenuEntries/BillingInfo/Success'));
const Cancel = React.lazy(() => import('./appFiles/components/SideMenuEntries/BillingInfo/Cancel'));
const Checkout = React.lazy(() => import('./appFiles/components/SideMenuEntries/CheckoutPage/CheckoutPage'));
const BeginProcess = React.lazy(() => import('./appFiles/components/SideMenuEntries/ProcessesComps/BeginProcess'));
const AdminCenter = React.lazy(() => import('./appFiles/components/AdminCenter/AdminCenter'));
const EmployeeDetails = React.lazy(() => import('./appFiles/components/SideMenuEntries/CompanyDetails/EmployeeDetails'));
const AdminCompany = React.lazy(() => import ('./appFiles/components/AdminCenter/AdminCenterComponents/AdminCompany/AdminCompany'));
const AdminList = React.lazy(() => import('./appFiles/components/AdminCenter/AdminCenterComponents/AdminList/AdminList'));
const FAQ = React.lazy(() => import('./appFiles/components/AdminCenter/AdminCenterComponents/FAQ/FAQ'));
const AdminVideoTutorials = React.lazy(() =>
    import('./appFiles/components/AdminCenter/AdminCenterComponents/VideoTutorials/VideoTutorials'));
const AdminDocumentation = React.lazy(() => import('./appFiles/components/AdminCenter/AdminCenterComponents/Documentation/Documentation'));
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const App = () => {
    const dispatch = useDispatch();
    const {authenticatedUser, lastKnownRoute} = useSelector(store => store.authReducer);
    const {authenticated} = useSelector(store => store.sessionReducer);
    const {onBoardingCompleted, companyData} = useSelector(store => store.flowReducer);
    const [showTrial, setShowTrial] = useState(false);
    const [routes, setRoutes] = useState(null);
    const settingRoutes = route => setRoutes(route);
    const navigate = useNavigate();
    const { setUser, isAuthorized } = usePermissionContext();

    useEffect( () => {
        //https://www.npmjs.com/package/@permify/react-role way
        authenticated && setUser(authenticatedUser);

        let canInvite = authenticatedUser?.permissions?.find( p => p === Permissions.INVITE_USERS) || false;

        (async function () {
            const showSuperAdminRoutes = authenticated && await isAuthorized([CoreRoles.SUPER_ADMIN]);
            const showOnboarding = authenticated && !onBoardingCompleted && await isAuthorized(null, [Permissions.UPDATE_COMPANY]) && !showSuperAdminRoutes;
            const showPrivateUser = authenticated && !showSuperAdminRoutes;
            const showGroupAdminRoutes = true;

            let routesPaths = (
                <Routes>
                    <Route path="/pageNotFound" element={<PageNotFound />} />
                    <Route path="/stripe/webhooks" element={<EmptyPage />} />
                    {!authenticated && <Route path="/login" element={<Login />} />}
                    {showPrivateUser && <Route path="/role/:positionId" element={<RoleInformation />} />}
                    {showPrivateUser && <Route path="/company-culture" element={<CompanyCulture />} />}
                    {showOnboarding && <Route path="/onboarding-chart" element={<OnBoardingOrganisationalChart />} />}
                    {showOnboarding && <Route path="/welcome" element={<WelcomeTo />} />}
                    {showOnboarding && <Route path="/welcome/company-information" element={<BasicCompanyInformations />} />}
                    {showOnboarding && <Route path="/welcome/company-mission" element={<CompanyMission />} />}
                    {showOnboarding && <Route path="/welcome/company-vision" element={<CompanyVision />} />}
                    {showPrivateUser && <Route path="/welcome/define-departments" element={<DefineDepartments />} />}
                    {showPrivateUser && <Route path="add-position" element={<AddNewPosition />} />}
                    {canInvite && showPrivateUser && <Route path="/invite-colleagues" element={<InvitedColleagues />} />}
                    {canInvite && showPrivateUser && <Route path="/invite-colleagues/email" element={<InviteColleaguesByEmail />} />}
                    {canInvite && showPrivateUser && <Route path="/invite-colleagues/file" element={<InviteColleaguesByFile />} />}
                    {showPrivateUser && <Route path="/help-center/video-tutorials" element={<VideoTutorials />} />}
                    {showPrivateUser && <Route path="/help-center/documentation" element={<Documentation />} />}
                    {showPrivateUser && <Route path="/chart" element={<EditOrganisationalChart />} />}
                    {(showPrivateUser && showGroupAdminRoutes) && <Route path="/group-chart" element={<EditGroupChart />} />}
                    {(showPrivateUser && showGroupAdminRoutes) && <Route path="/group-admin/:groupId" element={<GroupAdminCenter />} />}
                    {(showPrivateUser && showGroupAdminRoutes) && <Route path="/group-admin/companies/:id" element={<GroupAdminCompany />}/>}
                    {(showPrivateUser && showGroupAdminRoutes) && <Route path="/group-admin/company-details/:id" element={<EditGroupCompany />}/>}
                    {(showPrivateUser && showGroupAdminRoutes) && <Route path="/group-admin/employee-details/:userId" element={<EmployeeDetails />} />}
                    {(showPrivateUser && showGroupAdminRoutes) && <Route path="/group-admin/define-divisions" element={<DefineDivisions />} />}
                    {(showPrivateUser && showGroupAdminRoutes) && <Route path="/add-group-company/:groupId" element={<AddGroupCompany />} />}
                    {showPrivateUser && <Route path="/dashboard" element={<Dashboard />} />}
                    {showPrivateUser && <Route path="/processes" element={<Processes />} />}
                    {showPrivateUser && <Route path="/processes-flows" element={<ProcessesFlows />} />}
                    {showPrivateUser
                        && await isAuthorized(null, [Permissions.UPDATE_PROCEDURE, Permissions.DELETE_PROCEDURE])
                        && <Route path="/builder/procedures/:processId" element={<ProcessInformation />} />}
                    {showPrivateUser && <Route path="/components" element={<Components />} />}
                    {(showPrivateUser || showSuperAdminRoutes) &&
                        <Route path="/notifications" element={<Notifications />} />}
                    {showPrivateUser && <Route path="/components/:componentId" element={
                        <ComponentContext><ComponentPage /></ComponentContext>} />
                    }
                    {showPrivateUser && <Route path="/tasks" element={<Tasks />} />}
                    {showPrivateUser && <Route path="/task-history/:taskId" element={<InfoTask />} />}
                    {showPrivateUser && <Route path="/company" element={<Company />} />}
                    {showPrivateUser && <Route path="/add-company" element={<AddUserCompany />} />}
                    {showPrivateUser && <Route path="/company/colleagues/:employeeId" element={<CompanyDetails />} />}
                    {showPrivateUser && <Route path="/builder" element={<BluePrints />} />}
                    {showPrivateUser && <Route path="/builder/procedures" element={<BluePrints />} />}
                    {showPrivateUser && <Route path="/builder/components" element={<BluePrints />} />}
                    {showPrivateUser &&
                        <Route path="/builder/components/:componentId" element={<ComponentPageWrapper />} />}
                    {showPrivateUser && <Route path="/builder/components/new" element={<ComponentPageWrapper />} />}

                    {showPrivateUser && <Route path="/help-center" element={<HelpCenter />} />}
                    {showPrivateUser && <Route path="/tracker" element={<Tracker />} />}
                    {showPrivateUser && <Route path="/my-profile" element={<MyProfile />} />}
                    {showPrivateUser && await isAuthorized(null, [Permissions.UPDATE_COMPANY]) && (
                        <Route
                            path="/company-settings"
                            element={<Elements stripe={stripePromise}><CompanySettings /></Elements>}
                        />
                    )}
                    {showPrivateUser && <Route path="/account-settings" element={<AccountSettings />} />}
                    {showPrivateUser && await isAuthorized(null, [Permissions.UPDATE_BILLING]) && authenticatedUser.is_admin && (
                        <Route path="/billing-info" element={<Elements stripe={stripePromise}><BillingInfo /></Elements>} />
                    )}
                    {showPrivateUser && await isAuthorized(null, [Permissions.VIEW_BILLING]) && <Route path="/cancel" element={<Cancel />} />}
                    {showPrivateUser && await isAuthorized(null, [Permissions.VIEW_BILLING]) && <Route path="/success" element={<Success />} />}
                    {showPrivateUser && (
                        <Route path="/checkout" element={<Elements stripe={stripePromise}><Checkout /></Elements>} />
                    )}
                    {showPrivateUser && strategicRoadmapEnabled &&
                        <Route path="/strategic-roadmap" element={<StrategicRoadmap />} />}
                    {showPrivateUser && strategicRoadmapEnabled &&
                        <Route path="/strategic-roadmap/initiative" element={<StrategicInitiativeDetails />} />}
                    {showPrivateUser &&
                        <Route path="/guiding-documents" element={<GuidingDocuments />} />}
                    {showPrivateUser && <Route path="/begin-process/:taskId" element={<BeginProcess />} />}
                    {showPrivateUser && await isAuthorized(null, [Permissions.VIEW_FLOWS]) && <Route path="/flows" element={<Flows />} />}
                    <Route path="/invitations/accept/:hash" element={<AcceptInvitation authenticated={authenticated} />} />
                    {showPrivateUser && <Route path="/auth/password/reset" element={<ChangePassword />} />}
                    {!authenticated && <Route path="/reset-password" element={<ResetPassword />} />}
                    {!authenticated && <Route path="/auth/password/reset" element={<ChangePassword />} />}
                    {showSuperAdminRoutes && <Route path="/admin" element={<AdminCenter />} />}
                    {showSuperAdminRoutes && <Route path="/admin/companies/:id" element={<AdminCompany />}/>}
                    {showSuperAdminRoutes && <Route path="/admin/faq" element={<FAQ />} />}
                    {showSuperAdminRoutes && <Route path="/admin/misc" element={<MiscPage />} />}
                    {showSuperAdminRoutes && <Route path="/admin/video-tutorials" element={<AdminVideoTutorials />} />}
                    {showSuperAdminRoutes && <Route path="/admin/video-categories" element={<VideoCategories />} />}
                    {showSuperAdminRoutes && <Route path="/admin/documentation" element={<AdminDocumentation />} />}
                    {showSuperAdminRoutes && <Route path="/admin/employee-details/:userId" element={<EmployeeDetails />} />}
                    {showSuperAdminRoutes && <Route path="/admin/admin-list" element={<AdminList/>}/>}
                    {authenticated ?
                        <Route path="*" element={<Navigate to={showSuperAdminRoutes ? "/admin" : "/dashboard"} />} /> :
                        <Route path="*" element={<Navigate to="/login" />} />}
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            );
            // Add non-interactive routes where a trial is allowed, so user interaction isn't affected. (Only for on_trail status also don't add lastKnownRoute in dependency)
            const routeBasedTrialDialogAllowed = ["/dashboard", "/company-settings"].includes(lastKnownRoute);

            settingRoutes(routesPaths);
            if (companyData.license && companyData.license.type === 'lifetime') {
                setShowTrial(false);
            }
            else if (((onBoardingCompleted && companyData.status === "on_trial" && routeBasedTrialDialogAllowed)
                    || (companyData.status === "trial_ended" || companyData.status === "inactive"))
                && showPrivateUser) {
                setShowTrial(true);
            } else {
                setShowTrial(false);
            }
        })();
    }, [authenticated, authenticatedUser, companyData?.onboarding_status, onBoardingCompleted, companyData?.status]);

    const onboarding = useMemo(() => <OnboardingModals
        onClose={() => dispatch(setOnBoardingVisible(false))}/>, []);

    const isMobileScreen = window.innerWidth < 676 ? true : false;
    const [isDisclaimer, setIsDisclaimer] = useState(isMobileScreen);

    function handleIgnoreClick(){
        setIsDisclaimer(false);
    }


    if(isDisclaimer){
        return <MobileDisclaimer handleIgnoreClick={handleIgnoreClick}/>;
    }

    return (
        <Layout>
            {routes}
            {onboarding}
            {showTrial && (
                <Can
                    permissions={[Permissions.CREATE_BILLING, Permissions.UPDATE_BILLING]}
                    ifCannot={
                        (companyData.status === "trial_ended" || companyData.status === "inactive")
                            ? (
                                <ModalR
                                    blured={true}
                                    scrollable={true}
                                    isOpen={showTrial}
                                    backdropColor={true}
                                    shadowedContainerStyle={{width: authenticatedUser.is_admin? '666px' : '640px'}}
                                >
                                    <TrialEnd/>
                                </ModalR>
                            ) : null
                    }
                >
                    <ModalR
                        blured={true}
                        scrollable={true}
                        isOpen={showTrial}
                        backdropColor={true}
                        shadowedContainerStyle={{width: authenticatedUser.is_admin ? '666px' : '640px'}}
                    >
                        <TrialInfo
                            onContinue={() => setShowTrial(false)}
                            onUpgrade={() => {
                                setShowTrial(false);
                                navigate('/billing-info');
                            }}
                        />
                    </ModalR>
                </Can>
            )}
            <InfoModal/>
            <ErrorsModal/>
        </Layout>
    );
};

export default App;
