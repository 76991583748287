import cls from './LabeledField.scss';
import { Tooltip } from '../../../../assets/images/new-icons';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

const LabeledField = (props) => {
    const { label, error, children, tooltipText, labelClassname } = props;
    const randomID = String(Math.random());

    return (
        <div className={cls.labeledFieldHost}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label className={classNames(labelClassname, cls.labeledFieldLabel)}>{label}</label>
                <div data-tip={tooltipText} data-for={randomID}>
                    <Tooltip />
                    <ReactTooltip className={classNames(cls.showTooltip)} id={randomID} multiline effect={'float'} type={'dark'} clickable={true} place={'left'} />
                </div>
            </div>
            {children}
            {error?.length > 0 && <p className={cls.labeledFieldError}>{error}</p>}
        </div>
    );
};

export default LabeledField;
