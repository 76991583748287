import classNames from 'classnames';
import { useCombobox, useMultipleSelection } from 'downshift';
import {ComponentProps, ForwardedRef, forwardRef, useEffect, useRef, useState} from 'react';
import cls from './MultiSelectWithSearchNew.module.scss';
import { CloseIconPurple } from '../../../../assets/images/new-icons';
import {
    ErrorMessage,
    Input,
    InputContainer,
    Item,
    Label,
    MenuOverlayList,
    MenuOverlayListItem,
    TagProps,
    ToggleMenuInputButton,
    ToggleMenuInputButtonProps,
} from './SelectComponents';
import {isEqual} from "lodash";

export type MultiSelectWithSearchProps = {
    /** list of items to chose from */
    items: Item[];
    /**displayed as label above Control */
    title?: string;
    placeholder?: string;
    /** sets an id to the related elements as required by aria listbox rules. Falls back to `title` if none is provided */
    id?: string;
    /** adds a "*"-Indicator to the end of the title that turns red in case of an error*/
    hasMandatoryIndicator?: boolean;
    /** id's of the items that need to be selected initially*/
    initialSelectedItems: Item[];
    errorMessage?: string;
    onChange?: (selectedItems?: Item[]) => void;

    inputStyle?: Record<string, unknown>;
    menuOverlayStyle?: Record<string, unknown>;
    menuOverlayListItemStyle?: Record<string, unknown>;
    noTags?: boolean;
    triggerType?: ToggleMenuInputButtonProps['triggerType'];
    itemType?: 'checkboxes' | 'normal' | 'radio' | 'no-item-icons';
    checkboxEmplacement?: 'left' | 'right';
    inputValue?: string;
    leftIconItemContainerClassName?: string;
    /** prop to pass className to Input */
    inputContainerClassName?: string;
    inputClassName?: string;
    /** prop to pass className to MenuOverlayList */
    molclassname?: string;
    /** prop to pass className to MenuOverlayListItem */
    moliclassname?: string;
} & ComponentProps<'div'>;

// eslint-disable-next-line react/display-name
const MultiSelectWithSearchNew = forwardRef<HTMLDivElement, MultiSelectWithSearchProps>((props: MultiSelectWithSearchProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
        title,
        id = title?.replace(/ /g, '-'),
        noTags,
        triggerType,
        itemType,
        checkboxEmplacement,
        items,
        initialSelectedItems,
        children,
        placeholder,
        hasMandatoryIndicator,
        errorMessage,
        onChange,
        inputStyle,
        menuOverlayStyle,
        menuOverlayListItemStyle,
        className,
        leftIconItemContainerClassName,
        inputContainerClassName,
        inputClassName,
        molclassname,
        moliclassname,
        ...rest
    } = props;
    const [searchFilteredItems, setSearchFilteredItems] = useState(items);
    const [selectedItems, setSelectedItems] = useState(initialSelectedItems ?? []);
    const [localInputValue, setLocalInputValue] = useState('');
    const { getSelectedItemProps, getDropdownProps } = useMultipleSelection<Item>({
        initialSelectedItems: items?.filter(i => initialSelectedItems?.filter(e => e?.value == i?.value)?.length > 0),
        itemToString: item => (item ? item.value : localInputValue),
    });
    const selectedValuesRef = useRef([]);

    useEffect(() => {
        // to avoid unnecessary rendering used this logic
        if (!isEqual(selectedValuesRef.current, initialSelectedItems)){
            setSelectedItems(initialSelectedItems);
            selectedValuesRef.current = initialSelectedItems;
        }
    }, [initialSelectedItems]);

    useEffect(() => {
        onChange?.(selectedItems);
    }, [selectedItems]);
    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        openMenu,
        selectItem,
    } = useCombobox<Item | null>({
        items: items,
        id,
        itemToString: () => localInputValue,
        stateReducer: (state, actionAndChanges) => {
            const { type, changes } = actionAndChanges;

            switch (type) {
                //clear the input when clicking outside or toggle button
                case useCombobox.stateChangeTypes.ToggleButtonClick:
                case useCombobox.stateChangeTypes.InputBlur:
                    return {
                        ...changes,
                        inputValue: '',
                        //isOpen: true,
                    };
                case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    return {
                        ...changes,
                        isOpen: true, //keep menu open after selection
                        highlightedIndex: state.highlightedIndex,
                    };
                default:
                    return changes;
            }
        },
        onStateChange: ({ type, selectedItem }) => {
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    if (selectedItem) {
                        const indexOfItem = selectedItems.findIndex(item => item.value === selectedItem.value);

                        if (indexOfItem > -1) {
                            setSelectedItems([...selectedItems.filter(item => item.value !== selectedItem.value)]);
                        } else {
                            setSelectedItems([...selectedItems, selectedItem]);
                        }
                        selectItem(null);
                    }
                    break;
                default:
                    break;
            }
        },
        onInputValueChange: ({ inputValue }) => {
            setSearchFilteredItems(inputValue ? items?.filter(item => item?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())) : items);
            setLocalInputValue(inputValue ?? '');
        },
    });

    useEffect(() => setSearchFilteredItems(items), [items]);

    return (
        <div
            ref={ref}
            className={classNames(cls.host, className)}
            {...rest}
            onClick={() => {
                if (!isOpen) openMenu();
            }}
        >
            <Label {...getLabelProps()} hasMandatoryIndicator={hasMandatoryIndicator}>
                {title}
            </Label>
            {!noTags && selectedItems?.length > 0 && (
                <div className={cls.multiSelectSection}>
                    {selectedItems.map((selectedItem, index) => (
                        <Tag
                            hasCloseButton
                            key={`selected-item${index}`}
                            {...getSelectedItemProps({ selectedItem, index })}
                            onClick={() => {
                                setSelectedItems([...selectedItems.filter(item => item.value !== selectedItem.value)]);
                            }}
                        >
                            {selectedItem?.icon && <img alt="item-icon" className={cls.itemLeftIcon} src={selectedItem?.icon} />}
                            {selectedItem.label}
                        </Tag>
                    ))}
                </div>
            )}
            <InputContainer {...getComboboxProps()}
                triggerType={triggerType} className={inputContainerClassName} isActive={isOpen} hasError={errorMessage != null}>
                <Input
                    {...getInputProps({
                        placeholder,
                        'aria-invalid': errorMessage != null || undefined,
                        onFocus: () => {
                            if (!isOpen) {
                                openMenu();
                            }
                        },
                    })}
                    className={inputClassName}
                    style={inputStyle}
                />
                <ToggleMenuInputButton
                    {...getToggleButtonProps(
                        { ...getDropdownProps({ preventKeyAction: isOpen }) })}
                    isOpen={isOpen} triggerType={triggerType}>
                    {children}
                </ToggleMenuInputButton>
            </InputContainer>
            <MenuOverlayList {...getMenuProps()} className={molclassname} style={menuOverlayStyle}>
                {isOpen &&
                    searchFilteredItems?.map((item) => {
                        const itemIndex = items?.findIndex((e: any) => e?.value === item?.value);
                        return (
                            <MenuOverlayListItem
                                itemType={itemType ? itemType : 'normal'}
                                checkboxEmplacement={checkboxEmplacement}
                                isVisible={true}
                                className={moliclassname}
                                style={menuOverlayListItemStyle}
                                key={`${item?.value}${items?.indexOf(item)}`}
                                isHighlighted={highlightedIndex === itemIndex}
                                isSelected={selectedItems?.findIndex(e => e.value === item.value) > -1}
                                icon={item?.icon ?? undefined}
                                leftIconItemContainerClassName={leftIconItemContainerClassName}
                                {...getItemProps({ item, index: itemIndex })}
                            >
                                {item.label}
                            </MenuOverlayListItem>
                        )
                    })}
            </MenuOverlayList>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
    );
});

export const Tag = forwardRef<HTMLDivElement, TagProps>((props, ref) => {
    const { children, style = {}, className = '', hasCloseButton, ...rest } = props;

    return (
        <div ref={ref} tabIndex={hasCloseButton ? 0 : undefined}
            className={classNames(cls.hostCustomTagDefault, className)} style={style} {...rest}>
            {children}
            {hasCloseButton && <CloseIconPurple className={cls.customTagCloseIcon} />}
        </div>
    );
});

export default MultiSelectWithSearchNew;
