import { createReducerContext } from 'react-use';
const R = require('ramda');

type keysString = { [key: string]: string };
type keysBool = { [key: string]: boolean };
type keys = string | boolean | keysBool | keysString | { [key: string]: string | boolean | keysBool | keysString };

export type TheNewProcessStepContextData =
	| {
			open: boolean;
			activeElement: string;
			[key: string]: string | boolean;
	  }
	| keys
	| { [key: string]: keys | { [key: string]: keys } };
const clean = (obj: obje) => (obj ? filterObject(obj, error => error === true) : {});

const processStepContextReducer = (state: any, payload: any) => {
    const { open, activeElement, errors } = payload;
    const newErrors = errors ? clean(errors) : {};

    return {
        ...state,
        open: open,
        activeElement: activeElement,
        errors: { ...clean(state?.errors), ...newErrors },
    };
};

export const [useProcessStepContext, TheProcessStepContext] = createReducerContext(processStepContextReducer, { open: false, activeElement: '', errors: {} });

export type PathwayDerivedBlueprintType = {
	id: string;
	step_blueprint_id: string;
	name: string;
	icon: string;
	title: string;
	selected_fields: DerivedBluePrintFieldType[];
};
export type PathwayType = {
	id: string;
	name: string;
	rank: number;
	guidance: string;
	procedure_id: string;
	component_blueprints: PathwayDerivedBlueprintType[];
};
export type DerivedBluePrintFieldType = {
	id: string;
	step_blueprint_id: string;
	field_name: string;
	options: { [key: string]: string | number | boolean }[] | null;
	mandatory: boolean;
	visible: boolean;
	index: number;
	data_type: string;
	icon: string;
	process_step_blueprint_field_id: string;
};
export type DerivedBlueprintType = {
	id: string;
	step_blueprint_id: string;
	name: string;
	icon: string;
	title: string;
	is_active: boolean;
	is_inherited: boolean;

	inherited_from?: string;
	inherited_from_name?: string;
	is_duplicable?: string;

	status: {
		id: string;
		name: string;
	};
	selected_fields: DerivedBluePrintFieldType[];
	_uuid: string;
};

export type ProcessStepType = {
	isNewStep?: boolean;
	hasStatus?: boolean;
	hasTitle?: boolean;

	id: string;
	name: string;
	guidance: string;
	duration: number;
	rank: number;
	is_start: boolean;
	is_end?: boolean;
	procedure_id: string;
	options: {
		is_import_in_next?: boolean;
		is_start_next_process?: boolean;
		allows_search_components?: boolean;
	};

	parent_steps: string[];
	pathway_steps: any[];
	pathway_processes: any[];
	component_blueprints: DerivedBlueprintType[];
};
export type TransformedStepType = {
	isNewStep?: boolean;
	hasStatus?: boolean;
	hasTitle?: boolean;

	id?: string;
	name?: string;
	guidance?: string;
	duration?: number;
	rank?: number;
	is_start?: boolean;
	is_end?: boolean;
	procedure_id?: string;
	options?: {
		is_import_in_next?: boolean;
		is_start_next_process?: boolean;
		allows_search_components?: boolean;
	};

	parent_steps?: string[];
	pathway_steps?: any[];
	pathway_processes?: any[];
	component_blueprints?: DerivedBlueprintType[];
};

type ProcessActions = {
	[callerType: string]:
		| 'SET_ENTIRE_CONTEXT_STEPS_AT_ONCE'
		| 'RECOMPUTE RANK FOR ALL STEPS'
		| 'REMOVE_ENTRY_BY_ID_FROM_STEPS'
		| 'ADD_NEW_EMPTY_STEP'
		| 'SET_SELECTED_STEP'
		| 'REMOVE_SELECTED_STEP'
		| 'EDIT_STEP_OPTIONS'
		| 'EDIT_STEP_KEY_VALUE'
		| 'UPDATE_ARRAY_OF_PARENT_STEPS_IN_STEP'
		| 'UPDATE_ARRAY_FOR_PATHWAYS_IN_STEP'
		| 'ADD_NEW_DERIVED_COMPONENT_INSIDE_STEP_COMPONENT_BLUEPRINTS'
		| 'REMOVE_COMPONENT_FROM_STEP_COMPONENT_BLUEPRINTS'
		| 'UPDATE_KEY_VALUE_FOR_SPECIFIC_COMPONENT_BLUEPRINTS_STEP'
		| 'UPDATE_KEY_VALUE_FOR_SPECIFIC_FIELD_IN SPECIFIC_COMPONENT_BLUEPRINTS_STEP'
		| 'UPDATE_COMPONENT_INHERITED_BLUEPRINTS';
};
export const pat = {
    setAllSteps: 'SET_ENTIRE_CONTEXT_STEPS_AT_ONCE',
    reloadAllSteps: 'RELOAD_ENTIRE_CONTEXT_STEPS_AT_ONCE',
    recomputeStepsRanks: 'RECOMPUTE RANK FOR ALL STEPS',
    removeStepById: 'REMOVE_ENTRY_BY_ID_FROM_STEPS',
    addNewStep: 'ADD_NEW_EMPTY_STEP',
    setSelectedStep: 'SET_SELECTED_STEP',
    unsetSelectedStep: 'REMOVE_SELECTED_STEP',

    editSelectedStepOptions: 'EDIT_STEP_OPTIONS',
    updateSelectedStepKeyValue: 'EDIT_STEP_KEY_VALUE',
    updateSelectedStepParentSteps: 'UPDATE_ARRAY_OF_PARENT_STEPS_IN_STEP',
    updateSelectedStepPathways: 'UPDATE_ARRAY_FOR_PATHWAYS_IN_STEP',

    addNewComponentAtStep: 'ADD_NEW_DERIVED_COMPONENT_INSIDE_STEP_COMPONENT_BLUEPRINTS',
    removeComponentFromStep: 'REMOVE_COMPONENT_FROM_STEP_COMPONENT_BLUEPRINTS',
    updateComponentKeyValue: 'UPDATE_KEY_VALUE_FOR_SPECIFIC_COMPONENT_BLUEPRINTS_STEP',
    updateComponentFieldKeyValue: 'UPDATE_KEY_VALUE_FOR_SPECIFIC_FIELD_IN SPECIFIC_COMPONENT_BLUEPRINTS_STEP',
    updateComponentsInheritedBlueprints: 'UPDATE_COMPONENT_INHERITED_BLUEPRINTS',
} as const;

type Reducer<State, Action> = (state: State, action: Action) => State;
type ContextType = {
	steps: ProcessStepType[];
    reloadSteps: boolean;
	selectedStep: any; // ProcessStepType | { id: '', options: {} }|TransformedStepType
};

type ContextPayloadType = {
	type: keyof ProcessActions;
	value: ContextType['steps'] & ContextType['selectedStep'] & TransformedStepType;
};
const lensMatching = (pred: (a: () => boolean) => boolean) => (toF: (arg0: any) => readonly any[]) => (entities: readonly any[]) => {
    const index = R.findIndex(pred, entities ?? []);

    return R.map((entity: any) => R.update(index, entity, entities), toF(entities[index]));
};
//const lensById = R.compose<unknown, string>(lensMatching, R.propEq('id'));
//const lensByUUID = R.compose<unknown, string>(lensMatching, R.propEq('_uuid'));
const lensById = R.compose(lensMatching, R.propEq('id'));
const lensByUUID = R.compose(lensMatching, R.propEq('_uuid'));
const processContextReducer: Reducer<ContextType, ContextPayloadType> = (state: ContextType, payload: ContextPayloadType) => {
    const { type, value } = payload;

    /*
		const titlesOf = (arr: Array<any>) => arr?.map((i: any) => ({ [i.inherited_from_name]: i.title, [i?.is_inherited]: i.step_blueprint_id }));
	*/
    switch (type) {
        case pat.setAllSteps:
            return {
                ...state,
                steps: value,
            };
        case pat.reloadAllSteps:
            return {
                ...state,
                reloadSteps: value,
            };
        case pat.recomputeStepsRanks: {
            return {
                ...state,
                steps: state.steps,
            };
        }
        case pat.setSelectedStep:
            return {
                ...state,
                selectedStep: value,
            };
        case pat.unsetSelectedStep:
            return {
                ...state,
                selectedStep: { id: '', options: {} },
                steps: [...R.reject(R.propEq('id', 'newDefaultStep'))(state.steps)],
            };
        case pat.removeStepById:
            return {
                steps: [...R.reject(R.propEq('id', value))(state.steps)],
                selectedStep: { id: '', options: {} },
            };
        case pat.addNewStep: {
            const defaultNewStep = {
                id: 'newDefaultStep',
                isNewStep: true,
                name: '',
                guidance: '',
                duration: 0,
                rank: 1,
                is_start: !R.find(R.propEq('is_start', true))(state?.steps) || state?.steps?.length == 0,
                is_end: false,
                procedure_id: value, //(this is process id passed into value field of payload)
                options: {
                    is_import_in_next: true,
                    is_start_next_process: false,
                    allows_search_components: false,
                },
                parent_steps: [],
                pathway_steps: [],
                pathway_processes: [],
                component_blueprints: [],
            };

            return {
                ...state,
                steps: [...R.append(defaultNewStep)(state.steps)],
                selectedStep: defaultNewStep,
            };
        }
        case pat.editSelectedStepOptions:
            return {
                ...state,
                selectedStep: { ...state.selectedStep, options: { ...state.selectedStep.options, ...value.options } },
            };
        case pat.updateSelectedStepKeyValue: {
            return {
                ...state,
                selectedStep: { ...state.selectedStep, ...value },
            };
        }
        case pat.updateSelectedStepParentSteps:
            return {
                ...state,
                selectedStep: {
                    ...state.selectedStep,
                    parent_steps: [...R.uniq(value)],
                    rank: value?.length > 0 ? R.apply(Math.max, R.map(R.prop('rank'))(value)) + 1 : state.selectedStep.is_start ? 1 : 0,
                },
            };
        case pat.updateSelectedStepPathways: {
            const processes = [...R.reject(R.has('procedure_id'), value)]; //[...value.filter((i: any) => !i.hasOwnProperty('procedure_id'))]
            const steps = [...R.filter(R.has('procedure_id'), value)]; // [...value.filter((i: any) => i.hasOwnProperty('procedure_id'))]

            return {
                ...state,
                selectedStep: { ...state.selectedStep, pathway_processes: processes, pathway_steps: [...steps] },

            };
        }
        case pat.addNewComponentAtStep:
            return {
                ...state,
                selectedStep: { ...state.selectedStep, component_blueprints: [...(state.selectedStep.component_blueprints ?? []), value] },
            };
        case pat.removeComponentFromStep:
            return {
                ...state,
                selectedStep: {
                    ...state.selectedStep,
                    component_blueprints: [...R.reject(R.propEq('_uuid', value.uuid), state.selectedStep.component_blueprints)],
                },
            };

        case pat.updateComponentKeyValue: {
            return {
                ...state,
                selectedStep: {
                    ...state.selectedStep,
                    component_blueprints: [
                        ...R.map(
                            R.when(
                                R.allPass([R.propEq('step_blueprint_id', value.step_blueprint_id), R.propEq('_uuid', value.compBluePrintUUID), R.propEq('is_inherited', value.isInherited)]),
                                value?.status
                                    ? R.compose(R.assoc('status', value.status), R.assoc('hasStatus', value?.status?.id?.length > 0))
                                    : typeof value?.title === 'string'
                                        ? R.compose(
                                            // R.assoc('title', value.title),
                                            R.assocPath(['title'], value.title),
                                            R.assoc('hasTitle', value?.title?.length > 0)
									  )
                                        : value?.options
                                            ? R.compose(R.assoc('options', value.options))
                                            : [true, false].indexOf(value?.is_active) > -1
                                                ? R.assoc('is_active', value.is_active)
                                                : [true, false].indexOf(value?.is_duplicable) > -1
                                                    ? R.assoc('is_duplicable', value.is_duplicable)
                                                    : R.identity
                            )
                        )(state.selectedStep.component_blueprints),
                    ],
                },
            };
        }
        case pat.updateComponentFieldKeyValue: {
            const visibilityLens = R.compose(R.lensProp('component_blueprints'), lensByUUID(value.compBluePrintUUID), R.lensProp('selected_fields'), lensById(value.fieldId), R.lensPath(['visible']));
            const mandatoryLens = R.compose(R.lensProp('component_blueprints'), lensByUUID(value.compBluePrintUUID), R.lensProp('selected_fields'), lensById(value.fieldId), R.lensPath(['mandatory']));

            return {
                ...state,
                selectedStep: R.compose(R.set(visibilityLens, value.visible), R.set(mandatoryLens, value.mandatory))(state?.selectedStep),
            };
        }
        case pat.updateComponentsInheritedBlueprints: {
            const defaultInherited = R.compose(R.filter(R.propEq('is_inherited', true)), R.pathOr([], ['component_blueprints']), R.find(R.propEq('id', state?.selectedStep?.id)))(state.steps);
            const filtered = R.reject((x: any) => defaultInherited.some((e: any) => e.id == x.id && e.inherited_from == x.inherited_from && e.inherited_from_name == x.inherited_from_name))(value);
            const actualNewComponents = R.compose(R.filter(R.propEq('is_inherited', false)))(state?.selectedStep?.component_blueprints ?? []);

            //const intersectObj = (a: any, b: any) => R.pick(R.keys(a), b);
            //const partialEq = (a: any, b: any) => R.equals(a, intersectObj(a, b))
            //const filtered = R.reject((x: any) => defaultInherited.some((e: any) => partialEq(e, x)))(value);

            return {
                ...state,
                selectedStep: {
                    ...state.selectedStep,
                    component_blueprints: [
                        ...(actualNewComponents as Array<any>),
                        ...defaultInherited,
                        ...(filtered as Array<any>),
                        //...value
                    ],
                },
            };
        }
        default:
            return { ...state };
    }
};

export const [useProcessContext, TheProcessContext] = createReducerContext<Reducer<ContextType, ContextPayloadType>>(processContextReducer, { steps: [], reloadSteps: false, selectedStep: { id: '', options: {} } });

type obje = { [key: string]: boolean | null | undefined };
export const filterObject = (obj: obje, predicate: (entry: boolean | null | undefined) => void) =>
    Object.keys(obj)
        .filter(key => predicate(obj[key]))
        .reduce((res: obje, key) => {
            res[key] = obj[key];

            return res;
        }, {});
