import { type } from 'ramda';
import {
    ERRORS_MODAL_CLOSE,
    ERRORS_MODAL_OPEN,
    INFO_MODAL_CLOSE,
    INFO_MODAL_OPEN,
    PREVIEW_CHART_MODAL_CLOSE,
    PREVIEW_CHART_MODAL_OPEN,
    SET_IS_ONBOARDING,
    SET_ONBOARDING_MODALS_VISIBILITY,
    SET_ONBOARDING_VISIBLE,
    SET_PAGED_MODAL_CLOSE,
    SET_PAGED_MODAL_OPEN,
    SET_USER_OVERVIEW_MODAL_CLOSE,
    SET_USER_OVERVIEW_MODAL_OPEN,
    TUTORIAL_MODAL_CLOSE,
    TUTORIAL_MODAL_OPEN,
    RESET_MODALS_REDUCER,
    SET_PREVIOUS_MODAL_NAME,
    SET_IMAGES_LIST,
    SET_COUNTRIES_LIST,
    SET_CHART_PREVIEW,
} from './types';

const objectHas = (config, prop) => Object.prototype.hasOwnProperty.call(config, prop) == true;

export const setIsOnboarding = isOnboarding => (dispatch, _getState) => dispatch({ type: SET_IS_ONBOARDING, payload: isOnboarding });

export const setOnBoardingVisible = (componentVisible) => {
    return (dispatch, _getState) => dispatch({ type: SET_ONBOARDING_VISIBLE, payload: componentVisible });
};
export const setPreviousModalName = name => (dispatch, _getState) => dispatch({ type: SET_PREVIOUS_MODAL_NAME, payload: name });

export const setOnBoardingModalsVisibility = (modalVisible) => {
    return (dispatch, _getState) => {
        if (objectHas(modalVisible, 'firstModalVisible') && modalVisible?.firstModalVisible) {
            dispatch({ type: SET_IS_ONBOARDING, payload: true });
        }

        return dispatch({ type: SET_ONBOARDING_MODALS_VISIBILITY, payload: modalVisible });
    };
};

export const actions_ErrorsModalOpen = data => (dispatch, _getState) => dispatch({ type: ERRORS_MODAL_OPEN, payload: { isOpen: data.isOpen, options: data.options } });
export const actions_ErrorsModalClose = () => (dispatch, _getState) => dispatch({ type: ERRORS_MODAL_CLOSE, payload: { isOpen: false, options: {} } });

export const actions_InfoModalOpen = data => (dispatch, _getState) => dispatch({ type: INFO_MODAL_OPEN, payload: { isOpen: data.isOpen, options: data.options } });
export const action_InfoModalClose = () => (dispatch, _getState) => dispatch({ type: INFO_MODAL_CLOSE, payload: { isOpen: false, options: {} } });

export const actions_PreviewChartModalOpen = data => (dispatch, _getState) => dispatch({ type: PREVIEW_CHART_MODAL_OPEN, payload: { isOpen: data.isOpen, options: data.options } });
export const action_PreviewChartInfoModalClose = () => (dispatch, _getState) => dispatch({ type: PREVIEW_CHART_MODAL_CLOSE, payload: { isOpen: false, options: {} } });

export const tutorialModalOpen = options => (dispatch, _getState) => dispatch({ type: TUTORIAL_MODAL_OPEN, payload: { isOpen: true, options: options } });
export const tutorialModalClose = () => (dispatch, _getState) => dispatch({ type: TUTORIAL_MODAL_CLOSE, payload: { isOpen: false, options: {} } });

export const pagedModalOpen = options => (dispatch, _getState) => dispatch({ type: SET_PAGED_MODAL_OPEN, payload: { isOpen: true, options: options } });
export const pagedModalClose = () => (dispatch, _getState) => dispatch({ type: SET_PAGED_MODAL_CLOSE, payload: { isOpen: false, options: {} } });

export const resetModalsReducer = () => (dispatch, _getState) => dispatch({ type: RESET_MODALS_REDUCER });

export const setImagesList = (imagesList) => dispatch => dispatch({
    type: SET_IMAGES_LIST,
    payload: imagesList
})

export const setCountriesList = (countriesList) => dispatch => dispatch({
    type: SET_COUNTRIES_LIST,
    payload: countriesList
})

export const setChartPreview = (chartPreview) => dispatch =>dispatch({
    type: SET_CHART_PREVIEW,
    payload: chartPreview
})