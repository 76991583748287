import { memo, useEffect, useRef, useState } from 'react';
import './chartStyles.scss';
import cls from './II_NonManagerials.module.scss';
import { useSelector } from 'react-redux';

const II_NonManagerialRenders = memo((props) => {
    const { nmPositions: data, onlyNMPos, showPositionInfo, onContainerDimsChange } = props;
    const posContainerRef = useRef();
    const {chartPreview} = useSelector(store => store.modalsReducer);
    const [II_NMG_Dim] = useState({ colWidth: 0, colHeight: 0 });

    useEffect(() => {
        if (onContainerDimsChange) {
            onContainerDimsChange({
                width: posContainerRef.current?.clientWidth,
                height: posContainerRef.current?.clientHeight,
                offsetLeft: posContainerRef.current?.offsetLeft,
            });
        }
    }, [II_NMG_Dim]);
    if (data?.children?.length == 0) return null;

    return (
        <div ref={posContainerRef} className={cls.host} app-single-item={onlyNMPos ? 'true' : 'false'}>
            {data?.children?.length > 0 ? (
                <div className={cls.target}>
                    {data?.children.map((i, idx) => {
                        return (
                            <div
                                className={cls.cardContainerBase}
                                app-last-item={idx == data?.children?.length - 1 ? 'last' : ''}
                                onClick={() => {
                                    showPositionInfo(i.id);
                                }}
                                key={i + idx}
                                app-org-chart-preview={chartPreview === true ? 'true' : 'false'}
                            >
                                <p>
                                    <span className={cls.companyInfoTitle}>{i.name}</span>
                                    <span>{i.sub_name}</span>
                                </p>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div style={{position: 'relative', marginBottom: '10px', minHeight: '250px'}}/>
            )}
        </div>
    );
});

export default II_NonManagerialRenders;
