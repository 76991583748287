import {useCallback, useEffect, useState} from "react";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import { closeIcon, paperPlane } from "../../../assets/images/new-icons";
import { tutorialModalClose } from "../../../reduxStore/actions/modalsActions";
import { Button, CustomCheckbox, ModalR } from "../../common";
import "./TutorialModal.scss";

const visionCheckOptions = [
    "Is your vision statement a clear, vivid description of one single day in the future?",
    "Is that day a specific date, 3-5 years in the future?",
    "Is your vision statement written in the present tense?",
    "Does the vision statement avoid talking about how you got to that point?",
    "Does it contain specifics, such as revenue, employees, clients, or locations?",
    "Is the vision statement inspirational and slightly out of reach?",
    "Does it get you excited whenever you read it?",
];

const missionCheckOptions = [
    "Does your mission statement depict who you are, why you exist, and what you do?",
    "Does the mission statement help bring clarity and alignment to everyone that works for and with your business?",
    "Is your mission statement true right now, just as much as it will 10 years from now?",
];

const TutorialModal = (props) => {
    const { childrenWrapperStyle, onTutorialModalClose, isOpen, options, hasCheckbox } = props;
    const { tutorialTitle, tutorialType, onCancel, tutorialDescription, videoUrl, showDescription } = options;

    const modalClosing = () => {
        onCancel && onCancel();
        if (isOpen && onTutorialModalClose) {
            onTutorialModalClose();
        }
    };
    const textAreaLabelStyle = "position: absolute; top: 10%; left: 20px; right: 0;/*  bottom: 0, */ userSelect: none; pointerEvents: none; display: block; color: #9B9B9B; fontFamily: var(--app-text-secondary-font); font-size: 16px; font-weight: 400; letter-spacing: 0; line-height: 20px;";
    const [textAreaValue, setTextAreaValue] = useState(
        tutorialDescription ??
        `Hi there! Thank you so much for using Alvanda, being one of the first people to ever use it. This is an amazing moment!<br/><br/>
	
Just please know that we are still changing things as we go, as we are getting feedback. We didn't shoot tutorial videos just yet for every single place.<br/><br/>
	
Because, again we are changing the buttons, we're changing the text, so it might not be relevant from two weeks to another. <br/><br/>
	
However, if you have any questions or if you need any help whatsoever, please email us at <a onClick={handleEmailClick} href="#"> support@alvanda.com</a>  and we will help you, we'll hop on a call with you, and we'll do everything with you and for you.<br/><br/>
	
Thank you so much and have an amazing day. Good luck! Bye!`
    );
    const [textAreaTouched, setTextAreaTouched] = useState(false);


    const textAreaErrorMessage = textAreaValue && textAreaValue?.length < 3 ? `${tutorialTitle} description is required and must have at least 3 characters` : "";

    const [optionsChecked, setOptionsChecked] = useState([]);

    useEffect(() => {
        const checkOptions = (tutorialType === "vision") ? visionCheckOptions :
            (tutorialType === "mission") ? missionCheckOptions : [];

        // Map checkOptions to optionsChecked format
        const mappedOptions = checkOptions.map(option => ({
            text: option,
            value: false,
        }));

        setOptionsChecked(mappedOptions);
    }, [tutorialType]);


    const handleOptionChecked = useCallback(
        (idx, option, checked) => {
            setOptionsChecked(c => [...c.slice(0, idx), { text: option.text, value: checked }, ...c.slice(idx + 1)]);
        },
        [optionsChecked]
    );

    return (
        <div style={{}}>
            <ModalR blured={true} scrollable={true} isOpen={isOpen} onClose={modalClosing}
                shadowedContainerStyle={{ minWidth: "640px", width: "30%", height: "auto" }}>
                <div className={"firstDiv_T"} style={{ ...childrenWrapperStyle }}>
                    <div style={{ position: "absolute", top: "10px", right: "0px", textAlign: "end" }}>
                        <div onClick={modalClosing}
                            style={{ cursor: "pointer", backgroundColor: "transparent", border: 0 }}>
                            <img alt="" src={closeIcon} style={{ cursor: "pointer" }} />
                        </div>
                    </div>
                    <div className={"centered_T"}>
                        <h1 className={"header_T"}>{tutorialTitle} </h1>
                    </div>

                    <div className={"contentContainer_T"}>
                        <div className={"tutorialVideoContainerStyle_T"}>
                            <ReactPlayer
                                controls={true}
                                width="100%" height="314px"
                                url={videoUrl ?? "https://youtu.be/B-zXyqFPEh0"}
                                className={"reactPlayerStyle_T"} />
                        </div>
                    </div>
                    {(showDescription ?? true) ? (
                        <div>
                            <div className={"transcript"}>{"Transcript"}</div>
                            <div className={"contentContainer_T"}>
                                <div className={"textAreaStyle_T"}>
                                    Hi there! Thank you so much for using Alvanda, being one of the first people to ever
                                    use it. This is an amazing moment!<br /><br />

                                    Just please know that we are still changing things as we go, as we are getting
                                    feedback. We didn't shoot tutorial videos just yet for every single
                                    place.<br /><br />

                                    Because, again we are changing the buttons, we're changing the text, so it might not
                                    be relevant from two weeks to another. <br /><br />

                                    However, if you have any questions or if you need any help whatsoever, please email
                                    us at <a style={{ textDecoration: "none" }}
                                        href="mailto:support@alvanda.com"> support@alvanda.com</a> and we will help
                                    you, we'll hop on a call with you, and we'll do everything with you and for
                                    you.<br /><br />

                                    Thank you so much and have an amazing day. Good luck! Bye!
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{ height: "30px" }}></div>
                    )}
                    {hasCheckbox && <div className={"checkContainerStyle"}>
                        {optionsChecked?.length > 0 &&
                            optionsChecked?.map((i, idx) => {
                                return (
                                    <div className={"checkContainer"} key={idx}>
                                        <CustomCheckbox
                                            label={i.text}
                                            onChange={e => handleOptionChecked(idx, i, e.nativeEvent?.target?.checked)}
                                            checked={i.value}
                                            value="commitment"
                                            checkMarkStyle={{ width: "24px", height: "24px" }}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                    }
                    <div style={{ display: "flex", marginLeft: "auto", marginRight: "auto", marginTop: "24px" }}>
                        {/*<Button title={'Provide Feedback'} style={{ marginRight: '16px', width: '180px' }} lighter clicked={() => window.open('https://alvanda.com/support', '_blank')} icon={<img src={uploadIcon} />} />*/}
                        <Button title={"Email Support"} style={{ marginRight: "16px", width: "180px" }} lighter
                            clicked={() => (location.href = "mailto:" + "support@alvanda.com" + "?subject=" + "Feedback for Alvanda")}
                            icon={<img src={paperPlane} />} />
                    </div>
                </div>
            </ModalR>
        </div>
    );
};

const mapStateToProps = (store) => {
    const {
        tutorialModal: { isOpen, options },
    } = store.modalsReducer;

    return { isOpen, options };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onTutorialModalClose: () => dispatch(tutorialModalClose()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TutorialModal);
