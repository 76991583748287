import {useCallback, useEffect, useState} from "react";
import {deletePosition_AndItsChildren, deletePosition_AndKeepChildren} from "../../../services/company.service";
import {Button, CustomCheckbox, ModalR} from "../../common";
import cls from "./RoleInformation.module.scss";
import {CheckIcon, questionMarkDark, warningIcon} from "../../../assets/images/new-icons";
import {deletePosition, deletePositionCheckbox} from "./tooltips";
import {DeleteBin, successDone} from "../../../assets/images/icons";
import Loader from "react-loader-spinner";
import {isSafari} from "react-device-detect";

const DeletePositionComponent = (props) => {
    const { isOpen, onClose, positionInfo } = props;
    const [checkedFirst, setCheckedFirst] = useState(false);
    const [checkedSecond, setCheckedSecond] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showCase, setShowCase] = useState(0);
    const [deleteMessage] = useState('');
    const [warningAnnotation, setWarningAnnotation] = useState(false);
    const settingWarningAnnotation = (val) => {
        setWarningAnnotation(val);
    };
    const choseFirstOption = useCallback(() => {
        if (checkedFirst && checkedSecond) {
            setCheckedSecond(false);
        }
    }, [checkedFirst, checkedSecond]);
    const choseSecondOption = useCallback(() => {
        if (checkedSecond && checkedFirst) {
            setCheckedFirst(false);
        }
    }, [checkedFirst, checkedSecond]);

    useEffect(() => {
        if (checkedFirst && checkedSecond) {
            choseFirstOption();
        }
    }, [checkedFirst]);
    useEffect(() => {
        if (checkedSecond && checkedFirst) {
            choseSecondOption();
        }
    }, [checkedSecond]);

    useEffect(() => {
        if (showCase == 1) {
            var wa = `You are about to delete ${positionInfo.name}`;
            const sps = positionInfo.children.length;

            if (sps > 0) {
                if (checkedFirst) wa += ` and ${sps > 1 ? 'all ' + sps + ' subpositions' : sps + ' subposition '} 
                    associated with this position.`;
                if (checkedSecond) wa += `. All ${sps} subpositions associated within will will be assigned to the 
                    first higher hierarchical position.`;
            } else {
                wa += '.';
            }
            settingWarningAnnotation(wa);
        }
    }, [showCase]);

    const onBackToPositionPressed = () => {
        setLoading(false);
        setShowCase(0);
        onClose({ succes: undefined, message: 'User canceled.' });
    };
    const onDeletePressed = () => {
        if (!checkedFirst && !checkedSecond) {
            setCheckedSecond(true);
        } else {
            setShowCase(1);
        }
    };
    const onConfirmDeletePressed = useCallback(
        (alsoChildren) => {
            setLoading(true);
            if (alsoChildren) {
                deletePosition_AndItsChildren(positionInfo.id).subscribe({
                    next: () => {
                        onClose({ success: true, message: 'The Position Has Been Succesfully Deleted!' });
                    },
                    error: () => {
                        onClose({ success: false, message: 'Error occured in deleting position.' });
                    },
                });
            } else {
                deletePosition_AndKeepChildren(positionInfo.id).subscribe({
                    next: () => {
                        onClose({ success: true, message: 'The Position Has Been Succesfully Deleted!' });
                    },
                    error: () => {
                        onClose({ success: false, message: 'Error occured in deleting position.' });
                    },
                });
            }
        },
        [positionInfo]
    );

    return (
        <ModalR
            shadowedContainerStyle={{ maxWidth: '600px', minWidth: '475px', width: '70%' }}
            onClose={() => {
                onClose({ succes: undefined, message: 'User canceled.' });
            }}
            isOpen={isOpen}
            backdropColor={true}
            blured={true}
            hasCloseIcon
        >
            <div className={cls.deleteModalContainer}>
                {showCase == 0 && (
                    <div className={cls.deletePositionContainer}>
                        <img
                            src={questionMarkDark}
                            style={{
                                position: 'absolute',
                                width: '24px',
                                height: '24px',
                                top: '30px',
                                left: '20px',
                            }}
                        />
                        <h1 className={cls.deletePosition_title}>{'Delete Position'}</h1>
                        <p className={cls.deletePosition_description}>{'Please select the following action:'}</p>
                        <div className={cls.deletePosition_checkboxContainer}>
                            <CustomCheckbox
                                label={
                                    <p>
                                        I want to delete <span style={{ fontWeight: '600' }}>
                                            the position and all it’s subpositions.</span>
                                    </p>
                                }
                                tooltipText={deletePosition}
                                onChange={(checked) => {
                                    setCheckedFirst(checked);
                                }}
                                defaultChecked={checkedFirst}
                                mainContainerStyle={{ margin: '0 0 0 0', padding: 0 }}
                                checkMarkStyle={{ width: '24px', height: '24px' }}
                            />
                        </div>
                        <div className={cls.deletePosition_checkboxContainer}>
                            <CustomCheckbox
                                label={
                                    <p>
                                        I want to just delete <span style={{ fontWeight: '600' }}>the position.</span>
                                    </p>
                                }
                                tooltipText={deletePositionCheckbox}
                                onChange={(checked) => {
                                    setCheckedSecond(checked);
                                }}
                                defaultChecked={checkedSecond}
                                mainContainerStyle={{ margin: '0 0 0 0', padding: 0 }}
                                checkMarkStyle={{ width: '24px', height: '24px' }}
                            />
                        </div>
                        <div className={cls.deletePosition_buttonContainer}>
                            <Button
                                icon={<CheckIcon />}
                                title="Complete Deletion"
                                style={{
                                    marginLeft: '0px',
                                    marginTop: '20px',
                                    padding: '0 10px',
                                    height: '56px',
                                    width: '218px',
                                }}
                                titleStyle={{ textAlign: 'center', padding: '0 10px 0 0' }}
                                clicked={() => onDeletePressed()}
                            />
                        </div>
                    </div>
                )}
                {showCase == 1 && (
                    <div className={cls.warningDeletionContainer}>
                        <img alt="" src={warningIcon} style={{ marginBottom: '9px' }} />
                        <h1 className={cls.warningDeletion_warning}>{'Warning!'}</h1>
                        <p className={cls.warningDeletion_warningAnnotation}>{'This action is irreversible.'}</p>
                        <div className={cls.warningDeletion_warningInformation}>
                            <p>{warningAnnotation}</p>
                        </div>
                        <div className={cls.warningDeletion_buttonContainer}>
                            <Button
                                icon={<DeleteBin />}
                                loading={loading}
                                title="I Understand, Delete Permanently"
                                style={{
                                    marginLeft: '0px',
                                    backgroundColor: '#EB5757',
                                    width: '326px',
                                    height: '56px',
                                }}
                                titleStyle={{ textAlign: 'center', padding: '0 10px 0 0' }}
                                clicked={() => onConfirmDeletePressed(checkedFirst && !checkedSecond)}
                            />
                        </div>
                    </div>
                )}
                {showCase == 2 && (
                    <div className={cls.loadingContainer}>
                        <Loader type={isSafari ? 'Watch' : 'ThreeDots'}
                            color="var(--app-color-gray-dark)" secondaryColor="blue" height={'250px'} width={'250px'}/>
                    </div>
                )}
                {showCase == 3 && (
                    <div className={cls.resultOperationWrapper}>
                        <div className={cls.resultOperationContainer}>
                            <img alt="" src={successDone}
                                style={{ padding: '10px 10px', width: '194px', height: '194px' }} />
                            <div className={cls.resultContainer}>
                                <h1 className={cls.resultText}>{deleteMessage}</h1>
                            </div>
                            <div className={cls.buttonContainer}>
                                <Button title="Back to Chart" style={{ marginLeft: '0px'}} t
                                    itleStyle={{ textAlign: 'center', padding: '0 10px 0 0' }} clicked={() => onBackToPositionPressed()} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </ModalR>
    );
};

export default DeletePositionComponent;
