import {useState, useEffect} from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import {
    getDaysBetweenDates,
    isDateInRecurringLogic,
    recurTaskTime,
} from '../../../../shared/utility';
import './DateCalendar.scss';

const DateCalendar = ({dueDate, taskRecurringDetails, setTaskRecurringDetails}) => {
    const {skipWeekends, recurForever, taskRepeatNumber, recurType, customRecurOptions} = taskRecurringDetails || {};
    const [startDate, setStartDate] = useState(new Date());
    const [taskDatesList, setTaskDatesList] = useState([]);
    const startDateNumber = dueDate.getDate();

    useEffect(() => {
        setStartDate(dueDate);
    }, [dueDate]);

    useEffect(() => {
        let endDate = null;//for forever it would be null

        //Logic Start: For making list of dates who lies withing user selection.
        if (!recurForever) {
            const every = recurType?.value === 'custom' ? customRecurOptions.every : 1;
            const unit = recurType?.value === 'custom' ? customRecurOptions.period.value : recurTaskTime[recurType?.value];

            const dates = [];
            let currentDate = moment(startDate).add(every, unit);//e.g .add(1, 'days')

            for (let i = 0; i < taskRepeatNumber; i++) {
                const dayName = currentDate.format('dddd').toLowerCase();

                //In case day is weekend then that no will be skipped.
                if (skipWeekends && ['saturday', 'sunday'].includes(dayName)) {
                    i--;
                } else {
                    //Storing endDate here as this loop is taking care of weekEnd day checks.
                    endDate = currentDate.format('YYYY-MM-DD HH:mm:ss');
                    dates.push(currentDate.format('YYYY-MM-DD'));
                }
                // Increment the date based on the specified type and every value
                currentDate.add(every, unit);
            }
            setTaskDatesList(dates);
        } else {
            setTaskDatesList([]);
        }
        //Logic End: For making list of dates who lies withing user selection.

        if (recurType?.value === 'daily' || recurType?.value === 'weekly') {
            const recurDaysOfWeek = getDaysBetweenDates(startDate, endDate, recurType?.value, skipWeekends);

            setTaskRecurringDetails({
                ...taskRecurringDetails,
                endsAt: endDate,
                daysOfWeektoRecur: recurDaysOfWeek,
            });
        } else if (recurType?.value === 'monthly') {
            setTaskRecurringDetails({
                ...taskRecurringDetails,
                endsAt: endDate,
                dateOfMonthtoRecur: startDateNumber,
            });
        } else if (recurType?.value === 'yearly') {
            setTaskRecurringDetails({
                ...taskRecurringDetails,
                endsAt: endDate,
                monthToRecur: moment(startDate).format('MMMM'),
                dateOfMonthtoRecur: startDateNumber,
            });
        } else if (recurType?.value === 'custom' && customRecurOptions.period.label === 'Day') {
            const recurDaysOfWeekCustom = getDaysBetweenDates(startDate, endDate, 'daily', skipWeekends, customRecurOptions.every);

            setTaskRecurringDetails({
                ...taskRecurringDetails,
                endsAt: endDate,
                daysOfWeektoRecur: recurDaysOfWeekCustom,
            });
        }


    }, [startDate, skipWeekends, recurForever, taskRepeatNumber, customRecurOptions, recurType]);

    const highlightRecurringDate = (date) => {
        const every = recurType?.value === 'custom' ? customRecurOptions.every : 1;
        const unit = recurType?.value === 'custom' ? customRecurOptions.period.value : recurTaskTime[recurType?.value];

        if ((!recurForever && taskDatesList.includes(moment(date).format('YYYY-MM-DD'))) ||
            (recurForever && date > dueDate && isDateInRecurringLogic(date, startDate, every, unit, skipWeekends))) {
            return 'tile-highlight';
        }
    };

    return <Calendar calendarType="hebrew" value={startDate} showNeighboringMonth={false}
        tileClassName={({date}) => highlightRecurringDate(date)}
        formatShortWeekday={(_, startDate) => [`S`, `M`, `T`, `W`, `T`, `F`, `S`][startDate.getDay()]}
        className="react-calendar"/>;
};

export default DateCalendar;
