import { useEffect, useState } from 'react';
import { CustomCheckbox } from '../../../../common';
import cls from './MonthDaysSelect.module.scss';
import { VectorDown } from '../../../../../assets/images/new-icons';

function MonthDaysSelect({ dropdownType, items, onSelection, placeholder, message }) {
    const [isOpen, setIsOpen] = useState(false);
    const [toggleItems, setToggleItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    const handleOpenNestedMenu = (value) => {
        if (toggleItems.includes(value)) {
            setToggleItems(prev => prev.filter(el => el !== value));
        } else {
            setToggleItems([...toggleItems, value]);
        }
    };

    const handleItemClick = (item) => {
        const isItemSelected = selectedItems.findIndex(el => el.label === item.label) > -1;

        if (isItemSelected) {
            const updatedItems = selectedItems.filter(el => el.label !== item.label);

            setSelectedItems(updatedItems);
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    };

    const checkOptionSelected = (mainItemLabel, optionLabel) => {
        const mainItem = selectedItems?.find(item => item?.label === mainItemLabel);

        return mainItem?.options?.findIndex(item => item?.label === optionLabel) > -1;
    };

    const handleSelectOption = (mainItem, option) => {
        const isMainItemChecked = selectedItems?.findIndex(item => item?.label === mainItem?.label) > -1;

        if (isMainItemChecked) {
            const updatedItems = selectedItems.reduce((acc, item) => {
                const isSelected = item.options.findIndex(el => el.label === option.label) > -1;

                if (isSelected) {
                    const filteredOptions = item.options.filter(el => el.label !== option.label);

                    if (filteredOptions?.length > 0) {
                        return [...acc, { ...item, options: filteredOptions }];
                    } else {
                        return acc;
                    }
                } else {
                    return [...acc, { ...item, options: [...item.options, option] }];
                }
            }, []);

            setSelectedItems(updatedItems);
        } else {
            setSelectedItems(prev => [...prev, { ...mainItem, options: [option] }]);
        }
    };

    const renderSelectedItems = () => {
        if (dropdownType === 'multiselect') {
            return selectedItems.length > 0 ? (
                selectedItems?.slice(0, 2).map((item) => {
                    return item?.options?.slice(0, 2).map((childItem) => {
                        return `${item?.label[0].toUpperCase()}${item?.label.slice(1)} ${childItem.label},`;
                    });
                })
            ) : (
                <>{placeholder}</>
            );
        }
        if (dropdownType === 'select') {
            return selectedItems.length > 0 ? (
                selectedItems?.slice(0, 4).map((item, index) => {
                    const itemName = index === 0 ? item.label : index === 3 ? ` & ${selectedItems?.slice(-1)[0]?.label}` : `, ${item.label}`;

                    return `${itemName}`;
                })
            ) : (
                <>{placeholder}</>
            );
        }
    };

    useEffect(() => {
        onSelection(selectedItems);
    }, [selectedItems]);

    return (
        <>
            <div className={cls.customMonthOptionContainer} onClick={() => setIsOpen(!isOpen)}>
                <div className={cls.toggleMenuButton}>
                    <p>{renderSelectedItems()}</p>
                </div>
                <VectorDown
                    style={{
                        margin: '0 10px 0 8px',
                        transform: isOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
                        transition: 'transform 0.6s ease',
                    }}
                />
            </div>
            {isOpen && (
                <div className={cls.optionsContainer} onBlur={() => setIsOpen(false)}>
                    {items?.map(item => (
                        <>
                            <div key={item.label} className={cls.monthName}>
                                <CustomCheckbox
                                    labelStyle={{ fontWeight: 300, fontSize: '16px' }}
                                    label={item?.label}
                                    onChange={() => {
                                        handleItemClick(item);
                                    }}
                                    defaultChecked={selectedItems?.findIndex(el => el?.label === item.label) > -1}
                                    checkMarkStyle={{ width: '16px', height: '16px' }}
                                    checkIconStyle={{ width: '11px', height: '8px' }}
                                />
                                {dropdownType === 'multiselect' && (
                                    <VectorDown
                                        onClick={() => handleOpenNestedMenu(item?.label)}
                                        style={{
                                            margin: '0 10px 0 8px',
                                            transform: toggleItems.includes(item?.label) ? 'rotate(0deg)' : 'rotate(-90deg)',
                                            transition: 'transform 0.6s ease',
                                        }}
                                    />
                                )}
                            </div>
                            {dropdownType === 'multiselect' &&
								toggleItems?.includes(item?.label) &&
								item?.options?.map(option => (
								    <div key={option.label} className={cls.monthDateoption}>
								        <CustomCheckbox
								            labelStyle={{ fontWeight: 300, fontSize: '16px' }}
								            label={option?.label}
								            onChange={() => {
								                handleSelectOption(item, option);
								            }}
											defaultChecked={checkOptionSelected(item?.label, option.label)}
											checkMarkStyle={{ width: '16px', height: '16px' }}
											checkIconStyle={{ width: '11px', height: '8px' }}
										/>
									</div>
								))}
                        </>
                    ))}
                </div>
            )}
        </>
    );
}

export default MonthDaysSelect;
