import 'react-datepicker/dist/react-datepicker.css';
import { ModalR } from '../../../common';
import TaskForm from '../TaskForm/TaskForm';
import { closeIcon, createTaskIcon, editPencil, questionMarkDark } from '../../../../assets/images/new-icons';
import ConfirmationModal from '../../../../components/common/ConfirmationModal/ConfirmationModal'
const TaskModal = ({ isEdit, modalVisible, heading, onClose, isProcess, setIsProcessBegin, task, selectedProcedure,setunsavedChanges }) => {
    return (
        <div>
            <ModalR scrollable={true} isOpen={modalVisible} onClose={() => onClose()} blured={true}>
                <div>
                    <img src={questionMarkDark}
                        style={{ position: 'absolute', width: '24px', height: '24px', top: '35px', left: '40px' }} />
                    <div style={{ position: 'absolute', top: '35px', right: '40px' }}>
                        <div onClick={()=>{onClose()}} style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 0 }}>
                            <img alt="" src={closeIcon} style={{ width: '24px', height: '24px' }} />
                        </div>
                    </div>
                    <p
                        style={{
                            fontFamily: 'var(--app-text-main-font)',
                            fontSize: '24px',
                            color: 'var(--alvanda-dark-purple)',
                            fontWeight: 600,
                            marginTop: '24px',
                            marginBottom: '5px',
                            textAlign: 'center',
                        }}
                    >
                        <img
                            src={isEdit ? editPencil : createTaskIcon}
                            style={
                                isEdit
                                    ? { marginRight: '12px' }
                                    : {
                                        marginRight: '12px',
                                        height: '22px',
                                        marginBottom: '-2px',
									  }
                            }
                        />
                        {heading}
                    </p>
                    <TaskForm task={task}
                        setIsProcessBegin={setIsProcessBegin}
                        setDisplayModal={(modelState, saveCheckBypass)=>{onClose(saveCheckBypass)}}
                        isProcess={isProcess}
                        isEdit={isEdit}
                        selectedProcedure={selectedProcedure}
                        setunsavedChanges={(status)=>{setunsavedChanges(status)}} />
                </div>
            </ModalR>
        </div>
    );
};

export default TaskModal;
