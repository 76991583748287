import { useEffect, useState } from 'react';
import { FlagImage } from 'react-international-phone';
import 'react-international-phone/style.css';
import { connect } from 'react-redux';
import { UnfocusableWrapper } from '../..';
import { getAllCountries } from '../../../../services/constants.service';
import StyledInput from '../StyledInput/StyledInput';
import cls from './SelectCountry.module.scss';

const SelectCountry = props => {
	const { defaultValue, onChange, countriesList } = props;
	const [selectedCountry, setSelectedCountry] = useState(defaultValue);
	const [openDropdown, setOpenDropdown] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');

	useEffect(() => {
		if (countriesList.length === 0) getAllCountries().subscribe();
	}, []);

	function searchCountry(query) {
		let res = countriesList?.filter(country => {
			const [name, code, dialCode] = country;
			const normalizedQuery = query.toLowerCase();
			return name.toLowerCase().includes(normalizedQuery) || dialCode.includes(normalizedQuery);
		});

		return Array.isArray(res) ? res : [res];
	}
	return (
		<UnfocusableWrapper>
			<div className={cls.selectToggleMenuButton} onClick={() => setOpenDropdown(!openDropdown)}>
				<FlagImage iso2={selectedCountry.country_code} size="30px" />
				<p>{selectedCountry?.country_prefix}</p>
				<p>{selectedCountry?.country_name}</p>
				{openDropdown && (
					<div className={cls.toggleMenu}>
						{searchCountry(searchQuery)?.map(country => {
							const [name, code, dialCode] = country;
							return (
								<div
									onClick={() => {
										setSelectedCountry({ country_code: code, country_prefix: `+${dialCode}`, country_name: name });
										onChange({ country_code: code, country_prefix: `+${dialCode}`, country_name: name });
										setSearchQuery('');
									}}
									className={cls.toggleListItem}
									style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
								>
									<FlagImage iso2={code} size="24px" />
									<p>+{dialCode}</p>
									<p>{name}</p>
								</div>
							);
						})}
					</div>
				)}
			</div>
			{openDropdown ? <StyledInput className={cls.searchInput} extraClass={cls.innerSearchClass} placeholder={'Search for countries'} value={searchQuery} onChange={e => setSearchQuery(e.target.value)} /> : <div style={{ height: '42px' }}></div>}
		</UnfocusableWrapper>
	);
};

const mapStateToProps = store => {
	const { countriesList } = store.modalsReducer;
	return {
		countriesList,
	};
};

export default connect(mapStateToProps)(SelectCountry);
