import 'rc-datetime-picker/dist/picker.css';
import {Button, ModalR} from '../index';
import {closeIconPurple, successLarge} from '../../../assets/images/new-icons';
import cls from './SuccessModal.module.scss';

const SuccessModal = ({resultsMessage, onClose, isOpen, icon}) => {
    return (
        <ModalR isOpen={isOpen}
            shadowedContainerClassname={cls.modal} onClose={onClose} scrollable={true} blured={true}>
            <div className={cls.content}>
                <img alt={''} height={'120px'} width={'120px'} src={icon ? icon : successLarge}/>
                <p className={cls.resultMessage}>{resultsMessage}</p>
                <Button clicked={onClose} lighter title={'Close'}
                    icon={<img alt="" src={closeIconPurple}/>} className={cls.closeButton}/>
            </div>
        </ModalR>
    );
};

export default SuccessModal;
