import { useState, useMemo } from 'react';
import * as R from 'ramda';
import cls from './CurrencySelect.module.scss';
import {
    UnfocusableWrapper,
    SelectWithSearchInMenuList,
} from '../..';

const CurrencySelect = (props) => {
    const { defaultValue, validationErrors, onChange, currencyList, disable } = props;
    const { label, value, symbol } = defaultValue || {};
    const selectedCurrency = { label, value, symbol };

    const dropdownOptions = useMemo(() => {
        const optionsData = currencyList?.map((item) => {
            return {
                label: item?.label,
                value: item?.value,
                symbol: item?.symbol,
            };
        });

        return optionsData;
    }, [currencyList]);

    return (
        <UnfocusableWrapper
            app-variant={validationErrors?.currency_type ? 'error' : 'normal'}
            onClick={() => {
                const button = document.getElementsByClassName('selected-flag');

                if (button[0]) {
                    button[0]?.click();
                }
            }}
        >
            <div className={cls.selectToggleMenuButton}>
                <SelectWithSearchInMenuList
                    className={cls.selectHost}
                    disable={disable}
                    defaultSelected={selectedCurrency}
                    searchPlaceholder='Search for currency'
                    items={dropdownOptions}
                    searchInMenuList={true}
                    isCurrencySelect={true}
                    molcontainerclass={cls.selectMolContainer}
                    tmiClassName={cls.toggleButtonClass}
                    molclassname={cls.menuOverlayList}
                    triggerType={'chevron'}
                    placeholder={'Select...'}
                    onChange={(selection) => {
                        onChange({ ...R.omit(['flag'])(selection) });
                    }}
                />
            </div>
        </UnfocusableWrapper>
    );
};

export default CurrencySelect;
