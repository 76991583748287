import cls from './DatePickerComp.module.scss';
import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import { Calendar } from '../../../assets/images/icons';
import UnfocusableWrapper from '../UnfocusableWrapper/UnfocusableWrapper';
import classNames from 'classnames';
export const DatePickerComp = (props) => {
    const { value, selectedDate, disabled, dateChangeHandler, inputContainerClassName, inputClassName, inputStyle, iconClassName, placeholder, id, hasCalendarIcon, showTimeSelect, ...rest } = props;
    const dateTimePickerRef = useRef();

    const toggleDatePicker = () => {
        if (dateTimePickerRef?.current?.state?.isOpen) {
            dateTimePickerRef?.current?.togglePicker(false);
        }
    };
    const onChangeDate = (date) => {
        if (!showTimeSelect) {
            date.setHours(0, 0, 0, 0);
        }
        dateChangeHandler?.(date);
    };

    return (
        <>
            <UnfocusableWrapper onClickOutside={toggleDatePicker} style={{width: "100%"}}>
                <DatePicker
                    disabled={disabled || false}
                    dateFormatCalendar="MMMM"
                    showYearDropdown
                    yearDropdownItemNumber={90}
                    scrollableYearDropdown
                    placeholderText="Founding Date"
                    customInput={
                        <div className={classNames(cls.inputContainer, inputContainerClassName)}>
                            <input readOnly value={value} type="text" id={id ?? 'date-picker'} className={classNames(cls.inputField, inputClassName)} style={inputStyle} placeholder={placeholder} />
                            {hasCalendarIcon && <Calendar className={classNames(cls.calendarSVG, iconClassName)} />}
                        </div>
                    }
                    selected={selectedDate ?? new Date()}
                    onChange={onChangeDate}
                    timeFormat={'h:mm a'}
                    showTimeSelect={showTimeSelect}
                    wrapperClassName={cls.wrapper}
                    {...rest}
                />
            </UnfocusableWrapper>
        </>
    );
};
