import {getSupportRequestById} from "./support.service";
import {getGroupFilteredUsers} from "./group.service";

require('dotenv').config();

export const BASE_URL
    = window.location.hostname === 'localhost'
        ? process.env.REACT_APP_DEV_API_BASE_URL : 'https://'
        + window.location.hostname + process.env.REACT_APP_PRODUCTION_API_BASE_URL;
//export const BASE_URL = 'http://192.168.68.252/api/';

export const strategicRoadmapEnabled = process.env.REACT_APP_FEATURE_STRATEGIC_ROADMAP === 'enabled';

export const URLS = {
    BASE_URL: BASE_URL,
    auth: {
        login: {
            method: 'post',
            url: BASE_URL + 'auth/login',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        },
        verify2FA: {
            method: 'post',
            url: BASE_URL + 'auth/verify-2fa-login',
            headers: {
                Accept: 'application/json',
            },
        },
        getInvitationByHash: hash => ({
            method: 'get',
            url: BASE_URL + `invitations/hash/${hash}`,
            headers: {
                Accept: 'application/json',
                // 'Authorization': `Bearer ${access_token}`
            },
        }),
        getInvitationById: invitationId => ({
            method: 'get',
            url: BASE_URL + `invitations/${invitationId}`,
            headers: {
                Accept: 'application/json',
                // 'Authorization': `Bearer ${access_token}`
            },
        }),
        register: {
            method: 'post',
            url: BASE_URL + 'auth/register',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer null',
                'Content-Type': 'multipart/form-data',
            },
        },
        forgotPassword: {
            method: 'post',
            url: BASE_URL + 'auth/password/forgot',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        },
        resetPassword: {
            method: 'post',
            url: BASE_URL + 'auth/password/reset',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        },
        getBarCode: access_token => ({
            method: 'post',
            url: BASE_URL + 'auth/2fa/generate-secret',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        get2FADetails: access_token => ({
            method: 'get',
            url: BASE_URL + '2fa',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        enable2FA: (access_token, secretCode) => ({
            method: 'post',
            url: BASE_URL + 'auth/2fa/enable',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        disable2FA: (access_token, password) => ({
            method: 'post',
            url: BASE_URL + 'auth/2fa/disable',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        refreshToken: refresh_token => ({
            method: 'post',
            url: BASE_URL + 'auth/refresh',
            data: { refresh_token: refresh_token },
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        }),
        logout: access_token => ({
            method: 'get',
            url: BASE_URL + 'auth/logout',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
    },
    roles:{
        getAllRoles: access_token => ({
            method: 'get',
            url: BASE_URL + 'roles',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
    },
    users: {
        getAllUsers: access_token => ({
            method: 'get',
            url: BASE_URL + 'users',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        getUserProceduresById: (access_token, id) => ({
            method: 'get',
            url: BASE_URL + `users/${id}/procedures`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getUsersInSeveralPositions: access_token => ({
            method: 'post',
            url: BASE_URL + 'users/positions',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        getUnassignedUser: access_token => ({
            method: 'get',
            url: BASE_URL + 'users/unassigned',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        getAuthenticatedUser: access_token => ({
            method: 'get',
            url: BASE_URL + 'users/my-profile',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        addUserToAPosition: (access_token, authUserId, data) => ({
            method: 'post',
            url: BASE_URL + `users/${authUserId}/positions`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
            data: data,
        }),
        deleteUserbyId: (access_token, userId) => ({
            method: 'delete',
            url: BASE_URL + `users/${userId}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        deactivateUserbyId: access_token => ({
            method: 'post',
            url: BASE_URL + `users/deactivate`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        activateUserbyId: access_token=>({
            method: 'post',
            url: BASE_URL + `users/activate`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        changePassword: (access_token, userId) => ({
            method: 'post',
            url: BASE_URL + `users/${userId}/change-password`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        updateUserById: (access_token, userId) => ({
            method: 'patch',
            url: BASE_URL + `users/${userId}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        updateUserRoles: (access_token, userId) => ({
            method: 'patch',
            url: BASE_URL + `users/${userId}/roles-permissions`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        updateUserProfilePicture: (access_token, userId) => ({
            method: 'post',
            url: BASE_URL + `users/${userId}/update-photo`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getUsersFilter: access_token => ({
            method: 'get',
            url: BASE_URL + `users/filters`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        getFilteredUsers: (access_token, query) => ({
            method: 'post',
            url: BASE_URL + `users/filtered/?${query}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
                'Content-Type': 'application/json',
            },
        }),
        getUserById: (access_token, id) => ({
            method: 'get',
            url: BASE_URL + `users/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
                'Content-Type': 'application/json',
            },
        }),
        sendMail: (access_token, id) => ({
            method: 'post',
            url: BASE_URL + `users/${id}/send-email`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
    },
    account: {
        getMyAccounts: access_token => ({
            method: 'get',
            url: BASE_URL + `accounts`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        linkAccount: access_token => ({
            method: 'post',
            url: BASE_URL + `accounts/link`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        unlinkAccount: (access_token, linked_user_id) => ({
            method: 'delete',
            url: BASE_URL + `accounts/unlink/${linked_user_id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        switchAccount: access_token => ({
            method: 'post',
            url: BASE_URL + `accounts/switch`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        switchCompany: access_token => ({
            method: 'post',
            url: BASE_URL + `auth/switch-to-company`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
    },
    company: {
        getCompanyDetails: (access_token, companyId) => ({
            method: 'get',
            url: BASE_URL + 'companies/' + companyId,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getCompanies: (access_token, params) => ({
            method: 'get',
            url: BASE_URL + `companies?${params}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        exportCompaniesCSV: (access_token, params) => ({
            method: 'get',
            url: BASE_URL + `companies/exportCSV?${params}`,
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        }),
        updateCompanyDetails: (access_token, companyId) => ({
            method: 'patch',
            url: BASE_URL + 'companies/' + companyId,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getCompanySMTPProvider: access_token => ({
            method: 'get',
            url: BASE_URL + 'companies/smtp-provider',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        updateCompanySMTPProvider: access_token => ({
            method: 'patch',
            url: BASE_URL + 'companies/smtp-provider',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        testCompanySMTPProvider: access_token => ({
            method: 'post',
            url: BASE_URL + 'companies/smtp-provider/test',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        createCompanyForUser: access_token => ({
            method: 'post',
            url: BASE_URL + `companies/create-for-user`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        updateCompanyLogo: (access_token, companyId) => ({
            method: 'post',
            url: BASE_URL + `companies/${companyId}/logo`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        completeOnBoarding: (access_token, companyId) => ({
            method: 'post',
            url: BASE_URL + `companies/${companyId}/completeOnBoarding`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        deactivateCompany: (access_token, companyId) => ({
            method: 'post',
            url: BASE_URL + `companies/${companyId}/deactivate`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        pauseSubscription: (access_token, companyId, data) => ({
            method: 'post',
            url: BASE_URL + `companies/${companyId}/pause-subscription`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
            data: data,
        }),
        resumeSubscription: (access_token, companyId) => ({
            method: 'post',
            url: BASE_URL + `companies/${companyId}/resume-subscription`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        purchaseSubscription: (access_token, companyId, data) => ({
            method: 'post',
            url: BASE_URL + `companies/${companyId}/services`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
            data: data,
        }),
    },
    groups: {
        createDivision: (access_token, group_id) => ({
            method: 'post',
            url: BASE_URL + `groups/${group_id}/divisions`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        updateDivision: (access_token, group_id, division_id) => ({
            method: 'patch',
            url: BASE_URL + `groups/${group_id}/divisions/${division_id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }),
        deleteDivision: (access_token, group_id, division_id) => ({
            method: 'delete',
            url: BASE_URL + `groups/${group_id}/divisions/${division_id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        getAllDivisions: (access_token, group_id) => ({
            method: 'get',
            url: BASE_URL + `groups/${group_id}/divisions`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        createGroup: access_token => ({
            method: 'post',
            url: BASE_URL + 'groups',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        updateGroup: (access_token, group_id) => ({
            method: 'patch',
            url: BASE_URL + `groups/${group_id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getMyGroups: access_token => ({
            method: 'get',
            url: BASE_URL + 'groups',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getGroupOrgChart: (access_token, group_id) => ({
            method: 'get',
            url: BASE_URL + `groups/${group_id}/chart`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getCompanies: (access_token, group_id, params) => ({
            method: 'get',
            url: BASE_URL + `groups/${group_id}/group-companies?${params}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getCompanyDetails: (access_token, group_id, companyId) => ({
            method: 'get',
            url: BASE_URL + `groups/${group_id}/group-companies/${companyId}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getGroupFilteredUsers: (access_token, group_id, companyId, query) => ({
            method: 'get',
            url: BASE_URL + `groups/${group_id}/group-companies/${companyId}/users?${query}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        createGroupCompany: (access_token, group_id) => ({
            method: 'post',
            url: BASE_URL + `groups/${group_id}/group-companies`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        updateGroupCompanyDetails: (access_token, group_id, companyId) => ({
            method: 'patch',
            url: BASE_URL + `groups/${group_id}/group-companies/${companyId}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        updateGroupCompanyLogo: (access_token, group_id, companyId) => ({
            method: 'post',
            url: BASE_URL + `groups/${group_id}/group-companies/${companyId}/logo`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${access_token}`,
            },
        }),
    },
    flows: {
        getFlows: (access_token, component_blueprint_id, params) => ({
            method: 'post',
            url: BASE_URL + `flows/${component_blueprint_id}/?${params}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
    },
    departments: {
        getAllDepartments: access_token => ({
            method: 'get',
            url: BASE_URL + 'departments',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getDepartmentById: id => ({
            method: 'get',
            url: BASE_URL + `departments/${id}`,
            headers: {
                Accept: 'application/json',
            },
        }),
        createNewDepartment: access_token => ({
            method: 'post',
            url: BASE_URL + 'departments/',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        updateDepartmentById: (access_token, department_id) => ({
            method: 'patch',
            url: BASE_URL + `departments/${department_id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }),
        getAllDepartmentPositions: (access_token, department_id) => ({
            method: 'get',
            url: BASE_URL + `departments/${department_id}/positions`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getDepartmentManagerialPositions: (access_token, department_id) => ({
            method: 'get',
            url: BASE_URL + `departments/${department_id}/managerial-positions`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getCompanyAllDepartmentManagerialPositions: access_token => ({
            method: 'get',
            url: BASE_URL + `departments/managers`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        updateDepartmentsOrder: access_token => ({
            method: 'patch',
            url: BASE_URL + 'departments/order',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        deleteDepartment: (access_token, department_id) => ({
            method: 'delete',
            url: BASE_URL + `departments/${department_id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
    },
    invitations: {
        getInvitations: (access_token, query) => ({
            method: 'get',
            url: BASE_URL + `invitations/?${query}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        createInvitation: (access_token, sentFormData) => ({
            method: 'post',
            url: BASE_URL + 'invitations',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${access_token}`,
            },
            data: sentFormData,
        }),
        importInvitation: (access_token, sentFormData) => ({
            method: 'post',
            url: BASE_URL + 'invitations/import',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${access_token}`,
            },
            data: sentFormData,
        }),
        resendAnInvitation: (access_token, id) => ({
            method: 'post',
            url: BASE_URL + `invitations/resend/${id}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getInvitationById: (access_token, id) => ({
            method: 'get',
            url: BASE_URL + `invitations/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getInvitationByHash: (access_token, hash) => ({
            method: 'get',
            url: BASE_URL + `invitations/hash/${hash}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getAllInvitations: access_token => ({
            method: 'get',
            url: BASE_URL + 'invitations/',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        deleteInvitationById: (access_token, id) => ({
            method: 'delete',
            url: BASE_URL + `invitations/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
    },
    positions: {
        getAllPositions: (access_token, params) => ({
            method: 'get',
            url: BASE_URL + 'positions?' + params,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getPositionsOrgChart: access_token => ({
            method: 'get',
            url: BASE_URL + 'positions/chart',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getAllPositionsAsArray: (access_token, optional_params) => ({
            method: 'get',
            url: BASE_URL + 'positions' + optional_params,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getInvitablePositionsAsArray: access_token => ({
            method: 'get',
            url: BASE_URL + 'positions/invitable',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        addUserToPosition: (access_token, positionId, data) => ({
            method: 'post',
            url: BASE_URL + `positions/${positionId}/users`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'multipart/form-data',
            },
            data: data,
        }),
        deleteUserFromPosition: (access_token, positionId, data) => ({
            method: 'delete',
            url: BASE_URL + `positions/${positionId}/users`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: data,
        }),
        shareUserToPosition: (access_token, positionId, data) => ({
            method: 'post',
            url: BASE_URL + `positions/${positionId}/share-users`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                dataType: 'json',
                contentType: 'application/json',
            },
            data: data,
        }),
        unshareUserFromPosition: (access_token, positionId, data) => ({
            method: 'delete',
            url: BASE_URL + `positions/${positionId}/share-users`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: data,
        }),

        getPositionById: (access_token, id) => ({
            method: 'get',
            url: BASE_URL + `positions/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getPositionUsers: (access_token, id) => ({
            method: 'get',
            url: BASE_URL + `positions/${id}/users`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        createNewPosition: access_token => ({
            method: 'post',
            url: BASE_URL + 'positions',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        duplicatePosition: (access_token, positionId) => ({
            method: 'post',
            url: BASE_URL + `positions/${positionId}/duplicate`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        updatePositionById: (access_token, id) => ({
            method: 'patch',
            url: BASE_URL + `positions/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        deletePositionAndItsChildren: (access_token, id) => ({
            method: 'delete',
            url: BASE_URL + `positions/${id}?delete_children=1`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        deletePositionAndKeepChildren: (access_token, id) => ({
            method: 'delete',
            url: BASE_URL + `positions/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
    },
    tasks: {
        deleteTask: (access_token, taskId) => ({
            method: 'delete',
            url: BASE_URL + `tasks/${taskId}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        fetchTaskById: (access_token, taskId) => ({
            method: 'get',
            url: BASE_URL + `tasks/${taskId}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        editTask: (access_token, taskId) => ({
            method: 'patch',
            url: BASE_URL + `tasks/${taskId}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getAllTasks: (access_token, userId) => ({
            method: 'get',
            url: BASE_URL + `tasks?assigned_to=${userId}&status=incomplete&per_page=500`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        getAllCompletedTasks: (access_token, page, userId, per_page=50, sort = { type: "completed_at", order: "desc" }) => ({
            method: 'get',
            url: BASE_URL + `tasks?assigned_to=${userId}&status=complete&per_page=${per_page}&page=${page}&sort_by=${sort?.type}&sort_dir=${sort?.order}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        createTask: access_token => ({
            method: 'post',
            url: BASE_URL + 'tasks',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        handoverTask: (access_token, taskId) => ({
            method: 'post',
            url: BASE_URL + `tasks/${taskId}/handover`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'multipart/form-data',
            },
        }),
        startTaskById: (access_token, taskId) => ({
            method: 'post',
            url: BASE_URL + `tasks/${taskId}/start`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        pauseTaskById: (access_token, taskId) => ({
            method: 'post',
            url: BASE_URL + `tasks/${taskId}/pause`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        resumeTaskById: (access_token, taskId) => ({
            method: 'post',
            url: BASE_URL + `tasks/${taskId}/resume`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        attachComponentsToTask: (access_token, taskId) => ({
            method: 'post',
            url: BASE_URL + `tasks/${taskId}/attach-components`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        detachComponentsToTask: (access_token, taskId) => ({
            method: 'delete',
            url: BASE_URL + `tasks/${taskId}/detach-components`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        endTaskById: (access_token, taskId) => ({
            method: 'post',
            url: BASE_URL + `tasks/${taskId}/complete`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getStepComponents: (access_token, taskId) => ({
            method: 'post',
            url: BASE_URL + `tasks/${taskId}/step-components`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getInheritedComponents: (access_token, task_id) => ({
            method: 'post',
            url: BASE_URL + `tasks/${task_id}/inherited-components`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
    },
    comments: {
        createComment: access_token => ({
            method: 'post',
            url: BASE_URL + 'comments',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        reportComment: (access_token, commentId) => ({
            method: 'post',
            url: BASE_URL + `comments/${commentId}/report`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        deleteComment: (access_token, commentId) => ({
            method: 'delete',
            url: BASE_URL + `comments/${commentId}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        getAllComments: (access_token, object_type, object_id) => ({
            method: 'get',
            url: BASE_URL + `comments?object_type=${object_type}&object_id=${object_id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        getReportedComments: access_token => ({
            method: 'get',
            url: BASE_URL + `comments/list-reported`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        getCommentById: (access_token, commentId) => ({
            method: 'get',
            url: BASE_URL + `comments/${commentId}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        updateComment: (access_token, commentId) => ({
            method: 'patch',
            url: BASE_URL + `comments/${commentId}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
    },
    components: {
        createComponent: access_token => ({
            method: 'post',
            url: BASE_URL + 'components',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        editComponent: (access_token, component_id) => ({
            method: 'patch',
            url: BASE_URL + `components/${component_id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        removeComponent: (access_token, component_id) => ({
            method: 'delete',
            url: BASE_URL + `components/${component_id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getComponents: (access_token, query) => ({
            method: 'get',
            url: BASE_URL + `components/?per_page=50000&${query}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getComponentHistory: (access_token, component_id) => ({
            method: 'get',
            url: BASE_URL + `components/${component_id}?withHistory=1`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        duplicateComponent: (access_token, component_id) => ({
            method: 'patch',
            url: BASE_URL + `components/${component_id}/duplicate`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        fileUpload: access_token => ({
            method: 'post',
            url: BASE_URL + 'components/upload',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getComponentsFilters: access_token => ({
            method: 'get',
            url: BASE_URL + `components/filters`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        getFilteredComponents: (access_token, query) => ({
            method: 'post',
            url: BASE_URL + `components/filtered/?${query}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
                'Content-Type': 'application/json',
            },
        }),
        getComponentById: (access_token, id) => ({
            method: 'get',
            url: BASE_URL + `components/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        archiveComponent: (access_token, component_id) => ({
            method: 'post',
            url: BASE_URL + `components/${component_id}/archive`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        unArchiveComponent: (access_token, component_id) => ({
            method: 'post',
            url: BASE_URL + `components/${component_id}/un-archive`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
    },
    componentsBlueprint: {
        getAllComponents: (access_token, params) => ({
            method: 'get',
            url: BASE_URL + 'components/blueprints' + params,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'multipart/form-data',
            },
        }),
        createComponentBlueprint: access_token => ({
            method: 'post',
            url: BASE_URL + 'components/blueprints',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        updateComponentBlueprint: (access_token, component_blueprint_id) => ({
            method: 'patch',
            url: BASE_URL + `components/blueprints/${component_blueprint_id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        removeComponentBlueprint: (access_token, component_blueprint_id) => ({
            method: 'delete',
            url: BASE_URL + `components/blueprints/${component_blueprint_id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        addNewField: (access_token, component_blueprint_id) => ({
            method: 'post',
            url: BASE_URL + `components/blueprints/${component_blueprint_id}/add-field`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        updateField: (access_token, component_blueprint_id) => ({
            method: 'post',
            url: BASE_URL + `components/blueprints/${component_blueprint_id}/update-field`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getComponentFieldTypes: access_token => ({
            method: 'get',
            url: BASE_URL + 'components/blueprints/field-types',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getComponentBlueprintById: (access_token, id) => ({
            method: 'get',
            url: BASE_URL + `components/blueprints/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        deleteComponentBlueprintFieldbyId: (access_token, field_id) => ({
            method: 'delete',
            url: BASE_URL + `components/${field_id}/delete-field`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }),
        duplicateComponentBlueprint: (access_token, component_id) => ({
            method: 'post',
            url: BASE_URL + `components/blueprints/${component_id}/duplicate`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
    },
    statutes: {
        getAllStatutes: (access_token, component_blueprint_id) => ({
            method: 'get',
            url: BASE_URL + `components/blueprints/${component_blueprint_id}/statuses`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'multipart/form-data',
            },
        }),
        createStatus: (access_token, component_blueprint_id) => ({
            method: 'post',
            url: BASE_URL + `components/blueprints/${component_blueprint_id}/statuses`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'multipart/form-data',
            },
        }),
        editStatusById: (access_token, status_id, component_blueprint_id) => ({
            method: 'patch',
            url: BASE_URL + `components/blueprints/${component_blueprint_id}/statuses/${status_id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }),
        deleteStatus: (access_token, status_id, component_blueprint_id) => ({
            method: 'delete',
            url: BASE_URL + `components/blueprints/${component_blueprint_id}/statuses/${status_id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'multipart/form-data',
            },
        }),
    },
    payments: {
        getStripeProductsAndPrices: access_token => ({
            method: 'get',
            url: BASE_URL + `payments/products`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getCompanyInvoices: access_token => ({
            method: 'get',
            url: BASE_URL + 'payments/invoices',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        createSubscription: access_token => ({
            method: 'post',
            url: BASE_URL + 'payments/subscribe',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getCurrentSubscription: access_token => ({
            method: 'get',
            url: BASE_URL + 'payments/subscription',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        subscriptionPayment: access_token => ({
            method: 'post',
            url: BASE_URL + 'payments/subscribe',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'multipart/form-data',
            },
        }),
        servicesPayment: access_token => ({
            method: 'patch',
            url: BASE_URL + 'payments/services',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }),
        unsubscribe: access_token => ({
            method: 'patch',
            url: BASE_URL + 'payments/unsubscribe',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }),
        updateBillingInfo: access_token => ({
            method: 'post',
            url: BASE_URL + 'payments/methods',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }),
        checkPromoCode: access_token => ({
            method: 'post',
            url: BASE_URL + 'payments/promo-codes/check',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }),
    },

    processes: {
        getAllProcesses: (access_token, page, perPage) => ({
            method: 'get',
            url: BASE_URL + `procedures?per_page=${perPage}&page=${page}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getAllPinnedProcesses: access_token => ({
            method: 'get',
            url: BASE_URL + 'procedures/pinned',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        pinProcessById: (access_token, id) => ({
            method: 'post',
            url: BASE_URL + `procedures/${id}/pin`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        unpinProcessById: (access_token, id) => ({
            method: 'post',
            url: BASE_URL + `procedures/${id}/unpin`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getProcessById: (access_token, processId) => ({
            method: 'get',
            url: BASE_URL + `procedures/${processId}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        createNewProcess: access_token => ({
            method: 'post',
            url: BASE_URL + 'procedures',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        updateProcessById: (access_token, id) => ({
            method: 'patch',
            url: BASE_URL + `procedures/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }),
        deleteProcessById: (access_token, id) => ({
            method: 'delete',
            url: BASE_URL + `procedures/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        shareProcessToPosition: (access_token, process_Id) => ({
            method: 'post',
            url: BASE_URL + `procedures/${process_Id}/share`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        shareProcessToSeveralPositions: (access_token, process_Id) => ({
            method: 'post',
            url: BASE_URL + `procedures/${process_Id}/share`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        getProcessesFilters: access_token => ({
            method: 'get',
            url: BASE_URL + `procedures/filters`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        getFilteredProcesses: (access_token, query) => ({
            method: 'post',
            url: BASE_URL + `procedures/filtered/?${query}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
                'Content-Type': 'application/json',
            },
        }),
        requestAccess: access_token => ({
            method: 'post',
            url: BASE_URL + `procedures/request-access`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
                'Content-Type': 'application/json',
            },
        }),
        getProcessesFlows: access_token => ({
            method: 'post',
            url: BASE_URL + `processes`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
    },
    processSteps: {
        createNewProcStepUsingRawJson: access_token => ({
            method: 'post',
            url: BASE_URL + 'steps',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        getAllSteps: access_token => ({
            method: 'get',
            url: BASE_URL + 'steps/',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getAllStepsByProcessId: (access_token, processId) => ({
            method: 'get',
            url: BASE_URL + 'procedures/steps/' + processId,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getStepById: (access_token, stepId) => ({
            method: 'get',
            url: BASE_URL + 'steps/' + stepId,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getStepsByProcessId: (access_token, processId) => ({
            method: 'get',
            url: BASE_URL + 'procedures/steps/' + processId,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        updateProcessStepByIdUsingJson: (access_token, stepId) => ({
            method: 'patch',
            url: BASE_URL + 'steps/' + stepId,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        attachStepToParent: (access_token, stepId) => ({
            method: 'post',
            url: BASE_URL + 'steps/' + stepId + '/parent/attach',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        detachStepFromParent: (access_token, stepId) => ({
            method: 'post',
            url: BASE_URL + 'steps/' + stepId + '/parent/detach',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        attachChildStepToAStep: (access_token, stepId) => ({
            method: 'post',
            url: BASE_URL + 'steps/' + stepId + '/child/attach',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        attachChildStepToAProcess: (access_token, stepId) => ({
            method: 'post',
            url: BASE_URL + 'steps/' + stepId + '/procedure/attach',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        detachChildStepFromStep: (access_token, stepId) => ({
            method: 'post',
            url: BASE_URL + 'steps/' + stepId + '/child/detach',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        deleteStepById: (access_token, stepId, newParent) => ({
            method: 'delete',
            url: BASE_URL + 'steps/' + stepId,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        getAllInheritedStepsComponents: access_token => ({
            method: 'post',
            url: BASE_URL + 'steps/components/inherited-v2',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
            },
        }),
        getStepInheritedComponentsFromParent: (access_token, stepId) => ({
            method: 'post',
            url: BASE_URL + 'steps/' + stepId + '/parent-step-components',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
    },
    search: {
        searchAll: (access_token, queryParams) => ({
            method: 'get',
            url: BASE_URL + `search${queryParams}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        searchUsers: (access_token, queryParams) => ({
            method: 'get',
            url: BASE_URL + `search/users${queryParams}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        searchTasks: (access_token, queryParams) => ({
            method: 'get',
            url: BASE_URL + `search/tasks${queryParams}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        searchProcesses: (access_token, queryParams) => ({
            method: 'get',
            url: BASE_URL + `search/processes${queryParams}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        searchComponents: (access_token, queryParams) => ({
            method: 'get',
            url: BASE_URL + `search/components${queryParams}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        searchPositions: (access_token, queryParams) => ({
            method: 'get',
            url: BASE_URL + `search/positions${queryParams}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
    },
    devOps: {
        generatePasswordClient: {
            method: 'get',
            url: BASE_URL + 'ddevops / passport / client / password',
            headers: {
                Accept: 'application/json',
                Authorization: 'Basic dWd1cjoxMjM=',
            },
        },
        dbSeed: {
            method: 'get',
            url: BASE_URL + 'devops / db / seed',
            headers: {
                Accept: 'application/json',
                Authorization: 'Basic dWd1cjoxMjM=',
            },
        },
        dbMigrate: {
            method: 'get',
            url: BASE_URL + 'devops / db / migrate / ',
            headers: {
                Accept: 'application/json',
                Authorization: 'Basic dWd1cjoxMjM=',
            },
        },
    },
    superAdmin: {
        impersonate: (access_token, id, username, password) => ({
            method: 'get',
            url: BASE_URL + `impersonate/login/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Basic ${btoa(`${username}:${password}`)}`,
            },
        }),
        getAllAdmins: access_token => ({
            method: 'get',
            url: BASE_URL + 'super-admins',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        inviteAdmin: access_token=> ({
            method:'post',
            url: BASE_URL + 'super-admins',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
    },
    notifications: {
        getAllNotifications: (access_token, page) => ({
            method: 'get',
            url: BASE_URL + 'notifications' + page,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getUnreadNotifications: (access_token, page) => ({
            method: 'get',
            url: BASE_URL + 'notifications/unread' + page,
            headers: {
                //	'Accept': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getUnreadNotificationsCount: access_token => ({
            method: 'get',
            url: BASE_URL + 'notifications/count',
            headers: {
                // 'Accept': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        markAsReadAllNotifications: access_token => ({
            method: 'post',
            url: BASE_URL + 'notifications/read-all',
            headers: {
                //	'Accept': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        markAsReadNotificationById: (access_token, notificationId) => ({
            method: 'post',
            url: BASE_URL + `notifications/${notificationId}/read`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
    },
    legal: {
        getAllLegals: access_token => ({
            method: 'get',
            url: BASE_URL + 'legals',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        getLegalById: (access_token, id) => ({
            method: 'post',
            url: BASE_URL + `legals/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        createNewLegal: access_token => ({
            method: 'post',
            url: BASE_URL + 'legals',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        updateLegalById: (access_token, id) => ({
            method: 'patch',
            url: BASE_URL + `legals/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        deleteLegalById: (access_token, id) => ({
            method: 'delete',
            url: BASE_URL + `legals/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
    },
    faq: {
        getFaq: access_token => ({
            method: 'get',
            url: BASE_URL + 'questions',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        getFaqById: (access_token, id) => ({
            method: 'post',
            url: BASE_URL + `questions/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        createNewFaq: access_token => ({
            method: 'post',
            url: BASE_URL + 'questions',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        updateFaqById: (access_token, id) => ({
            method: 'patch',
            url: BASE_URL + `questions/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        deleteFaqById: (access_token, id) => ({
            method: 'delete',
            url: BASE_URL + `questions/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
    },
    documentation: {
        getAllDocumentations: access_token => ({
            method: 'get',
            url: BASE_URL + 'documentations',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        getDocumentationById: (access_token, id) => ({
            method: 'post',
            url: BASE_URL + `documentations/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        createNewDocumentation: access_token => ({
            method: 'post',
            url: BASE_URL + 'documentations',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        updateDocumentationById: (access_token, id) => ({
            method: 'patch',
            url: BASE_URL + `documentations/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        deleteDocumentationById: (access_token, id) => ({
            method: 'delete',
            url: BASE_URL + `documentations/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
    },
    videos: {
        getAllVideos: access_token => ({
            method: 'get',
            url: BASE_URL + 'videos',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        getVideoById: (access_token, id) => ({
            method: 'post',
            url: BASE_URL + `videos/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        createNewVideoTutorial: access_token => ({
            method: 'post',
            url: BASE_URL + 'videos',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        updateVideoTutorialById: (access_token, id) => ({
            method: 'patch',
            url: BASE_URL + `videos/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        deleteVideoTutorialById: (access_token, id) => ({
            method: 'delete',
            url: BASE_URL + `videos/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        uploadVideo: access_token => ({
            method: 'post',
            url: BASE_URL + `videos/upload`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        getAllVideoCategories: access_token => ({
            method: 'get',
            url: BASE_URL + 'video/categories',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        deleteVideoCategoryById: (access_token, id) => ({
            method: 'delete',
            url: BASE_URL + `video/categories/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        updateVideoCategoryById: (access_token, id) => ({
            method: 'patch',
            url: BASE_URL + `video/categories/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        updateVideoById: (access_token, id) => ({
            method: 'patch',
            url: BASE_URL + `videos/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        createVideoCategory: access_token => ({
            method: 'post',
            url: BASE_URL + 'video/categories',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
    },
    images:{
        uploadImage: access_token => ({
            method: 'post',
            url: BASE_URL + `media`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        deleteImage: (access_token, id) => ({
            method: 'delete',
            url: BASE_URL + `media/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
    },
    strategicInitiatives: {
        getAllStrategicInitiatives: access_token => ({
            method: 'get',
            url: BASE_URL + 'strategic-initiatives',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getStrategicInitiativeById: (access_token, id) => ({
            method: 'get',
            url: BASE_URL + `strategic-initiatives/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        getActionsByStrategicInitiativeId: (access_token, id) => ({
            method: 'get',
            url: BASE_URL + `strategic-initiatives/${id}/actions`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        createActionByStrategicInitiativeId: (access_token, id) => ({
            method: 'post',
            url: BASE_URL + `strategic-initiatives/${id}/actions`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        updateActionById: (access_token, id) => ({
            method: 'put',
            url: BASE_URL + `strategic-actions/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        updateInitiativePositionById: (access_token, id) => ({
            method: 'patch',
            url: BASE_URL + `strategic-actions/${id}/move`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        deleteActionById: (access_token, id) => ({
            method: 'delete',
            url: BASE_URL + `strategic-actions/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        updateStrategicInitiativeById: (access_token, id) => ({
            method: 'patch',
            url: BASE_URL + `strategic-initiatives/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        createNewStrategicInitiative: access_token => ({
            method: 'post',
            url: BASE_URL + 'strategic-initiatives',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
    },
    strategicAreas: {
        getAllStrategicAreas: access_token => ({
            method: 'get',
            url: BASE_URL + 'strategic-areas',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        createNewStrategicArea: access_token => ({
            method: 'post',
            url: BASE_URL + 'strategic-areas',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        updateStrategicArea: (access_token, id) => ({
            method: 'patch',
            url: BASE_URL + `strategic-areas/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        deleteStrategicArea: (access_token, id) => ({
            method: 'delete',
            url: BASE_URL + `strategic-areas/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
    },
    guidingDocuments: {
        getAllGuidingDocuments: access_token => ({
            method: 'get',
            url: BASE_URL + 'guiding-documents',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
        getGuidingDocumentById: (access_token, id) => ({
            method: 'get',
            url: BASE_URL + `guiding-documents/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        createNewGuidingDocument: (access_token, id) => ({
            method: 'post',
            url: BASE_URL + `guiding-documents`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        updateGuidingDocumentById: (access_token, id) => ({
            method: 'put',
            url: BASE_URL + `guiding-documents/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
        deleteGuidingDocumentById: (access_token, id) => ({
            method: 'delete',
            url: BASE_URL + `guiding-documents/${id}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token} `,
            },
        }),
    },
    support: {
        shareFeedbackOrAskHelp: access_token => ({
            method: 'post',
            url: BASE_URL + 'supports',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'multipart/form-data',
            },
        }),
        getSupportRequestById: (access_token, supportId) => ({
            method: 'get',
            url: BASE_URL + `supports/${supportId}`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        }),
    },
    consants: {
        getCountries: access_token => ({
            method: 'get',
            url: BASE_URL + 'countries',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${access_token}`,
                'Content-Type': 'multipart/form-data',
            },
        }),
    },
};
