import Spinner from './Spinner';
const CenteredLoadingIndicator = (props) => {
    const { visible } = props;

    return visible ? (
        <span style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner size={70} type={'ThreeDots'} />
        </span>
    ) : null;
};

export default CenteredLoadingIndicator;
