const qsSimple = require('qs');

import { Observable } from 'rxjs';
import { setAuthenticatedUser } from '../reduxStore/actions/authActions';
import { deleteUser, setUnassignedUsersArray, setUsersArray, updateUser, activateUser } from '../reduxStore/actions/flowActions';
import { store } from '../reduxStore/store';
import { URLS } from './API_CONSTANTS';
import { proceduresButtonIcon } from '../assets/images/new-icons';
import { axiosInstance } from './axiosInstance';
import { sessionService } from 'redux-react-session';
import queryString from 'query-string';

export function normalizeUserData(data) {
    const {id, email, profile = {}} = data;
    const result = {
        ...data, // Spread the top-level properties first so it doesn't override id with profile.id
        ...profile,
        id,
        email,
        profile_id: profile.id || id || null,
        company_id: profile.company?.id || data.company?.id || null,
        company_name: profile.company?.name || data.company?.name || null,
        position: profile.positions ? profile.positions?.map(u => u.name).join(', ') : data.positions?.map(u => u.name).join(', ') || '',
    };

    delete result.profile;

    return result;
}

export const setUserOnAPosition = (position, userId=false) => {
    const {
        sessionSettings: { last_access_token },
        authenticatedUser: { id },
    } = store.getState().authReducer;

    const data = new FormData();

    data.append('position_id', `${position.id}`);
    const config = {
        ...URLS.users.addUserToAPosition(last_access_token, userId? userId:id, data),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status == 200 && response?.data) {
                    store.dispatch(setAuthenticatedUser({ position: position }));
                    subscriber.next(response.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const getAllUsers = () => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.users.getAllUsers(last_access_token),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status == 200 && response?.data && response?.data?.data) {
                    const users = [
                        ...(response?.data?.data?.map(user_info => ({
                            ...normalizeUserData(user_info),
                        })) ?? []),
                    ];

                    store.dispatch(setUsersArray(users));

                    subscriber.next(users);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const getUserProcedures = (id) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.users.getUserProceduresById(last_access_token, id),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status == 200 && response?.data && response?.data?.data) {
                    const procedures = response?.data?.data.map(data => ({ value: data.id, label: data.name, icon: proceduresButtonIcon }));

                    subscriber.next(procedures);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const getUsersByPositions = (positions, dropdown = true) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    let config = {
        ...URLS.users.getUsersInSeveralPositions(last_access_token),
        handlerEnabled: true,
    };

    // when you see a ‘*’ you don’t send anything in the payload (which will return all users in the company)
    if (!(positions.length > 0 && positions.includes("*"))) {
        config.data = { positions: positions, dropdown };
    } else{
        config.data = { dropdown };
    }

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status == 200 && response?.data && response?.data?.data) {
                    const users = [
                        ...(response?.data?.data?.map(i => ({
                            label: i.profile.first_name + ' ' + i.profile.last_name,
                            value: i.id,
                        })) ?? []),
                    ];

                    subscriber.next(users);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const getUnassignedUsers = () => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.users.getUnassignedUser(last_access_token),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status == 200 && response?.data && response?.data?.data) {
                    const users = [
                        ...(response?.data?.data?.map(user_info => ({
                            ...normalizeUserData(user_info),
                        })) ?? []),
                    ];

                    store.dispatch(setUnassignedUsersArray(users));
                    subscriber.next(users);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const removeUserbyId = (userId) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = new FormData();
    const defaultConfig = { ...URLS.users.deleteUserbyId(last_access_token, userId) };
    const config = {
        ...defaultConfig,
        headers: { ...defaultConfig.headers },
        data: data,
        handlerEnabled: true,
        refreshOrgChart: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data) {
                    store.dispatch(deleteUser(userId));
                    subscriber.next(response.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const activateUserbyId =(user)=>{
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = new FormData();

    data.append('user_id', user.id);
    const defaultConfig = { ...URLS.users.activateUserbyId(last_access_token) };
    const config = {
        ...defaultConfig,
        headers: { ...defaultConfig.headers },
        data: data,
        handlerEnabled: true,
        refreshOrgChart: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data) {
                    store.dispatch(activateUser({...user, is_active: true}));
                    subscriber.next(response.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const deactivateUserbyId = (userId, task_assignee_id) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = new FormData();

    data.append('user_id', userId);
    data.append('task_assignee_id', task_assignee_id);
    const defaultConfig = { ...URLS.users.deactivateUserbyId(last_access_token) };
    const config = {
        ...defaultConfig,
        headers: { ...defaultConfig.headers },
        data: data,
        handlerEnabled: true,
        refreshOrgChart: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data) {
                    store.dispatch(deleteUser(userId));
                    subscriber.next(response.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const getAuthenticatedUser = () => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.users.getAuthenticatedUser(last_access_token),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data) {
                    subscriber.next(normalizeUserData(response.data.data));
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const changeUsersPassword = (oldPassword, newPassword, newPasswordConfirmation, userId) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    var data = new FormData();

    data.append('new_password', newPassword);
    data.append('new_password_confirmation', newPasswordConfirmation);
    data.append('old_password', oldPassword);

    const config = {
        ...URLS.users.changePassword(last_access_token, userId),
        data: data,
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data) {
                    subscriber.next(response.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const updateUserById = (userId, first_name, last_name, timezone, email, notify) => {
    const {
        sessionSettings: { last_access_token },
        authenticatedUser: { id },
    } = store.getState().authReducer;
    const query = JSON.stringify(
        { first_name, last_name, timezone, email, notify_old_email: !!notify },
        {
            skipNull: true,
            skipEmptyString: true,
        }
    );
    const removeEmptyNullFalse = (obj) => {
        // Use Object.entries to convert the object into an array of key-value pairs
        // Use Array.filter to remove unwanted properties (empty, null, and false)
        const filteredEntries = Object.entries(obj).filter(([key, value]) => {
            // Remove empty properties (empty string, empty array, and empty object)
            if (value === '' || (Array.isArray(value) && value.length === 0) || (typeof value === 'object' && value !== null && Object.keys(value).length === 0)) {
                return false;
            }

            // Remove null and false properties
            return value !== null && value !== false;
        });

        // Use Object.fromEntries to convert the filtered array back into an object
        const filteredObject = Object.fromEntries(filteredEntries);

        return filteredObject;
    };
    const data = removeEmptyNullFalse({ first_name, last_name, timezone, email, notify_old_email: !!notify });

    const defaultConfig = { ...URLS.users.updateUserById(last_access_token, userId) };
    const config = {
        ...defaultConfig,
        data: data,
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data?.data) {
                    const user_info = normalizeUserData(response?.data?.data);

                    if (id === userId) {
                        sessionService.saveUser({ ...user_info });
                        store.dispatch(setAuthenticatedUser({ ...user_info }));
                    }
                    store.dispatch(updateUser(user_info));
                    subscriber.next(user_info);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const clearUserProfilePicture = (userId) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = qsSimple.stringify({ photo: '' });
    const defaultConfig = { ...URLS.users.updateUserById(last_access_token, userId) };
    const config = {
        ...defaultConfig,
        data: data,
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data?.data) {
                    const user_info = normalizeUserData(response?.data?.data);

                    sessionService.saveUser({ ...user_info });
                    store.dispatch(setAuthenticatedUser({ ...user_info }));
                    subscriber.next(user_info);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const updateUserProfilePicture = (userId, avatarFile) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = new FormData();

    data.append('photo', avatarFile);
    const config = {
        ...URLS.users.updateUserProfilePicture(last_access_token, userId),
        data: data,
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data?.data) {
                    const user_info = normalizeUserData(response?.data?.data);

                    sessionService.saveUser({ ...user_info });
                    store.dispatch(setAuthenticatedUser({ ...user_info }));
                    subscriber.next(user_info);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const updateUserRoles = (userId, roles) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = new FormData();

    data.append('roles', roles);
    const config = {
        ...URLS.users.updateUserRoles(last_access_token, userId),
        data: {"roles": roles},
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status == 200 && response?.data) {
                    subscriber.next(normalizeUserData(response?.data?.data));
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const getUsersFilter = () => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.users.getUsersFilter(last_access_token),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status == 200 && response?.data && response?.data?.data) {
                    subscriber.next(response?.data?.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const getUserById = (id) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.users.getUserById(last_access_token, id),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status == 200 && response?.data && response?.data?.data) {
                    //Normalize Profile layer
                    subscriber.next(normalizeUserData(response?.data?.data));
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const getFilteredUsers = (sentData, sortBy = ['created_at'], order = 'desc') => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const query = queryString.stringify(
        { sort: order, sort_by: sortBy },
        {
            skipNull: true,
            skipEmptyString: true,
        }
    );

    const config = {
        ...URLS.users.getFilteredUsers(last_access_token, query),
        data: sentData,
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status == 200 && response?.data?.data) {
                    const users = [
                        ...(response?.data?.data?.map(user_info => ({
                            ...normalizeUserData(user_info),
                        })) ?? []),
                    ];

                    store.dispatch(setUsersArray(users));
                    subscriber.next(users);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const sendMail = (userId, payload) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.users.sendMail(last_access_token, userId),
        data: payload,
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response)=>{
                subscriber.next('api is successfull');
                subscriber.complete();
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
