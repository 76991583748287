import React from 'react';
import { components } from '../../../assets/images/icons/index';
import './BlueprintCard.scss';

const BlueprintCard = (props) => {
    return (
        <div id="blueprint-card" onClick={props.onClick}>
            <div style={{ padding: '15px' }}>
                <div className={'blueprint-card-top-container'}>
                    <img src={props.blueprintCardImgSrc} alt={''} />
                    <p>{props.blueprintCardTitle}</p>
                </div>
                {props.blueprintCardNumberComp > 0 ? (
                    <div className={'blueprint-card-bottom-container'}>
                        <p>Blueprint for </p>
                        <img src={components} alt={''} />
                        <p style={{ paddingLeft: '5px' }}>{props.blueprintCardNumberComp}</p>
                    </div>
                ) : (
                    <div className={'blueprint-card-bottom-container'}>
                        <p>Blueprint not used yet.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BlueprintCard;
