import classNames from 'classnames';
import { isSafari } from 'react-device-detect';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import cls from './Button.module.scss';
import ReactTooltip from 'react-tooltip';

const Button = (props) => {
    const { loading, disabled, disableEffectOnly, dataTip, clicked, style, title, titleStyle, titleContainerStyle, children, icon, type, className, light, loaderRadius, lighter, lighterNoBorder, loaderEditor = false, iconWidth, ...rest} = props;

    return (
        <>
            <button
                data-tip={dataTip}
                type={type ?? 'button'}
                disabled={disabled}
                className={classNames(cls.host, className)}
                comp-state={disabled || disableEffectOnly ? 'disabled' : 'enabled'}
                comp-loading={loading ? 'loading' : null}
                comp-variant={light ? 'light' : ''}
                comp-revert={lighter ? 'lighter' : ''}
                border-none-comp-revert={lighterNoBorder ? 'lighterNoBorder' : ''}
                style={style}
                onClick={clicked}
                {...rest}
            >
                {loading ? (
                    <div className={`${cls.loaderContainer} ${loaderEditor ? cls.loaderEditor : "" }` }>
                        <Loader type={isSafari ? 'Watch' : 'ThreeDots'} color={lighter ? 'var(--alvanda-highlight-purple)' : 'white'} secondaryColor="red" radius={loaderRadius ?? 32} height={loaderRadius ?? 32} width={`${loaderRadius ?? 32}px`} />
                    </div>
                ) : null}
                <div className={cls.target}>
                    {!loading && icon && <div className={cls.iconContainer} style={{width:iconWidth? iconWidth:""}}>{icon}</div>}
                    {title && (
                        <div className={cls.titleContainer} style={titleContainerStyle}>
                            <p style={titleStyle}>{title}</p>
                        </div>
                    )}
                </div>
                {children}
            </button>
            {dataTip && <ReactTooltip className={classNames(cls.showTooltip)} />}
        </>
    );
};

export default Button;
