import { connect } from 'react-redux';
import { $generateNodesFromDOM, $generateHtmlFromNodes } from "@lexical/html";
import TextEditorTheme from "./TextEditorTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $insertNodes } from "lexical";
import { useEffect, useState, useRef } from "react";
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import { ImageNode } from "./nodes/ImageNode";
import ImagePlugin from "./plugins/ImagePlugin";
import { deleteImage } from "../../../services/image.service";
import {first} from 'rxjs/operators';
import { setImagesList } from '../../../reduxStore/actions/modalsActions';

function Placeholder() {
    return <div className="editor-placeholder">Type Here...</div>;
}

function TextEditor(props) {
    const { data, onChangeCallback = _ => _, disabled = false, autoFocus = false, uniqueIdentifier, savePressed = false, setSavePressed } = props;
    const [isFirstRender, setIsFirstRender] = useState(true);
    const htmlContent = useRef("");
    const uploadedImages = useRef([]);
    const unsavedImages = useRef([]);
    const saveClick = useRef(false);
    const getImages = (htmlString) => {
        const parser = new DOMParser();
        const imgs = parser.parseFromString(htmlString, 'text/html').getElementsByTagName('img');
        const ids = [...imgs].map(img => Number(img.getAttribute('alt')));

        return [...new Set(ids)];
    };

    function checkForDeletedImages() {
        uploadedImages.current.forEach((id) => {
            if (!(getImages(htmlContent.current).includes(id))) {
                deleteImage(id)
                    .pipe(first())
                    .subscribe({
                        next: (response) => {
                            const updatedList = uploadedImages.current.filter(item => item !== id);

                            uploadedImages.current = updatedList;
                        },
                        error: (_err) => {
                            console.log(_err);
                        },
                    });
            }
        });
        setSavePressed(false);
    }
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = 'Data will be lost if you leave the page, are you sure?';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(()=>{
        saveClick.current = savePressed;
        if(savePressed == true){
            unsavedImages.current = [];
        }
    }, [savePressed]);

    useEffect(() => () => {
        if(saveClick.current == true){
            checkForDeletedImages();
        }
        else{
            unsavedImages.current.forEach(id => deleteImage(id).pipe(first()).subscribe());
            unsavedImages.current = [];
        }
    }, []);

    const onChange = (editorState, editor) => {
        editorState.read(() => {
            const root = $getRoot();

            if (root.getFirstChild().isEmpty() && root.getChildrenSize() === 1) {
                onChangeCallback('');

                return;
            }
            const htmlString = $generateHtmlFromNodes(editor, null);

            htmlContent.current = htmlString;
            onChangeCallback(htmlString);
        });
    };

    const LoadInitialContent = ({ initialContent }) => {
        const [editor] = useLexicalComposerContext();

        if (initialContent == null){
            initialContent = ' ';
        }
        useEffect(() => {
            if (isFirstRender && !initialContent) {
                setIsFirstRender(false);
            }

            if (isFirstRender && initialContent) {
                setIsFirstRender(false);
                editor.update(() => {
                    const parser = new DOMParser();
                    const dom = parser.parseFromString(initialContent, "text/html");

                    uploadedImages.current = getImages(initialContent);
                    htmlContent.current = initialContent;
                    const nodes = $generateNodesFromDOM(editor, dom);

                    try {
                        $getRoot().select();
                        $insertNodes(nodes);
                    } catch (e) {
                    }
                });
            }
        }, [isFirstRender, editor]);

        return null;
    };

    const editorConfig = {
        theme: TextEditorTheme,
        onError(error) {
            throw error;
        },
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode,
            ImageNode,
        ],
    };


    return (
        <div style={{ position: "relative" }} id={uniqueIdentifier} key={uniqueIdentifier}>
            {disabled && <div className='disabledOverlay'></div>}
            <LexicalComposer initialConfig={editorConfig}>
                <div className="editor-container">
                    <ToolbarPlugin uniqueIdentifier={uniqueIdentifier} isEditable={!disabled} uploadedImages={uploadedImages} unsavedImages={unsavedImages}/>
                    <div className="editor-inner">
                        <RichTextPlugin
                            contentEditable={<ContentEditable className="editor-input" />}
                            placeholder={<Placeholder />}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        <LoadInitialContent initialContent={data} />
                        <OnChangePlugin onChange={onChange} />
                        {autoFocus && <AutoFocusPlugin />}
                        <CodeHighlightPlugin />
                        <ListPlugin />
                        <LinkPlugin />
                        <AutoLinkPlugin />
                        <ListMaxIndentLevelPlugin maxDepth={7} />
                        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                        <HistoryPlugin  />
                        <ImagePlugin/>
                    </div>
                </div>
            </LexicalComposer>
        </div>
    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        setImagesList: value => dispatch(setImagesList(value)),
    };
};

const mapStateToProps = (store) => {
    const {imagesList} = store.modalsReducer;

    return {
        imagesList,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextEditor);
