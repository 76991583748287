export const parentPosition='What other position will people in this position report to? ' +
        '(Eg. A Sales Representative typically reports to a Sales Manager.)';
export const newPositionName = 'Otherwise called the Position Title (Eg. Social Media Specialist,' +
    ' Office Manager, VP of Sales)';
export const deletePosition = 'This option will delete this Position' +
    ' and every Position that reports to them within the organizational chart.';
export const deletePositionCheckbox = 'This option will delete only this Position. ' +
    'If there are any positions that report to this one, after deletion they will ' +
    'report to the position this one currently reports to.';
export const roleName = 'Otherwise called the Position Title (Eg. Social Media Specialist,' +
    ' Office Manager, VP of Sales)';
export const department = 'The main Department people in this role are part of.';
export const isManagerial = 'Managerial Positions can have other Positions reporting to them in the Org Chart';
export const subpositions = 'This list updates automatically based on the positions' +
    ' added under this position in the org chart.';
export const desiredResult = 'A one paragraph sentence describing the ' +
    'end result or benefit to the organization if the person(s) in this role succeed.';
export const strategicWork = 'A numbered list of decision-making or knowledge work the person(s) ' +
    'in this role should conduct which will help the organization become more effective and profitable.';
export const tacticalWork = 'A numbered list of actions the person(s) in this role ' +
    'should conduct to help the organization take steps towards its vision.';
export const positionStandards = 'A numbered list of standards and principles specific ' +
    'to this position that will ensure quality of service and alignment with the organization’s core values.';
export const resorces = 'A numbered list of all the resources which will be provided to the person(s) ' +
    'in this role to help them reach the Desired Result.';
export const accountability = 'Exactly who the person(s) in this role are ' +
    'accountable to, how often, and what will they need to report on.';
export const consequencesSuccess = 'Any forms of standard compensation (eg. base salary), private or public praise, ' +
    'bonuses for performance, or other perks for the person(s) in this role in case they succeed at achieving the Desired Result.';
export const consequencesFailure = 'Any forms of actions that must be taken ' +
    'taken by or compensation penalties that must be applied to the person(s)' +
    ' in this position in case they fail at achieving the Desired Result.';
export const peopleInPosition = 'The person(s) in this organization that commit to achieving' +
    'the Desired Result and follow its standards of quality.';
export const sharedResources = 'The person(s) from other areas of the organization' +
    ' whom this position can delegate tasks to and work with directly. ' +
    '(E.g. a VP of Sales having access to work with the Graphic Designer from another Department)';
export const selectPosition = 'These will be the Positions what will be able to assign' +
    ' tasks to and work directly with the selected colleagues from your team.'
