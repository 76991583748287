import React from 'react';
import { connect } from 'react-redux';
import alvandaLogo from '../../../../assets/images/branding/alvandaLogo.png';
import { backIcon, importFromFileIcon, orgChartIcon, uploadIcon } from '../../../../assets/images/icons';
import { action_InfoModalClose } from '../../../../reduxStore/actions/modalsActions';
import Button from '../Button/Button';
import ModalR from '../Modal/ModalR';
const InfoModal = (props) => {
    const { onInfoModalClose, options, isOpen } = props || {};

    const modalClosing = () => {
        onClose && onClose();
        if (isOpen && onInfoModalClose) {
            onInfoModalClose();
        }
    };

    const { hasBackButton, backBtnClick, hederLogo, headingImage, heading, headingCentered, subheading, subheadingCentered, continueDisabled, firstBtnText, onFirstButtonClick, secondBtnText, onSecondButtonClick, onClose } = options || {};

    const styles = {
        shadowedContainerStyle: { minWidth: '850px', minHeight: '429px' },
        modalWrapperStyle: { minWidth: '850px', minHeight: '429px' },
        childrenWrapperStyle: { alignItems: 'center' },
        iconStyle: { flex: 1 },
    };

    const { shadowedContainerStyle, modalWrapperStyle, childrenWrapperStyle, iconStyle } = styles;

    return isOpen ? (
        <div style={{ backgroundColor: 'var(--app-color-error)' }}>
            <ModalR modalWrapperStyle={modalWrapperStyle} shadowedContainerStyle={shadowedContainerStyle} scrollable={true} isOpen={isOpen} onCloseÎ={() => { }} blured={true}>
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        height: '100%',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        alignItems: 'stretch',
                        margin: '2% 10% 2% 10%',
                        ...childrenWrapperStyle,
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                        <img src={importFromFileIcon} style={iconStyle} alt="top-icon" />
                    </div>
                    {hasBackButton ? (
                        <div style={{ position: 'absolute', left: '-60px', top: '8px', right: 0, bottom: 0, width: '25px', height: '25px' }}>
                            <div onClick={backBtnClick} style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 0 }}>
                                <img alt={'back-icon'} src={backIcon} style={{ width: '24px', height: '24px' }} />
                            </div>
                        </div>
                    ) : null}
                    {hederLogo ? <img alt="alvanda-logo" src={alvandaLogo} style={{ position: 'absolute', left: '-60px', top: '1.3rem', right: 0, bottom: 0, width: '25px', height: '25px' }} /> : null}
                    {heading && (
                        <div
                            style={{
                                margin: '0px 0 16px 0',
                                alignSelf: 'stretch',
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: headingCentered ? 'center' : 'flex-start',
                                alignItems: headingCentered ? 'center' : 'stretch',
                            }}
                        >
                            {headingImage && <img alt={'heading'} src={headingImage} style={{ alignSelf: 'center', height: '32px', width: '32px' }} />}
                            <h1 style={{ color: '#4A4A4A', fontFamily: 'var(--app-font-gt-walsheim-pro)', fontSize: '3rem', fontWeight: '500', letterSpacing: '0', lineHeight: '3rem', margin: '0', padding: '0 0 0 10px', textAlign: headingCentered ? 'center' : 'left' }}>{heading}</h1>
                        </div>
                    )}
                    {subheading && (
                        <div
                            style={{
                                alignSelf: 'stretch',
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: subheadingCentered ? 'center' : 'flex-start',
                                alignItems: subheadingCentered ? 'center' : 'stretch',
                            }}
                        >
                            <p
                                style={{
                                    margin: '0px 0 16px 0',
                                    color: 'var(--app-color-gray-light)',
                                    fontFamily: 'var(--app-text-secondary-font)',
                                    fontSize: '1.3rem',
                                    fontWeight: 500,
                                    letterSpacing: 0,
                                    lineHeight: '1.3rem',
                                    textAlign: props.subheadingCentered ? 'center' : 'left',
                                    padding: '0 0 0 0',
                                }}
                            >
                                {subheading}
                            </p>
                        </div>
                    )}
                    <div style={{ paddingTop: '20px', width: '100%' }}>
                        <div style={{ alignSelf: 'stretch', display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            {props.bottomPreviousButton && (
                                <div style={{ flex: '1 1 auto', cursor: 'pointer', alignSelf: 'center', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} onClick={props.bottomPreviousButtonClick}>
                                    <img alt={'back-icon'} src={backIcon} style={{ opacity: '0.5', color: 'var(--app-color-gray-light)', width: '22px', height: '22px', marginRight: '10px', marginTop: '2px' }} />
                                    <p style={{ color: 'var(--app-color-gray-light)', fontFamily: 'var(--app-text-secondary-font)', fontSize: '16px', fontWeight: 'bold', letterSpacing: 0, lineHeight: '24px' }}>{props.bottomPreviousButton}</p>
                                </div>
                            )}
                            {props.hasPrevieOrgChart ? (
                                <div style={{ flex: '1 1 auto', cursor: 'pointer', alignSelf: 'center', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <div
                                        onClick={() => {
                                            onClose();
                                        }}
                                        style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        <img alt={''} src={orgChartIcon} style={{ width: '24px', height: '24px', marginRight: '7px' }} />
                                        <p style={{ fontFamily: ' var( --app-font-quicksand-bold)', fontSize: '16px', color: '#4A4A4A' }}>{'Preview Org. Chart'}</p>
                                    </div>
                                </div>
                            ) : null}
                            <div style={{ flex: '1 1 auto', position: 'relative', alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                <Button
                                    disabled={continueDisabled}
                                    loading={props.loading ? props.loading : undefined}
                                    clicked={(e) => {
                                        onSecondButtonClick();
                                        modalClosing(e);
                                    }}
                                    style={{}}
                                    title={secondBtnText}
                                ></Button>
                            </div>

                            <div style={{ flex: '1 1 auto', position: 'relative', alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                <Button
                                    disabled={continueDisabled}
                                    loading={props.loading ? props.loading : undefined}
                                    clicked={(e) => {
                                        onFirstButtonClick();
                                        modalClosing(e);
                                    }}
                                    style={{}}
                                    title={firstBtnText}
                                ></Button>
                            </div>

                            {props.bottomSkipButton && (
                                <div style={{ pointerEvents: props.skipDisabled ? 'none' : undefined, flex: '1 1 auto', cursor: 'pointer', alignSelf: 'center', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} onClick={props.bottomSkipButtonClick}>
                                    <p style={{ color: 'var(--app-color-gray-light)', fontFamily: 'var(--app-text-secondary-font)', fontSize: '16px', fontWeight: 'bold', letterSpacing: 0, lineHeight: '24px' }}>{props.bottomSkipButton}</p>
                                </div>
                            )}
                            {props.hasDownloadExample ? (
                                <div style={{ flex: '1 1 auto', cursor: 'pointer', alignSelf: 'center', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <div style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                        <img alt={''} src={uploadIcon} style={{ width: '20px', height: '20px', marginRight: '7px', marginTop: '3px' }} />
                                        <p style={{ fontFamily: ' var( --app-font-quicksand-bold)', fontSize: '16px', color: '#4A4A4A' }}>{'Download Example'}</p>
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        {props.hasSkipBtn ? (
                            <div style={{ position: 'absolute', right: '5%' }}>
                                <button onClick={props.skipBtnClicked} className="btn btn-link">
                                    <span className="skip-btn-text">Skip this Step</span>
                                </button>
                            </div>
                        ) : null}
                    </div>
                </div>
            </ModalR>
        </div>
    ) : null;
};

const mapStateToProps = (store) => {
    const {
        infoModal: { isOpen, options },
    } = store.modalsReducer;

    return { isOpen, options };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onInfoModalClose: () => dispatch(action_InfoModalClose()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoModal);
