import classNames from 'classnames';
import { useCombobox } from 'downshift';
import { ComponentProps, useCallback, useEffect, useState } from 'react';
import { XSign } from '../../../../assets/images/icons';
import { ErrorMessage, Input, InputContainer, Label,
    MenuOverlayList, MenuOverlayListItem, ToggleMenuInputButton } from './SelectComponents';
import cls from './SelectWithSearch.module.scss';
type Item = { value: string; label: string; symbol?: string; icon?: string; element?: React.ReactNode };
export type SelectWithSearchProps = {
	defaultSelectedItem: any;
	/** list of items to chose from */
	items: Item[];
	/**displayed as label above Control */
	title: string;
	placeholder?: string;
	/** sets an id to the related elements as required by aria listbox rules. Falls back to `title` if none is provided */
	id?: string;
	/** provide use if you inserted it */
	isInComponent?: boolean;
	/** adds a "*"-Indicator to the end of the title that turns red in case of an error*/
	hasMandatoryIndicator?: boolean;
	errorMessage?: string;
	isClearable: boolean;
	removeValue: boolean;
	onChange?: (selectedItem?: Item | null) => void;
	onDropDownClosed?: (selectedItems?: Item[]) => void;
	onFocus?: () => void;
    onClear?: () => void;
    onBlur?: () => void;
    isRelative?: boolean;
	/** determines if should auto scroll to selected item into dropdown menu  */
	focusSelectedMenuItem: boolean;
	/** adds a chevron dropDown Indicator */
	triggerType?: 'chevron' | 'downArrow' | 'eyeglass' | 'eyeglassGrey';
	itemType?: 'checkboxes' | 'normal' | 'radio' | 'no-item-icons';
	/** prop to remove selectedItem from state when isOpen */
	responsiveOnChange: boolean;
	/** prop to pass className to clear selected value mark */
	clearControlsClassName: string;
	/** prop to pass className to Input */
	inputContainerClassName: string;
	inputClassName: string;
	/** prop to pass className to MenuOverlayList */
	molclassname: string;
	/** prop to pass className to MenuOverlayListItem */
	moliclassname: string;
    disabled?: boolean;
    hideToggleBtn?: boolean;
	inputStyle: Record<string, unknown>;
	menuOverlayListStyle: Record<string, unknown>;
	menuOverlayListItemStyle: Record<string, unknown>;
    fadeOnDisable: boolean;
    ButtonToShow?: JSX.Element;
    inputElementId?: string;
    inputRef: React.RefObject<HTMLInputElement>;
} & Omit<ComponentProps<'div'>, 'onChange'>;

const SelectWithSearch = (props: SelectWithSearchProps) => {
    const {
        defaultSelectedItem,
        items,
        title,
        id = title?.replace(/ /g, '-'),
        placeholder,
        hasMandatoryIndicator,
        responsiveOnChange,
        errorMessage,
        itemType,
        disabled,
        hideToggleBtn,
        onChange,
        onDropDownClosed,
        focusSelectedMenuItem,
        className,
        inputContainerClassName,
        inputClassName,
        clearControlsClassName,
        molclassname,
        moliclassname,
        inputStyle,
        menuOverlayListStyle,
        menuOverlayListItemStyle,
        triggerType,
        isClearable,
        removeValue,
        onBlur,
        isRelative=true,
        onClear,
        fadeOnDisable,
        ButtonToShow,
        inputElementId,
        inputRef,
        ...rest
    } = props;

    const [inputItems, setInputItems] = useState(items);
    const [touched, setTouched] = useState(false);
    const settingTouched = (val: boolean) => setTouched(val);

    const hasError = errorMessage != null;
    const { isOpen, getToggleButtonProps,
        getLabelProps, getMenuProps, getInputProps, getComboboxProps, highlightedIndex, getItemProps, selectedItem, openMenu, closeMenu, selectItem, inputValue } = useCombobox({
        items: inputItems,
        id,
        selectedItem: defaultSelectedItem,
        stateReducer: (state, actionAndChanges) => {
            const { type, changes } = actionAndChanges;

            switch (type) {
                //clear the input when clicking outside or toggle button
                case useCombobox.stateChangeTypes.ToggleButtonClick:
                case useCombobox.stateChangeTypes.InputBlur:
                    onBlur?.();

                    return {
                        ...changes,
                    };
                case useCombobox.stateChangeTypes.FunctionOpenMenu:
                    setInputItems(items);

                    return {
                        ...changes,
                        highlightedIndex: state.selectedItem ? items.indexOf(state.selectedItem) : -1,
                    };
            }

            return changes;
        },
        itemToString: item => item?.label ?? '',
        onSelectedItemChange: event => onChange?.(event.selectedItem),
        onInputValueChange: ({ inputValue }) => {
            if (inputValue) {
                setInputItems(items.filter(item => item?.label?.toLowerCase()?.includes(inputValue.toLowerCase())));
            } else {
                setInputItems(items);
            }
        },
    });

    useEffect(() => {
        responsiveOnChange && isOpen && selectItem({ value: '', label: '' });
    }, [isOpen, selectItem]);

    useEffect(() => {
        removeValue && selectItem(null);
    }, [removeValue]);

    //scroll to select Item when opening
    useEffect(() => {
        if (isOpen && selectedItem && focusSelectedMenuItem) {
            requestAnimationFrame(() => {
                const selectedElement = document.querySelector(`#${id}-item-${items.indexOf(selectedItem)}`);

                selectedElement?.scrollIntoView({ behavior: 'smooth', block: 'center' /*  inline: 'center' */ });
            });
        }
    }, [id, isOpen, items, selectedItem]);

    const triggerDropDown = useCallback(() => {
        touched && onDropDownClosed?.(selectedItem);
    }, [onDropDownClosed, selectedItem, touched]);

    useEffect(() => {
        if (isOpen) {
            settingTouched(true);
        } else {
            triggerDropDown();
            settingTouched(false);
        }
    }, [isOpen, triggerDropDown]);

    return (
        <div style={isRelative? {position: "relative"} : {}} className={classNames(cls.host, className, disabled && fadeOnDisable && cls.custonDisable)} {...rest}>
            <Label {...getLabelProps()} hasMandatoryIndicator={hasMandatoryIndicator}>
                {title}
            </Label>
            <InputContainer {...getComboboxProps()} isActive={isOpen} hasError={hasError} className={classNames(cls.inputContainer, inputContainerClassName)}>
                <Input
                    value={'dad'}
                    className={classNames(cls.input, inputClassName)}
                    style={inputStyle}
                    {...getInputProps({
                        placeholder,
                        disabled,
                        'aria-invalid': errorMessage != null || undefined,
                        onFocus: () => {
                            if (!isOpen) {
                                openMenu();
                            }
                            props?.onFocus?.();
                        },
                        ...(inputElementId && inputRef ?
                            {
                                id: inputElementId,
                                ref: inputRef,
                            } : {}),
                    })}

                />
                {isClearable && selectedItem?.label && (
                    <div
                        className={clearControlsClassName}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            selectItem(null);
                            onClear?.();
                        }}
                    >
                        <XSign className={cls.toggleMenuControlsIcons} />
                    </div>
                )}
                {!hideToggleBtn && <ToggleMenuInputButton disabled={disabled} triggerType={triggerType} isOpen={isOpen} onClick={() => (isOpen ? closeMenu() : openMenu())} {...getToggleButtonProps()} />}
            </InputContainer>
            <MenuOverlayList {...getMenuProps()} className={classNames(cls.menuOverlayList, molclassname)} app-is-visible={isOpen ? 'true' : 'false'}>
                <div style={menuOverlayListStyle}>
                    {isOpen &&
					inputItems?.map((item, index) => (
					    <MenuOverlayListItem itemType={itemType} isVisible={true} key={`${item}${index}`} className={classNames(cls.menuOverlayListItem, moliclassname)} style={menuOverlayListItemStyle} isHighlighted={highlightedIndex === index} {...getItemProps({ item, index })}>
                            {item?.icon && <img alt="" style={{ alignSelf: 'center', marginLeft: '8px', marginRight: '12px', height: '17px' }} src={item?.icon} />}
                            {item?.element && <>{item?.element}</>}
                            {item.label}
                            <pre className={cls.currencySymbol}>{item?.symbol} </pre>
                        </MenuOverlayListItem>
					))}
                </div>
                {ButtonToShow && ButtonToShow}
            </MenuOverlayList>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
    );
};

export default SelectWithSearch;
