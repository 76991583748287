import DatePicker from 'react-datepicker';
import moment from 'moment';
import cls from './RangeDatePicker.module.scss';
import classNames from 'classnames';
import {useEffect} from "react";
export const RangeDatePicker = (props) => {
    const { error, dateChangeHandler, startDate, endDate, inputContainerClassName } = props;

    const startString = startDate && moment(startDate).isValid() ? moment(startDate).format('Do of MMMM YYYY') : '';
    const endString = endDate && moment(endDate).isValid() ? moment(endDate).format('Do of MMMM YYYY') : '';

    useEffect(() => {
        //logic for when range get's load set default dates as range.
        if(!startDate && !endDate){
            dateChangeHandler?.([new Date(),new Date()]);
        }
    }, [startDate, endDate]);

    return (
        <div className={cls.host}>
            <DatePicker
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={90}
                selected={startDate}
                onChange={dateChangeHandler}
                startDate={startDate}
                endDate={endDate}
                value={[startDate, endDate]}
                customInput={
                    <div className={classNames(cls.customInputContainer, inputContainerClassName)}>
                        <input readOnly value={startString + ' - ' + endString} type="text" id={'range-date'} className={cls.customInput} placeholder={'Select Range...'} />
                    </div>
                }
                wrapperClassName={cls.wrapper}
                selectsRange
            />
            <div>{error?.length > 0 && <p className={cls.errorGeneralText}>{error} </p>}</div>
        </div>
    );
};
