import {useEffect, useState} from "react";
import {Button, ModalR, RenderTitle, SelectD} from "../../common";
import cls from "./RoleInformation.module.scss";
import {CheckIcon, closeIcon, questionMarkDark} from "../../../assets/images/new-icons";
import {getAllPositions} from "../../../services/company.service";
import {first} from "rxjs/operators";
import {selectPosition} from "./tooltips";

const SharePositionModal = (props) => {
    const {
        isOpen, onClose,
        onShareUserOnPosition, setSelectedSharingPosition,
        sharingError, setSharingError, sharingLoading
    } = props;
    const [positions, setPositions] = useState([]);
    const retreivePos = () => {
        getAllPositions()
            .pipe(first())
            .subscribe({
                next: (dataPositions) => {
                    setPositions(dataPositions?.map(i => ({label: i?.name, value: i.id})));
                },
            });
    };

    useEffect(() => {
        isOpen && retreivePos();
        setSharingError('');
    }, [isOpen]);

    return (
        <ModalR shadowedContainerStyle={{width: '536px'}} isOpen={isOpen} backdropColor={true} blured={true}>
            <div className={cls.duplicateModalContainer}>
                <div>
                    <img
                        alt={""}
                        src={questionMarkDark}
                        className={cls.questionMarkIcon}
                    />
                    <img
                        alt=""
                        src={closeIcon}
                        className={cls.closeIcon}
                        onClick={() => {
                            onClose();
                        }}
                    />
                    <p className={cls.modalTitle}>
                        Share Position
                    </p>
                    <div className={cls.duplicatingPosition_description}>
                        <RenderTitle title={'Select Position '} tooltipText={selectPosition}/>
                        <div className={cls.duplicatingPosition_parent}>
                            <SelectD
                                key={'Position'}
                                isClearable
                                placeholder="Select Who You Want To Share Colleagues With"
                                items={positions}
                                moliclassname={cls.duplicatingPosition_selectPositionmoli}
                                onChange={(selection) => {
                                    selection?.value && setSelectedSharingPosition(selection.value);
                                }}
                            />
                        </div>
                    </div>
                    {sharingError && <p style={{color: 'red'}}>{sharingError}</p>}
                    <Button
                        icon={<CheckIcon style={{padding: '1px 1px', width: '24px', height: '24px'}}/>}
                        title="Share Colleagues"
                        className={cls.shareColleagueButton}
                        loading={sharingLoading}
                        clicked={onShareUserOnPosition}
                        titleStyle={{textAlign: 'center', padding: '0 2px 0 0'}}
                    />
                </div>
            </div>
        </ModalR>
    );
};

export default SharePositionModal;
