import React from 'react';
import ReactTooltip from 'react-tooltip';
import './FieldButton.scss';

const FieldButton = (props) => {
    return (
        <div id="field-button" onClick={() => props.clicked()}>
            <img alt={''} src={props.fieldIcon} data-tip={props.tooltipText} />
            <ReactTooltip className="customTheme" />
        </div>
    );
};

export default FieldButton;
