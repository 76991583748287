import { useNavigate } from 'react-router-dom';
import { userLogout } from '../../../services/auth.service';
import { ModalR } from '../index';
import Button from '../UI/Button/Button';
import cls from './LogoutModal.module.scss';
import { logoutLargeIcon, closeIcon } from '../../../assets/images/new-icons';
import { useState } from 'react';

const LogoutModal = (props) => {
    const navigate = useNavigate();
    const { openModal, setOpenModal, onLogout } = props;
    const [loading, setLoading] = useState(false);

    const userLogoutHandler = () => {
        setLoading(true);
        userLogout().subscribe({
            next: (_data) => {
                setLoading(false);
                typeof onLogout === 'function'? onLogout() : navigate('/');
            },
            error: () => window.location.reload(),
        });
    };
    const triggerCloseModal = () => setOpenModal(false);

    return (
        <>
            <ModalR isOpen={openModal} onClose={triggerCloseModal} backdropColor={true} blured={true} scrollable={true} shadowedContainerStyle={{ width: '30%', padding: '40px', paddingBottom: '24px' }}>
                <div>
                    <img alt={''} className={cls.logoutImage} src={logoutLargeIcon} />
                    <p className={cls.logoutText}>{'Are you sure you want to log out of your account?'}</p>
                    <Button clicked={triggerCloseModal} title={'Back to Alvanda'} className={cls.logoutButton} />
                    <Button className={cls.backButton} style={{ width: '100%' }} loading={loading} lighter clicked={() => userLogoutHandler()} title={'Yes, Logout'} />
                </div>
            </ModalR>
        </>
    );
};

export default LogoutModal;
