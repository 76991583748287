import classNames from 'classnames';
import { useMultipleSelection, useSelect } from 'downshift';
import React, {ComponentProps, memo, ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import { XSign } from '../../../../assets/images/icons';
import cls from './Multiselect.module.scss';
import { ErrorMessage, Label, MenuOverlayList, MenuOverlayListItem, ToggleMenuButton } from './SelectComponents';
import Tag from './Tag';
import { isSafari } from 'react-device-detect';
import Loader from 'react-loader-spinner';
import {isEqual} from "lodash";

const R = require('ramda');

type Item = { value: string; label: string; icon?: string; index?: number };
export type MultiSelectProps = {
	/** list of items to chose from */
	items: Item[];
	/**displayed as label above Control */
	title: string;
	placeholder?: string;
	/** sets an id to the related elements as required by aria listbox rules. Falls back to `title` if none is provided */
	id?: string;
	/** adds a "*"-Indicator to the end of the title that turns red in case of an error*/
	hasMandatoryIndicator?: boolean;
	/** id's of teh items that need to be selected initially*/
	initialSelectedValues: string[];
	errorMessage?: string;
	onChange?: (selectedItems?: Item[]) => void;
	onDropDownClosed?: (selectedItems?: Item[]) => void;
	noTags: boolean;
	isClearable: boolean;
	triggerType?: 'chevron' | 'downArrow';
	itemType?: 'checkboxes' | 'normal';
	checkboxEmplacement?: 'right' | 'left';
	toggleLeftIcon?: string;
	/* limit for selecting items when is  singleSelectFirstItem*/
	selectionLimit: number;
	/* particular behaviour for first item element */
	singleSelectFirstItem: boolean;
	/** prop to pass className to Input */
	inputClassName: string;
	/** prop to pass className to MenuOverlayList */
	molclassname: string;
	/** prop to pass className to MenuOverlayListItem */
	moliclassname: string;
	/** prop to pass className to toggle menu input button */
	menuButtonPlaceholder: string;
	tmiClassName?: string;
	error: boolean;
	loading: boolean;
    noDataMessage: string;
    SelectAddItemComp?: ReactNode;
} & ComponentProps<'div'>;

const MultiSelect = memo((props: MultiSelectProps) => {
    const {
        items,
        title,
        id = title?.replace(/ /g, '-'),
        placeholder,
        hasMandatoryIndicator,
        noTags,
        isClearable,
        /* triggerType, */ itemType,
        singleSelectFirstItem,
        selectionLimit,
        className,
        /* inputClassName, */ molclassname,
        moliclassname,
        tmiClassName,
        menuButtonPlaceholder,
        errorMessage,
        initialSelectedValues,
        onChange,
        toggleLeftIcon,
        checkboxEmplacement,
        error,
        loading,
        noDataMessage,
        SelectAddItemComp,
        onDropDownClosed,
        ...rest
    } = props;

    const { getSelectedItemProps, getDropdownProps, removeSelectedItem, setSelectedItems, selectedItems } = useMultipleSelection<Item>({
        initialSelectedItems: items.filter(item =>
            initialSelectedValues?.includes(item?.value) || initialSelectedValues?.includes(item?.label)
        ) ?? [],
        itemToString: item => item?.label,
    });
    const initialSelectedValuesRef = useRef();
    const [touched, setTouched] = useState(false);
    const settingTouched = (val: boolean) => setTouched(val);
    const triggerSelectedItems = useCallback(() => {
        if (touched) {
            onChange?.(selectedItems);
        }
    }, [onChange, selectedItems, touched]);

    useEffect(() => {
        triggerSelectedItems();
    }, [selectedItems]);
    const { isOpen, getToggleButtonProps, getLabelProps, getMenuProps,
        highlightedIndex, getItemProps, selectItem } = useSelect<Item | null>({
            items, id,
            itemToString: item => item?.label || '',
            stateReducer: (state, actionAndChanges) => {
                const { type, changes } = actionAndChanges;

                switch (type) {
                    case useSelect.stateChangeTypes.MenuKeyDownEnter:
                    case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
                    case useSelect.stateChangeTypes.ItemClick:
                        if (itemType == 'checkboxes') {
                            return {
                                ...changes,
                                isOpen: true, //keep the menu open after selection
                                highlightedIndex: state.highlightedIndex,
                            };
                        } else if (singleSelectFirstItem && changes.selectedItem != items[0] && selectedItems.includes(items[0])) {
                            return {
                                ...state,
                                isOpen: false, //keep the menu open after selection
                                highlightedIndex: -1,
                            };
                        } else {
                            return {
                                ...changes,
                                isOpen: true, //keep the menu open after selection
                                highlightedIndex: state.highlightedIndex,
                            };
                        }
                }

                return changes;
            },
            onStateChange: (args) => {
                const { type, selectedItem } = args;

                switch (type) {
                    case useSelect.stateChangeTypes.MenuKeyDownEnter:
                    case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
                    case useSelect.stateChangeTypes.ItemClick:
                        processSelectingItems(selectedItem);
                        break;
                    default:
                        break;
                }
            },
        });

    const processSelectingItems = useCallback(
        (selectedItem) => {
            if (selectedItem) {
                const index = R.findIndex(R.propEq('value', selectedItem.value))(selectedItems);

                if (itemType == 'checkboxes') {
                    if (index > -1) {
                        setSelectedItems(R.remove(index, 1, selectedItems));
                    } else {
                        setSelectedItems([...selectedItems, selectedItem]);
                    }
                } else if (singleSelectFirstItem) {
                    if (selectedItem == items[0]) {
                        if (index > -1) {
                            setSelectedItems(R.remove(index, 1, selectedItems));
                        } else {
                            setSelectedItems([selectedItem]);
                        }
                    } else {
                        if (index > -1) {
                            setSelectedItems(R.remove(index, 1, selectedItems));
                        } else {
                            if (selectedItems.length < selectionLimit && selectedItems.map(i => i.label.toUpperCase()).indexOf(items[0].label.toUpperCase()) == -1) {
                                setSelectedItems([...selectedItems, selectedItem]);
                            }
                        }
                    }
                } else {
                    if (index > -1) {
                        setSelectedItems(R.remove(index, 1, selectedItems));
                    } else {
                        setSelectedItems([...selectedItems, selectedItem]);
                    }
                }
                selectItem(null);
            }
        },
        [itemType, items, selectItem, selectedItems, selectionLimit, setSelectedItems, singleSelectFirstItem]
    );

    useEffect(() => {
        // to avoid unnecessary rendering used this logic
        if (initialSelectedValues?.length > 0 && items.length > 0 && (!initialSelectedValuesRef.current || !isEqual(initialSelectedValuesRef.current, initialSelectedValues))) {
            const selected = items.filter(item =>
                initialSelectedValues?.includes(item?.value) || initialSelectedValues?.includes(item?.label)
            ) ?? [];

            setSelectedItems(selected);
            initialSelectedValuesRef.current = initialSelectedValues;
        }
    }, [initialSelectedValues, items]);

    const triggerDropDown = useCallback(() => {
        touched && onDropDownClosed?.(selectedItems);
    }, [onDropDownClosed, selectedItems, touched]);

    useEffect(() => {
        if (isOpen) {
            settingTouched(true);
        } else {
            triggerDropDown();
            settingTouched(false);
        }
    }, [isOpen, triggerDropDown]);
    const isReadOnly = useCallback(
        (item: Item, index: number) => {
            if (singleSelectFirstItem && index > 0) {
                if (selectedItems.some(i => i.label?.toLocaleLowerCase() == items[0].label?.toLocaleLowerCase())) return true;
                if (selectionLimit && selectionLimit === selectedItems?.length) {
                    if (!selectedItems.some(i => i?.label?.toLocaleLowerCase() == item.label?.toLocaleLowerCase())) return true;
                }
            }

            return false;
        },
        [items, selectedItems, selectionLimit, singleSelectFirstItem]
    );

    return (
        <div className={classNames(cls.host, className)} {...rest} app-variant-has-error={error ? 'error' : ''}>
            <Label {...getLabelProps()} hasMandatoryIndicator={hasMandatoryIndicator}>
                {title}
            </Label>
            {!noTags && (
                <div className={cls.tagsContainer}>
                    {selectedItems.map((selectedItem, index) => (
                        <Tag
                            style={{ marginBottom: '8px', marginRight: '20px' }}
                            hasCloseButton
                            key={`selecetd-item-${index}`}
                            {...getSelectedItemProps({ selectedItem, index })}
                            onClick={() => {
                                removeSelectedItem(selectedItem);
                            }}
                        >
                            {selectedItem?.label?.length > 0 ? selectedItem?.label?.charAt(0)?.toUpperCase() + selectedItem?.label?.slice(1) : placeholder}
                        </Tag>
                    ))}
                </div>
            )}
            <ToggleMenuButton
                {...getToggleButtonProps({ ...getDropdownProps({ preventKeyAction: isOpen }) })}
                isOpen={isOpen}
                className={classNames(cls.toggleMenu, tmiClassName)}
                extraControl={
                    isClearable &&
					selectedItems?.length > 0 && (
<div
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                selectItem(null);
                            }}
                        >
                            <XSign className={cls.toggleMenuControlsIcons} />
                        </div>
                    )
                }
            >
                {toggleLeftIcon && (
                    <div className={cls.toggleLeftIconContainer}>
                        <img alt={''} src={toggleLeftIcon} />
                    </div>
                )}
                {selectedItems?.length > 0 ? (
                    <p className={menuButtonPlaceholder}>
                        {selectedItems
                            ?.map(i => i?.label?.charAt(0)?.toUpperCase() + i?.label?.slice(1))
                            .join(', ')}
                    </p>
                ) : (
                    <p className={menuButtonPlaceholder}>{placeholder}</p>
                )}
            </ToggleMenuButton>

            <MenuOverlayList style={{display: isOpen ? '' : 'none'}}
                className={classNames(cls.menuOverlayList, molclassname)} {...getMenuProps()}>
                {isOpen && (
                    loading ? (
                            <Loader type={isSafari ? 'Watch' : 'ThreeDots'} color={'var(--app-color-gray-dark)'}
                                secondaryColor="lightgray" radius={22} height={22} width={'22px'}/>
                    ) : (
                        <>
                            {noDataMessage ? <div className={cls.noDataStyle}>{noDataMessage}</div> : items?.map((item, index) => (
                                <MenuOverlayListItem
                                    isUnique={singleSelectFirstItem && index === 0}
                                    key={`${item}${index}`}
                                    isHighlighted={highlightedIndex === index}
                                    isSelected={selectedItems.map(i => i.value).includes(item?.value)}
                                    isReadOnly={isReadOnly(item, index)}
                                    icon={item?.icon}
                                    className={classNames(cls.menuOverlayListItem, moliclassname)}
                                    isVisible={true}
                                    itemType={itemType ?? 'normal'}
                                    checkboxEmplacement={checkboxEmplacement}
                                    {...getItemProps({item, index})}
                                >
                                    {item.label}
                                </MenuOverlayListItem>
                            ))}
                        </>
                    )
                )}
                {SelectAddItemComp && (
                    <div style={{ position: 'sticky', bottom: 0, backgroundColor: 'var(--alvanda-invisible-purple)' }}>
                        {React.cloneElement(SelectAddItemComp, { isOpen: isOpen })}
                    </div>
                )}
            </MenuOverlayList>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
    );
});

export default MultiSelect;
