import cls from './RecurTask.module.scss';
import { SelectD, RenderTitle, CustomCheckbox } from '../../../common';
import StyledInput from '../../../common/UI/StyledInput/StyledInput';
import DateCalendar from '../DateCalendar/DateCalendar';
import MonthDaysSelect from './monthDaysSelect/MonthDaysSelect';
import { recurTypeOptions, monthDaysList, weekDays, monthsAndDaysList, customTypeOptions } from '../../../../shared/utility';
import MessageContainer from "../../ProcessesComps/MessageContainer/MessageContainer";

const RecurTask = ({ dueDate, taskRecurringDetails, setTaskRecurringDetails, message, disabled = false  }) => {
    const { skipWeekends, recurForever, recurType, taskRepeatNumber, customRecurOptions } = taskRecurringDetails || {};

    return (
        <div className={cls.recurTaskMainContainer}>
            <div className={cls.recurringOptionsContainer}>
                <div>
                    <RenderTitle title={'Recurring Options'} tooltipText={'Choose the frequency at which the task should recur. Select the appropriate option based on your recurrence preference.'} />
                    <SelectD
                        isDisabled={disabled}
                        title=""
                        placeholder="Select"
                        tmiClassName={cls.selectText}
                        molclassname={cls.optionsMOL}
                        className={cls.inputField}
                        closeVectorRotation={'rotate(-90deg)'}
                        openVectorRotation={'rotate(0deg)'}
                        onChange={selection =>
                            setTaskRecurringDetails(prev => ({
                                ...prev,
                                skipWeekends: false,//necessary to reset.
                                recurType: selection,
                                daysOfWeektoRecur: null,
                                dateOfMonthtoRecur: null,
                                monthToRecur: null,
                                endsAt: null,
                                customRecurOptions: {
                                    every: 1,
                                    period: customTypeOptions[0],
                                    value: null,
                                },
                            }))
                        }
                        items={recurTypeOptions}
                        defaultSelected={recurType}
                    />
                    {message && message?.errors?.type && <MessageContainer message={message.errors.type} />}
                    {message && message?.errors?.date_of_month_to_recur && <MessageContainer message={message.errors.date_of_month_to_recur} />}
                    {message && message?.errors?.days_of_week_to_recur && <MessageContainer message={message.errors.days_of_week_to_recur} />}
                    {message && message?.errors?.month_to_recur && <MessageContainer message={message.errors.month_to_recur} />}
                </div>
                {recurType.value === 'custom' && (
                    <>
                        <div className={cls.customRecurSelection}>
                            <div>
                                <StyledInput
                                    disabled={disabled}
                                    label={'Every'}
                                    value={customRecurOptions?.every}
                                    type="Number"
                                    extraClass={cls.repeatInputHost}
                                    counterBtnsContainer={cls.counterBtnsStyle}
                                    onChange={e =>
                                        setTaskRecurringDetails(prev => ({
                                            ...prev,
                                            customRecurOptions: { ...customRecurOptions, every: e.target.value },
                                        }))
                                    }
                                />
                            </div>
                            <SelectD
                                isDisabled={disabled}
                                title=""
                                tmiClassName={cls.selectText}
                                molclassname={cls.optionsMOL}
                                className={cls.periodSelectInput}
                                closeVectorRotation={'rotate(-90deg)'}
                                openVectorRotation={'rotate(0deg)'}
                                onChange={(selection) => {
                                    setTaskRecurringDetails(prev => ({
                                        ...prev,
                                        customRecurOptions: { ...customRecurOptions, period: selection },
                                    }));
                                }}
                                items={customTypeOptions}
                                defaultSelected={customRecurOptions?.period}
                            />
                            {message && message?.errors?.interval && <MessageContainer message={message.errors.interval} />}
                            {message && message?.errors?.interval_type && <MessageContainer message={message.errors.interval_type} />}
                        </div>
                        <div className={cls.customMonthAndDay}>
                            {customRecurOptions?.period?.value === 'year' && (
                                <>
                                    <RenderTitle title={'Select month and day'} tooltipText={'select month and day tooltip'} />
                                    <MonthDaysSelect
                                        dropdownType="multiselect"
                                        items={monthsAndDaysList}
                                        placeholder={'Select Month'}
                                        onSelection={(selection) => {
                                            if (disabled) return;
                                            setTaskRecurringDetails(prev => ({
                                                ...prev,
                                                customRecurOptions: {
                                                    ...customRecurOptions,
                                                    value: selection.map(item => ({ label: item.label, options: item.options.map(op => op.value) })),
                                                },
                                            }));
                                        }}
                                    />
                                </>
                            )}
                            {customRecurOptions?.period?.value === 'month' && (
                                <>
                                    <RenderTitle title={'Select days of month'} tooltipText={'select days of month tooltip'} />
                                    <MonthDaysSelect
                                        dropdownType="select"
                                        items={monthDaysList}
                                        placeholder={'Select day'}
                                        onSelection={(selection) => {
                                            if (disabled) return;
                                            setTaskRecurringDetails(prev => ({
                                                ...prev,
                                                customRecurOptions: { ...customRecurOptions, value: selection.map(item => item.value) },
                                            }));
                                        }}
                                    />
                                </>
                            )}
                            {customRecurOptions?.period?.value === 'week' && (
                                <>
                                    <RenderTitle title={'Select days of week'} tooltipText={'select days of week tooltip'} />
                                    <MonthDaysSelect
                                        dropdownType="select"
                                        items={weekDays}
                                        placeholder={'Select week'}
                                        onSelection={(selection) => {
                                            if (disabled) return;
                                            setTaskRecurringDetails(prev => ({
                                                ...prev,
                                                customRecurOptions: { ...customRecurOptions, value: selection.map(item => item.value) },
                                            }));
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </>
                )}
                <div className={cls.recurCheckboxOptions}>
                    {(recurType.value === 'daily' || (recurType.value === 'custom' && customRecurOptions?.period?.label === 'Day')) && (
                        <CustomCheckbox
                            disabled={disabled}
                            labelStyle={{ fontWeight: 300, fontSize: '16px' }}
                            label={'Skip weekends'}
                            onChange={checked => setTaskRecurringDetails(prev => ({ ...prev, skipWeekends: checked }))}
                            defaultChecked={skipWeekends}
                            mainContainerStyle={{ margin: '16px 0 0 0' }}
                            checkMarkStyle={{ width: '16px', height: '16px' }}
                            checkIconStyle={{ width: '11px', height: '8px' }}
                        />
                    )}
                    <CustomCheckbox
                        disabled={disabled}
                        labelStyle={{ fontWeight: 300, fontSize: '16px' }}
                        label={'Recur forever'}
                        onChange={checked => setTaskRecurringDetails(prev => ({ ...prev, recurForever: checked }))}
                        defaultChecked={recurForever}
                        mainContainerStyle={{ margin: '16px 0 0 0' }}
                        checkMarkStyle={{ width: '16px', height: '16px' }}
                        checkIconStyle={{ width: '11px', height: '8px' }}
                    />
                </div>
                {!recurForever && (
                    <div className={cls.taskRepeatInput}>
                        <StyledInput
                            disabled={disabled}
                            label={'Repeat'}
                            value={taskRepeatNumber}
                            type="Number"
                            extraClass={cls.repeatInputHost}
                            counterBtnsContainer={cls.counterBtnsStyle}
                            showCounterButton={!disabled}
                            onChange={e => {
                                if (disabled) return;
                                setTaskRecurringDetails(prev => ({...prev, taskRepeatNumber: +e.target.value}))
                            }}
                            handleIncrement={() => setTaskRecurringDetails(prev => ({ ...prev, taskRepeatNumber: taskRepeatNumber + 1 }))}
                            handleDecrement={() => setTaskRecurringDetails(prev => ({ ...prev, taskRepeatNumber: taskRepeatNumber - 1 }))}
                        />
                    </div>
                )}
            </div>
            <div className={cls.calendarContainer}>
                <DateCalendar dueDate={dueDate} taskRecurringDetails={taskRecurringDetails} setTaskRecurringDetails={setTaskRecurringDetails} />
            </div>
        </div>
    );
};

export default RecurTask;
