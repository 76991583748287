import React from 'react';

function Chevron(props: any) {
    return (
        <svg className={props?.className} width={props?.width ?? '12'} height="7" viewBox="0 0 12 7" fill={props?.fill ?? 'none'} xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 1.5L6 6L10.5 1.5" stroke={props?.stroke ?? '#EBEBF9'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default Chevron;