import {useEffect, useRef, useState} from 'react';
import {components} from 'react-select';
import moment from 'moment';
import {alphabetical} from '../assets/images/icons';
import {vectorDown} from '../assets/images/new-icons';
import roundArrow from '../assets/images/chart/roundArrow.svg';
export { useMountedState } from 'react-use';
import {v4 as uuidV4} from 'uuid';
export const uncachedUrl = urlString => `${urlString}?time=${new Date()}`;
export const generateNewUUID = () => uuidV4();
export const DropdownIndicator = (props) => {
    const {
        selectProps: { menuIsOpen },
    } = props;
    const [rotate, setRotate] = useState('');
    const settingRotate = val => setRotate(val);

    useEffect(() => {
        settingRotate(menuIsOpen ? 'accordion__icon rotate' : 'accordion__icon');
    }, [menuIsOpen]);

    return (
        <components.DropdownIndicator {...props}>
            <img alt="drdw" className={`${rotate}`} src={vectorDown} />
        </components.DropdownIndicator>
    );
};

export const recurTaskTime = {
    daily: 'days',
    weekly: 'weeks',
    monthly: 'months',
    yearly: 'years',
    quarterly: 'quarter',
};
export const isDateInRecurringLogic = (date, startDate, every, unit, skipWeekends) => {
    const formattedStartDate = moment(startDate).startOf('day');
    const formattedDate = moment(date).startOf('day');

    // Check if the date is a weekend and return false if skipping weekends is enabled
    if (skipWeekends && (formattedDate.day() === 0 || formattedDate.day() === 6)) {
        return false;
    }

    const unitDifference = formattedDate.diff(formattedStartDate, unit, true);

    return unitDifference >= 0 && unitDifference % every === 0;
};

export const getTaskRecurRepeatDays = (start, end, every, unit, skipWeekends) => {
    if (!start || !end || !unit) {
        return 1;
    }
    //Ignore times just consider dates else days will not be correct.
    const startDate = moment(start).startOf('day');
    const endDate = moment(end).startOf('day');
    let repeatCount = 0;


    while (startDate.isBefore(endDate)) {
        const dayName = startDate.format('dddd').toLowerCase();

        // Check if the current day is a weekday (Monday to Friday) and not in skipWeekdays
        if (!skipWeekends || (skipWeekends && !['saturday', 'sunday'].includes(dayName))) {
            repeatCount++;
        }

        // Move to the next date based on the recurrence type
        startDate.add(every, unit);
    }

    return repeatCount;
};

export const getDaysBetweenDates = (startDate, endDate, type, skipWeekends, every = 1) => {
    const startMoment = moment(startDate).add(every, 'day');
    const endMoment = endDate ? moment(endDate) : moment(startDate).add(50, 'day');//for Frover case
    const days = [];

    while (startMoment.isSameOrBefore(endMoment, 'day')) {
        const dayName = startMoment.format('dddd').toLowerCase();

        if (type === 'daily') {
            if (!days.includes(dayName) && (!skipWeekends || !['saturday', 'sunday'].includes(dayName))) {
                days.push(dayName);
            }
        } else if (type === 'weekly') {
            if (days.length === 0) {
                days.push(dayName);
                break;
            }
        }
        //Maximum possible days reached no need to continue.
        if ((skipWeekends && days.length) >= 5 || (!skipWeekends && days.length >= 7)) break;
        startMoment.add(every, 'day');
    }

    return days;
};

export const durationOptions =
[
    { value: '12', label: '12 hours' },
    { value: '24', label: '24 hours' },
    { value: '48', label: '48 hours' },
    { value: '72', label: '72 hours' },
    { value: '96', label: '96 hours' },
    { value: '120', label: '120 hours' },
];
export const defaultSelectStyles = {
    container: (provided, _state) => ({ ...provided, width: '100%' }),
    control: (provided, _state) => ({
        ...provided,
        boxShadow: 'none',
        position: 'relative',
        flex: '1 1 100%',
        minWidth: '250px',
        maxWidth: '100%',
        width: '100%',
        fontFamily: 'var(--app-text-secondary-font)',
        fontSize: '1.2em',
        lineHeight: '20px',
        color: 'var(--app-color-gray-light)',
        border: 'none',
        outline: 'none',
        textAlign: 'left',
        justifyContent: 'center',
        alignItems: 'stretch',
        alignContent: 'center',
        alignSelf: 'stretch',
        margin: '0px 0 0 0 ',
        padding: '0 0px 0 10px',
    }),
    input: (provided, _state) => ({
        ...provided,
        margin: '0px 0 0px 0px ',
        fontFamily: 'var(--app-text-secondary-font)',
        fontSize: '1.2em',
        lineHeight: '20px',
        color: 'var(--app-color-gray-light)',
        border: 'none',
        outline: 'none',
        alignSelf: 'center',
        padding: '0',
        width: '100%',
        textAlign: 'left',
    }),
    menu: (provided, { _data, _isDisabled, _isFocused, isSelected }) => ({
        ...provided,
        justifyContent: 'center',
        alignItems: 'center',
        verticalTextAlign: 'center',
        alignContent: 'center',
        color: isSelected ? 'var(--app-color-gray-light)' : '#4A4A4A',
    }),
    menulist: (provided, { _data, _isDisabled, _isFocused, _isSelected }) => ({
        ...provided,
        justifyContent: 'center',
        alignItems: 'center',
        verticalTextAlign: 'center',
        alignContent: 'center',
        backgroundColor: 'transparent',
    }),
    option: (provided, { _data, _isDisabled, isFocused, isSelected }) => ({
        ...provided,
        minHeight: '20px',
        paddingTop: '10px',
        fontSize: '0.9em',
        verticalTextAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        color: isSelected ? '#4A4A4A' : isFocused ? 'white' : 'var(--app-color-gray-light)',
        backgroundColor: isSelected ? '#D2D2D2' : isFocused ? '#4A4A4A' : 'transparent',
        borderRadius: '8px',
    }),
    placeholder: (provided, _state) => ({ ...provided, fontSize: '1em', lineHeight: '20px', color: 'var(--app-color-gray-light)', fontWeight: 400, overflow: 'hidden' }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition, marginLeft: '0px', fontSize: '0.75em', lineHeight: '20px', color: '#4A4A4A' };
    },
};
export const selectStyles = {
    container: (provided, _state) => ({ ...provided, width: '100%' }),
    control: (provided, _state) => ({
        ...provided,
        boxShadow: 'none',
        position: 'relative',
        flex: '1 1 100%',
        minWidth: '250px',
        maxWidth: '100%',
        width: '100%',
        fontFamily: 'var(--app-text-secondary-font)',
        fontSize: '16px',
        color: 'var(--alvanda-dark-purple)',
        border: '1px solid rgba(11, 11, 40, 0.2)!important',
        outline: 'none',
        textAlign: 'left',
        justifyContent: 'center',
        alignItems: 'stretch',
        alignContent: 'center',
        alignSelf: 'stretch',
        margin: '0px 0 0 0 ',
        padding: '0 0px 0 10px',
        height: '48px',
    }),
    input: (provided, _state) => ({
        ...provided,
        margin: '0px 0 0px 0px ',
        fontFamily: 'var(--app-text-secondary-font)',
        fontSize: '1.2em',
        lineHeight: '20px',
        color: 'var(--alvanda-dark-purple)',
        border: 'none',
        outline: 'none',
        alignSelf: 'center',
        padding: '0',
        width: '100%',
        textAlign: 'left',
        height: '48px',
    }),
    menu: (provided, { _data, _isDisabled, _isFocused, isSelected }) => ({
        ...provided,
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'var(--app-text-secondary-font)',
        verticalTextAlign: 'center',
        alignContent: 'center',
        width: '80%',
        color: isSelected ? 'var(--alvanda-dark-purple)' : '#4A4A4A',
    }),
    option: (provided, { _data, _isDisabled, isFocused, isSelected }) => ({
        ...provided,
        minHeight: '20px',
        paddingTop: '10px',
        fontSize: '0.9em',
        verticalTextAlign: 'center',
        fontFamily: 'var(--app-text-secondary-font)',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        color: isSelected ? '#4A4A4A' : isFocused ? 'white' : 'var(--app-color-gray-light)',
        backgroundColor: isSelected ? '#D2D2D2' : isFocused ? '#4A4A4A' : 'transparent',
        borderRadius: '8px',
    }),
    placeholder: (provided, _state) => ({ ...provided, fontSize: '1em', lineHeight: '20px', color: 'var(--app-color-gray-light)', fontWeight: 400, overflow: 'hidden' }),
};
export const ValueContainer = ({ children, ...props }) => {
    return (
        components.ValueContainer && (
            <components.ValueContainer {...props}>
                {!!children && <img alt="valc" src={alphabetical} style={{ position: 'absolute', left: 6, width: '16px' /* height:'10px' */ }} />}
                {children}
            </components.ValueContainer>
        )
    );
};
export const CustomOption = ({ innerProps, isDisabled, label }) =>
    !isDisabled ? (
        <div {...innerProps} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            <div style={{ width: '20px', height: '20px' }}>
                <img src={roundArrow} alt=">" style={{ width: '20px', height: '20px' }} />
            </div>
            <div style={{ margin: '0 0 0 10px', flex: 1 }}>
                <p>{label}</p>
            </div>
        </div>
    ) : null;
export const useStateRef = (initialValue: any): Array<any> => {
    const [value, setValue] = useState(initialValue);
    const ref = useRef(value);

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return [value, setValue, ref];
};

export const required = (value) => {
    if (!value) {
        return (
            <p style={{ fontFamily: 'var(--app-text-secondary-font)', fontSize: '1rem', lineHeight: '22px', color: 'var(--app-color-error)', margin: '1px 0 2px 0', padding: '0 0 0 1px' }} role="alert">
                {'This field is required!'}
            </p>
        );
    }
};

export const waitUntilConditionMet = (checkFunction, maxRetries = 5, resolveAlways = true, retryInterval = 2000) => {
    return new Promise((resolve, reject) => {
        let retries = 0;

        function checkAndRetry() {
            if (checkFunction()) {
                if (retries < maxRetries) {
                    retries++;
                    setTimeout(checkAndRetry, retryInterval);
                } else {
                    if (resolveAlways) {
                        resolve();
                    } else {
                        reject(new Error('Max retries reached. Unable to proceed.'));
                    }
                }
            } else {
                resolve();
            }
        }

        checkAndRetry();
    });
};

export const sleep = (time) => {
    return new Promise(resolve => setTimeout(resolve, time));
};

/**
 * str='' Output: true
 * str='Hello, World!' Output: false
 * str=42 Output: false
 * str=undefined Output: true
 * str=null Output: true
 * str=[] Output: true
 * str=[1, 2, 3] Output: false
 * str={} Output: true
 * str={ key: 'value' } Output: false
 */
export const isEmptyValue = (str) => {
    //Some string as 'undefined'/'null' as string value
    if (str === undefined || str === 'undefined' || str === null || str === "null") return true;
    if (typeof str === 'string' || typeof str === 'number') {
        const stringValue = String(str).trim();

        return stringValue.length === 0;
    } else if (Array.isArray(str)) {
        return str.length === 0;
    } else if (typeof str === 'object') {
        return Object.keys(str).length === 0;
    }

    return false;
};

export const isNotEmptyValue = (str) => {
    return !isEmptyValue(str);
};

/**
 * formatPriceWithCurrency(100, 'USD') => '$100.00'
 * formatPriceWithCurrency(1500.75, 'EUR') => '€1,500.75'
 * formatPriceWithCurrency(0, 'GBP') => '£0.00'
 * formatPriceWithCurrency(50000, 'JPY') => '¥50,000'
 */
export const formatPriceWithCurrency = (amount, currency) => {
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: isEmptyValue(currency) ? 'USD' : currency,
    }).format(amount);
};
/**
 * pluralize(5, 'month') => 5 months
 * pluralize(1, 'month') => 1 month
 */
export const pluralize = (count, noun, suffix = "s") => {
    return `${count} ${noun}${count !== 1 ? suffix : ""}`;
};

export const YEARLY_PLAN_ID = process.env.REACT_APP_YEARLY_PLAN_ID;
/**
 * getDiscountAmount(100, 5) => 5
 * getDiscountAmount(100, 0) => 0
 * getDiscountAmount(100, null) => 0
 */
export const getDiscountAmount = (amount, discountPercentage, doRoundOff = false) => {
    if (isEmptyValue(discountPercentage) || discountPercentage === 0) return 0;

    let discountAmount = (Number(amount) * Number(discountPercentage)) / 100;

    return doRoundOff ? Math.round(discountAmount) : discountAmount;
};

/**
 * getDiscountedPrice(100, 5) => 95
 * getDiscountedPrice(100, 0) => 100
 * getDiscountedPrice(100, null) => 100
 */
export const getDiscountedPrice = (amount, discountPercentage, doRoundOff = false) => {
    if (isEmptyValue(discountPercentage) || discountPercentage === 0) return amount;

    let discountedPrice = Number(amount) - getDiscountAmount(amount, discountPercentage, doRoundOff);

    return doRoundOff ? Math.round(discountedPrice) : discountedPrice;
};

/**
 * htmlContent='<div>    </div>' Output: true
 * htmlContent='<p><br></p>' Output: true
 * htmlContent='<span>&ensp;</span' Output: true
 * htmlContent='aasd' Output: false
 * htmlContent='<p>This is some text.</p>' Output: false
 */
export const isHTMLContentEmpty = (htmlContent) => {
    if (typeof htmlContent !== 'string' || htmlContent.trim() === '') {
        return true;
    }
    const aux = document.createElement('div');

    try {
        aux.innerHTML = htmlContent;
    } catch (error) {
        return true;
    }
    const trimmedContent = aux.innerText.trim();

    // Remove the temporary div to clean up
    aux.remove();

    return trimmedContent === '';
};

/**
 * htmlContent='<div>ASD</div>' Output: ASD
 * htmlContent='<p><br></p>' Output: ""
 */
export const htmlToString = (htmlContent) => {
    if (typeof htmlContent !== 'string' || htmlContent.trim() === '') {
        return "";
    }
    const aux = document.createElement('div');

    try {
        aux.innerHTML = htmlContent;
    } catch (error) {
        return true;
    }
    const trimmedContent = aux.innerText.trim();

    // Remove the temporary div to clean up
    aux.remove();

    return trimmedContent;
};

export const revivalBack = () => {
    window.onpopstate = undefined;
    window.history.back();
};

export const numberOfEmployees = [
    { value: '1-10', label: '1-10 employees', id: 0 },
    { value: '11-50', label: '11-50 employees', id: 1 },
    { value: '51-200', label: '51-200 employees', id: 2 },
    { value: '201-500', label: '201-500 employees', id: 3 },
    { value: '501+', label: '501+ employees', id: 3 },
];
export const positions = [
    { value: 'front-end-developer', label: 'Front End Developer', id: 0 },
    { value: 'back-end-developer', label: 'Back End Developer', id: 1 },
    { value: 'ui-ux-designer', label: 'UI/UX Designer', id: 2 },
    { value: 'project-manager', label: 'Project Manager', id: 3 },
];

export const colleagues = [
    { name: 'Moga B.', position: 'Frontend Developer', id: '0' },
    { name: 'Daniel IP.', position: 'Frontend Developer', id: '1' },
    { name: 'Daniel R.', position: 'Backend Developer', id: '2' },
    { name: 'Ugur K.', position: 'Dev Ops', id: '3' },
    { name: 'Daniel D.', position: '', id: '4' },
    { name: 'Bogdan G.', position: 'Project Manager', id: '5' },
    { name: 'Andreea T.', position: 'QA Tester', id: '6' },
    { name: 'Grant', position: '', id: '7' },
];

export const componentIconsList = [
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/aim.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/aim.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/antivirus.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/antivirus.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/bar-chart.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/bar-chart.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/bar-chart2.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/bar-chart2.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/briefcase.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/briefcase.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/chatting.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/chatting.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/clipboard.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/clipboard.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/community.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/community.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/connection.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/connection.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/currency.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/currency.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/electromagnet.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/electromagnet.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/email.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/email.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/filter.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/filter.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/financing.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/financing.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/goal.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/goal.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/good-feedback.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/good-feedback.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/headphones.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/headphones.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/idea.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/idea.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/income.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/income.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/like.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/like.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/line-graph.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/line-graph.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/loudspeaker.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/loudspeaker.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/magnifying-glass.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/magnifying-glass.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/manager.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/manager.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/money.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/money.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/online-marketing.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/online-marketing.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/online-payment.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/online-payment.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/pie-chart.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/pie-chart.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/pie-chart2.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/pie-chart2.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/pie-chart3.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/pie-chart3.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/price-tag.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/price-tag.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/puzzle.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/puzzle.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/rocket.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/rocket.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/settings.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/settings.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/shopping-bag.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/shopping-bag.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/shopping-cart.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/shopping-cart.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/social-engineering.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/social-engineering.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/stall.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/stall.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/statistics.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/statistics.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/statistics2.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/statistics2.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/strategy.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/strategy.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/target-audience.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/target-audience.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/target.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/target.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/target2.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/target2.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/trophy.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/trophy.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/user.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/user.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/viral-marketing.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/viral-marketing.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/web-maintenance.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/web-maintenance.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/worldwide.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/worldwide.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
    {
        value: 'https://alvanda-storage.s3.amazonaws.com/icons/components/youtube.svg',
        label: (
            <div>
                <img alt="compI" src={'https://alvanda-storage.s3.amazonaws.com/icons/components/youtube.svg'} style={{ width: '18px', height: '18px' }} />
            </div>
        ),
    },
];
export const minSecOptions = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '23', value: '23' },
    { label: '24', value: '24' },
    { label: '25', value: '25' },
    { label: '26', value: '26' },
    { label: '27', value: '27' },
    { label: '28', value: '28' },
    { label: '29', value: '29' },
    { label: '30', value: '30' },
    { label: '31', value: '31' },
    { label: '32', value: '32' },
    { label: '33', value: '33' },
    { label: '34', value: '34' },
    { label: '35', value: '35' },
    { label: '36', value: '36' },
    { label: '37', value: '37' },
    { label: '38', value: '38' },
    { label: '39', value: '39' },
    { label: '40', value: '40' },
    { label: '41', value: '41' },
    { label: '42', value: '42' },
    { label: '43', value: '43' },
    { label: '44', value: '44' },
    { label: '45', value: '45' },
    { label: '46', value: '46' },
    { label: '47', value: '47' },
    { label: '48', value: '48' },
    { label: '49', value: '49' },
    { label: '50', value: '50' },
    { label: '51', value: '51' },
    { label: '52', value: '52' },
    { label: '53', value: '53' },
    { label: '54', value: '54' },
    { label: '55', value: '55' },
    { label: '56', value: '56' },
    { label: '57', value: '57' },
    { label: '58', value: '58' },
    { label: '59', value: '59' },
    { label: '60', value: '60' },
];
export const hoursOptions = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '23', value: '23' },
    { label: '24', value: '24' },
];
export const daysOptions = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '23', value: '23' },
    { label: '24', value: '24' },
    { label: '25', value: '25' },
    { label: '26', value: '26' },
    { label: '27', value: '27' },
    { label: '28', value: '28' },
    { label: '29', value: '29' },
    { label: '30', value: '30' },
    { label: '31', value: '31' },
];
export const timeUnitsOptions = [
    { label: 'Seconds', value: 1 },
    { label: 'Minutes', value: 60 },
    { label: 'Hours', value: 3600 },
    { label: 'Days', value: 86400 },
];

export const timeUnitsOptionsWithoutSeconds = [
    {label: 'Minutes', value: 60},
    {label: 'Hours', value: 3600},
    {label: 'Days', value: 86400},
];

export const durationUnitOptions = [
    {label: 'Seconds', value: 'seconds'},
    {label: 'Minutes', value: 'minutes'},
    {label: 'Hours', value: 'hours'},
    {label: 'Days', value: 'days'},
];

export const isInt = (n) => {
    return Number(n) === n && n % 1 === 0;
};

export const convertToSeconds = (time, unit) => {
    time = Number(time);
    switch (unit?.toLowerCase()) {
        case 'seconds':
            return time;
        case 'minutes':
            return time * 60;
        case 'hours':
            return time * 3600;
        case 'days':
            return time * 86400;
        default:
            return time;
    }
};

export const transformSeconds = (seconds) => {
    if (!seconds) return {value: 0, unit: 'Seconds'};

    const minutes = Math.floor(Number(seconds) / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days && days > 0) {
        return {value: days, unit: 'Days'};
    } else if (hours && hours > 0) {
        return {value: hours, unit: 'Hours'};
    } else if (minutes && minutes > 0) {
        return {value: minutes, unit: 'Minutes'};
    } else if (seconds && seconds > 0) {
        return {value: seconds, unit: 'Seconds'};
    } else {
        return {value: 0, unit: 'Seconds'};
    }
};

export const pad = (value) => {
    return value.toString().padStart(2, '0');
};

// helper of formatTimeDifferenceFromNow, formatDurationFromSeconds
const formatMomentDuration = (duration) => {
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;
    const seconds = Math.floor(duration.asSeconds()) % 60;

    return (hours > 0 ? pad(hours) + ':' : '') + pad(minutes) + ':' + pad(seconds);
};

//utcTimestamp=2023-12-18T12:58:49.000000Z to outputs: "48:39:30" or "01:02"
export const formatTimeDifferenceFromNow = (utcTimestamp, additionalSeconds = 0) => {
    const msDifference = moment.utc().diff(moment.utc(utcTimestamp));
    const duration = moment.duration(msDifference + (additionalSeconds * 1000));

    return formatMomentDuration(duration);
};

//totalSeconds=175170 to outputs: "48:39:30" or "01:02"
export const formatDurationFromSeconds = (totalSeconds) => {
    const duration = moment.duration(totalSeconds, 'seconds');

    return formatMomentDuration(duration);
};

export const transformSecondsInString = (seconds) => {
    const minutes = +seconds / 60;
    let hours = 0,
        days = 0;

    if (minutes > 60) hours = minutes / 60;
    if (hours > 24) days = hours / 24;
    if (+seconds < 60) return +seconds.toFixed(0) + ' s';
    if (hours == 0) return minutes?.toFixed(0) + ' min';
    if (days == 0) return hours?.toFixed(0) + ' hours';

    return days?.toFixed(0) + ' days';
};

export const convertSecondsToString = (seconds) => {
    if (seconds < 60) {
        return seconds + ' s';
    } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        return remainingSeconds > 0 ? `${minutes}m ${remainingSeconds}s` : `${minutes} m`;
    } else if (seconds < 86400) {
        const hours = Math.floor(seconds / 3600);
        const remainingMinutes = Math.floor((seconds % 3600) / 60);

        const hoursString = remainingMinutes > 0 ? `${hours}h ${remainingMinutes}m` : `${hours} h`;

        return hoursString;
    } else if (seconds < 2592000) {
        const days = Math.floor(seconds / 86400);

        return `${days} days`;
    } else if (seconds < 31536000) {
        const months = Math.floor(seconds / 2592000);
        const monthsText = months > 1 ? 'mnth' : 'mnths';

        return `${months} ${monthsText}`;
    } else {
        const years = Math.floor(seconds / 31536000);

        return `${years} years`;
    }
};

export function debounce(func, wait, immediate) {
    let timeout;

    return function executedFunction() {
        const vm = this;
        const args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(vm, args);
        };
        const callNow = immediate && !timeout;

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(vm, args);
    };
}

export const convertDateFormat = (date) => {
    const parts = date.split(' ');

    if (parts.length === 6) {
        const day = parts[0];
        const month = parts[2];
        const year = parts[3];
        const time = parts[4];
        const amPm = parts[5];

        return `${month} ${day} ${year} ${time} ${amPm}`;
    } else {
        return moment(date).format('MMM DD YYYY h:mm:ss A');
    }
};

export const currency_list = [{
    code: 'AF',
    label: "Afghan Afghani",
    symbol: "؋",
    value: "AFN",
}, {
    code: "AL",
    label: "Albanian Lek",
    symbol: "Lek",
    value: "ALL",
}, {
    code: "DZ",
    label: "Algerian Dinar",
    symbol: "دج",
    value: "DZD",
}, {
    code: "AO",
    label: "Angolan Kwanza",
    symbol: "Kz",
    value: "AOA",
}, {
    code: "AR",
    label: "Argentine Peso",
    symbol: "$",
    value: "ARS",
}, {
    code: "AM",
    label: "Armenian Dram",
    symbol: "֏",
    value: "AMD",
}, {
    code: "AW",
    label: "Aruban Florin",
    symbol: "ƒ",
    value: "AWG",
}, {
    code: "AU",
    label: "Australian Dollar",
    symbol: "$",
    value: "AUD",
}, {
    code: "AZ",
    label: "Azerbaijani Manat",
    symbol: "m",
    value: "AZN",
}, {
    code: "BS",
    label: "Bahamian Dollar",
    symbol: "B$",
    value: "BSD",
}, {
    code: "BH",
    label: "Bahraini Dinar",
    symbol: ".د.ب",
    value: "BHD",
}, {
    code: "BD",
    label: "Bangladeshi Taka",
    symbol: "৳",
    value: "BDT",
}, {
    code: "BB",
    label: "Barbadian Dollar",
    symbol: "Bds$",
    value: "BBD",
}, {
    code: 'BY',
    label: "Belarusian Ruble",
    symbol: "Br",
    value: "BYR",
}, {
    code: 'BI',
    label: "Belgian Franc",
    symbol: "fr",
    value: "BEF",
}, {
    code: "BZ",
    label: "Belize Dollar",
    symbol: "$",
    value: "BZD",
}, {
    code: "BM",
    label: "Bermudan Dollar",
    symbol: "$",
    value: "BMD",
}, {
    code: "BT",
    label: "Bhutanese Ngultrum",
    symbol: "Nu.",
    value: "BTN",
}, {
    label: "Bitcoin",
    symbol: "฿",
    value: "BTC",
}, {
    code: "BO",
    label: "Bolivian Boliviano",
    symbol: "Bs.",
    value: "BOB",
}, {
    code: "BA",
    label: "Bosnia-Herzegovina Convertible Mark",
    symbol: "KM",
    value: "BAM",
}, {
    code: "BW",
    label: "Botswanan Pula",
    symbol: "P",
    value: "BWP",
}, {
    code: "BR",
    label: "Brazilian Real",
    symbol: "R$",
    value: "BRL",
}, {
    code: "GB",
    label: "British Pound Sterling",
    symbol: "£",
    value: "GBP",
}, {
    code: "BN",
    label: "Brunei Dollar",
    symbol: "B$",
    value: "BND",
}, {
    code: "BG",
    label: "Bulgarian Lev",
    symbol: "Лв.",
    value: "BGN",
}, {
    code: "BI",
    label: "Burundian Franc",
    symbol: "FBu",
    value: "BIF",
}, {
    code: "KH",
    label: "Cambodian Riel",
    symbol: "KHR",
    value: "KHR",
}, {
    code: "CA",
    label: "Canadian Dollar",
    symbol: "$",
    value: "CAD",
}, {
    code: "CV",
    label: "Cape Verdean Escudo",
    symbol: "$",
    value: "CVE",
}, {
    code: "KY",
    label: "Cayman Islands Dollar",
    symbol: "$",
    value: "KYD",
}, {
    code: "BJ",
    label: "CFA Franc BCEAO",
    symbol: "CFA",
    value: "XOF",
}, {
    code: "CM",
    label: "CFA Franc BEAC",
    symbol: "FCFA",
    value: "XAF",
}, {
    code: "PF",
    label: "CFP Franc",
    symbol: "₣",
    value: "XPF",
}, {
    code: "CL",
    label: "Chilean Peso",
    symbol: "$",
    value: "CLP",
}, {
    code: "CN",
    label: "Chinese Yuan",
    symbol: "¥",
    value: "CNY",
}, {
    code: "CO",
    label: "Colombian Peso",
    symbol: "$",
    value: "COP",
}, {
    code: "KM",
    label: "Comorian Franc",
    symbol: "CF",
    value: "KMF",
}, {
    code: "CG",
    label: "Congolese Franc",
    symbol: "FC",
    value: "CDF",
}, {
    code: "CR",
    label: "Costa Rican Colón",
    symbol: "₡",
    value: "CRC",
}, {
    code: "HR",
    label: "Croatian Kuna",
    symbol: "kn",
    value: "HRK",
}, {
    code: "CU",
    label: "Cuban Convertible Peso",
    symbol: "$, CUC",
    value: "CUC",
}, {
    code: "CZ",
    label: "Czech Republic Koruna",
    symbol: "Kč",
    value: "CZK",
}, {
    code: "DK",
    label: "Danish Krone",
    symbol: "Kr.",
    value: "DKK",
}, {
    code: "DJ",
    label: "Djiboutian Franc",
    symbol: "Fdj",
    value: "DJF",
}, {
    code: "DO",
    label: "Dominican Peso",
    symbol: "$",
    value: "DOP",
}, {
    code: "DM",
    label: "East Caribbean Dollar",
    symbol: "$",
    value: "XCD",
}, {
    code: "EG",
    label: "Egyptian Pound",
    symbol: "ج.م",
    value: "EGP",
}, {
    code: "ER",
    label: "Eritrean Nakfa",
    symbol: "Nfk",
    value: "ERN",
}, {
    code: 'EE',
    label: "Estonian Kroon",
    symbol: "kr",
    value: "EEK",
}, {
    code: "ET",
    label: "Ethiopian Birr",
    symbol: "Nkf",
    value: "ETB",
}, {
    code: "AT",
    label: "Euro",
    symbol: "€",
    value: "EUR",
}, {
    code: "FK",
    label: "Falkland Islands Pound",
    symbol: "£",
    value: "FKP",
}, {
    code: "FJ",
    label: "Fijian Dollar",
    symbol: "FJ$",
    value: "FJD",
}, {
    code: "GM",
    label: "Gambian Dalasi",
    symbol: "D",
    value: "GMD",
}, {
    code: "GE",
    label: "Georgian Lari",
    symbol: "ლ",
    value: "GEL",
}, {
    code: 'DE',
    label: "German Mark",
    symbol: "DM",
    value: "DEM",
}, {
    code: "GH",
    label: "Ghanaian Cedi",
    symbol: "GH₵",
    value: "GHS",
}, {
    code: "GI",
    label: "Gibraltar Pound",
    symbol: "£",
    value: "GIP",
}, {
    code: "GR",
    label: "Greek Drachma",
    symbol: "₯, Δρχ, Δρ",
    value: "GRD",
}, {
    code: "GT",
    label: "Guatemalan Quetzal",
    symbol: "Q",
    value: "GTQ",
}, {
    code: "GN",
    label: "Guinean Franc",
    symbol: "FG",
    value: "GNF",
}, {
    code: "GY",
    label: "Guyanaese Dollar",
    symbol: "$",
    value: "GYD",
}, {
    code: "HT",
    label: "Haitian Gourde",
    symbol: "G",
    value: "HTG",
}, {
    code: "HN",
    label: "Honduran Lempira",
    symbol: "L",
    value: "HNL",
}, {
    code: "HK",
    label: "Hong Kong Dollar",
    symbol: "$",
    value: "HKD",
}, {
    code: "HU",
    label: "Hungarian Forint",
    symbol: "Ft",
    value: "HUF",
}, {
    code: "IS",
    label: "Icelandic Króna",
    symbol: "kr",
    value: "ISK",
}, {
    code: "IN",
    label: "Indian Rupee",
    symbol: "₹",
    value: "INR",
}, {
    code: "ID",
    label: "Indonesian Rupiah",
    symbol: "Rp",
    value: "IDR",
}, {
    code: "IR",
    label: "Iranian Rial",
    symbol: "﷼",
    value: "IRR",
}, {
    code: "IQ",
    label: "Iraqi Dinar",
    symbol: "د.ع",
    value: "IQD",
}, {
    code: "IL",
    label: "Israeli New Sheqel",
    symbol: "₪",
    value: "ILS",
}, {
    code: 'IT',
    label: "Italian Lira",
    symbol: "L,£",
    value: "ITL",
}, {
    code: "JM",
    label: "Jamaican Dollar",
    symbol: "J$",
    value: "JMD",
}, {
    code: "JP",
    label: "Japanese Yen",
    symbol: "¥",
    value: "JPY",
}, {
    code: "JO",
    label: "Jordanian Dinar",
    symbol: "ا.د",
    value: "JOD",
}, {
    code: "KZ",
    label: "Kazakhstani Tenge",
    symbol: "лв",
    value: "KZT",
}, {
    code: "KE",
    label: "Kenyan Shilling",
    symbol: "KSh",
    value: "KES",
}, {
    code: "KW",
    label: "Kuwaiti Dinar",
    symbol: "ك.د",
    value: "KWD",
}, {
    code: "KG",
    label: "Kyrgystani Som",
    symbol: "лв",
    value: "KGS",
}, {
    code: "LA",
    label: "Laotian Kip",
    symbol: "₭",
    value: "LAK",
}, {
    code: 'LV',
    label: "Latvian Lats",
    symbol: "Ls",
    value: "LVL",
}, {
    code: "LB",
    label: "Lebanese Pound",
    symbol: "£",
    value: "LBP",
}, {
    code: "LS",
    label: "Lesotho Loti",
    symbol: "L",
    value: "LSL",
}, {
    code: "LR",
    label: "Liberian Dollar",
    symbol: "$",
    value: "LRD",
}, {
    code: "LY",
    label: "Libyan Dinar",
    symbol: "د.ل",
    value: "LYD",
}, {
    code: "LT",
    label: "Lithuanian Litas",
    symbol: "Lt",
    value: "LTL",
}, {
    code: "MO",
    label: "Macanese Pataca",
    symbol: "$",
    value: "MOP",
}, {
    code: "MK",
    label: "Macedonian Denar",
    symbol: "ден",
    value: "MKD",
}, {
    code: "MG",
    label: "Malagasy Ariary",
    symbol: "Ar",
    value: "MGA",
}, {
    code: "MW",
    label: "Malawian Kwacha",
    symbol: "MK",
    value: "MWK",
}, {
    code: "MY",
    label: "Malaysian Ringgit",
    symbol: "RM",
    value: "MYR",
}, {
    code: "MV",
    label: "Maldivian Rufiyaa",
    symbol: "Rf",
    value: "MVR",
}, {
    code: 'MR',
    label: "Mauritanian Ouguiya",
    symbol: "MRU",
    value: "MRO",
}, {
    code: "MU",
    label: "Mauritian Rupee",
    symbol: "₨",
    value: "MUR",
}, {
    code: "MX",
    label: "Mexican Peso",
    symbol: "$",
    value: "MXN",
}, {
    code: "MD",
    label: "Moldovan Leu",
    symbol: "L",
    value: "MDL",
}, {
    code: "MN",
    label: "Mongolian Tugrik",
    symbol: "₮",
    value: "MNT",
}, {
    code: "MA",
    label: "Moroccan Dirham",
    symbol: "MAD",
    value: "MAD",
}, {
    code: "MZ",
    label: "Mozambican Metical",
    symbol: "MT",
    value: "MZM",
}, {
    code: "MM",
    label: "Myanmar Kyat",
    symbol: "K",
    value: "MMK",
}, {
    code: "NA",
    label: "Namibian Dollar",
    symbol: "$",
    value: "NAD",
}, {
    code: "NP",
    label: "Nepalese Rupee",
    symbol: "₨",
    value: "NPR",
}, {
    code: "SX",
    label: "Netherlands Antillean Guilder",
    symbol: "ƒ",
    value: "ANG",
}, {
    code: "TW",
    label: "New Taiwan Dollar",
    symbol: "$",
    value: "TWD",
}, {
    code: "NZ",
    label: "New Zealand Dollar",
    symbol: "$",
    value: "NZD",
}, {
    code: "NI",
    label: "Nicaraguan Córdoba",
    symbol: "C$",
    value: "NIO",
}, {
    code: "NG",
    label: "Nigerian Naira",
    symbol: "₦",
    value: "NGN",
}, {
    code: "KP",
    label: "North Korean Won",
    symbol: "₩",
    value: "KPW",
}, {
    code: "NO",
    label: "Norwegian Krone",
    symbol: "kr",
    value: "NOK",
}, {
    code: "OM",
    label: "Omani Rial",
    symbol: ".ع.ر",
    value: "OMR",
}, {
    code: "PK",
    label: "Pakistani Rupee",
    symbol: "₨",
    value: "PKR",
}, {
    code: "PA",
    label: "Panamanian Balboa",
    symbol: "B/.",
    value: "PAB",
}, {
    code: "PG",
    label: "Papua New Guinean Kina",
    symbol: "K",
    value: "PGK",
}, {
    code: "PY",
    label: "Paraguayan Guarani",
    symbol: "₲",
    value: "PYG",
}, {
    code: "PE",
    label: "Peruvian Nuevo Sol",
    symbol: "S/.",
    value: "PEN",
}, {
    code: "PH",
    label: "Philippine Peso",
    symbol: "₱",
    value: "PHP",
}, {
    code: "PL",
    label: "Polish Zloty",
    symbol: "zł",
    value: "PLN",
}, {
    code: "QA",
    label: "Qatari Rial",
    symbol: "ق.ر",
    value: "QAR",
}, {
    code: "RO",
    label: "Romanian Leu",
    symbol: "RON",
    value: "RON",
}, {
    code: "RU",
    label: "Russian Ruble",
    symbol: "₽",
    value: "RUB",
}, {
    code: "RW",
    label: "Rwandan Franc",
    symbol: "FRw",
    value: "RWF",
}, {
    code: "SV",
    label: "Salvadoran Colón",
    symbol: "₡",
    value: "SVC",
}, {
    code: "WS",
    label: "Samoan Tala",
    symbol: "SAT",
    value: "WST",
}, {
    code: "SA",
    label: "Saudi Riyal",
    symbol: "﷼",
    value: "SAR",
}, {
    code: "RS",
    label: "Serbian Dinar",
    symbol: "din",
    value: "RSD",
}, {
    code: "SC",
    label: "Seychellois Rupee",
    symbol: "SRe",
    value: "SCR",
}, {
    code: "SL",
    label: "Sierra Leonean Leone",
    symbol: "Le",
    value: "SLL",
}, {
    code: "SG",
    label: "Singapore Dollar",
    symbol: "$",
    value: "SGD",
}, {
    code: "SK",
    label: "Slovak Koruna",
    symbol: "Sk",
    value: "SKK",
}, {
    code: "SB",
    label: "Solomon Islands Dollar",
    symbol: "Si$",
    value: "SBD",
}, {
    code: "SO",
    label: "Somali Shilling",
    symbol: "Sh.so.",
    value: "SOS",
}, {
    code: "ZA",
    label: "South African Rand",
    symbol: "R",
    value: "ZAR",
}, {
    code: "KR",
    label: "South Korean Won",
    symbol: "₩",
    value: "KRW",
}, {
    code: '',
    label: "Special Drawing Rights",
    symbol: "SDR",
    value: "XDR",
}, {
    code: "LK",
    label: "Sri Lankan Rupee",
    symbol: "Rs",
    value: "LKR",
}, {
    code: "SH",
    label: "St. Helena Pound",
    symbol: "£",
    value: "SHP",
}, {
    code: "SD",
    label: "Sudanese Pound",
    symbol: ".س.ج",
    value: "SDG",
}, {
    code: "SR",
    label: "Surivaluese Dollar",
    symbol: "$",
    value: "SRD",
}, {
    code: "SZ",
    label: "Swazi Lilangeni",
    symbol: "E",
    value: "SZL",
}, {
    code: "SE",
    label: "Swedish Krona",
    symbol: "kr",
    value: "SEK",
}, {
    code: "LI",
    label: "Swiss Franc",
    symbol: "CHf",
    value: "CHF",
}, {
    code: "SY",
    label: "Syrian Pound",
    symbol: "LS",
    value: "SYP",
}, {
    code: 'ST',
    label: "São Tomé and Príncipe Dobra",
    symbol: "Db",
    value: "STD",
}, {
    code: "TJ",
    label: "Tajikistani Somoni",
    symbol: "SM",
    value: "TJS",
}, {
    code: "TZ",
    label: "Tanzanian Shilling",
    symbol: "TSh",
    value: "TZS",
}, {
    code: "TH",
    label: "Thai Baht",
    symbol: "฿",
    value: "THB",
}, {
    code: "TO",
    label: "Tongan Pa'anga",
    symbol: "$",
    value: "TOP",
}, {
    code: "TT",
    label: "Trinidad & Tobago Dollar",
    symbol: "$",
    value: "TTD",
}, {
    code: "TN",
    label: "Tunisian Dinar",
    symbol: "ت.د",
    value: "TND",
}, {
    code: "TR",
    label: "Turkish Lira",
    symbol: "₺",
    value: "TRY",
}, {
    code: "TM",
    label: "Turkmenistani Manat",
    symbol: "T",
    value: "TMT",
}, {
    code: "UG",
    label: "Ugandan Shilling",
    symbol: "USh",
    value: "UGX",
}, {
    code: "UA",
    label: "Ukrainian Hryvnia",
    symbol: "₴",
    value: "UAH",
}, {
    code: "AE",
    label: "United Arab Emirates Dirham",
    symbol: "إ.د",
    value: "AED",
}, {
    code: "UY",
    label: "Uruguayan Peso",
    symbol: "$",
    value: "UYU",
}, {
    code: "US",
    label: "US Dollar",
    symbol: "$",
    value: "USD",
}, {
    code: "UZ",
    label: "Uzbekistan Som",
    symbol: "лв",
    value: "UZS",
}, {
    code: "VU",
    label: "Vanuatu Vatu",
    symbol: "VT",
    value: "VUV",
}, {
    code: "VE",
    label: "Venezuelan BolÃvar",
    symbol: "Bs",
    value: "VEF",
}, {
    code: "VN",
    label: "Vietnamese Dong",
    symbol: "₫",
    value: "VND",
}, {
    code: "YE",
    label: "Yemeni Rial",
    symbol: "﷼",
    value: "YER",
}, {
    code: 'ZM',
    label: "Zambian Kwacha",
    symbol: "ZK",
    value: "ZMK",
}];

export const fileExtensions = [
    { label: 'Adobe Acrobat Reader (.pdf)', value: '.pdf' },
    { label: 'Microsoft Word (.doc)', value: '.doc' },
    { label: 'Microsoft Word (.docx)', value: '.docx' },
    { label: 'Microsoft Excel (.xls)', value: '.xls' },
    { label: 'Microsoft Excel (.xlsx)', value: '.xlsx' },
    { label: 'Microsoft Power Point file (.ppt)', value: '.ppt' },
    { label: 'Microsoft Power Point file (.pptx)', value: '.pptx' },
    { label: 'Image file (.jpg)', value: '.jpg' },
    { label: 'Image file (.jpeg)', value: '.jpeg' },
    { label: 'Image file (.png)', value: '.pbg' },
    { label: 'JSON file (.json)', value: '.json' },
];
export const dropdownComponentSelectionType = ['single', 'multiple'];

export const timezonesList = [
    {
        label: 'Africa/Abidjan',
        value: 'Africa/Abidjan',
    },
    {
        label: 'Africa/Accra',
        value: 'Africa/Accra',
    },
    {
        label: 'Africa/Addis Ababa',
        value: 'Africa/Addis_Ababa',
    },
    {
        label: 'Africa/Algiers',
        value: 'Africa/Algiers',
    },
    {
        label: 'Africa/Asmara',
        value: 'Africa/Asmara',
    },
    {
        label: 'Africa/Bamako',
        value: 'Africa/Bamako',
    },
    {
        label: 'Africa/Bangui',
        value: 'Africa/Bangui',
    },
    {
        label: 'Africa/Banjul',
        value: 'Africa/Banjul',
    },
    {
        label: 'Africa/Bissau',
        value: 'Africa/Bissau',
    },
    {
        label: 'Africa/Blantyre',
        value: 'Africa/Blantyre',
    },
    {
        label: 'Africa/Brazzaville',
        value: 'Africa/Brazzaville',
    },
    {
        label: 'Africa/Bujumbura',
        value: 'Africa/Bujumbura',
    },
    {
        label: 'Africa/Cairo',
        value: 'Africa/Cairo',
    },
    {
        label: 'Africa/Casablanca',
        value: 'Africa/Casablanca',
    },
    {
        label: 'Africa/Ceuta',
        value: 'Africa/Ceuta',
    },
    {
        label: 'Africa/Conakry',
        value: 'Africa/Conakry',
    },
    {
        label: 'Africa/Dakar',
        value: 'Africa/Dakar',
    },
    {
        label: 'Africa/Dar es Salaam',
        value: 'Africa/Dar_es_Salaam',
    },
    {
        label: 'Africa/Djibouti',
        value: 'Africa/Djibouti',
    },
    {
        label: 'Africa/Douala',
        value: 'Africa/Douala',
    },
    {
        label: 'Africa/El Aaiun',
        value: 'Africa/El_Aaiun',
    },
    {
        label: 'Africa/Freetown',
        value: 'Africa/Freetown',
    },
    {
        label: 'Africa/Gaborone',
        value: 'Africa/Gaborone',
    },
    {
        label: 'Africa/Harare',
        value: 'Africa/Harare',
    },
    {
        label: 'Africa/Johannesburg',
        value: 'Africa/Johannesburg',
    },
    {
        label: 'Africa/Juba',
        value: 'Africa/Juba',
    },
    {
        label: 'Africa/Kampala',
        value: 'Africa/Kampala',
    },
    {
        label: 'Africa/Khartoum',
        value: 'Africa/Khartoum',
    },
    {
        label: 'Africa/Kigali',
        value: 'Africa/Kigali',
    },
    {
        label: 'Africa/Kinshasa',
        value: 'Africa/Kinshasa',
    },
    {
        label: 'Africa/Lagos',
        value: 'Africa/Lagos',
    },
    {
        label: 'Africa/Libreville',
        value: 'Africa/Libreville',
    },
    {
        label: 'Africa/Lome',
        value: 'Africa/Lome',
    },
    {
        label: 'Africa/Luanda',
        value: 'Africa/Luanda',
    },
    {
        label: 'Africa/Lubumbashi',
        value: 'Africa/Lubumbashi',
    },
    {
        label: 'Africa/Lusaka',
        value: 'Africa/Lusaka',
    },
    {
        label: 'Africa/Malabo',
        value: 'Africa/Malabo',
    },
    {
        label: 'Africa/Maputo',
        value: 'Africa/Maputo',
    },
    {
        label: 'Africa/Maseru',
        value: 'Africa/Maseru',
    },
    {
        label: 'Africa/Mbabane',
        value: 'Africa/Mbabane',
    },
    {
        label: 'Africa/Mogadishu',
        value: 'Africa/Mogadishu',
    },
    {
        label: 'Africa/Monrovia',
        value: 'Africa/Monrovia',
    },
    {
        label: 'Africa/Nairobi',
        value: 'Africa/Nairobi',
    },
    {
        label: 'Africa/Ndjamena',
        value: 'Africa/Ndjamena',
    },
    {
        label: 'Africa/Niamey',
        value: 'Africa/Niamey',
    },
    {
        label: 'Africa/Nouakchott',
        value: 'Africa/Nouakchott',
    },
    {
        label: 'Africa/Ouagadougou',
        value: 'Africa/Ouagadougou',
    },
    {
        label: 'Africa/Porto-Novo',
        value: 'Africa/Porto-Novo',
    },
    {
        label: 'Africa/Sao Tome',
        value: 'Africa/Sao_Tome',
    },
    {
        label: 'Africa/Tripoli',
        value: 'Africa/Tripoli',
    },
    {
        label: 'Africa/Tunis',
        value: 'Africa/Tunis',
    },
    {
        label: 'Africa/Windhoek',
        value: 'Africa/Windhoek',
    },
    {
        label: 'America/Adak',
        value: 'America/Adak',
    },
    {
        label: 'America/Anchorage',
        value: 'America/Anchorage',
    },
    {
        label: 'America/Anguilla',
        value: 'America/Anguilla',
    },
    {
        label: 'America/Antigua',
        value: 'America/Antigua',
    },
    {
        label: 'America/Araguaina',
        value: 'America/Araguaina',
    },
    {
        label: 'America/Argentina/Buenos Aires',
        value: 'America/Argentina/Buenos_Aires',
    },
    {
        label: 'America/Argentina/Catamarca',
        value: 'America/Argentina/Catamarca',
    },
    {
        label: 'America/Argentina/Cordoba',
        value: 'America/Argentina/Cordoba',
    },
    {
        label: 'America/Argentina/Jujuy',
        value: 'America/Argentina/Jujuy',
    },
    {
        label: 'America/Argentina/La Rioja',
        value: 'America/Argentina/La_Rioja',
    },
    {
        label: 'America/Argentina/Mendoza',
        value: 'America/Argentina/Mendoza',
    },
    {
        label: 'America/Argentina/Rio Gallegos',
        value: 'America/Argentina/Rio_Gallegos',
    },
    {
        label: 'America/Argentina/Salta',
        value: 'America/Argentina/Salta',
    },
    {
        label: 'America/Argentina/San Juan',
        value: 'America/Argentina/San_Juan',
    },
    {
        label: 'America/Argentina/San Luis',
        value: 'America/Argentina/San_Luis',
    },
    {
        label: 'America/Argentina/Tucuman',
        value: 'America/Argentina/Tucuman',
    },
    {
        label: 'America/Argentina/Ushuaia',
        value: 'America/Argentina/Ushuaia',
    },
    {
        label: 'America/Aruba',
        value: 'America/Aruba',
    },
    {
        label: 'America/Asuncion',
        value: 'America/Asuncion',
    },
    {
        label: 'America/Atikokan',
        value: 'America/Atikokan',
    },
    {
        label: 'America/Bahia',
        value: 'America/Bahia',
    },
    {
        label: 'America/Bahia Banderas',
        value: 'America/Bahia_Banderas',
    },
    {
        label: 'America/Barbados',
        value: 'America/Barbados',
    },
    {
        label: 'America/Belem',
        value: 'America/Belem',
    },
    {
        label: 'America/Belize',
        value: 'America/Belize',
    },
    {
        label: 'America/Blanc-Sablon',
        value: 'America/Blanc-Sablon',
    },
    {
        label: 'America/Boa Vista',
        value: 'America/Boa_Vista',
    },
    {
        label: 'America/Bogota',
        value: 'America/Bogota',
    },
    {
        label: 'America/Boise',
        value: 'America/Boise',
    },
    {
        label: 'America/Cambridge Bay',
        value: 'America/Cambridge_Bay',
    },
    {
        label: 'America/Campo Grande',
        value: 'America/Campo_Grande',
    },
    {
        label: 'America/Cancun',
        value: 'America/Cancun',
    },
    {
        label: 'America/Caracas',
        value: 'America/Caracas',
    },
    {
        label: 'America/Cayenne',
        value: 'America/Cayenne',
    },
    {
        label: 'America/Cayman',
        value: 'America/Cayman',
    },
    {
        label: 'America/Chicago',
        value: 'America/Chicago',
    },
    {
        label: 'America/Chihuahua',
        value: 'America/Chihuahua',
    },
    {
        label: 'America/Costa Rica',
        value: 'America/Costa_Rica',
    },
    {
        label: 'America/Creston',
        value: 'America/Creston',
    },
    {
        label: 'America/Cuiaba',
        value: 'America/Cuiaba',
    },
    {
        label: 'America/Curacao',
        value: 'America/Curacao',
    },
    {
        label: 'America/Danmarkshavn',
        value: 'America/Danmarkshavn',
    },
    {
        label: 'America/Dawson',
        value: 'America/Dawson',
    },
    {
        label: 'America/Dawson Creek',
        value: 'America/Dawson_Creek',
    },
    {
        label: 'America/Denver',
        value: 'America/Denver',
    },
    {
        label: 'America/Detroit',
        value: 'America/Detroit',
    },
    {
        label: 'America/Dominica',
        value: 'America/Dominica',
    },
    {
        label: 'America/Edmonton',
        value: 'America/Edmonton',
    },
    {
        label: 'America/Eirunepe',
        value: 'America/Eirunepe',
    },
    {
        label: 'America/El Salvador',
        value: 'America/El_Salvador',
    },
    {
        label: 'America/Fort Nelson',
        value: 'America/Fort_Nelson',
    },
    {
        label: 'America/Fortaleza',
        value: 'America/Fortaleza',
    },
    {
        label: 'America/Glace Bay',
        value: 'America/Glace_Bay',
    },
    {
        label: 'America/Goose Bay',
        value: 'America/Goose_Bay',
    },
    {
        label: 'America/Grand Turk',
        value: 'America/Grand_Turk',
    },
    {
        label: 'America/Grenada',
        value: 'America/Grenada',
    },
    {
        label: 'America/Guadeloupe',
        value: 'America/Guadeloupe',
    },
    {
        label: 'America/Guatemala',
        value: 'America/Guatemala',
    },
    {
        label: 'America/Guayaquil',
        value: 'America/Guayaquil',
    },
    {
        label: 'America/Guyana',
        value: 'America/Guyana',
    },
    {
        label: 'America/Halifax',
        value: 'America/Halifax',
    },
    {
        label: 'America/Havana',
        value: 'America/Havana',
    },
    {
        label: 'America/Hermosillo',
        value: 'America/Hermosillo',
    },
    {
        label: 'America/Indiana/Indianapolis',
        value: 'America/Indiana/Indianapolis',
    },
    {
        label: 'America/Indiana/Knox',
        value: 'America/Indiana/Knox',
    },
    {
        label: 'America/Indiana/Marengo',
        value: 'America/Indiana/Marengo',
    },
    {
        label: 'America/Indiana/Petersburg',
        value: 'America/Indiana/Petersburg',
    },
    {
        label: 'America/Indiana/Tell City',
        value: 'America/Indiana/Tell_City',
    },
    {
        label: 'America/Indiana/Vevay',
        value: 'America/Indiana/Vevay',
    },
    {
        label: 'America/Indiana/Vincennes',
        value: 'America/Indiana/Vincennes',
    },
    {
        label: 'America/Indiana/Winamac',
        value: 'America/Indiana/Winamac',
    },
    {
        label: 'America/Inuvik',
        value: 'America/Inuvik',
    },
    {
        label: 'America/Iqaluit',
        value: 'America/Iqaluit',
    },
    {
        label: 'America/Jamaica',
        value: 'America/Jamaica',
    },
    {
        label: 'America/Juneau',
        value: 'America/Juneau',
    },
    {
        label: 'America/Kentucky/Louisville',
        value: 'America/Kentucky/Louisville',
    },
    {
        label: 'America/Kentucky/Monticello',
        value: 'America/Kentucky/Monticello',
    },
    {
        label: 'America/Kralendijk',
        value: 'America/Kralendijk',
    },
    {
        label: 'America/La Paz',
        value: 'America/La_Paz',
    },
    {
        label: 'America/Lima',
        value: 'America/Lima',
    },
    {
        label: 'America/Los Angeles',
        value: 'America/Los_Angeles',
    },
    {
        label: 'America/Lower Princes',
        value: 'America/Lower_Princes',
    },
    {
        label: 'America/Maceio',
        value: 'America/Maceio',
    },
    {
        label: 'America/Managua',
        value: 'America/Managua',
    },
    {
        label: 'America/Manaus',
        value: 'America/Manaus',
    },
    {
        label: 'America/Marigot',
        value: 'America/Marigot',
    },
    {
        label: 'America/Martinique',
        value: 'America/Martinique',
    },
    {
        label: 'America/Matamoros',
        value: 'America/Matamoros',
    },
    {
        label: 'America/Mazatlan',
        value: 'America/Mazatlan',
    },
    {
        label: 'America/Menominee',
        value: 'America/Menominee',
    },
    {
        label: 'America/Merida',
        value: 'America/Merida',
    },
    {
        label: 'America/Metlakatla',
        value: 'America/Metlakatla',
    },
    {
        label: 'America/Mexico City',
        value: 'America/Mexico_City',
    },
    {
        label: 'America/Miquelon',
        value: 'America/Miquelon',
    },
    {
        label: 'America/Moncton',
        value: 'America/Moncton',
    },
    {
        label: 'America/Monterrey',
        value: 'America/Monterrey',
    },
    {
        label: 'America/Montevideo',
        value: 'America/Montevideo',
    },
    {
        label: 'America/Montserrat',
        value: 'America/Montserrat',
    },
    {
        label: 'America/Nassau',
        value: 'America/Nassau',
    },
    {
        label: 'America/New York',
        value: 'America/New_York',
    },
    {
        label: 'America/Nipigon',
        value: 'America/Nipigon',
    },
    {
        label: 'America/Nome',
        value: 'America/Nome',
    },
    {
        label: 'America/Noronha',
        value: 'America/Noronha',
    },
    {
        label: 'America/North Dakota/Beulah',
        value: 'America/North_Dakota/Beulah',
    },
    {
        label: 'America/North Dakota/Center',
        value: 'America/North_Dakota/Center',
    },
    {
        label: 'America/North Dakota/New Salem',
        value: 'America/North_Dakota/New_Salem',
    },
    {
        label: 'America/Nuuk',
        value: 'America/Nuuk',
    },
    {
        label: 'America/Ojinaga',
        value: 'America/Ojinaga',
    },
    {
        label: 'America/Panama',
        value: 'America/Panama',
    },
    {
        label: 'America/Pangnirtung',
        value: 'America/Pangnirtung',
    },
    {
        label: 'America/Paramaribo',
        value: 'America/Paramaribo',
    },
    {
        label: 'America/Phoenix',
        value: 'America/Phoenix',
    },
    {
        label: 'America/Port-au-Prince',
        value: 'America/Port-au-Prince',
    },
    {
        label: 'America/Port of Spain',
        value: 'America/Port_of_Spain',
    },
    {
        label: 'America/Porto Velho',
        value: 'America/Porto_Velho',
    },
    {
        label: 'America/Puerto Rico',
        value: 'America/Puerto_Rico',
    },
    {
        label: 'America/Punta Arenas',
        value: 'America/Punta_Arenas',
    },
    {
        label: 'America/Rainy River',
        value: 'America/Rainy_River',
    },
    {
        label: 'America/Rankin Inlet',
        value: 'America/Rankin_Inlet',
    },
    {
        label: 'America/Recife',
        value: 'America/Recife',
    },
    {
        label: 'America/Regina',
        value: 'America/Regina',
    },
    {
        label: 'America/Resolute',
        value: 'America/Resolute',
    },
    {
        label: 'America/Rio Branco',
        value: 'America/Rio_Branco',
    },
    {
        label: 'America/Santarem',
        value: 'America/Santarem',
    },
    {
        label: 'America/Santiago',
        value: 'America/Santiago',
    },
    {
        label: 'America/Santo Domingo',
        value: 'America/Santo_Domingo',
    },
    {
        label: 'America/Sao Paulo',
        value: 'America/Sao_Paulo',
    },
    {
        label: 'America/Scoresbysund',
        value: 'America/Scoresbysund',
    },
    {
        label: 'America/Sitka',
        value: 'America/Sitka',
    },
    {
        label: 'America/St Barthelemy',
        value: 'America/St_Barthelemy',
    },
    {
        label: 'America/St Johns',
        value: 'America/St_Johns',
    },
    {
        label: 'America/St Kitts',
        value: 'America/St_Kitts',
    },
    {
        label: 'America/St Lucia',
        value: 'America/St_Lucia',
    },
    {
        label: 'America/St Thomas',
        value: 'America/St_Thomas',
    },
    {
        label: 'America/St Vincent',
        value: 'America/St_Vincent',
    },
    {
        label: 'America/Swift Current',
        value: 'America/Swift_Current',
    },
    {
        label: 'America/Tegucigalpa',
        value: 'America/Tegucigalpa',
    },
    {
        label: 'America/Thule',
        value: 'America/Thule',
    },
    {
        label: 'America/Thunder Bay',
        value: 'America/Thunder_Bay',
    },
    {
        label: 'America/Tijuana',
        value: 'America/Tijuana',
    },
    {
        label: 'America/Toronto',
        value: 'America/Toronto',
    },
    {
        label: 'America/Tortola',
        value: 'America/Tortola',
    },
    {
        label: 'America/Vancouver',
        value: 'America/Vancouver',
    },
    {
        label: 'America/Whitehorse',
        value: 'America/Whitehorse',
    },
    {
        label: 'America/Winnipeg',
        value: 'America/Winnipeg',
    },
    {
        label: 'America/Yakutat',
        value: 'America/Yakutat',
    },
    {
        label: 'America/Yellowknife',
        value: 'America/Yellowknife',
    },
    {
        label: 'Antarctica/Casey',
        value: 'Antarctica/Casey',
    },
    {
        label: 'Antarctica/Davis',
        value: 'Antarctica/Davis',
    },
    {
        label: 'Antarctica/DumontDUrville',
        value: 'Antarctica/DumontDUrville',
    },
    {
        label: 'Antarctica/Macquarie',
        value: 'Antarctica/Macquarie',
    },
    {
        label: 'Antarctica/Mawson',
        value: 'Antarctica/Mawson',
    },
    {
        label: 'Antarctica/McMurdo',
        value: 'Antarctica/McMurdo',
    },
    {
        label: 'Antarctica/Palmer',
        value: 'Antarctica/Palmer',
    },
    {
        label: 'Antarctica/Rothera',
        value: 'Antarctica/Rothera',
    },
    {
        label: 'Antarctica/Syowa',
        value: 'Antarctica/Syowa',
    },
    {
        label: 'Antarctica/Troll',
        value: 'Antarctica/Troll',
    },
    {
        label: 'Antarctica/Vostok',
        value: 'Antarctica/Vostok',
    },
    {
        label: 'Arctic/Longyearbyen',
        value: 'Arctic/Longyearbyen',
    },
    {
        label: 'Asia/Aden',
        value: 'Asia/Aden',
    },
    {
        label: 'Asia/Almaty',
        value: 'Asia/Almaty',
    },
    {
        label: 'Asia/Amman',
        value: 'Asia/Amman',
    },
    {
        label: 'Asia/Anadyr',
        value: 'Asia/Anadyr',
    },
    {
        label: 'Asia/Aqtau',
        value: 'Asia/Aqtau',
    },
    {
        label: 'Asia/Aqtobe',
        value: 'Asia/Aqtobe',
    },
    {
        label: 'Asia/Ashgabat',
        value: 'Asia/Ashgabat',
    },
    {
        label: 'Asia/Atyrau',
        value: 'Asia/Atyrau',
    },
    {
        label: 'Asia/Baghdad',
        value: 'Asia/Baghdad',
    },
    {
        label: 'Asia/Bahrain',
        value: 'Asia/Bahrain',
    },
    {
        label: 'Asia/Baku',
        value: 'Asia/Baku',
    },
    {
        label: 'Asia/Bangkok',
        value: 'Asia/Bangkok',
    },
    {
        label: 'Asia/Barnaul',
        value: 'Asia/Barnaul',
    },
    {
        label: 'Asia/Beirut',
        value: 'Asia/Beirut',
    },
    {
        label: 'Asia/Bishkek',
        value: 'Asia/Bishkek',
    },
    {
        label: 'Asia/Brunei',
        value: 'Asia/Brunei',
    },
    {
        label: 'Asia/Chita',
        value: 'Asia/Chita',
    },
    {
        label: 'Asia/Choibalsan',
        value: 'Asia/Choibalsan',
    },
    {
        label: 'Asia/Colombo',
        value: 'Asia/Colombo',
    },
    {
        label: 'Asia/Damascus',
        value: 'Asia/Damascus',
    },
    {
        label: 'Asia/Dhaka',
        value: 'Asia/Dhaka',
    },
    {
        label: 'Asia/Dili',
        value: 'Asia/Dili',
    },
    {
        label: 'Asia/Dubai',
        value: 'Asia/Dubai',
    },
    {
        label: 'Asia/Dushanbe',
        value: 'Asia/Dushanbe',
    },
    {
        label: 'Asia/Famagusta',
        value: 'Asia/Famagusta',
    },
    {
        label: 'Asia/Gaza',
        value: 'Asia/Gaza',
    },
    {
        label: 'Asia/Hebron',
        value: 'Asia/Hebron',
    },
    {
        label: 'Asia/Ho Chi Minh',
        value: 'Asia/Ho_Chi_Minh',
    },
    {
        label: 'Asia/Hong Kong',
        value: 'Asia/Hong_Kong',
    },
    {
        label: 'Asia/Hovd',
        value: 'Asia/Hovd',
    },
    {
        label: 'Asia/Irkutsk',
        value: 'Asia/Irkutsk',
    },
    {
        label: 'Asia/Jakarta',
        value: 'Asia/Jakarta',
    },
    {
        label: 'Asia/Jayapura',
        value: 'Asia/Jayapura',
    },
    {
        label: 'Asia/Jerusalem',
        value: 'Asia/Jerusalem',
    },
    {
        label: 'Asia/Kabul',
        value: 'Asia/Kabul',
    },
    {
        label: 'Asia/Kamchatka',
        value: 'Asia/Kamchatka',
    },
    {
        label: 'Asia/Karachi',
        value: 'Asia/Karachi',
    },
    {
        label: 'Asia/Kathmandu',
        value: 'Asia/Kathmandu',
    },
    {
        label: 'Asia/Khandyga',
        value: 'Asia/Khandyga',
    },
    {
        label: 'Asia/Kolkata',
        value: 'Asia/Kolkata',
    },
    {
        label: 'Asia/Krasnoyarsk',
        value: 'Asia/Krasnoyarsk',
    },
    {
        label: 'Asia/Kuala Lumpur',
        value: 'Asia/Kuala_Lumpur',
    },
    {
        label: 'Asia/Kuching',
        value: 'Asia/Kuching',
    },
    {
        label: 'Asia/Kuwait',
        value: 'Asia/Kuwait',
    },
    {
        label: 'Asia/Macau',
        value: 'Asia/Macau',
    },
    {
        label: 'Asia/Magadan',
        value: 'Asia/Magadan',
    },
    {
        label: 'Asia/Makassar',
        value: 'Asia/Makassar',
    },
    {
        label: 'Asia/Manila',
        value: 'Asia/Manila',
    },
    {
        label: 'Asia/Muscat',
        value: 'Asia/Muscat',
    },
    {
        label: 'Asia/Nicosia',
        value: 'Asia/Nicosia',
    },
    {
        label: 'Asia/Novokuznetsk',
        value: 'Asia/Novokuznetsk',
    },
    {
        label: 'Asia/Novosibirsk',
        value: 'Asia/Novosibirsk',
    },
    {
        label: 'Asia/Omsk',
        value: 'Asia/Omsk',
    },
    {
        label: 'Asia/Oral',
        value: 'Asia/Oral',
    },
    {
        label: 'Asia/Phnom Penh',
        value: 'Asia/Phnom_Penh',
    },
    {
        label: 'Asia/Pontianak',
        value: 'Asia/Pontianak',
    },
    {
        label: 'Asia/Pyongyang',
        value: 'Asia/Pyongyang',
    },
    {
        label: 'Asia/Qatar',
        value: 'Asia/Qatar',
    },
    {
        label: 'Asia/Qostanay',
        value: 'Asia/Qostanay',
    },
    {
        label: 'Asia/Qyzylorda',
        value: 'Asia/Qyzylorda',
    },
    {
        label: 'Asia/Riyadh',
        value: 'Asia/Riyadh',
    },
    {
        label: 'Asia/Sakhalin',
        value: 'Asia/Sakhalin',
    },
    {
        label: 'Asia/Samarkand',
        value: 'Asia/Samarkand',
    },
    {
        label: 'Asia/Seoul',
        value: 'Asia/Seoul',
    },
    {
        label: 'Asia/Shanghai',
        value: 'Asia/Shanghai',
    },
    {
        label: 'Asia/Singapore',
        value: 'Asia/Singapore',
    },
    {
        label: 'Asia/Srednekolymsk',
        value: 'Asia/Srednekolymsk',
    },
    {
        label: 'Asia/Taipei',
        value: 'Asia/Taipei',
    },
    {
        label: 'Asia/Tashkent',
        value: 'Asia/Tashkent',
    },
    {
        label: 'Asia/Tbilisi',
        value: 'Asia/Tbilisi',
    },
    {
        label: 'Asia/Tehran',
        value: 'Asia/Tehran',
    },
    {
        label: 'Asia/Thimphu',
        value: 'Asia/Thimphu',
    },
    {
        label: 'Asia/Tokyo',
        value: 'Asia/Tokyo',
    },
    {
        label: 'Asia/Tomsk',
        value: 'Asia/Tomsk',
    },
    {
        label: 'Asia/Ulaanbaatar',
        value: 'Asia/Ulaanbaatar',
    },
    {
        label: 'Asia/Urumqi',
        value: 'Asia/Urumqi',
    },
    {
        label: 'Asia/Ust-Nera',
        value: 'Asia/Ust-Nera',
    },
    {
        label: 'Asia/Vientiane',
        value: 'Asia/Vientiane',
    },
    {
        label: 'Asia/Vladivostok',
        value: 'Asia/Vladivostok',
    },
    {
        label: 'Asia/Yakutsk',
        value: 'Asia/Yakutsk',
    },
    {
        label: 'Asia/Yangon',
        value: 'Asia/Yangon',
    },
    {
        label: 'Asia/Yekaterinburg',
        value: 'Asia/Yekaterinburg',
    },
    {
        label: 'Asia/Yerevan',
        value: 'Asia/Yerevan',
    },
    {
        label: 'Atlantic/Azores',
        value: 'Atlantic/Azores',
    },
    {
        label: 'Atlantic/Bermuda',
        value: 'Atlantic/Bermuda',
    },
    {
        label: 'Atlantic/Canary',
        value: 'Atlantic/Canary',
    },
    {
        label: 'Atlantic/Cape Verde',
        value: 'Atlantic/Cape_Verde',
    },
    {
        label: 'Atlantic/Faroe',
        value: 'Atlantic/Faroe',
    },
    {
        label: 'Atlantic/Madeira',
        value: 'Atlantic/Madeira',
    },
    {
        label: 'Atlantic/Reykjavik',
        value: 'Atlantic/Reykjavik',
    },
    {
        label: 'Atlantic/South Georgia',
        value: 'Atlantic/South_Georgia',
    },
    {
        label: 'Atlantic/St Helena',
        value: 'Atlantic/St_Helena',
    },
    {
        label: 'Atlantic/Stanley',
        value: 'Atlantic/Stanley',
    },
    {
        label: 'Australia/Adelaide',
        value: 'Australia/Adelaide',
    },
    {
        label: 'Australia/Brisbane',
        value: 'Australia/Brisbane',
    },
    {
        label: 'Australia/Broken Hill',
        value: 'Australia/Broken_Hill',
    },
    {
        label: 'Australia/Currie',
        value: 'Australia/Currie',
    },
    {
        label: 'Australia/Darwin',
        value: 'Australia/Darwin',
    },
    {
        label: 'Australia/Eucla',
        value: 'Australia/Eucla',
    },
    {
        label: 'Australia/Hobart',
        value: 'Australia/Hobart',
    },
    {
        label: 'Australia/Lindeman',
        value: 'Australia/Lindeman',
    },
    {
        label: 'Australia/Lord Howe',
        value: 'Australia/Lord_Howe',
    },
    {
        label: 'Australia/Melbourne',
        value: 'Australia/Melbourne',
    },
    {
        label: 'Australia/Perth',
        value: 'Australia/Perth',
    },
    {
        label: 'Australia/Sydney',
        value: 'Australia/Sydney',
    },
    {
        label: 'Europe/Amsterdam',
        value: 'Europe/Amsterdam',
    },
    {
        label: 'Europe/Andorra',
        value: 'Europe/Andorra',
    },
    {
        label: 'Europe/Astrakhan',
        value: 'Europe/Astrakhan',
    },
    {
        label: 'Europe/Athens',
        value: 'Europe/Athens',
    },
    {
        label: 'Europe/Belgrade',
        value: 'Europe/Belgrade',
    },
    {
        label: 'Europe/Berlin',
        value: 'Europe/Berlin',
    },
    {
        label: 'Europe/Bratislava',
        value: 'Europe/Bratislava',
    },
    {
        label: 'Europe/Brussels',
        value: 'Europe/Brussels',
    },
    {
        label: 'Europe/Bucharest',
        value: 'Europe/Bucharest',
    },
    {
        label: 'Europe/Budapest',
        value: 'Europe/Budapest',
    },
    {
        label: 'Europe/Busingen',
        value: 'Europe/Busingen',
    },
    {
        label: 'Europe/Chisinau',
        value: 'Europe/Chisinau',
    },
    {
        label: 'Europe/Copenhagen',
        value: 'Europe/Copenhagen',
    },
    {
        label: 'Europe/Dublin',
        value: 'Europe/Dublin',
    },
    {
        label: 'Europe/Gibraltar',
        value: 'Europe/Gibraltar',
    },
    {
        label: 'Europe/Guernsey',
        value: 'Europe/Guernsey',
    },
    {
        label: 'Europe/Helsinki',
        value: 'Europe/Helsinki',
    },
    {
        label: 'Europe/Isle of Man',
        value: 'Europe/Isle_of_Man',
    },
    {
        label: 'Europe/Istanbul',
        value: 'Europe/Istanbul',
    },
    {
        label: 'Europe/Jersey',
        value: 'Europe/Jersey',
    },
    {
        label: 'Europe/Kaliningrad',
        value: 'Europe/Kaliningrad',
    },
    {
        label: 'Europe/Kirov',
        value: 'Europe/Kirov',
    },
    {
        label: 'Europe/Kyiv',
        value: 'Europe/Kyiv',
    },
    {
        label: 'Europe/Lisbon',
        value: 'Europe/Lisbon',
    },
    {
        label: 'Europe/Ljubljana',
        value: 'Europe/Ljubljana',
    },
    {
        label: 'Europe/London',
        value: 'Europe/London',
    },
    {
        label: 'Europe/Luxembourg',
        value: 'Europe/Luxembourg',
    },
    {
        label: 'Europe/Madrid',
        value: 'Europe/Madrid',
    },
    {
        label: 'Europe/Malta',
        value: 'Europe/Malta',
    },
    {
        label: 'Europe/Mariehamn',
        value: 'Europe/Mariehamn',
    },
    {
        label: 'Europe/Minsk',
        value: 'Europe/Minsk',
    },
    {
        label: 'Europe/Monaco',
        value: 'Europe/Monaco',
    },
    {
        label: 'Europe/Moscow',
        value: 'Europe/Moscow',
    },
    {
        label: 'Europe/Oslo',
        value: 'Europe/Oslo',
    },
    {
        label: 'Europe/Paris',
        value: 'Europe/Paris',
    },
    {
        label: 'Europe/Podgorica',
        value: 'Europe/Podgorica',
    },
    {
        label: 'Europe/Prague',
        value: 'Europe/Prague',
    },
    {
        label: 'Europe/Riga',
        value: 'Europe/Riga',
    },
    {
        label: 'Europe/Rome',
        value: 'Europe/Rome',
    },
    {
        label: 'Europe/Samara',
        value: 'Europe/Samara',
    },
    {
        label: 'Europe/San Marino',
        value: 'Europe/San_Marino',
    },
    {
        label: 'Europe/Sarajevo',
        value: 'Europe/Sarajevo',
    },
    {
        label: 'Europe/Saratov',
        value: 'Europe/Saratov',
    },
    {
        label: 'Europe/Simferopol',
        value: 'Europe/Simferopol',
    },
    {
        label: 'Europe/Skopje',
        value: 'Europe/Skopje',
    },
    {
        label: 'Europe/Sofia',
        value: 'Europe/Sofia',
    },
    {
        label: 'Europe/Stockholm',
        value: 'Europe/Stockholm',
    },
    {
        label: 'Europe/Tallinn',
        value: 'Europe/Tallinn',
    },
    {
        label: 'Europe/Tirane',
        value: 'Europe/Tirane',
    },
    {
        label: 'Europe/Ulyanovsk',
        value: 'Europe/Ulyanovsk',
    },
    {
        label: 'Europe/Uzhgorod',
        value: 'Europe/Uzhgorod',
    },
    {
        label: 'Europe/Vaduz',
        value: 'Europe/Vaduz',
    },
    {
        label: 'Europe/Vatican',
        value: 'Europe/Vatican',
    },
    {
        label: 'Europe/Vienna',
        value: 'Europe/Vienna',
    },
    {
        label: 'Europe/Vilnius',
        value: 'Europe/Vilnius',
    },
    {
        label: 'Europe/Volgograd',
        value: 'Europe/Volgograd',
    },
    {
        label: 'Europe/Warsaw',
        value: 'Europe/Warsaw',
    },
    {
        label: 'Europe/Zagreb',
        value: 'Europe/Zagreb',
    },
    {
        label: 'Europe/Zaporozhye',
        value: 'Europe/Zaporozhye',
    },
    {
        label: 'Europe/Zurich',
        value: 'Europe/Zurich',
    },
    {
        label: 'Indian/Antananarivo',
        value: 'Indian/Antananarivo',
    },
    {
        label: 'Indian/Chagos',
        value: 'Indian/Chagos',
    },
    {
        label: 'Indian/Christmas',
        value: 'Indian/Christmas',
    },
    {
        label: 'Indian/Cocos',
        value: 'Indian/Cocos',
    },
    {
        label: 'Indian/Comoro',
        value: 'Indian/Comoro',
    },
    {
        label: 'Indian/Kerguelen',
        value: 'Indian/Kerguelen',
    },
    {
        label: 'Indian/Mahe',
        value: 'Indian/Mahe',
    },
    {
        label: 'Indian/Maldives',
        value: 'Indian/Maldives',
    },
    {
        label: 'Indian/Mauritius',
        value: 'Indian/Mauritius',
    },
    {
        label: 'Indian/Mayotte',
        value: 'Indian/Mayotte',
    },
    {
        label: 'Indian/Reunion',
        value: 'Indian/Reunion',
    },
    {
        label: 'Pacific/Apia',
        value: 'Pacific/Apia',
    },
    {
        label: 'Pacific/Auckland',
        value: 'Pacific/Auckland',
    },
    {
        label: 'Pacific/Bougainville',
        value: 'Pacific/Bougainville',
    },
    {
        label: 'Pacific/Chatham',
        value: 'Pacific/Chatham',
    },
    {
        label: 'Pacific/Chuuk',
        value: 'Pacific/Chuuk',
    },
    {
        label: 'Pacific/Easter',
        value: 'Pacific/Easter',
    },
    {
        label: 'Pacific/Efate',
        value: 'Pacific/Efate',
    },
    {
        label: 'Pacific/Enderbury',
        value: 'Pacific/Enderbury',
    },
    {
        label: 'Pacific/Fakaofo',
        value: 'Pacific/Fakaofo',
    },
    {
        label: 'Pacific/Fiji',
        value: 'Pacific/Fiji',
    },
    {
        label: 'Pacific/Funafuti',
        value: 'Pacific/Funafuti',
    },
    {
        label: 'Pacific/Galapagos',
        value: 'Pacific/Galapagos',
    },
    {
        label: 'Pacific/Gambier',
        value: 'Pacific/Gambier',
    },
    {
        label: 'Pacific/Guadalcanal',
        value: 'Pacific/Guadalcanal',
    },
    {
        label: 'Pacific/Guam',
        value: 'Pacific/Guam',
    },
    {
        label: 'Pacific/Honolulu',
        value: 'Pacific/Honolulu',
    },
    {
        label: 'Pacific/Kiritimati',
        value: 'Pacific/Kiritimati',
    },
    {
        label: 'Pacific/Kosrae',
        value: 'Pacific/Kosrae',
    },
    {
        label: 'Pacific/Kwajalein',
        value: 'Pacific/Kwajalein',
    },
    {
        label: 'Pacific/Majuro',
        value: 'Pacific/Majuro',
    },
    {
        label: 'Pacific/Marquesas',
        value: 'Pacific/Marquesas',
    },
    {
        label: 'Pacific/Midway',
        value: 'Pacific/Midway',
    },
    {
        label: 'Pacific/Nauru',
        value: 'Pacific/Nauru',
    },
    {
        label: 'Pacific/Niue',
        value: 'Pacific/Niue',
    },
    {
        label: 'Pacific/Norfolk',
        value: 'Pacific/Norfolk',
    },
    {
        label: 'Pacific/Noumea',
        value: 'Pacific/Noumea',
    },
    {
        label: 'Pacific/Pago Pago',
        value: 'Pacific/Pago_Pago',
    },
    {
        label: 'Pacific/Palau',
        value: 'Pacific/Palau',
    },
    {
        label: 'Pacific/Pitcairn',
        value: 'Pacific/Pitcairn',
    },
    {
        label: 'Pacific/Pohnpei',
        value: 'Pacific/Pohnpei',
    },
    {
        label: 'Pacific/Port Moresby',
        value: 'Pacific/Port_Moresby',
    },
    {
        label: 'Pacific/Rarotonga',
        value: 'Pacific/Rarotonga',
    },
    {
        label: 'Pacific/Saipan',
        value: 'Pacific/Saipan',
    },
    {
        label: 'Pacific/Tahiti',
        value: 'Pacific/Tahiti',
    },
    {
        label: 'Pacific/Tarawa',
        value: 'Pacific/Tarawa',
    },
    {
        label: 'Pacific/Tongatapu',
        value: 'Pacific/Tongatapu',
    },
    {
        label: 'Pacific/Wake',
        value: 'Pacific/Wake',
    },
    {
        label: 'Pacific/Wallis',
        value: 'Pacific/Wallis',
    },
    {
        label: 'UTC',
        value: 'UTC',
    },
];

export function controlledUnsubscription(fn) {
    try {
        return fn?.unsubscribe?.();
    } catch (error) {
        console.warn(error);
    }
}
export const industryFields = [
    { value: 47, groups: 'corp, fin', label: 'Accounting' },
    { value: 94, groups: 'man, tech, tran', label: 'Airlines/Aviation' },
    { value: 120, groups: 'leg, org', label: 'Alternative Dispute Resolution' },
    { value: 125, groups: 'hlth', label: 'Alternative Medicine' },
    { value: 127, groups: 'art, med', label: 'Animation' },
    { value: 19, groups: 'good', label: 'Apparel & Fashion' },
    { value: 50, groups: 'cons', label: 'Architecture & Planning' },
    { value: 111, groups: 'art, med, rec', label: 'Arts and Crafts' },
    { value: 53, groups: 'man', label: 'Automotive' },
    { value: 52, groups: 'gov, man', label: 'Aviation & Aerospace' },
    { value: 41, groups: 'fin', label: 'Banking' },
    { value: 12, groups: 'gov, hlth, tech', label: 'Biotechnology' },
    { value: 36, groups: 'med, rec', label: 'Broadcast Media' },
    { value: 49, groups: 'cons', label: 'Building Materials' },
    { value: 138, groups: 'corp, man', label: 'Business Supplies and Equipment' },
    { value: 129, groups: 'fin', label: 'Capital Markets' },
    { value: 54, groups: 'man', label: 'Chemicals' },
    { value: 90, groups: 'org, serv', label: 'Civic & Social Organization' },
    { value: 51, groups: 'cons, gov', label: 'Civil Engineering' },
    { value: 128, groups: 'cons, corp, fin', label: 'Commercial Real Estate' },
    { value: 118, groups: 'tech', label: 'Computer & Network Security' },
    { value: 109, groups: 'med, rec', label: 'Computer Games' },
    { value: 3, groups: 'tech', label: 'Computer Hardware' },
    { value: 5, groups: 'tech', label: 'Computer Networking' },
    { value: 4, groups: 'tech', label: 'Computer Software' },
    { value: 48, groups: 'cons', label: 'Construction' },
    { value: 24, groups: 'good, man', label: 'Consumer Electronics' },
    { value: 25, groups: 'good, man', label: 'Consumer Goods' },
    { value: 91, groups: 'org, serv', label: 'Consumer Services' },
    { value: 18, groups: 'good', label: 'Cosmetics' },
    { value: 65, groups: 'agr', label: 'Dairy' },
    { value: 1, groups: 'gov, tech', label: 'Defense & Space' },
    { value: 99, groups: 'art, med', label: 'Design' },
    { value: 69, groups: 'edu', label: 'Education Management' },
    { value: 132, groups: 'edu, org', label: 'E-Learning' },
    { value: 112, groups: 'good, man', label: 'Electrical/Electronic Manufacturing' },
    { value: 28, groups: 'med, rec', label: 'Entertainment' },
    { value: 86, groups: 'org, serv', label: 'Environmental Services' },
    { value: 110, groups: 'corp, rec, serv', label: 'Events Services' },
    { value: 76, groups: 'gov', label: 'Executive Office' },
    { value: 122, groups: 'corp, serv', label: 'Facilities Services' },
    { value: 63, groups: 'agr', label: 'Farming' },
    { value: 43, groups: 'fin', label: 'Financial Services' },
    { value: 38, groups: 'art, med, rec', label: 'Fine Art' },
    { value: 66, groups: 'agr', label: 'Fishery' },
    { value: 34, groups: 'rec, serv', label: 'Food & Beverages' },
    { value: 23, groups: 'good, man, serv', label: 'Food Production' },
    { value: 101, groups: 'org', label: 'Fund-Raising' },
    { value: 26, groups: 'good, man', label: 'Furniture' },
    { value: 29, groups: 'rec', label: 'Gambling & Casinos' },
    { value: 145, groups: 'cons, man', label: 'Glass, Ceramics & Concrete' },
    { value: 75, groups: 'gov', label: 'Government Administration' },
    { value: 148, groups: 'gov', label: 'Government Relations' },
    { value: 140, groups: 'art, med', label: 'Graphic Design' },
    { value: 124, groups: 'hlth, rec', label: 'Health, Wellness and Fitness' },
    { value: 68, groups: 'edu', label: 'Higher Education' },
    { value: 14, groups: 'hlth', label: 'Hospital & Health Care' },
    { value: 31, groups: 'rec, serv, tran', label: 'Hospitality' },
    { value: 137, groups: 'corp', label: 'Human Resources' },
    { value: 134, groups: 'corp, good, tran', label: 'Import and Export' },
    { value: 88, groups: 'org, serv', label: 'Individual & Family Services' },
    { value: 147, groups: 'cons, man', label: 'Industrial Automation' },
    { value: 84, groups: 'med, serv', label: 'Information Services' },
    { value: 96, groups: 'tech', label: 'Information Technology and Services' },
    { value: 42, groups: 'fin', label: 'Insurance' },
    { value: 74, groups: 'gov', label: 'International Affairs' },
    { value: 141, groups: 'gov, org, tran', label: 'International Trade and Development' },
    { value: 6, groups: 'tech', label: 'Internet' },
    { value: 45, groups: 'fin', label: 'Investment Banking' },
    { value: 46, groups: 'fin', label: 'Investment Management' },
    { value: 73, groups: 'gov, leg', label: 'Judiciary' },
    { value: 77, groups: 'gov, leg', label: 'Law Enforcement' },
    { value: 9, groups: 'leg', label: 'Law Practice' },
    { value: 10, groups: 'leg', label: 'Legal Services' },
    { value: 72, groups: 'gov, leg', label: 'Legislative Office' },
    { value: 30, groups: 'rec, serv, tran', label: 'Leisure, Travel & Tourism' },
    { value: 85, groups: 'med, rec, serv', label: 'Libraries' },
    { value: 116, groups: 'corp, tran', label: 'Logistics and Supply Chain' },
    { value: 143, groups: 'good', label: 'Luxury Goods & Jewelry' },
    { value: 55, groups: 'man', label: 'Machinery' },
    { value: 11, groups: 'corp', label: 'Management Consulting' },
    { value: 95, groups: 'tran', label: 'Maritime' },
    { value: 97, groups: 'corp', label: 'Market Research' },
    { value: 80, groups: 'corp, med', label: 'Marketing and Advertising' },
    { value: 135, groups: 'cons, gov, man', label: 'Mechanical or Industrial Engineering' },
    { value: 126, groups: 'med, rec', label: 'Media Production' },
    { value: 17, groups: 'hlth', label: 'Medical Devices' },
    { value: 13, groups: 'hlth', label: 'Medical Practice' },
    { value: 139, groups: 'hlth', label: 'Mental Health Care' },
    { value: 71, groups: 'gov', label: 'Military' },
    { value: 56, groups: 'man', label: 'Mining & Metals' },
    { value: 35, groups: 'art, med, rec', label: 'Motion Pictures and Film' },
    { value: 37, groups: 'art, med, rec', label: 'Museums and Institutions' },
    { value: 115, groups: 'art, rec', label: 'Music' },
    { value: 114, groups: 'gov, man, tech', label: 'Nanotechnology' },
    { value: 81, groups: 'med, rec', label: 'Newspapers' },
    { value: 100, groups: 'org', label: 'Non-Profit Organization Management' },
    { value: 57, groups: 'man', label: 'Oil & Energy' },
    { value: 113, groups: 'med', label: 'Online Media' },
    { value: 123, groups: 'corp', label: 'Outsourcing/Offshoring' },
    { value: 87, groups: 'serv, tran', label: 'Package/Freight Delivery' },
    { value: 146, groups: 'good, man', label: 'Packaging and Containers' },
    { value: 61, groups: 'man', label: 'Paper & Forest Products' },
    { value: 39, groups: 'art, med, rec', label: 'Performing Arts' },
    { value: 15, groups: 'hlth, tech', label: 'Pharmaceuticals' },
    { value: 131, groups: 'org', label: 'Philanthropy' },
    { value: 136, groups: 'art, med, rec', label: 'Photography' },
    { value: 117, groups: 'man', label: 'Plastics' },
    { value: 107, groups: 'gov, org', label: 'Political Organization' },
    { value: 67, groups: 'edu', label: 'Primary/Secondary Education' },
    { value: 83, groups: 'med, rec', label: 'Printing' },
    { value: 105, groups: 'corp', label: 'Professional Training & Coaching' },
    { value: 102, groups: 'corp, org', label: 'Program Development' },
    { value: 79, groups: 'gov', label: 'Public Policy' },
    { value: 98, groups: 'corp', label: 'Public Relations and Communications' },
    { value: 78, groups: 'gov', label: 'Public Safety' },
    { value: 82, groups: 'med, rec', label: 'Publishing' },
    { value: 62, groups: 'man', label: 'Railroad Manufacture' },
    { value: 64, groups: 'agr', label: 'Ranching' },
    { value: 44, groups: 'cons, fin, good', label: 'Real Estate' },
    { value: 40, groups: 'rec, serv', label: 'Recreational Facilities and Services' },
    { value: 89, groups: 'org, serv', label: 'Religious Institutions' },
    { value: 144, groups: 'gov, man, org', label: 'Renewables & Environment' },
    { value: 70, groups: 'edu, gov', label: 'Research' },
    { value: 32, groups: 'rec, serv', label: 'Restaurants' },
    { value: 27, groups: 'good, man', label: 'Retail' },
    { value: 121, groups: 'corp, org, serv', label: 'Security and Investigations' },
    { value: 7, groups: 'tech', label: 'Semiconductors' },
    { value: 58, groups: 'man', label: 'Shipbuilding' },
    { value: 20, groups: 'good, rec', label: 'Sporting Goods' },
    { value: 33, groups: 'rec', label: 'Sports' },
    { value: 104, groups: 'corp', label: 'Staffing and Recruiting' },
    { value: 22, groups: 'good', label: 'Supermarkets' },
    { value: 8, groups: 'gov, tech', label: 'Telecommunications' },
    { value: 60, groups: 'man', label: 'Textiles' },
    { value: 130, groups: 'gov, org', label: 'Think Tanks' },
    { value: 21, groups: 'good', label: 'Tobacco' },
    { value: 108, groups: 'corp, gov, serv', label: 'Translation and Localization' },
    { value: 92, groups: 'tran', label: 'Transportation/Trucking/Railroad' },
    { value: 59, groups: 'man', label: 'Utilities' },
    { value: 106, groups: 'fin, tech', label: 'Venture Capital & Private Equity' },
    { value: 16, groups: 'hlth', label: 'Veterinary' },
    { value: 93, groups: 'tran', label: 'Warehousing' },
    { value: 133, groups: 'good', label: 'Wholesale' },
    { value: 142, groups: 'good, man, rec', label: 'Wine and Spirits' },
    { value: 119, groups: 'tech', label: 'Wireless' },
    { value: 103, groups: 'art, med, rec', label: 'Writing and Editing' },
];
export const sortByOptions = [
    { label: 'Date (Oldest First)', value: ['created_at', 'asc'] },
    { label: 'Date (Newest First)', value: ['created_at', 'desc'] },
    { label: 'Name (A → Z)', value: ['name', 'asc'] },
    { label: 'Name (Z → A)', value: ['name', 'desc'] },
];

export const companySizeOptions = [
    { label: '1-10', value: '1-10' },
    { label: '11-50', value: '11-50' },
    { label: '51-200', value: '51-200' },
    { label: '201-500', value: '201-500' },
    { label: '501+', value: '501+' },
];

export const numberOfUsers = [
    { label: '1-10', value: '1-10' },
    { label: '11-50', value: '11-50' },
    { label: '51-100', value: '51-100' },
    { label: '101-250', value: '101-250' },
    { label: '251-500', value: '251-500' },
];

export const subscriptionAmountOptions = [
    { label: '$0-$100', value: '0,100' },
    { label: '$101-$500', value: '101,500' },
    { label: '$501-$1,000', value: '501,1000' },
    { label: '$1,001-$5,000', value: '1001,5000' },
    { label: '$5,001-$10,000', value: '5001,10000' },
];

export const recurTypeOptions = [
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Yearly', value: 'yearly' },
    { label: 'Custom...', value: 'custom' },
];
export function getRecurTypeOption(recurType) {
    return recurTypeOptions.find(option => option.value === recurType) || recurTypeOptions[0];
}

export const customTypeOptions = [
    { label: 'Day', value: 'days' },
    { label: 'Week', value: 'weeks' },
    { label: 'Month', value: 'months' },
    { label: 'Year', value: 'years' },
];

export function getCustomTypeOption(intervalType) {
    return customTypeOptions.find(option => option.value === intervalType) || customTypeOptions[0];
}

export const weekDays = [
    { label: 'Sunday', value: 'sunday' },
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
    { label: 'Saturday', value: 'saturday' },
];

export const monthDaysList = [
    { label: '1st', value: '1' },
    { label: '2nd', value: '2' },
    { label: '3rd', value: '3' },
    { label: '4th', value: '4' },
    { label: '5th', value: '5' },
    { label: '6th', value: '6' },
    { label: '7th', value: '7' },
    { label: '8th', value: '8' },
    { label: '9th', value: '9' },
    { label: '10th', value: '10' },
    { label: '11th', value: '11' },
    { label: '12th', value: '12' },
    { label: '13th', value: '13' },
    { label: '14th', value: '14' },
    { label: '15th', value: '15' },
    { label: '16th', value: '16' },
    { label: '17th', value: '17' },
    { label: '18th', value: '18' },
    { label: '19th', value: '19' },
    { label: '20th', value: '20' },
    { label: '21st', value: '21' },
    { label: '22nd', value: '22' },
    { label: '23rd', value: '23' },
    { label: '24th', value: '24' },
    { label: '25th', value: '25' },
    { label: '26th', value: '26' },
    { label: '27th', value: '27' },
    { label: '28th', value: '28' },
    { label: '29th', value: '29' },
    { label: '30th', value: '30' },
    { label: '31st', value: '31' },
];

export const monthsAndDaysList = [
    {
        label: 'January',
        value: 'january',
        options: [...monthDaysList],
    },
    {
        label: 'February',
        value: 'february',
        options: [...monthDaysList],
    },
    {
        label: 'March',
        value: 'march',
        options: [...monthDaysList],
    },
    {
        label: 'April',
        value: 'april',
        options: [...monthDaysList],
    },
    {
        label: 'May',
        value: 'may',
        options: [...monthDaysList],
    },
    {
        label: 'June',
        value: 'june',
        options: [...monthDaysList],
    },
    {
        label: 'July',
        value: 'july',
        options: [...monthDaysList],
    },
    {
        label: 'August',
        value: 'august',
        options: [...monthDaysList],
    },
    {
        label: 'September',
        value: 'september',
        options: [...monthDaysList],
    },
    {
        label: 'October',
        value: 'october',
        options: [...monthDaysList],
    },
    {
        label: 'November',
        value: 'november',
        options: [...monthDaysList],
    },
    {
        label: 'December',
        value: 'december',
        options: [...monthDaysList],
    },
];

export const roleFields = [
    {
        label: 'Foresters',
        value: 'foresters',
    },
    {
        label: 'Biochemist',
        value: 'biochemist',
    },
    {
        label: 'Food Scientist',
        value: 'food scientist',
    },
    {
        label: 'Animal Geneticist',
        value: 'animal geneticist',
    },
    {
        label: 'Farmer',
        value: 'farmer',
    },
    {
        label: 'Wildlife Biologist',
        value: 'wildlife biologist',
    },
    {
        label: 'Horticulturist',
        value: 'horticulturist',
    },
    {
        label: 'Plant Nursery Attendant',
        value: 'plant nursery attendant',
    },
    {
        label: 'Agriculture Teacher',
        value: 'agriculture teacher',
    },
    {
        label: 'Plant Biologist',
        value: 'plant biologist',
    },
    {
        label: 'Wildlife Inspector',
        value: 'wildlife inspector',
    },
    {
        label: 'Soil And Plant Scientist',
        value: 'soil and plant scientist',
    },
    {
        label: 'Beekeeper',
        value: 'beekeeper',
    },
    {
        label: 'Aquatic Ecologist',
        value: 'aquatic ecologist',
    },
    {
        label: 'Conservationist',
        value: 'conservationist',
    },
    {
        label: 'Veterinarian',
        value: 'veterinarian',
    },
    {
        label: 'Zoologist',
        value: 'zoologist',
    },
    {
        label: 'Groomer',
        value: 'groomer',
    },
    {
        label: 'Animal Control Officer',
        value: 'animal control officer',
    },
    {
        label: 'Kennel Attendant',
        value: 'kennel attendant',
    },
    {
        label: 'Pet Walker',
        value: 'pet walker',
    },
    {
        label: 'Animal Sitter',
        value: 'animal sitter',
    },
    {
        label: 'Animal Shelter Manager',
        value: 'animal shelter manager',
    },
    {
        label: 'Veterinary Assistant',
        value: 'veterinary assistant',
    },
    {
        label: 'Animal Nutritionist',
        value: 'animal nutritionist',
    },
    {
        label: 'Biologist',
        value: 'biologist',
    },
    {
        label: 'Wildlife Rehabilitator',
        value: 'wildlife rehabilitator',
    },
    {
        label: 'Breeder',
        value: 'breeder',
    },
    {
        label: 'Veterinary Pathologist',
        value: 'veterinary pathologist',
    },
    {
        label: 'Veterinary Ophthalmologist',
        value: 'veterinary ophthalmologist',
    },
    {
        label: 'Project Manager',
        value: 'project manager',
    },
    {
        label: 'Actuary',
        value: 'actuary',
    },
    {
        label: 'Business Teacher',
        value: 'business teacher',
    },
    {
        label: 'Business Reporter',
        value: 'business reporter',
    },
    {
        label: 'Admissions Representative',
        value: 'admissions representative',
    },
    {
        label: 'Office Manager',
        value: 'office manager',
    },
    {
        label: 'Office Clerk',
        value: 'office clerk',
    },
    {
        label: 'Assistant Buyer',
        value: 'assistant buyer',
    },
    {
        label: 'Business Development Manager',
        value: 'business development manager',
    },
    {
        label: 'Salon Manager',
        value: 'salon manager',
    },
    {
        label: 'Makeup Artist',
        value: 'makeup artist',
    },
    {
        label: 'Nail Technician',
        value: 'nail technician',
    },
    {
        label: 'Message Therapist',
        value: 'message therapist',
    },
    {
        label: 'Barber',
        value: 'barber',
    },
    {
        label: 'Beautician',
        value: 'beautician',
    },
    {
        label: 'Skin Care Specialist',
        value: 'skin care specialist',
    },
    {
        label: 'Fashion Designer',
        value: 'fashion designer',
    },
    {
        label: 'Esthetician',
        value: 'esthetician',
    },
    {
        label: 'Electrologist',
        value: 'electrologist',
    },
    {
        label: 'Cosmetology Instructor',
        value: 'cosmetology instructor',
    },
    {
        label: 'Hairdresser',
        value: 'hairdresser',
    },
    {
        label: 'Fashion Show Stylist',
        value: 'fashion show stylist',
    },
    {
        label: 'Spa Manager',
        value: 'spa manager',
    },
    {
        label: 'Wedding Stylist',
        value: 'wedding stylist',
    },
    {
        label: 'Call Center Agent',
        value: 'call center agent',
    },
    {
        label: 'Client Services Coordinator',
        value: 'client services coordinator',
    },
    {
        label: 'Technical Support Representative',
        value: 'technical support representative',
    },
    {
        label: 'Virtual Assistant',
        value: 'virtual assistant',
    },
    {
        label: 'Customer Care Associate',
        value: 'customer care associate',
    },
    {
        label: 'Retail Sales Associate',
        value: 'retail sales associate',
    },
    {
        label: 'Cashier',
        value: 'cashier',
    },
    {
        label: 'Concierge',
        value: 'concierge',
    },
    {
        label: 'Customer Service Manager',
        value: 'customer service manager',
    },
    {
        label: 'Help Desk Assistant',
        value: 'help desk assistant',
    },
    {
        label: 'Account Coordinator',
        value: 'account coordinator',
    },
    {
        label: 'Service Adviser',
        value: 'service adviser',
    },
    {
        label: 'Bank Teller',
        value: 'bank teller',
    },
    {
        label: 'Front Desk Coordinator',
        value: 'front desk coordinator',
    },
    {
        label: 'Client Services Manager',
        value: 'client services manager',
    },
    {
        label: 'Art Director',
        value: 'art director',
    },
    {
        label: 'Graphic Designer',
        value: 'graphic designer',
    },
    {
        label: 'Writer',
        value: 'writer',
    },
    {
        label: 'Editor',
        value: 'editor',
    },
    {
        label: 'Illustrator',
        value: 'illustrator',
    },
    {
        label: 'Public Relations Specialist',
        value: 'public relations specialist',
    },
    {
        label: 'Actor',
        value: 'actor',
    },
    {
        label: 'Singer',
        value: 'singer',
    },
    {
        label: 'Producer',
        value: 'producer',
    },
    {
        label: 'Web Developer',
        value: 'web developer',
    },
    {
        label: 'Architect',
        value: 'architect',
    },
    {
        label: 'Multimedia Animator',
        value: 'multimedia animator',
    },
    {
        label: 'Painter',
        value: 'painter',
    },
    {
        label: 'Tattoo Artist',
        value: 'tattoo artist',
    },
    {
        label: 'Dancer',
        value: 'dancer',
    },
    {
        label: 'English Teacher',
        value: 'english teacher',
    },
    {
        label: 'College Professor',
        value: 'college professor',
    },
    {
        label: 'Tutor',
        value: 'tutor',
    },
    {
        label: 'Test Scorer',
        value: 'test scorer',
    },
    {
        label: 'Test Administrator',
        value: 'test administrator',
    },
    {
        label: 'Assistant Professor',
        value: 'assistant professor',
    },
    {
        label: 'Principal',
        value: 'principal',
    },
    {
        label: 'Superintendent',
        value: 'superintendent',
    },
    {
        label: 'Vice Principal',
        value: 'vice principal',
    },
    {
        label: 'Substitute Teacher',
        value: 'substitute teacher',
    },
    {
        label: 'Librarian',
        value: 'librarian',
    },
    {
        label: 'Math Teacher',
        value: 'math teacher',
    },
    {
        label: 'Science Teacher',
        value: 'science teacher',
    },
    {
        label: 'Instructional Designer',
        value: 'instructional designer',
    },
    {
        label: 'Guidance Counselor',
        value: 'guidance counselor',
    },
    {
        label: 'Civil Engineer',
        value: 'civil engineer',
    },
    {
        label: 'Mechanical Engineer',
        value: 'mechanical engineer',
    },
    {
        label: 'Chemical Engineer',
        value: 'chemical engineer',
    },
    {
        label: 'Biological Engineer',
        value: 'biological engineer',
    },
    {
        label: 'Nuclear Engineer',
        value: 'nuclear engineer',
    },
    {
        label: 'Aerospace Engineer',
        value: 'aerospace engineer',
    },
    {
        label: 'Electrical Engineer',
        value: 'electrical engineer',
    },
    {
        label: 'Environmental Engineer',
        value: 'environmental Engineer',
    },
    {
        label: 'Geological Engineer',
        value: 'geological engineer',
    },
    {
        label: 'Marine Engineer',
        value: 'marine engineer',
    },
    {
        label: 'Petroleum Engineer',
        value: 'petroleum engineer',
    },
    {
        label: 'Safety Engineer',
        value: 'safety engineer',
    },
    {
        label: 'Product Engineer',
        value: 'product engineer',
    },
    {
        label: 'Compliance Engineer',
        value: 'compliance engineer',
    },
    {
        label: 'Senior process Engineer',
        value: 'senior process engineer',
    },
    {
        label: 'Financial Planner',
        value: 'financial planner',
    },
    {
        label: 'Financial Adviser',
        value: 'financial adviser',
    },
    {
        label: 'Budget Analyst',
        value: 'budget analyst',
    },
    {
        label: 'Credit Analyst',
        value: 'credit analyst',
    },
    {
        label: 'Financial Services Representative',
        value: 'financial services representative',
    },
    {
        label: 'Financial Manager',
        value: 'financial manager',
    },
    {
        label: 'Cost Estimator',
        value: 'cost estimator',
    },
    {
        label: 'Loan Officer',
        value: 'loan officer',
    },
    {
        label: 'Investment Banking Analyst',
        value: 'investment banking analyst',
    },
    {
        label: 'Financial Auditor',
        value: 'financial auditor',
    },
    {
        label: 'Nurse',
        value: 'nurse',
    },
    {
        label: 'Doctor',
        value: 'doctor',
    },
    {
        label: 'Caregiver',
        value: 'caregiver',
    },
    {
        label: 'Physical Therapist',
        value: 'physical therapist',
    },
    {
        label: 'Personal Trainer',
        value: 'personal trainer',
    },
    {
        label: 'Pharmacist',
        value: 'pharmacist',
    },
    {
        label: 'Pharmacy Assistant',
        value: 'pharmacy assistant',
    },
    {
        label: 'Dental Hygienist',
        value: 'dental hygienist',
    },
    {
        label: 'Orthodontist',
        value: 'orthodontist',
    },
    {
        label: 'Travel Nurse',
        value: 'travel nurse',
    },
    {
        label: 'Dental Assistant',
        value: 'dental assistant',
    },
    {
        label: 'Occupational Therapy Aide',
        value: 'occupational therapy aide',
    },
    {
        label: 'Dentist',
        value: 'dentist',
    },
    {
        label: 'Surgical Technologist',
        value: 'surgical technologist',
    },
    {
        label: 'Dietitian',
        value: 'dietitian',
    },
    {
        label: 'Travel Agent',
        value: 'travel agent',
    },
    {
        label: 'Housekeeper',
        value: 'housekeeper',
    },
    {
        label: 'Porter',
        value: 'porter',
    },
    {
        label: 'Casino Host',
        value: 'casino host',
    },
    {
        label: 'Hotel Clerk',
        value: 'hotel clerk',
    },
    {
        label: 'Meeting Planner',
        value: 'meeting planner',
    },
    {
        label: 'Executive Chef',
        value: 'executive chef',
    },
    {
        label: 'Tour Guide',
        value: 'tour guide',
    },
    {
        label: 'Flight Attendant',
        value: 'flight attendant',
    },
    {
        label: 'Event Manager',
        value: 'event manager',
    },
    {
        label: 'Human Resources Manager',
        value: 'human resources manager',
    },
    {
        label: 'Compensations And Benefits Manager',
        value: 'compensations and benefits manager',
    },
    {
        label: 'Administrative Assistant',
        value: 'administrative assistant',
    },
    {
        label: 'Human resources Generalist',
        value: 'human resources generalist',
    },
    {
        label: 'Talent acquisition Coordinator',
        value: 'talent acquisition coordinator',
    },
    {
        label: 'Executive Recruiter',
        value: 'executive recruiter',
    },
    {
        label: 'Human Resources Specialist',
        value: 'human resources specialist',
    },
    {
        label: 'Human Resources Director',
        value: 'human resources director',
    },
    {
        label: 'Human Resources Assistant',
        value: 'human resources assistant',
    },
    {
        label: 'Labor Relations Specialist',
        value: 'labor relations specialist',
    },
    {
        label: 'Human Resources Consultant',
        value: 'human resources consultant',
    },
    {
        label: 'International Human Resources Associate',
        value: 'international human resources associate',
    },
    {
        label: 'Human Resources Systems Administrator',
        value: 'human resources systems administrator',
    },
    {
        label: 'Compensation Advisor',
        value: 'compensation advisor',
    },
    {
        label: 'Training And Development Manager',
        value: 'training and development manager',
    },
    {
        label: 'UX Designer',
        value: 'ux designer',
    },
    {
        label: 'UI Developer',
        value: 'ui developer',
    },
    {
        label: 'IT Manager',
        value: 'it manager',
    },
    {
        label: 'Computer Programmer',
        value: 'computer programmer',
    },
    {
        label: 'SQL Developer',
        value: 'sql developer',
    },
    {
        label: 'Software Developer',
        value: 'software developer',
    },
    {
        label: 'Web Administrator',
        value: 'web administrator',
    },
    {
        label: 'Data Architect',
        value: 'data architect',
    },
    {
        label: 'Business Intelligence Developer',
        value: 'business intelligence developer',
    },
    {
        label: 'Mobile Application Developer',
        value: 'mobile application developer',
    },
    {
        label: 'Information Security Analyst',
        value: 'information security analyst',
    },
    {
        label: 'Front-end Web Developer',
        value: 'front-end web developer',
    },
    {
        label: 'Java Developer',
        value: 'java developer',
    },
    {
        label: 'Database Manager',
        value: 'database manager',
    },
    {
        label: 'Software Engineer',
        value: 'software engineer',
    },
    {
        label: 'Chief Of Operations',
        value: 'chief of operations',
    },
    {
        label: 'Team Leader',
        value: 'team leader',
    },
    {
        label: 'Manager',
        value: 'manager',
    },
    {
        label: 'Executive',
        value: 'executive',
    },
    {
        label: 'Director',
        value: 'director',
    },
    {
        label: 'Supervisor',
        value: 'supervisor',
    },
    {
        label: 'President',
        value: 'president',
    },
    {
        label: 'Vice President',
        value: 'vice president',
    },
    {
        label: 'Team Lead',
        value: 'team lead',
    },
    {
        label: 'Marketing Coordinator',
        value: 'marketing coordinator',
    },
    {
        label: 'Marketing Manager',
        value: 'marketing manager',
    },
    {
        label: 'Marketing Assistant',
        value: 'marketing assistant',
    },
    {
        label: 'Marketing Director',
        value: 'marketing director',
    },
    {
        label: 'Product Marketing Manager',
        value: 'product marketing manager',
    },
    {
        label: 'Creative Director',
        value: 'creative director',
    },
    {
        label: 'Demand Generation Director',
        value: 'demand generation director',
    },
    {
        label: 'Social Media Manager',
        value: 'social media manager',
    },
    {
        label: 'Account Manager',
        value: 'account manager',
    },
    {
        label: 'Brand Manager',
        value: 'brand manager',
    },
    {
        label: 'Content Marketing Manager',
        value: 'content marketing manager',
    },
    {
        label: 'Marketing Analyst',
        value: 'marketing analyst',
    },
    {
        label: 'Marketing Consultant',
        value: 'marketing consultant',
    },
    {
        label: 'Social media Coordinator',
        value: 'social media coordinator',
    },
    {
        label: 'Search Engine Optimization Specialist',
        value: 'search engine optimization specialist',
    },
    {
        label: 'Chief Operations Officer',
        value: 'chief operations officer',
    },
    {
        label: 'Logistics Coordinator',
        value: 'logistics coordinator',
    },
    {
        label: 'Warehouse Supervisor',
        value: 'warehouse supervisor',
    },
    {
        label: 'Supply Chain Specialist',
        value: 'supply chain specialist',
    },
    {
        label: 'Distribution Supervisor',
        value: 'distribution supervisor',
    },
    {
        label: 'Supply Chain Coordinator',
        value: 'supply chain coordinator',
    },
    {
        label: 'Operations Assistant',
        value: 'operations assistant',
    },
    {
        label: 'Scrum Master',
        value: 'scrum master',
    },
    {
        label: 'Continuous Improvement Lead',
        value: 'continuous improvement lead',
    },
    {
        label: 'Operations Manager',
        value: 'operations manager',
    },
    {
        label: 'Sales Representative',
        value: 'sales representative',
    },
    {
        label: 'Sales Director',
        value: 'sales director',
    },
    {
        label: 'Sales Manager',
        value: 'sales manager',
    },
    {
        label: 'Insurance Sales Agent',
        value: 'insurance sales agent',
    },
    {
        label: 'Telemarketer',
        value: 'telemarketer',
    },
    {
        label: 'Retail Salesperson',
        value: 'retail salesperson',
    },
    {
        label: 'Store Manager',
        value: 'store manager',
    },
    {
        label: 'Account Executive',
        value: 'account executive',
    },
    {
        label: 'B2B Sales Specialist',
        value: 'b2b sales specialist',
    },
    {
        label: 'Sales Analyst',
        value: 'sales analyst',
    },
    {
        label: 'Real Estate Broker',
        value: 'real estate broker',
    },
    {
        label: 'Sales Associate',
        value: 'sales associate',
    },
    {
        label: 'Sales Consultant',
        value: 'sales consultant',
    },
    {
        label: 'Collection Agent',
        value: 'collection agent',
    },
    {
        label: 'Regional Sales Manager',
        value: 'regional sales manager',
    },
];

export function isValidURL(url) {
    const validUrlPattern = /(https?:\/\/www\.|https?:\/\/|ftp:\/\/www\.|ftp:\/\/)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(:[0-9]+)?(\/.*)?|(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(:[0-9]+)?(\/.*)?|(https?:\/\/www\.|https?:\/\/|http:\/\/www\.|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?(:[0-9]+)?(\/.*)?/g;

    try {
	  return validUrlPattern.test(url);
    } catch (e) {
	  return false;
    }
}

export function removeValueIfNullOrEmpty(list) {
    if (!Array.isArray(list)) {
        return [];
    }

    return list.map((item) => {
        if (item && item.hasOwnProperty('value') && (item.value === null || item.value === '')) {
            const { value, ...newItem } = item;

            return newItem;
        }

        return item;
    });
}
