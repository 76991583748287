import { PermifyProvider, usePermify, HasAccess } from "@permify/react-role";
import * as React from "react";

export const usePermissionContext = usePermify;
export const PermissionProvider = PermifyProvider;

export enum CoreRoles {
    SUPER_ADMIN = "Superadmin",
    OWNER = "Owner",
    ADMIN = "Admin",
    EMPLOYEE = "Employee",
}

export enum Permissions {
    VIEW_ANY_COMPONENT_BLUEPRINT = "viewAny-componentBlueprint",
    VIEW_COMPONENT_BLUEPRINT = "view-componentBlueprint",
    CREATE_COMPONENT_BLUEPRINT = "create-componentBlueprint",
    UPDATE_COMPONENT_BLUEPRINT = "update-componentBlueprint",
    DELETE_COMPONENT_BLUEPRINT = "delete-componentBlueprint",
    VIEW_ANY_COMPONENT = "viewAny-component",
    VIEW_COMPONENT = "view-component",
    CREATE_COMPONENT = "create-component",
    UPDATE_COMPONENT = "update-component",
    DELETE_COMPONENT = "delete-component",
    VIEW_ANY_PROCEDURE = "viewAny-procedure",
    VIEW_PROCEDURE = "view-procedure",
    CREATE_PROCEDURE = "create-procedure",
    UPDATE_PROCEDURE = "update-procedure",
    DELETE_PROCEDURE = "delete-procedure",
    VIEW_ANY_TASK = "viewAny-task",
    VIEW_TASK = "view-task",
    CREATE_TASK = "create-task",
    UPDATE_TASK = "update-task",
    DELETE_TASK = "delete-task",
    VIEW_ANY_STATUS = "viewAny-status",
    VIEW_STATUS = "view-status",
    CREATE_STATUS = "create-status",
    UPDATE_STATUS = "update-status",
    DELETE_STATUS = "delete-status",
    VIEW_ANY_DEPARTMENT = "viewAny-department",
    VIEW_DEPARTMENT = "view-department",
    CREATE_DEPARTMENT = "create-department",
    UPDATE_DEPARTMENT = "update-department",
    DELETE_DEPARTMENT = "delete-department",
    VIEW_ANY_POSITION = "viewAny-position",
    VIEW_POSITION = "view-position",
    CREATE_POSITION = "create-position",
    UPDATE_POSITION = "update-position",
    DELETE_POSITION = "delete-position",
    VIEW_ANY_COMPANY = "viewAny-company",
    VIEW_COMPANY = "view-company",
    CREATE_COMPANY = "create-company",
    UPDATE_COMPANY = "update-company",
    DELETE_COMPANY = "delete-company",
    VIEW_ANY_USER = "viewAny-user",
    VIEW_USER = "view-user",
    CREATE_USER = "create-user",
    UPDATE_USER = "update-user",
    DELETE_USER = "delete-user",
    VIEW_ANY_INVITATION = "viewAny-invitation",
    VIEW_INVITATION = "view-invitation",
    CREATE_INVITATION = "create-invitation",
    UPDATE_INVITATION = "update-invitation",
    DELETE_INVITATION = "delete-invitation",
    VIEW_ANY_BILLING = "viewAny-billing",
    VIEW_BILLING = "view-billing",
    CREATE_BILLING = "create-billing",
    UPDATE_BILLING = "update-billing",
    DELETE_BILLING = "delete-billing",
    VIEW_ANY_STRATEGIC_AREA = "viewAny-strategicArea",
    VIEW_STRATEGIC_AREA = "view-strategicArea",
    CREATE_STRATEGIC_AREA = "create-strategicArea",
    UPDATE_STRATEGIC_AREA = "update-strategicArea",
    DELETE_STRATEGIC_AREA = "delete-strategicArea",
    VIEW_ANY_DOCUMENT = "viewAny-document",
    VIEW_DOCUMENT = "view-document",
    CREATE_DOCUMENT = "create-document",
    UPDATE_DOCUMENT = "update-document",
    DELETE_DOCUMENT = "delete-document",
    VIEW_ANY_QUESTION = "viewAny-question",
    VIEW_QUESTION = "view-question",
    CREATE_QUESTION = "create-question",
    UPDATE_QUESTION = "update-question",
    DELETE_QUESTION = "delete-question",
    VIEW_ANY_LEGAL = "viewAny-legal",
    VIEW_LEGAL = "view-legal",
    CREATE_LEGAL = "create-legal",
    UPDATE_LEGAL = "update-legal",
    DELETE_LEGAL = "delete-legal",
    VIEW_ANY_VIDEO = "viewAny-video",
    VIEW_VIDEO = "view-video",
    CREATE_VIDEO = "create-video",
    UPDATE_VIDEO = "update-video",
    DELETE_VIDEO = "delete-video",
    INVITE_USERS = "invite-users",

    VIEW_FLOWS = "viewAny-component",
}

export type PermissionWrapperProps = {
    permissions?: typeof Permissions[] & string[];
    ifCannot?: React.ReactElement;
    roles?: typeof CoreRoles[] & string[] | string[];
    children: React.ReactChild;
};

export const Can = (props: PermissionWrapperProps) => {
    const { roles, permissions, ifCannot, children } = props;

    return (<HasAccess roles={roles} permissions={permissions} renderAuthFailed={ifCannot || <></>} isLoading={<></>}>{children}</HasAccess>);
};


