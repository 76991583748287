import useOnclickOutside from "react-cool-onclickoutside";
import classNames from "classnames";

const UnfocusableWrapper = (props) => {
    const {onClickOutside, className, children, ignoreClass = true, ...rest} = props;
    const ref = useOnclickOutside(
        () => {
            if(onClickOutside){
                onClickOutside();
            }
        },
        {
            ignoreClass: "my-ignore-class",
        }
    );

    // when has multiple `UnfocusableWrapper` on one page `my-ignore-class` is needed so that it doesn't trigger `onClickOutside` when clicked on other `UnfocusableWrapper` component
    // ignoreClass=false means `my-ignore-class` is not needed
    return (
        <div ref={ref} className={classNames(className, { "my-ignore-class": ignoreClass })} {...rest} tag={'UnfocusableWrapper'}>
            {children}
        </div>
    );
};

export default UnfocusableWrapper;
