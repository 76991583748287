import moment from 'moment';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import { sessionService } from 'redux-react-session';
import thunk from 'redux-thunk';
import { appReducer, reducersPersistConfig } from './reducers';
const logger = createLogger({
    // ...options
    predicate: (getState, action) => ['SET_AUTHENTICATED_USER'].indexOf(action.type) > -1,
    collapsed: true, // (getState, action) => action.type != 'SET_INVITE_COLLEAGUES_LIST',

    //  predicate, // if specified this function will be called before each action is processed with this middleware.
    //  collapsed, // takes a Boolean or optionally a Function that receives `getState` function for accessing current store state and `action` object as parameters. Returns `true` if the log group should be collapsed, `false` otherwise.
    duration: false, // Boolean, // print the duration of each action?
    timestamp: true, // Boolean, // print the timestamp with each action?

    //level = log : ('log' | 'console' | 'warn' | 'error' | 'info'), // console's level
    // colors: ColorsObject, // colors for title, prev state, action and next state: https://github.com/LogRocket/redux-logger/blob/master/src/defaults.js#L12-L18
    //titleFormatter, // Format the title used when logging actions.
    // stateTransformer: (state) => {
    //     let newState = {};

    //     for (var i of Object.keys(state)) {
    //       if (Immutable.Iterable.isIterable(state[i])) {
    //         newState[i] = state[i].toJS();
    //       } else {
    //         newState[i] = state[i];
    //       }
    //     };

    //     return newState;
    //   },
    //stateTransformer, // Transform state before print. Eg. convert Immutable object to plain JSON.
    //actionTransformer, // Transform action before print. Eg. convert Immutable object to plain JSON.
    //errorTransformer, // Transform error before print. Eg. convert Immutable object to plain JSON.

    //logger = console: LoggerObject, // implementation of the `console` API.
    logErrors: true, // Boolean, // should the logger catch, log, and re-throw errors?

    diff: false, //: Boolean, // (alpha) show diff between states?
    // diffPredicate // (alpha) filter function for showing states diff, similar to `predicate`
});

const pReducer = persistReducer(reducersPersistConfig, appReducer);
const middleware = composeWithDevTools(applyMiddleware(thunk, logger));
const store = createStore(pReducer, middleware);
const persistor = persistStore(store);

const validateSession = (session) => {
    return !!(session?.expirationDate && session?.last_access_token && moment(session.expirationDate, 'YYYY-MM-DD hh:mm:ss:ssss')?.valueOf() > moment().valueOf());
};
const options = {
    refreshOnCheckAuth: true,
    redirectPath: store.getState().flowReducer?.onBoardingCompleted ? (store.getState().authReducer?.lastKnownRoute?.length > 0 ? store.getState().authReducer?.lastKnownRoute : '/') : '/',
    driver: 'COOKIES',
    validateSession,
};

sessionService
    .initSessionService(store, options)
    .then(() => {
        sessionService
            .loadSession(store.getState().sessionReducer)
            .then((_currentSession) => {
                const redirect = store.getState().authReducer?.lastKnownRoute;

                if (redirect?.length > 0) {
                    /*   */
                }
            })
            .catch(err => console.log(err));
        sessionService.refreshFromLocalStorage();
    })
    .catch(() => console.log('Redux React Session is ready and there is no session in your storage'));

export { persistor, store };

export type StoreType = {
	authReducer?: typeof store.authReducer;
	modalsReducer?: typeof store.modalsReducer;
	flowReducer?: typeof store.flowReducer;
	sessionReducer?: typeof store.sessionReducer;
};
