import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { isSafari } from 'react-device-detect';
import Loader from 'react-loader-spinner';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Form from 'react-validation/build/form';
import { Calendar, processesIcon, taskIcon } from '../../../../assets/images/icons';
import { addNewTask, setProcessTask } from '../../../../reduxStore/actions/flowActions';
import { createTask, deleteTask, editTask, startTask } from '../../../../services/tasks.service';
import {CustomCheckbox, MultiSelectWithSearchNew} from '../../../common';
import Button from '../../../common/UI/Button/Button';
import cls from './TaskForm.module.scss';
import RecurTask from '../RecurTask/RecurTask';
import MessageContainer from '../../ProcessesComps/MessageContainer/MessageContainer';
import { deleteIcon, saveIcon, rightArrow, ClockGrey } from '../../../../assets/images/new-icons';
import { store } from '../../../../reduxStore/store';
import {
    isHTMLContentEmpty,
    customTypeOptions,
    recurTypeOptions,
    getTaskRecurRepeatDays,
    getCustomTypeOption,
    getRecurTypeOption, getTaskRecurRepeatDaysForCustom, recurTaskTime,
} from '../../../../shared/utility';
import { DeleteModal, RenderTitle, SelectWithSearchInMenuList } from '../../../common';
import TextEditor from '../../../common/TextEditor/TextEditor';
import StyledInput from '../../../common/UI/StyledInput/StyledInput';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import SelectWithSearch from "../../../common/UI/Select/SelectWithSearch";
import {getAllCompanyPositionsAsArray, getCompanyDepartments} from "../../../../services/company.service";
import {getAllUsers} from "../../../../services/user.service";

function TaskForm(props) {
    const authenticatedUser = store.getState().authReducer.authenticatedUser;

    const navigate = useNavigate();
    const {
        selectedProcess,
        isProcess, setIsProcessBegin,
        companyUsersArray, companyPositionsArray, companyDepartments, companyProcesses,
        task, isEdit, assigneeId, setDisplayModal, userId, selectedProcedure,setunsavedChanges,
    } = props;
    const dispatch = useDispatch();
    const [selectedAssignee, setSelectedAssignee] = useState(task?.assignee?.id ? { assignee_id: task?.assignee?.id } : isProcess ? { assignee_id: authenticatedUser.profile_id } : '');
    const [selectedAssigneeList, setSelectedAssigneeList] = useState([]);
    const [taskProcess, setTaskProcess] = useState(task?.process?.id ? { process: task?.process?.id } : '');
    const [taskName, setTaskName] = useState(task?.name || '');
    const [taskDescription, setTaskDescription] = useState(task?.description || '');
    const [dueDate, setDueDate] = useState(task?.due_date ? new Date(task?.due_date) : new Date());
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingStart, setLoadingStart] = useState(false);
    const [loadingRemove, setLoadingRemove] = useState(false);
    const [toDeleteTask, setToDeleteTask] = useState(false);
    const [InitialState,setInitialState] = useState(task ? {...task, isInitial: false} : {});
    const [unsavedCheckBypass , setUnsavedCheckBypass] = useState(false);
    const assigneeTypes = [
        {label: "Users", value: "users"},
        {label: "Positions", value: "positions"},
        {label: "Departments",value: "departments"},
    ];

    const [assigneeType , setAssigneeType] = useState(assigneeTypes[0]);
    const [assigneeList, setAssigneeList] = useState([]);


    const compareTaskState = ()=>{
        if (
            isEdit && !unsavedCheckBypass && (
                InitialState?.assignee?.id !== selectedAssignee.assignee_id ||
            InitialState?.name !== taskName ||
            (InitialState?.description ?? '') !== (taskDescription ?? '') ||
            (InitialState?.due_date ? new Date(InitialState?.due_date) : new Date()).getTime() !== dueDate.getTime())
        ) {
            isEdit && setunsavedChanges(true);
        }
        else
            isEdit && setunsavedChanges(false);
    };


    const [savePressed, setSavePressed] = useState(false);

    const [enableRecurring, setEnableRecurring] = useState(false);
    const [allowColleagueChangeDueDate, setAllowColleagueChangeDueDate] = useState(!! task?.colleague_can_change_due_date);
    const [taskRecurringDetails, setTaskRecurringDetails] = useState({
        skipWeekends: false,
        recurForever: false,
        recurType: recurTypeOptions[0],
        taskRepeatNumber: 1,
        daysOfWeektoRecur: null,
        dateOfMonthtoRecur: null,
        monthToRecur: null,
        endsAt: null,
        customRecurOptions: {
            every: 1,
            period: customTypeOptions[0],
            value: null,
        },
    });

    useEffect(() => {
        let list = [];

        switch (assigneeType.value) {
            case "positions":
                list = companyPositionsArray?.length > 0
                    ? companyPositionsArray
                        .filter(elem => elem.value !== '0')
                    : [];
                break;
            case "departments":
                list = companyDepartments?.length > 0?companyDepartments: [];
                break;
            case "users":
            default:
                list = companyUsersArray?.length > 0
                    ? companyUsersArray.map(elem => ({
                        label: `${elem.first_name} ${elem.last_name}`,
                        value: elem.profile_id,
                    }))
                    : [];
                break;
        }
        setAssigneeList(list);
    }, [assigneeType, companyUsersArray, companyPositionsArray, companyDepartments]);

    const processesList =
		companyProcesses?.length > 0
		    ? companyProcesses.map((elem) => {
		        return { label: elem.name, value: elem.id };
			  })
		    : [];

    const retrieveDepartmentsFromServer = () => {
        setAssigneeType(assigneeTypes[0]);
        getAllUsers().subscribe();
        getCompanyDepartments().subscribe();
        getAllCompanyPositionsAsArray().subscribe();
    };

    useEffect(() => {
        retrieveDepartmentsFromServer();
        setTaskProcess(c => ({
            ...c,
            process: selectedProcedure?.id,
            procedure_id: selectedProcedure?.id,
        }));
    }, []);

    useEffect(() => {
        if (isProcess && selectedProcess?.id) {
            const setInitials = () => {
                setTaskProcess({ procedure_id: selectedProcess.id });
                setTaskDescription(selectedProcess.description);
            };

            setInitials();
        }
    }, [isProcess, selectedProcess.description, selectedProcess.id]);

    const beginProcess = () => {
        store.dispatch(setProcessTask(task, false));
        navigate('/begin-process/'+task.id);
    };

    const userExistsInAssigneeList = () => {
        return selectedAssigneeList.some(assignee => assignee.value === authenticatedUser.profile_id);
    };

    const getRecurTaskPayload = () => {
        return {
            type: taskRecurringDetails.recurType.value,
            ...(['daily', 'weekly'].includes(taskRecurringDetails.recurType.value) && {
                days_of_week_to_recur: taskRecurringDetails.daysOfWeektoRecur,
            }),
            ...(['monthly', 'yearly'].includes(taskRecurringDetails.recurType.value) && {
                date_of_month_to_recur: taskRecurringDetails.dateOfMonthtoRecur,
            }),
            ...(taskRecurringDetails.recurType.value === 'yearly' && {
                month_to_recur: taskRecurringDetails.monthToRecur?.toLowerCase(),
            }),
            ends_at: taskRecurringDetails.recurForever ? null : taskRecurringDetails.endsAt,
            ...(taskRecurringDetails.recurType.value === 'custom' && {
                interval: taskRecurringDetails.customRecurOptions.every,
                interval_type: taskRecurringDetails.customRecurOptions.period.value,
                ...(taskRecurringDetails.customRecurOptions.period.label === 'Day' && {
                    days_of_week_to_recur: taskRecurringDetails.daysOfWeektoRecur,
                }),
            }),
        };
    };

    const addTask = (toStart) => {
        setSavePressed(true);
        if (toStart) {
            setLoadingStart(true);
        } else setLoading(true);


        createTask({
            assignee_ids: selectedAssigneeList.map(item => item.value),
            assignee_type: assigneeType.value,
            procedure_id: taskProcess.procedure_id,
            name: taskName,
            description: isHTMLContentEmpty(taskDescription) ? '' : taskDescription,
            // Necessary to convert to UTC because dueDate is local timezone but server returned due_date in utc.
            due_date: moment(dueDate).utc().format('YYYY-MM-DD HH:mm:ss'),
            colleague_can_change_due_date: allowColleagueChangeDueDate,
            is_recur: enableRecurring,
            ...(enableRecurring ? getRecurTaskPayload() : {}),
        }).subscribe({
            next: (data) => {
                data.assignee.id === authenticatedUser.profile_id && dispatch(addNewTask(data));
                setSelectedAssignee('');
                setTaskProcess('');
                setTaskName('');
                setTaskDescription('');
                setDueDate(new Date());
                if (isProcess) {
                    navigate('/begin-process/' + data.id);
                    setIsProcessBegin(true);
                }
                if (toStart) {
                    //Necessary to call begin-process inside when task is started because it cause glitch why task not started
                    startTask(data.id).subscribe({
                        complete: () => {
                            navigate('/begin-process/' + data.id);
                            setLoadingStart(false);
                            setDisplayModal(false);
                        },
                    });
                }
            },
            error: (error) => {
                if (error) {
                    setMessage(error.data);
                    if (toStart) {
                        setLoadingStart(false);
                    } else setLoading(false);
                }
            },
            complete: () => {
                if (!toStart) setLoading(false);

                !isProcess && !toStart && setDisplayModal(false);
            },
        });
    };

    const taskEdit = () => {
        setLoading(true);
        let assignee_id = selectedAssignee ? selectedAssignee.assignee_id : assigneeId;

        editTask(
            {
                assignee_id,
                name: taskName,
                // Necessary to convert to UTC because dueDate is local timezone but server returned due_date in utc.
                due_date: moment(dueDate).utc().format('YYYY-MM-DD HH:mm:ss'),
                description: isHTMLContentEmpty(taskDescription) ? '' : taskDescription,
                is_recur: enableRecurring,
                colleague_can_change_due_date: allowColleagueChangeDueDate,
                ...(enableRecurring ? getRecurTaskPayload() : {}),
            },
            props?.task?.id
        ).subscribe({
            next: () => {
                setLoading(false);
                setDisplayModal(false,true);//second paramtere is to bypass state comparison check
            },
            error: (error) => {
                if (error) {
                    setMessage(error.data);
                    setLoading(false);
                }
            },
        });
    };
    const removeTask = (taskId) => {
        setLoadingRemove(true);
        deleteTask(taskId).subscribe({
            next: () => {
                setLoadingRemove(false);
                setToDeleteTask(false);
            },
            error: () => {
                setLoadingRemove(false);
            },
            complete: () => {
                setDisplayModal(false,true);
            },
        });
    };


    const innerRef = useOnClickOutside(
        ()=>{
            compareTaskState();
        }
    );

    useEffect(() => {
        if (task?.recur_config) {
            const { days_of_week_to_recur, date_of_month_to_recur, monthToRecur, ends_at, interval, interval_type, type } = task?.recur_config || {};

            const tempSkipWeekends = !days_of_week_to_recur?.some(day => ['saturday', 'sunday'].includes(day.toLowerCase()));
            const repeat = getTaskRecurRepeatDays(task?.due_date, ends_at, interval ?? 1, type === "custom" ? interval_type : recurTaskTime[type], tempSkipWeekends);
            let skipWeekends = false;

            //it's better if we ask BE to store skipWeekends value as below logic might not be reliable.
            if ((type === "daily" || interval_type === "days") && days_of_week_to_recur && (!ends_at || repeat > 5 || (repeat * interval > 5))) {
                skipWeekends = tempSkipWeekends;
            }

            setTaskRecurringDetails({
                skipWeekends: skipWeekends,
                recurForever: ends_at ? false : true,
                recurType: getRecurTypeOption(type),
                taskRepeatNumber: repeat,
                daysOfWeektoRecur: days_of_week_to_recur,
                dateOfMonthtoRecur: date_of_month_to_recur,
                monthToRecur: monthToRecur,
                endsAt: ends_at,
                customRecurOptions: {
                    every: interval ?? 1,
                    period: getCustomTypeOption(interval_type),
                    value: null,
                },
            });
        }

        setEnableRecurring(task?.is_recur);
    }, [task]);

    const isDateFieldsDisabled = isEdit && !allowColleagueChangeDueDate && authenticatedUser.id !== task?.creator?.id;

    return (
        <div className={cls.newTaskFormHost} ref={innerRef}>
            <Form style={{flex: 1}}>
                <div style={{marginBottom: '12px'}}>
                    <StyledInput
                        tabIndex={1}
                        setMinWidth
                        label={'Task Name'}
                        placeholder="Type here..."
                        value={taskName}
                        onFocus={() => setMessage('')}
                        onChange={e => setTaskName(e.target.value)}
                        error={message?.errors?.name}
                        hasError={message?.errors?.name}
                        tooltipText={'The name of this specific task. (E.g. a standard process might be called “Issuing An Invoice” and a task might be called “Issuing December Invoice For Microsoft”)'}
                        mandatory
                    />
                </div>
                <div style={{marginBottom: "12px"}}>
                    <RenderTitle
                        setMinWidth
                        title={'Description'}
                        tooltipText={'Anything that will help the Assignee have the ' + 'context they need to do great work on this specific task. (E.g. Remember to add a 10% ' + 'discount to this month’s invoice.)'}
                        required={false}
                        titleClassName={message?.errors?.name ? cls.titleClassNameError : cls.titleClassName}
                    />
                    <TextEditor
                        tabIndex={2}
                        uniqueIdentifier="taskForm_description" data={taskDescription}
                        editorInputClassName={cls.descriptionInput}
                        onChangeCallback={(data) => {
                            if (!InitialState.isInitial) {
                                setInitialState((prev) => {
                                    return {...prev, description: data, isInitial: true};
                                });
                            }
                            setTaskDescription(data);
                        }}
                        savePressed={savePressed} setSavePressed={setSavePressed}/>

                    {message && message?.errors?.description &&
                        <MessageContainer message={message.errors.description}/>}
                </div>
                {!isEdit &&
                <div className={cls.assigneeCreatorContainer} style={{marginBottom: '12px'}}>
                    <div style={{flex: 1}}>
                        <RenderTitle
                            title={'Filter Assignee By'}
                            tooltipText={'Base on selection assignee list will be filtered by departments, users, positions.'}
                            required
                            titleClassName={message?.errors?.Assignee ? cls.titleClassNameError : cls.titleClassName}
                        />
                        <SelectWithSearch
                            key={'AssigneeType'}
                            items={assigneeTypes}
                            defaultSelectedItem={assigneeType}
                            molclassname={cls.menuOverlayList}
                            moliclassname={cls.menuOverlayListItem}
                            placeholder={'Select Assignee Type...'}
                            triggerType={'chevron'}
                            onChange={(val) => {
                                setAssigneeType(val);
                                setSelectedAssigneeList([]);
                            }}
                        />
                    </div>
                </div>
                }
                <div className={cls.assigneeCreatorContainer} style={{marginBottom: '12px'}}>
                    <div style={{flex: 1}}>
                        <RenderTitle
                            title={'Assignee'}
                            tooltipText={'The person/department/position that is accountable for completing the task by or before the Due Date.' + ' (Task Assignees can be changed later.)'}
                            required
                            titleClassName={message?.errors?.Assignee ? cls.titleClassNameError : cls.titleClassName}
                        />
                        {isEdit?
                            (<SelectWithSearchInMenuList
                                tabIndex={3}
                                className={cls.selectHost}
                                key={'Assignee'}
                                isClearable
                                defaultSelected={assigneeList[assigneeList.findIndex(c => c.value === selectedAssignee.assignee_id)]}
                                items={assigneeList}
                                toggleBtnChildrenClass={cls.toggleBtnChildren}
                                triggerType={"chevron"}
                                molcontainerclass={cls.selectMolContainer}
                                tmiClassName={cls.toggleButtonClass}
                                molclassname={cls.menuOverlayList}
                                placeholder={'Assignee'}
                                searchPlaceholder='Search Assignee here...'
                                placeholderClassName={cls.placeholderClass}
                                disable={isProcess}
                                onFocus={() => setMessage('')}
                                onChange={(position) => {
                                    if (position) {
                                        setSelectedAssignee(c => ({...c, assignee_id: position.value}));
                                    } else if (position === null) {
                                        setSelectedAssignee('');
                                    }
                                }}
                            />):(

                                <MultiSelectWithSearchNew
                                    placeholder={`Select Assignees By ${assigneeType.label}...`}
                                    items={assigneeList}
                                    triggerType={'eyeglassGrey'}
                                    itemType={'checkboxes'}
                                    checkboxEmplacement={'left'}
                                    initialSelectedItems={selectedAssigneeList}
                                    inputContainerClassName={cls.assigneeInputContainer}
                                    molclassname={cls.assigneeSelectMenuOverlay}
                                    moliclassname={cls.assigneeSelectMenuOverlayItem}
                                    disable={isProcess}
                                    onFocus={() => setMessage('')}
                                    onChange={(selection) => {
                                        if(selection){
                                            setSelectedAssigneeList(selection);
                                        }else{
                                            setSelectedAssigneeList([]);
                                        }
                                    }}
                                />)}
                        {message && message?.errors?.assignee_id &&
                            <MessageContainer message={message.errors.assignee_id}/>}
                    </div>
                    {props?.task?.creator && (
                        <div style={{flex: 1}}>
                            <RenderTitle title={'Creator'}
                                tooltipText={'The person that has created a task. Task Creators cannot be changed later.'}
                                titleClassName={cls.titleClassName}/>
                            <input style={{cursor: 'auto',border: '1px solid var(--app-color-gray-lighter)'}}
                                placeholder={task?.creator.first_name + ' ' + task?.creator.last_name} readOnly/>
                        </div>
                    )}
                </div>
                <div style={{marginBottom: '12px'}}>
                    <RenderTitle
                        title={'Procedure'}
                        tooltipText={'The Procedure the Assignee must follow when working on this task. ' + 'For organizations to thrive, all tasks must follow specific, pre-defined procedures.'}
                        required
                        titleClassName={message?.errors?.name ? cls.titleClassNameError : cls.titleClassName}
                    />
                    {typeof selectedProcedure !== 'undefined' && selectedProcedure ? (
                        <p style={{color: '#4a4a4a'}}>
                            <span>
                                <img style={{marginRight: '10px', marginBottom: '-4px', width: '24px'}} alt=""
                                    className={'grey-svg-image-filter'} src={processesIcon}/>
                            </span>
                            {selectedProcedure.name}
                        </p>
                    ) : (
                        <>
                            {!isProcess && !isEdit ? (
                                <SelectWithSearchInMenuList
                                    className={cls.selectHost}
                                    key={'Process'}
                                    isClearable
                                    defaultSelected={processesList[processesList.findIndex(c => c.value === taskProcess.process)]}
                                    items={processesList}
                                    toggleBtnChildrenClass={cls.toggleBtnChildren}
                                    triggerType={"chevron"}
                                    molcontainerclass={cls.selectMolContainer}
                                    tmiClassName={cls.toggleButtonClass}
                                    molclassname={cls.menuOverlayList}
                                    placeholder={'Select Procedure...'}
                                    searchPlaceholder='Search Procedure here...'
                                    placeholderClassName={cls.placeholderClass}
                                    onChange={(procedure) => {
                                        if (procedure) {
                                            setTaskProcess(c => ({
                                                ...c,
                                                process: procedure.value,
                                                procedure_id: procedure.value,
                                            }));
                                        } else if (procedure === null) {
                                            setTaskProcess({});
                                        }
                                    }}
                                    onFocus={() => setMessage('')}/>
                            ) : !isEdit ? (
                                <p style={{color: '#4a4a4a'}}>
                                    <span>
                                        <img style={{marginRight: '10px', marginBottom: '-4px', width: '24px'}} alt=""
                                            className={'grey-svg-image-filter'} src={processesIcon}/>
                                    </span>
                                    {selectedProcess.name}
                                </p>
                            ) : (
                                <p style={{color: '#4a4a4a'}}>
                                    <span>
                                        <img style={{marginRight: '10px', marginBottom: '-4px', width: '24px'}} alt=""
                                            className={'grey-svg-image-filter'} src={processesIcon}/>
                                    </span>
                                    {processesList.length > 0 ? processesList[processesList.findIndex(c => c.value === taskProcess.process)]?.label : 'N/A'}
                                </p>
                            )}
                        </>
                    )}

                    {message && message?.errors?.procedure_id &&
                        <MessageContainer message={message.errors.procedure_id}/>}
                </div>
                <div className={cls.dateAndTimeSelectContainer} style={{marginBottom: '12px'}}>
                    <div className={cls.dueDateContainer}>
                        <RenderTitle
                            title={'Due Date'}
                            tooltipText={`The exact calendar date before or by which this task should be completed. 
                                If this date is passed, the task will appear in the Overdue tasks for the Assignee.`}
                            required
                            titleClassName={message?.errors?.name ? cls.titleClassNameError : cls.titleClassName}
                        />
                        <DatePicker
                            disabled={isDateFieldsDisabled}
                            placeholderText="Due Date"
                            customInput={
                                <div
                                    id={'custom-input'}
                                    className={cls.estimatedTimeInputContainer}
                                    style={{
                                        borderColor: message?.errors?.due_date ? 'var(--app-color-error)' : 'var(--app-color-gray-lighter)',
                                    }}
                                >
                                    <input
                                        tabIndex={4}
                                        onFocus={() => setMessage('')}
                                        value={moment(dueDate).format('DD/MM/YYYY')}
                                        type="text"
                                        required={message?.errors?.due_date}
                                        id={'due-date'}
                                        className={cls.estimatedTimeInput}
                                        style={{
                                            textAlign: dueDate?.length > 0 ? 'center' : 'left',
                                        }}
                                        placeholder={'Due Date'}
                                        onChange={(_e) => {
                                        }}
                                    />
                                    <Calendar className={cls.calendarSVG}/>
                                </div>
                            }
                            wrapperClassName={cls.datePickerWrapper}
                            selected={dueDate}
                            onChange={(e) => {
                                setDueDate(e);
                            }}
                            showMonthDropdown
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={90}
                        />
                        {message && message?.errors?.due_date && <MessageContainer message={message.errors.due_date}/>}
                    </div>
                    <div>
                        <RenderTitle
                            title={'Estimated Time'}
                            tooltipText={'The exact clock time before or by which this task should be completed. If this time is passed, the task will appear in the Overdue tasks for the Assignee.'}
                            required
                            titleClassName={message?.errors?.name ? cls.titleClassNameError : cls.titleClassName}/>
                        <DatePicker
                            disabled={isDateFieldsDisabled}
                            placeholderText="Estimated Time"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={5}
                            timeCaption="Time"
                            dateFormat="hh:mm"
                            customInput={
                                <div
                                    id={'custom-input'}
                                    className={cls.estimatedTimeInputContainer}
                                    style={{
                                        borderColor: message?.errors?.due_date ? 'var(--app-color-error)' : 'var(--app-color-gray-lighter)',
                                    }}
                                >
                                    <input
                                        tabIndex={5}
                                        onFocus={() => setMessage('')}
                                        type="text"
                                        value={moment(dueDate).format('HH:mm:ss')}
                                        required={message?.errors?.due_date}
                                        id={'due-date'}
                                        className={cls.estimatedTimeInput}
                                        style={{
                                            textAlign: dueDate?.length > 0 ? 'center' : 'left',
                                        }}
                                        placeholder={'Estimated Time'}
                                        onChange={(_e) => {
                                        }}
                                    />
                                    <ClockGrey className={cls.clockSVG}/>
                                </div>
                            }
                            className={cls.datePickerWrapper}
                            selected={dueDate}
                            onChange={(e) => {
                                setDueDate(e);
                            }}
                        />
                        {message && message?.errors?.due_date && <MessageContainer message={message.errors.due_date}/>}
                    </div>
                    <div>
                        <CustomCheckbox
                            tabIndex={6}
                            disabled={isDateFieldsDisabled}
                            labelStyle={{fontWeight: 300, fontSize: '16px'}}
                            label={'Set Recurring'}
                            onChange={(checked) => {
                                setEnableRecurring(checked);
                            }}
                            defaultChecked={enableRecurring}
                            mainContainerStyle={{margin: '32px 0 0 0'}}
                            checkMarkStyle={{width: '16px', height: '16px'}}
                            checkIconStyle={{width: '11px', height: '8px'}}
                        />
                    </div>
                </div>

                {enableRecurring && <RecurTask dueDate={dueDate} taskRecurringDetails={taskRecurringDetails}
                    setTaskRecurringDetails={setTaskRecurringDetails} message={message}
                    disabled={isDateFieldsDisabled}/>}

                <div>
                    <CustomCheckbox
                        disabled={isEdit && authenticatedUser.id !== task?.creator?.id}
                        labelStyle={{fontWeight: 300, fontSize: '16px'}}
                        label={'Allow colleague to change Due Date and Time'}
                        onChange={(checked) => {
                            setAllowColleagueChangeDueDate(checked);
                        }}
                        defaultChecked={allowColleagueChangeDueDate}
                        mainContainerStyle={{margin: '32px 0 0 0'}}
                        checkMarkStyle={{width: '16px', height: '16px'}}
                        checkIconStyle={{width: '11px', height: '8px'}}
                        tabIndex={7}
                    />
                </div>
            </Form>
            {!isEdit ? (
                <div className={cls.buttonsContainer} style={{alignSelf: isProcess ? 'center' : ''}}>
                    <Button
                        clicked={() => addTask(false)}
                        className={cls.saveButton}
                        disabled={!taskName || !dueDate || selectedAssigneeList.length === 0 || !taskProcess.procedure_id}
                        style={{marginLeft: '10px', display: isProcess ? 'none' : 'flex'}}
                        title={'Create & Save'}
                        loading={loading}
                        icon={<img alt="" src={taskIcon}/>}
                    />

                    {userExistsInAssigneeList() && (
                        <Button
                            clicked={() => addTask(true)}
                            className={cls.saveButton}
                            disabled={!taskName || !dueDate || selectedAssigneeList.length===0 || !taskProcess.procedure_id}
                            title={isProcess ? 'Start Procedure' : 'Create & Start'}
                            loading={loadingStart}
                            icon={<img alt="" src={isProcess ? rightArrow : taskIcon}/>}
                        />
                    )}
                </div>
            ) : (
                <div style={{paddingTop: '20px', display: 'flex'}}>
                    <Button title={'Delete'} style={{marginRight: '12px'}} lighter clicked={() => setToDeleteTask(true)}
                        icon={<img src={deleteIcon}/>}/>
                    <Button
                        title={'Save'}
                        lighter
                        clicked={() => taskEdit()}
                        icon={
                            loading ? (
                                <Loader type={isSafari ? 'Watch' : 'ThreeDots'} style={{marginRight: '8px'}}
                                    color="var(--app-color-gray-lighter)" secondaryColor="lightgray" radius={20} height={30} width={'30px'}/>
                            ) : (
                                <img style={{height: '20px', width: '20px', marginRight: '8px'}} alt="" src={saveIcon}/>
                            )
                        }
                    />
                    {userExistsInAssigneeList() && !isEdit &&
                        <Button clicked={() => beginProcess()} className={cls.saveButton} title={'Start Procedure'}
                            icon={<img alt="" src={rightArrow}/>}/>}
                </div>
            )}
            {toDeleteTask && <DeleteModal fieldId={task.id} isOpen={toDeleteTask} removefield={removeTask} loading={loadingRemove}
                onClose={setToDeleteTask} fieldName={'task'}/>}
        </div>
    );
}

const mapStateToProps = (container) => {
    const {selectedProcess, companyUsersArray, companyPositionsArray, companyDepartments, companyProcesses} = container.flowReducer;
    const {authenticatedUser} = container.authReducer;

    return {selectedProcess, companyUsersArray, companyPositionsArray, companyDepartments, companyProcesses, userId: authenticatedUser.id};
};

export default connect(mapStateToProps)(TaskForm);
