import { useNavigate } from 'react-router-dom';

const Link = (props) => {
    const { targetURL = "#", className, style } = props;
    const navigate = useNavigate();

    return <>
        <a href={targetURL} className={className} style={{ textDecoration: "none", ...style }}
            onClick={(e) => {
                e.preventDefault();
                navigate(targetURL);

                return false;
            }}

        >
            {props.children}
        </a>
    </>;
};

export default Link;