import cls from './TaskBoardPanel.module.scss';

const TaskBoardPanel = (props) => {
    const { iconSrc, cardTitle, children } = props;

    return (
        <div className={cls.taskBoardPanelHost}>
            <div className={cls.container}>
                <div className={cls.titleContainer}>
                    {iconSrc && <img alt="" src={iconSrc} />}
                    <p className={cls.cardTitle}>{cardTitle}</p>
                </div>
                {children}
            </div>
        </div>
    );
};

export default TaskBoardPanel;
