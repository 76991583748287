import React from 'react';
import { componentsSettingIcon, saveInputIcon } from '../../../../../assets/images/icons';
import './FieldRow.scss';
import MessageContainer from '../../../../SideMenuEntries/ProcessesComps/MessageContainer/MessageContainer';
import { tooltip } from '../../../../../assets/images/new-icons';
import cls from "../../../TwoStatesFieldWrapper/FieldWrapper.module.scss";

const FieldRow = (props) => {
    const { fieldIcon, mandatory, fieldLabel, visible, settingsClicked, children, hasSaveInputBtn, hasSettingsBtn, error } = props;

    return (
        <div id={'field-row-container'} style={{ width: props.isProcess ? 'calc(50% - 20px)' : '', ...props.style, display: visible ? '' : 'none' }}>
            {fieldLabel ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="field-row" style={{ fontSize: '12px', fontWeight: '600', color: error ? 'var(--app-color-error)' : '' }} className={'field-row-label'}>
                        {fieldLabel}
                    </label>
                    {mandatory && <span className={cls.requiredTitle}>{'*'}</span>}
                    {/* <img src={tooltip} />*/}
                </div>
            ) : (
                <></>
            )}
            <div id="field-row" app-variant-has-error={error ? 'error' : ''}>
                <div className="field-row-container">
                    <div className="field-row-inner-container">
                        <img src={fieldIcon} alt={''} />
                    </div>
                    {children}
                </div>
                {hasSaveInputBtn && (
                    <div
                        style={{ cursor: 'pointer', paddingRight: '10px' }}
                        onClick={() => {
                            alert('Component full name saved');
                        }}
                    >
                        <img src={saveInputIcon} alt={''} />
                    </div>
                )}
                {hasSettingsBtn && (
                    <div style={{ cursor: 'pointer', paddingRight: '10px' }} className={'grey-svg-image-filter'} onClick={() => settingsClicked()}>
                        <img alt="" src={componentsSettingIcon} style={{ width: '24px', height: '24px' }} />
                    </div>
                )}
            </div>
            {error && <MessageContainer message={error} />}
        </div>
    );
};

export default FieldRow;
