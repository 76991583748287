import { memo, useEffect, useRef, useState } from 'react';
import './chartStyles.scss';
import cls from './I_NonManagerials.module.scss';
import { useSelector } from 'react-redux';

const I_NonManagerials = memo((props) => {
    const {chartPreview} = useSelector(store => store.modalsReducer);
    const [colDim] = useState({ colWidth: 0, colHeight: 0 });
    const gManDirPosRef = useRef();
    const { data, onlyNMPos, showPositionInfo, onContainerDimsChange } = props;

    useEffect(() => {
        if (onContainerDimsChange) {
            onContainerDimsChange({
                width: gManDirPosRef.current?.clientWidth,
                height: gManDirPosRef.current?.clientHeight,
                offsetLeft: gManDirPosRef.current?.offsetLeft,
            });
        }
    }, [colDim]);

    return (
        <div ref={gManDirPosRef} className={cls.host}>
            {data?.children?.length > 0 ? (
                <div className={cls.target}>
                    {data?.children.map((i, idx) => {
                        return (
                            <div
                                className={cls.cardContainerBase}
                                app-single-item_i={onlyNMPos ? 'true' : 'false'}
                                app-last-item={idx == data?.children?.length - 1 ? 'last' : ''}
                                app-org-chart-preview={chartPreview === true ? 'true' : 'false'}
                                onClick={() => {
                                    showPositionInfo(i.id);
                                }}
                                key={i + idx}
                            >
                                <p>
                                    <span className={cls.companyInfoTitle}>{i.name}</span>
                                    <span>{i.sub_name}</span>
                                </p>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div style={{position: 'relative', marginBottom: '10px', minHeight: '250px'}}/>
            )}
        </div>
    );
});

export default I_NonManagerials;
