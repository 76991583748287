import classNames from 'classnames';
import { chunk } from 'lodash';
import { forwardRef, memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import {
    ArrowDown, CheckedStyle, CloseIcon2,
    DeleteBlackIco, DoneMarkIconDark, TaskBoardWhite, TaskBoard, deleteWhiteIcon,
} from '../../../assets/images/icons';
import {OLContext, useOLContext} from '../../../context/appRender.context';
import {
    durationUnitOptions,
    timeUnitsOptions,
    timeUnitsOptionsWithoutSeconds,
    transformSeconds,
    useStateRef,
} from '../../../shared/utility';
import {SelectWithSearch, ToggleSwitch, Spinner, Button, ModalR, CustomCheckbox} from '../../common';
import MultiselectWithSearchR from '../UI/Select/MultiselectWithSearchR';
import UnfocusableWrapper from '../UnfocusableWrapper/UnfocusableWrapper';
import cls from './FieldWrapper.module.scss';
import {
    plusIcon, deleteButtonIcon, Tooltip, shareIconPurple,
    plusIconPurple, shredder, closeIconPurple, errorInfo,
} from '../../../assets/images/new-icons';
import MessageContainer from '../../SideMenuEntries/ProcessesComps/MessageContainer/MessageContainer';
import { deleteBinBlue } from '../../../assets/images/icons';
import { Can } from '../../common';
import { Permissions } from '../../common';

export const FieldWrapper = (props) => {
    const {
        id, title, required, tooltipText, value, titleSize,
        isFocused, loading, useContext, readOnly, withAckButton, otherContent,
        defaultHighlighted, ungrouped, dispalysIconValue, placeholder, sectionError, fieldWrapperStyle, noBorder, style, clickParent = true,
    } = props;
    const [contextState, setContextState] = useContext();

    useEffect(() => {
        if (contextState.activeElement == id || (isFocused && !readOnly)) {
            openingEditMode();
        } else {
            closingEditMode();
        }
    }, [id]);
    const closingEditMode = () => {
        setContextState({ open: false, errors: { [id]: false } });
    };
    const openingEditMode = useCallback(() => {
        if (!readOnly) {
            setContextState({ open: true, activeElement: id, errors: { [id]: false } });
        }
    }, [id, readOnly]);

    return contextState.activeElement === id || isFocused ? (
        <UnfocusableWrapper
            style={style}
            onClickOutside={() => closingEditMode()}
            className={classNames(cls.fieldWrapperActive, fieldWrapperStyle)}
            id={id}
            app-field-wrapper-variant={ungrouped ? 'ungrouped' : ''}
            app-field-wrapper-active={contextState.activeElement == id || isFocused ? 'active' : ''}
            app-variant-has-error={contextState?.errors?.[id] ?? sectionError?.length > 0 ? 'true' : 'false'}
        >
            <div>
                <RenderTitle title={title} required={required} tooltipText={tooltipText} hasError={sectionError} />
                {withAckButton && (
                    <div onClick={() => closingEditMode()} className={cls.checkMarkContainer}>
                        <CheckedStyle />
                    </div>
                )}
            </div>
            <div className={cls.nonEditChildrenContainer} style={{ border: noBorder && 'none' }}>
                {props.children}
            </div>
            {sectionError?.length > 0 && (
                <div className={cls.errorContainer}>
                    <p className={cls.error}><span><img alt="" src={errorInfo} /></span>{sectionError}</p>
                </div>
            )}
        </UnfocusableWrapper>
    ) : (
        <div
            className={classNames(cls.fieldWrapperInactive, fieldWrapperStyle)}
            onClick={() => { !clickParent ? openingEditMode() : () => { }; }}
            app-variant-inactive-ungrouped={ungrouped ? 'true' : 'false'}
            app-variant-inactive-default-highlighted={defaultHighlighted ? 'true' : 'false'}
            app-variant-inactive-with-error={sectionError?.length > 0 ? 'true' : 'false'}
            app-variant-inactive-read-only={readOnly ? 'true' : 'false'}
            app-field-wrapper-variant={ungrouped ? 'ungrouped' : ''}
            app-field-wrapper-active={contextState.activeElement == id || isFocused ? 'active' : ''}
            app-variant-has-error={sectionError?.length > 0 ? 'true' : 'false'}
        >
            <RenderTitle title={title} titleSize={titleSize} tooltipText={tooltipText} hasError={sectionError} />
            {!otherContent ? (
                typeof value === 'string' ? (
                    <div className={cls.editParagraphContainer}>
                        {dispalysIconValue && value?.length > 0 ? (
                            <div className={cls.fieldWrapperInactiveImageContainer}>
                                <img alt={'ico'} className={cls.fieldWrapperInactiveImage} src={value} />
                                <ArrowDown className={cls.fieldWrapperInactiveSVG} />
                            </div>
                        ) : loading ? (
                            <span className={cls.fieldWrapperInactiveSpinnerContainer}>
                                <Spinner size={30} type={'ThreeDots'} />
                            </span>
                        ) : (
                            <p className={cls.fieldWrapperInactiveText} app-variant-palceholder={value?.length > 0 ? 'true' : 'false'}>
                                {value.length > 0 ? value : placeholder}
                            </p>
                        )}
                    </div>
                ) : value ? (
                    value
                ) : loading ? (
                    <span className={cls.fieldWrapperInactiveSpinnerContainer}>
                        <Spinner size={30} type={'ThreeDots'} />
                    </span>
                ) : (
                    <p className={cls.fieldWrapperInactiveText}>{placeholder}</p>
                )
            ) : (
                props.children
            )}
            {sectionError?.length > 0 && <span className={cls.errorContainer}>{sectionError}</span>}
        </div>
    );
};
export const FieldWrapper_v2 = (props) => {
    const { title, tooltipText, sectionError, fieldWrapperStyle } = props;

    return (
        <UnfocusableWrapper className={classNames(cls.fieldWrapperActive, fieldWrapperStyle)}>
            <div className={cls.titleContainer}>
                <RenderTitle title={title} tooltipText={tooltipText} hasError={sectionError} />
            </div>
            {props.children}
            {sectionError?.length > 0 && (
                <div className={cls.errorContainer}>
                    <p>{sectionError}</p>
                </div>
            )}
        </UnfocusableWrapper>
    );
};
export const ContainerWrapper = forwardRef((props, ref) => {
    const { title, tooltipText, withAckButton, ungrouped, bordered, onAckPress, columned, style, required, containerError } = props;

    return (
        <div ref={ref} className={cls.containerWrapper}
            style={{ ...style }} app-variant-ungruouped={ungrouped ? 'true' : 'false'}
            app-variant-bordered={bordered ? 'true' : 'false'}>
            <div className={cls.containerWrapperTitleContainer}>
                <RenderTitle title={title} tooltipText={tooltipText} required={required} hasError={containerError} />
                {withAckButton && (
                    <button type={'button'} className={cls.containerWrapperAckButton} onClick={onAckPress}>
                        <CheckedStyle />
                    </button>
                )}
            </div>
            <div className={cls.containerWrapperChildrenContainer} app-variant-columned={columned ? 'true' : 'false'}>
                {props.children}
            </div>
            {containerError?.length > 0 && <span className={cls.errorContainer}>{containerError}</span>}
        </div>
    );
});
export const RenderYesNoSwitcher = (props) => {
    const { title, tooltipText, ungrouped, bordered, value, toggleSelected, style } = props;

    return (
        <div className={cls.renderYesNoSwitcherWrapper} style={{ ...style }}
            app-variant-ungrouped={ungrouped ? 'true' : 'false'}
            app-variant-bordered={bordered ? 'true' : 'false'}>
            {title && <div className={cls.renderYesNoSwitcherTitleContainer}>
                <RenderTitle title={title} tooltipText={tooltipText} titleClassName={cls.renderYesNoSwitcherTitle} />
            </div>
            }
            <div className={cls.renderYesNoSwitcherToggleContainer}>
                <ToggleSwitch value={value} toggleSelected={toggleSelected} name={title} />
            </div>
        </div>
    );
};
export const RenderTitle = (props) => {
    const { title, tooltipText, titleSize, required, titleClassName = cls.title, style, hasError, setMinWidth = false } = props;
    const tooltipDivRef = useRef(null);
    const [randomID] = useState(String(Math.random()));

    return title?.length > 0 ? (
        <div className={cls.renderTitle} style={style} is-errored={hasError ? 'error' : ''}>
            <div className={cls.titleContainer}>
                <p className={titleClassName} style={{fontSize: titleSize ? titleSize : ''}}>{title}</p>
                {required && <p className={cls.requiredTitle}>{'*'}</p>}
            </div>
            {tooltipText?.length > 0 && (
                <div ref={tooltipDivRef} className={cls.tooltipContainer} data-tip={tooltipText} data-for={randomID}>
                    <Tooltip className={cls.icon}/>
                    <ReactTooltip id={randomID} multiline effect={'float'} type={'dark'}
                        clickable={true} className={classNames(cls.showTooltip, setMinWidth && cls.optionalMinWidth)} place={'left'}/>
                </div>
            )}
        </div>
    ) : null;
};
export const RenderTooltip = (props) => {
    const {tooltipText, icon, style} = props;
    const tooltipDivRef = useRef(null);
    const [randomID] = useState(String(Math.random()));

    return tooltipText?.length > 0 ? (
        <div className={cls.renderTitle} style={style}>
            <div ref={tooltipDivRef} className={cls.tooltipContainer} data-tip={tooltipText} data-for={randomID}>
                {icon || <Tooltip className={cls.icon} />}
                <ReactTooltip className={classNames(cls.showTooltip)}
                    id={randomID} multiline effect={'float'} type={'dark'} clickable={true} place={'top'} />
            </div>
        </div>
    ) : null;
};
export const RenderDinamicTextInput = (props) => {
    const { placeholder, readonly, text, onTextChanged, onControlTouched, numberOfLines, maxLength, style } = props;
    const textAreaRef = useRef();
    const [textAreaValue, setTextAreaValue] = useState(text);
    const textAreaOnChange = useCallback(
        (newValue) => {
            let text = newValue;

            if (maxLength && text.length > maxLength) return;
            if (numberOfLines) text = text?.split('\n').slice(0, numberOfLines ?? undefined);
            setTextAreaValue(text);
            onTextChanged(text);
        },
        [numberOfLines, maxLength]
    );

    useLayoutEffect(() => {
        // Reset height - important to shrink on delete
        textAreaRef.current.style.height = 'inherit';
        // Set height
        textAreaRef.current.style.height = `${Math.max(textAreaRef.current.scrollHeight, 20)}px`;
    }, [textAreaValue]);

    return (
        <div className={cls.renderDinamicTextInputWrapper} style={{ ...style }}>
            <textarea
                ref={textAreaRef}
                type="text"
                placeholder={placeholder}
                name="textarea"
                readOnly={readonly}
                rows={numberOfLines ? numberOfLines : undefined}
                className={cls.renderDinamicTextInputTextarea}
                value={textAreaValue}
                onBlur={(e) => {
                    onControlTouched && onControlTouched();
                }}
                onChange={e => textAreaOnChange(e.target.value)}
            />
        </div>
    );
};

export const RenderDoubleSelectFramedEntry = (props) => {
    const {seconds, onTextChanged, onControlTouched, disabled = false, defaultUnit, allowSecondsSelection = true} = props;
    const { value, unit } = transformSeconds(seconds);
    const [fieldValue, setFieldValue] = useState(value);
    const [selectedMU, setSelectedMU] = useState(defaultUnit ?? unit);
    const timeOptions= allowSecondsSelection ? timeUnitsOptions : timeUnitsOptionsWithoutSeconds;

    const transformDurationInSeconds = useCallback(() => {
        let multiplier = 1;

        switch (selectedMU) {
            case 'Seconds':
                multiplier = 1;
                break;
            case 'Minutes':
                multiplier = 60;
                break;
            case 'Hours':
                multiplier = 60 * 60;
                break;
            case 'Days':
                multiplier = 60 * 60 * 24;
                break;
            default:
                return multiplier;
        }

        return +(+fieldValue * multiplier)?.toFixed(2);
    }, [selectedMU, fieldValue]);

    useEffect(() => {
        if(value === 0) setFieldValue(value); //Update only when value gets reset to zero
    }, [value]);

    useEffect(() => {
        onTextChanged?.(transformDurationInSeconds());
    }, [fieldValue, selectedMU]);

    return (
        <div className={cls.renderDoubleSelect}
            onBlur={() => onControlTouched && onControlTouched() && onTextChanged?.(transformDurationInSeconds())}>
            {disabled && <div className={cls.renderDoubleSelect} style={{background: "#efefef", position: "absolute", left: "0", top: "0", zIndex: "100", opacity: "0.5", cursor: "default"}}></div>}
            <div className={cls.firstSelectContainer}>
                <input
                    id={'numeric-selection'}
                    itemType={'no-item-icons'}
                    className={cls.doubleSelectFirstSelectContainer}
                    placeholder={'Type here...'}
                    value={fieldValue}
                    onChange={(val) => {
                        if (val.target.value?.length < 9) {
                            setFieldValue(Number(val.target.value));
                        }
                    }}
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                />
            </div>
            <div className={cls.secondSelectContainer} onBlur={() => onControlTouched && onControlTouched()}>
                <SelectWithSearch
                    id={'measure-unit-selection'}
                    triggerType={'chevron'}
                    className={cls.doubleSelectFirstSelectContainer}
                    molclassname={cls.doubleSelectFirstSelectMOL}
                    moliclassname={cls.doubleSelectFirstSelectMOLI}
                    inputClassName={cls.unitInput}
                    placeholder={selectedMU ?? 'Select...'}
                    defaultSelectedItem={selectedMU ?? timeOptions[0]}
                    onChange={(opt) => {
                        if (opt?.label?.length > 0) {
                            setSelectedMU(opt?.label);
                        } else {
                            setSelectedMU(timeOptions[0]);
                        }
                    }}
                    items={timeOptions}
                />
            </div>
        </div>

    );
};
// This code is for STEP MAIN time duration
export const RenderDurationInputWithUnitEntry = (props) => {
    const { seconds, defaultSeconds, onTextChanged, onControlTouched, disabled = false, defaultUnit, unitSectionClassName= "", unitInputClassName= "" } = props;
    const [fieldValue, setFieldValue] = useState(seconds);
    const [selectedMU, setSelectedMU] = useState(defaultUnit || durationUnitOptions[0].value);

    useEffect(() => {
        if(seconds === 0 || seconds === defaultSeconds) setFieldValue(seconds); //Only when get reset.
    }, [seconds]);

    useEffect(() => {
        onTextChanged?.(fieldValue, selectedMU);
    }, [fieldValue, selectedMU]);

    return (
        <div className={cls.renderDoubleSelect} onBlur={() => onControlTouched && onControlTouched()}>
            {disabled && (
                <div className={cls.renderDoubleSelect} style={{ background: '#efefef', position: 'absolute', left: '0', top: '0', zIndex: '100', opacity: '0.5', cursor: 'default' }}></div>
            )}
            <div className={classNames(cls.firstSelectContainer, unitSectionClassName)}>
                <input
                    id={'numeric-selection'}
                    itemType={'no-item-icons'}
                    className={classNames(cls.doubleSelectFirstSelectContainer, unitInputClassName)}
                    placeholder={'Type here...'}
                    value={fieldValue}
                    onChange={(val) => {
                        if (val.target.value?.length < 6) {
                            setFieldValue(Number(val.target.value));
                        }
                    }}
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                />
            </div>
            <div className={cls.secondSelectContainer} onBlur={() => onControlTouched && onControlTouched()}>
                <SelectWithSearch
                    id={'measure-unit-selection'}
                    triggerType={'chevron'}
                    className={cls.doubleSelectFirstSelectContainer}
                    molclassname={cls.doubleSelectFirstSelectMOL}
                    moliclassname={cls.doubleSelectFirstSelectMOLI}
                    inputClassName={cls.unitInput}
                    placeholder={selectedMU || 'Select...'}
                    defaultSelectedItem={durationUnitOptions.find(option => option.value === selectedMU) || durationUnitOptions[0]}
                    onChange={(opt) => {
                        if (opt?.value) {
                            setSelectedMU(opt.value);
                        } else {
                            setSelectedMU(durationUnitOptions[0].value);
                        }
                    }}
                    items={durationUnitOptions}
                />
            </div>
        </div>
    );
};

export const RenderYesNoOptions = (props) => {
    const { onValueChanged, value, disabled = false } = props;
    const [selected, setSelected] = useState(value);

    return (
        <div className={cls.renderYesNoOptionsWrapper}>
            <button
                disabled={disabled}
                type={'button'}
                onClick={() => {
                    setSelected(true);
                    onValueChanged(true);
                }}
                className={cls.renderYesNoOptionsWrapperYesContainer}
                app-variant-selected={selected ? 'true' : 'false'}
            >
                <p>{'Yes'}</p>
            </button>
            <button
                disabled={disabled}
                type={'button'}
                onClick={() => {
                    setSelected(false);
                    onValueChanged(false);
                }}
                className={cls.renderYesNoOptionsWrapperNoContainer}
                app-variant-selected={selected ? 'false' : 'true'}
            >
                <p>{'No'}</p>
            </button>
        </div>
    );
};
const RenderAddBulletField = (props) => {
    const { unfocused, previousValues, onChange } = props;
    const [editMode, setEditMode] = useState(false);
    const [inputValue, setInputValue, inputValueRef] = useStateRef('');
    const setingEditMode = val => setEditMode(val);

    useEffect(() => {
        setingEditMode(false);
    }, [unfocused]);
    const saveInputValue = useCallback(() => {
        if (inputValue?.length > 0 && onChange) {
            onChange(previousValues + `~${inputValue}`);
            setInputValue('');
        } else {
            onChange(previousValues + `~${inputValueRef?.current}`);
            setInputValue('');
        }
    }, [inputValue, previousValues]);

    return (
        <div onClick={() => setEditMode(true)} onMouseLeave={() => setEditMode(false)} className={cls.renderAddBulletFieldWrapper}>
            <div className={cls.renderAddBulletFieldInputContainer}>
                <div className={cls.renderAddBulletFieldAnnotationWrapper}>
                    <button type={'button'} className={cls.renderAddBulletFieldSaveButton} onClick={saveInputValue}>
                        <TaskBoardWhite />
                        <p>{'ADD'}</p>
                    </button>
                </div>
                <input
                    className={cls.renderAddBulletFieldInputStyle}
                    type="text"
                    name={'addListItem'}
                    style={{ height: !(inputValue?.length > 0) ? '30px' : '29px' }}
                    placeholder={'(insert position specific and press ADD button or SHIFT+ENTER keys to add new item)'}
                    value={inputValue}
                    readOnly={!editMode}
                    onChange={e => setInputValue(e.target.value)}
                    onFocus={() => {
                    }}
                    onBlur={() => {
                        /* setInputValue('') */
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && e.shiftKey /*|| && event.ctrlKey  */) {
                            saveInputValue();
                        }
                    }}
                />
                <div className={cls.renderAddBulletFieldAnnotationWrapper}>
                    {inputValue?.length > 0 && (
                        <button type={'button'} className={cls.renderAddBulletFieldCancelbutton} onClick={() => setInputValue('')}>
                            <CloseIcon2 />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
const RenderBulletEntry = (props) => {
    const { id, value, index, readOnly, unfocused, onDeleteItem, onEditedItem } = props;
    const textareaRef = useRef(null);
    const [editState, setEditState] = useOLContext();
    const [editMode, setEditMode] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [inputValue, setInputValue] = useState(value);
    const [, setTextAreaTouched] = useState(false);

    useLayoutEffect(() => {
        // Reset height - important to shrink on delete
        textareaRef.current.style.height = 'inherit';
        // Set height
        textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, 20)}px`;
    }, [inputValue]);

    useEffect(() => {
        if (editState.activeElement == id && !readOnly) {
            openingEditMode();
        } else {
            closingEditMode();
        }
    }, [id, editState.activeElement]);

    const openingEditMode = useCallback(() => {
        if (!readOnly) {
            setShowButtons(true);
            setEditMode(true);
            setEditState({ open: true, activeElement: id });
        }
    }, [id, readOnly]);

    const closingEditMode = () => {
        if (showButtons) {
            setShowButtons(false);
        }
        if (editMode) {
            setEditMode(false);
        }
    };

    useEffect(() => closingEditMode(), [unfocused]);
    const textAreaLabelStyle =
        'position: absolute; top: 35%; left: 10px; right: 0;/*  bottom: 0, */ userSelect: none; pointerEvents: none; display: block; color: var(--app-color-gray-light); fontFamily: var(--app-text-secondary-font); font-size: 16px; font-weight: 400; letter-spacing: 0; line-height: 20px;';
    const textAreaOnChange = (text) => {
        setInputValue(text);
    };

    if (value?.length < 1) return null;

    return (
        <div className={cls.renderBulletEntryWrapper} onClick={openingEditMode}>
            <div className={cls.renderBulletEntryContainer} app-variant-show-buttons={showButtons ? 'true' : 'false'}>
                <div className={cls.renderBulletEntryLabelContainer}>
                    <label className={cls.renderBulletEntryItemNumber} app-variant-show-buttons={showButtons ? 'true' : 'false'}>
                        {+index + 1 + '.'}
                    </label>
                </div>
                <div className={cls.renderBulletEntryTextareaContainer}>
                    <textarea
                        ref={textareaRef}
                        type="text"
                        name="textarea"
                        className={cls.renderBulletEntryTextarea}
                        style={{
                            backgroundColor: !showButtons ? '#FFFFFF' : '#D1D1D1',
                            padding: !showButtons ? '0px 0px 0 5px' : '5px 0px 0 0',
                        }}
                        value={inputValue}
                        onBlur={(e) => {
                            setTextAreaTouched(true);
                        }}
                        onChange={e => textAreaOnChange(e.target.value)}
                    />
                    {inputValue?.length < 1 &&
                        <label className={cls.renderBulletEntryTextareaLabel}>{'insert value for persistence'} </label>}
                </div>
                {showButtons && (
                    <div className={cls.renderBulletEntryAnnotationWrapper}>
                        <div
                            className={cls.renderBulletEntrySaveButton}
                            onClick={() => {
                                setShowButtons(false);
                                setEditMode(false);
                                onEditedItem(inputValue);
                            }}
                        >
                            <TaskBoard />
                            <p>{'SAVE'}</p>
                        </div>
                        <div
                            className={cls.renderBulletEntryCancelButton}
                            onClick={() => {
                                onDeleteItem(inputValue);
                                setShowButtons(false);
                                setEditMode(false);
                            }}
                        >
                            <CloseIcon2 />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export const RenderListFramedEntry = (props) => {
    const { id, readOnly, useContext, customFormatStringArray, onChange } = props;
    const [editState, setEditState] = useContext();
    const [renderedValues, setRenderedValues] = useState();
    const settingRenderedValues = vals => setRenderedValues(vals);

    useEffect(() => {
        settingRenderedValues(customFormatStringArray?.split('~')?.filter(i => i?.trim()?.length > 0));
    }, [customFormatStringArray]);

    const reloadDatas = () => {
        setEditState({ open: true, activeElement: '' });
        setTimeout(() => setEditState({ open: true, activeElement: id }), 0);
    };
    const onEditedItem = useCallback(
        (editedItem, index) => {
            onChange && onChange([...renderedValues.slice(0, index), editedItem, ...renderedValues.slice(index + 1)]?.join('~'));
            reloadDatas();
        },
        [renderedValues, onChange]
    );

    const onDeleteItem = useCallback(
        (index) => {
            onChange && onChange([...renderedValues.slice(0, index), ...renderedValues.slice(index + 1)]?.join('~'));
            reloadDatas();
        },
        [renderedValues, onChange]
    );

    return editState.activeElement === id && !readOnly ? (
        <div className={cls.renderListFramedEntryActiveWrapper}>
            <OLContext key={id}>
                {renderedValues?.length > 0 ? (
                    <div className={cls.renderListFramedEntryActiveItem}>
                        {renderedValues.map((item, index) => {
                            return <RenderBulletEntry key={index} id={item.toString()} index={index}
                                unfocused={editState.activeElement == id && !readOnly} value={item}
                                onDeleteItem={() => onDeleteItem(index)}
                                onEditedItem={editedItem => onEditedItem(editedItem, index)} />;
                        })}
                    </div>
                ) : null}
            </OLContext>
            <div className={cls.renderListFramedEntryRenderAddBulletFieldWrapper}>
                <RenderAddBulletField previousValues={customFormatStringArray} onChange={newValue => onChange && onChange(newValue)} />
            </div>
        </div>
    ) : (
        <div className={cls.renderListFramedEntryInactiveWrapper} style={{ height: renderedValues?.length > 0 ? '100%' : '56px' }}>
            {renderedValues?.length > 0 ? (
                <div className={cls.renderListFramedEntryInactiveItemContainer}>
                    {renderedValues?.map((item, index) => (
                        <label key={index} className={cls.renderListFramedEntryInactiveItem}>
                            {index + 1}
                            {'. '}
                            {item}
                        </label>
                    ))}
                </div>
            ) : null}
        </div>
    );
};
const ButtonWithTooltipRender = (props) => {
    const { onClick, disabled, tooltipText, title } = props;
    const tooltipDivRef = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const onButtonClick = useCallback(() => {
        if (disabled) {
            setShowTooltip(true);
            ReactTooltip.show(tooltipDivRef?.current);
        } else {
            if (onClick) {
                onClick();
            }
        }
    }, [disabled]);

    return (
        <div
            className={cls.buttonWithTooltipRenderWrapper}
            onMouseLeave={() => {
                setShowTooltip(false);
                ReactTooltip.hide(tooltipDivRef?.current);
            }}
        >
            <div className={cls.buttonWithTooltipRenderRemoveButton}
                ref={tooltipDivRef} onClick={onButtonClick} data-tip={tooltipText} id="tooltip">
                <DeleteBlackIco className={cls.buttonWithTooltipRenderRemoveButtonIcon} />
                <p className={cls.buttonWithTooltipRenderParagraph}>{title}</p>
            </div>
            {showTooltip &&
                <ReactTooltip multiline effect={'float'} type={'dark'}
                    clickable={true} textColor={'var(--app-color-gray-dark)'} backgroundColor={'#ffff'}
                    borderColor={'var(--app-color-gray-dark)'} className={cls.tooltip} place={'top'} />}
        </div>
    );
};

export const RenderPositionsPeople = (props) => {
    const {
        usersOnPosition, usersToAddOnPosition,
        onAddUserOnPosition, onRemoveUserFromPosition, setIsShareOpen, setShareUserPosition, canEditPosition, loadingChangePosition
    } = props;
    const [peopleInThisPosition, setPeopleInThisPosition] = useState([]);
    const [peopleToAdd, setPeopleToAdd] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState();
    const [showAddEmployee, setShowAddEmployee] = useState(false);
    const [selectedToAdd, setSelectedToAdd] = useState({});
    const [message, setMessage] = useState('');
    const [showRemoveUserPosition, setShowRemoveUserPosition] = useState(false);
    const settingPeopleToAdd = val => setPeopleToAdd(val);
    const settingPeopleInThisPosition = val => setPeopleInThisPosition(val);

    useEffect(() => {
        const employees = usersToAddOnPosition?.map((i, _idx) => ({
            label: i.first_name + ' ' + i.last_name + ' ',
            sublabel: i.positions.flatMap((position, id) => position.name + (id === i.positions.length - 1 ? "" : ", ")),
            value: i?.profile_id,
        }));

        settingPeopleToAdd(employees?.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0)));
    }, [usersToAddOnPosition]);

    useEffect(() => {
        settingPeopleInThisPosition(chunk(usersOnPosition?.sort(compareObjects), 4));
    }, [usersOnPosition]);

    let compareObjects = (a, b) => {
        if (a.first_name < b.first_name) {
            return -1;
        }
        if (a.first_name > b.first_name) {
            return 1;
        }
        if (a.last_name < b.last_name) {
            return -1;
        }
        if (a.last_name > b.last_name) {
            return 1;
        }

        return a.age - b.age;
    };

    const onSelectPerson = person => (setSelectedEmployee(person), setShareUserPosition(person));
    const onAddUserOnThisPosition = useCallback(() => {
        if (onAddUserOnPosition) {
            onAddUserOnPosition(selectedToAdd);
            setSelectedToAdd({});
        }
    }, [selectedToAdd, onAddUserOnPosition]);

    useEffect(() => {
        if(!loadingChangePosition){
            setShowAddEmployee(false);
        }
    }, [loadingChangePosition]);

    const onRemoveUserFromThisPosition = useCallback(() => {
        onRemoveUserFromPosition && onRemoveUserFromPosition(selectedEmployee);
        setSelectedEmployee();
        setShowRemoveUserPosition(false);
    }, [selectedEmployee, onRemoveUserFromPosition]);

    const DeletePeopleInPositionModal = (props) => {
        const { isOpen, onClose, loading } = props;

        return (
            <ModalR isOpen={isOpen}
                onClose={() => setShowRemoveUserPosition(false)} backdropColor={true} blured={true} scrollable={true}
                shadowedContainerStyle={{ width: '300px', height: '350px' }}>
                <div style={{ marginTop: '40px' }}>
                    <img alt={''} style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }} src={shredder} />
                    <p
                        style={{
                            textAlign: 'center',
                            fontSize: '18px',
                            fontWeight: '600',
                            fontFamily: 'var(--app-text-secondary-font)',
                            color: 'var(--alvanda-dark-purple)',
                        }}
                    >
                        {'Remove user in this position'}
                    </p>
                    <p
                        style={{
                            textAlign: 'center',
                            fontSize: '15px',
                            fontFamily: 'var(--app-text-secondary-font)',
                            color: 'var(--alvanda-dark-purple)',
                            marginBottom: 0,
                        }}
                    >
                        {'Are you sure you want to remove this user from this position?'}
                    </p>

                    <div style={{ display: 'flex', justifyContent: 'center', gap: '16px', marginTop: '32px' }}>
                        <Button loading={loading} clicked={onRemoveUserFromThisPosition} icon={<img alt="" src={deleteWhiteIcon} />}
                            title={'Remove User'} />
                        <Button loading={loading} lighter clicked={() => onClose(false)} title={'Don’t Remove User'}
                            icon={<img alt="" src={closeIconPurple} />} />
                    </div>
                </div>
            </ModalR>
        );
    };

    const getDisplayName = (item) => {
        const firstName = item?.first_name;
        const lastName = item?.last_name;
        const email = item?.email;

        // Check if both first and last name exist
        if (firstName && lastName) {
            return `${firstName.substring(0, 1).toUpperCase()}${lastName.substring(0, 1).toUpperCase()}`;
        }

        // Check if only first name exists
        if (firstName) {
            return firstName.substring(0, 1).toUpperCase();
        }

        // If no first or last name, fallback to email
        return email.substring(0, 2).toUpperCase() || '';
    };

    return (
        <UnfocusableWrapper
            onClickOutside={() => {
                console.log("onClickOutside");
                setMessage('');
                setShowAddEmployee(false);
                if (!showRemoveUserPosition) {
                    setSelectedEmployee();
                }
            }}
        >
            <div className={cls.renderPositionsPeopleActiveWrapper}>
                {peopleInThisPosition?.length > 0 && (
                    <div className={cls.renderPositionsPeopleActiveContainer}>
                        {peopleInThisPosition?.map((chunk, idx) => (
                            <div className={cls.renderPositionsPeopleActiveChunksContainer} key={idx}>
                                {chunk?.map((item, index) => (
                                    <div
                                        className={cls.renderPositionsPeopleActiveChunkWrapper}
                                        key={item?.first_name + index}
                                        onClick={() => {
                                            setMessage('');
                                            onSelectPerson(item);
                                        }}
                                    >
                                        {item.photo?
                                            <div className={cls.renderPositionsPeopleInactiveChunkContainer}>
                                                <img src={item.photo}/>
                                            </div> :
                                            <div className={cls.renderPositionsPeopleInactiveNameContainer}>
                                                <p>{getDisplayName(item)}</p>
                                            </div>
                                        }
                                        <p className={cls.renderPositionsPeopleActiveChunkItemParagraph}>
                                            {item?.first_name} {item?.last_name?.substring(0, 1)?.toUpperCase() + '.'}{' '}
                                        </p>
                                        {canEditPosition && <div>
                                            {selectedEmployee?.id?.indexOf(item?.id) > -1 && (
                                                <div className={cls.deleteButton} onClick={()=>setShowRemoveUserPosition(true)}>
                                                    <img src={deleteButtonIcon}/>
                                                </div>
                                            )}
                                        </div>}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                )}
                {showAddEmployee ? (
                    canEditPosition && <div className={cls.renderPositionsPeopleActiveAddEmployeeControls}>
                        <div className={cls.renderPositionsPeopleActiveAddEmployeeContainer}>
                            <div className={cls.renderPositionsPeopleActiveAddEmployeeMultiselectContainer}>
                                <MultiselectWithSearchR
                                    placeholder={'Search for employees...'}
                                    labelsTag
                                    tagCloseButtonType={'circleX' /* | "X" */}
                                    tagType={'shadowed' /* | "step-plated" | "plated" */}
                                    tagEmplacement={'top'}
                                    checkboxEmplacement={'left'}
                                    onChange={val => setSelectedToAdd(val)}
                                    items={peopleToAdd}
                                    className={cls.renderPositionsPeopleActiveAddEmployeeMultiSelectWithSearch}
                                    inputClassName={cls.renderPositionsPeopleActiveAddEmployeeMultiSelectWithSearchInput}
                                    menuOverlayListItemChildClassName={cls.renderPositionsPeopleActiveMenuOverlayListItem}
                                    molclassname={cls.renderPositionsPeopleActiveAddEmployeeMenuOverlay}
                                    moliclassname={cls.renderPositionsPeopleActiveAddEmployeeMenuItem}
                                />
                            </div>
                            <div className={cls.renderPositionsPeopleActiveAddEmployeeControls}>
                                <Button clicked={onAddUserOnThisPosition}
                                    loading={loadingChangePosition}
                                    disabled={loadingChangePosition}
                                    title={'Assign Colleagues To This Position'}
                                    icon={<img alt="" src={plusIcon}
                                        style={{ width: '15px', height: '15px' }} />}
                                />
                            </div>
                        </div>
                        <Button
                            clicked={() => {
                                if (selectedEmployee === undefined) {
                                    setMessage('You need to select a user to share to a position');
                                } else {
                                    setIsShareOpen();
                                }
                            }}
                            lighter
                            disabled={loadingChangePosition}
                            title={'Share with Other Positions'}
                            icon={<img alt="" src={shareIconPurple} />}
                            style={{ height: '32px', marginLeft: '20px' }}
                        />
                    </div>
                ) : (
                    canEditPosition && <div className={cls.renderPositionsPeopleActiveDefaultControlsContainer}>
                        <div className={cls.renderPositionsPeopleActiveDefaultControlsAnnotationWrapper}>
                            <Button
                                clicked={() => {
                                    setShowAddEmployee(true);
                                    setSelectedEmployee();
                                }}
                                lighter
                                title={'Assign Colleagues To This Position'}
                                icon={<img alt="" src={plusIconPurple} style={{ width: '15px', height: '15px' }} />}
                                style={{ height: '32px' }}
                            />
                        </div>
                        <Button
                            clicked={() => {
                                if (selectedEmployee === undefined) {
                                    setMessage('You need to select a user to share to a position');
                                } else {
                                    setIsShareOpen();
                                }
                            }}
                            lighter
                            disabled={loadingChangePosition}
                            title={'Share with Other Positions'}
                            icon={<img alt="" src={shareIconPurple} />}
                            style={{ height: '32px', marginRight: '40px' }}
                        />
                    </div>
                )}
                {message && (
                    <div className={cls.simpleContainerWrapperChildrenContainer}>
                        <MessageContainer message={message} />
                    </div>
                )}
                <DeletePeopleInPositionModal isOpen={showRemoveUserPosition} />
            </div>
        </UnfocusableWrapper>
    );
};
export const RenderSharedResourcesForPosition = (props) => {
    const { id, readOnly, useContext, sharedUsersOnPosition, usersToShareOnPosition, onUnshareUserFromPosition = () => { }, onUnshareUsersFromPosition = () => { } } = props;
    // const [editState] = useContext();
    const [sharedResources, setSharedResources] = useState({});



    useEffect(() => {
        const positions = [];

        sharedUsersOnPosition?.map((it) => {
            if (it?.positions?.length > 0) {
                it?.positions?.map((pos) => {
                    if (positions?.map(i => i.id).indexOf(pos.id) === -1) {
                        positions.push(pos);
                    }
                });
            } else {
                if (positions?.map(i => i.id).indexOf('000') === -1) {
                    positions.push({ id: '000', name: 'Unassigned' });
                }
            }
        });
        const jsonPos = {};

        positions?.map((i) => {
            if (i.name === 'Unassigned') {
                return (jsonPos[i.name] = [...(sharedUsersOnPosition?.filter(u => u?.positions?.length === 0) ?? [])]);
            } else {
                return (jsonPos[i.name] = [...(sharedUsersOnPosition?.filter(u => u?.positions?.map(e => e?.id).indexOf(i.id) > -1) ?? [])]);
            }
        });
        setSharedResources(jsonPos);
    }, [usersToShareOnPosition, sharedUsersOnPosition]);

    const renderSharedResourcesInGroupsOfFour = (items) => {
        const groupsOfFour = [];
        const fake = [...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items, ...items];

        // Group items into sets of four
        for (let i = 0; i < items.length; i += 4) {
            groupsOfFour.push(items.slice(i, i + 4));
        }

        return groupsOfFour.map((group, groupIndex) => (
            <div key={groupIndex} className={cls.groupWrapper}>
                {group.map((item, index) => (
                    <div
                        className={cls.renderSharedResourcesForPositionInactiveColumnItemWrapper}
                        key={item?.first_name + index}

                    >
                        {/* Your item content goes here */}
                        <div className={cls.renderSharedResourcesForPositionInactiveColumnMarkerContainer}>
                            <img src={item.photo} alt={""} />
                        </div>
                        <div className={cls.resourceContent}>
                            <p className={cls.renderSharedResourcesForPositionInactiveColumnItem}>
                                {item?.first_name} {item?.last_name?.substring(0, 1)?.toUpperCase() + '.'}
                            </p>
                            <img
                                onClick={() => { onUnshareUserFromPosition(item); }}
                                alt=""
                                src={deleteBinBlue}
                                style={{ width: '16px', height: '16px', marginLeft: '8px', display: 'none' }}
                            />
                        </div>
                    </div>
                ))}
            </div>
        ));
    };

    return (
        <>
            {Object.keys(sharedResources)?.length > 0 ? (
                <div className={cls.renderSharedResourcesForPositionInactiveWrapper}>
                    <div className={cls.outerWrapper}>
                        {Object.keys(sharedResources)?.map((key, idx) => (
                            <div key={key + idx} className={cls.renderSharedResourcesForPositionInactiveColumn}>
                                <div className={cls.renderSharedResourceTitle}>
                                    <p className={cls.renderSharedResourcesForPositionInactiveColumnTitle}>{key}</p>
                                    <img
                                        onClick={() => { onUnshareUsersFromPosition(sharedResources[key]); }}
                                        alt="" src={deleteBinBlue} style={{ cursor: 'pointer', width: '22px', height: '24px', display: 'none' }} />
                                </div>
                                <div className={cls.overflowWrapper}>
                                    {renderSharedResourcesInGroupsOfFour(sharedResources[key])}
                                </div>
                            </div>

                        ))}
                    </div>
                </div>
            ) : (
                <div className={cls.renderSharedResourcesForPositionEmptyContainer}>
                    <div />
                    <p>{'No shared resource'}</p>
                </div>
            )}
        </>
    );
};
export const SimpleContainerWrapper = (props) => {
    const { title, columned } = props;

    return (
        <div className={cls.simpleContainerWrapperHost}>
            <div className={cls.simpleContainerWrapperTitleContainer}>
                <p className={cls.simpleContainerWrapperTitleText}>{title}</p>
            </div>
            <div className={cls.simpleContainerWrapperChildrenContainer} app-variant-columned={columned ? 'true' : 'false'}>
                {props.children}
            </div>
        </div>
    );
};
export const RenderBooleansOptions = (props) => {
    const { selected, setSelected, first, second, extraClassName } = props;

    return (
        <div className={classNames(cls.renderBooleansOptions, extraClassName)}>
            <div cont-variant={selected ? 'selected' : 'unselected'} onClick={() => setSelected(true)}>
                <p>{first}</p>
                {selected && <DoneMarkIconDark />}
            </div>
            <div cont-variant={!selected ? 'selected' : 'unselected'} onClick={() => setSelected(false)}>
                <p>{second}</p>
                {!selected && <DoneMarkIconDark />}
            </div>
        </div>
    );
};

export const RenderCheckboxOptions = (props) => {
    const { selected, setSelected, first, second, checkboxHost } = props;

    return (
        <div className={checkboxHost}>
            <div>
                <CustomCheckbox
                    label={first}
                    onChange={(checked) => {
                        setSelected(checked);
                    }}
                    defaultChecked={selected}
                    labelStyle={{ fontWeight: 300, fontSize: '16px', display: 'flex', alignItems: 'center' }}
                    mainContainerStyle={{ margin: '0 0 0 0' }}
                    checkMarkStyle={{ width: '16px', height: '16px', borderRadius: '50%' }}
                    checkIconStyle={{ width: '11px', height: '8px' }}
                />
            </div>
            <div>
                <CustomCheckbox
                    label={second}
                    onChange={(checked) => {
                        setSelected(!checked);
                    }}
                    defaultChecked={!selected}
                    labelStyle={{ fontWeight: 300, fontSize: '16px', display: 'flex', alignItems: 'center' }}
                    mainContainerStyle={{ margin: '0 0 0 0' }}
                    checkMarkStyle={{ width: '16px', height: '16px', borderRadius: '50%' }}
                    checkIconStyle={{ width: '11px', height: '8px' }}
                />
            </div>
        </div>
    );
};

export const ContainerWrapperWithValidation = memo((props) => {
    const { title, tooltipText, withAckButton, ungrouped, bordered, onAckPress, columned, style, required, hasError } = props;

    return (
        <div
            className={cls.cw_withValidation}
            app-variant-ungrouped={ungrouped ? 'ungrouped' : null}
            app-variant-bordered={bordered ? 'bordered' : null}
            app-variant-columned={columned ? 'column' : null}
            app-variant-with-ack-button={withAckButton ? 'withAckButton' : null}
            app-variant-has-error={hasError ? 'error' : null}
            style={{ ...style }}
        >
            <div className={cls.header}>
                <RenderTitle title={title} tooltipText={tooltipText} required={required} hasError={hasError} />
                {withAckButton && (
                    <div onClick={onAckPress} className={cls.acknowledgeContainer}>
                        <CheckedStyle className={cls.acknowledgeIcon} />
                    </div>
                )}
            </div>
            <div className={cls.childrenContainer}>{props.children}</div>
        </div>
    );
});
