import React from 'react';
import ModalR from '../UI/Modal/ModalR';
import Button from '../UI/Button/Button';
import { closeIconPurple, shredder, closeIcon, deleteWhiteIcon } from '../../../assets/images/new-icons';
import cls from './DeleteModal.module.scss';

const DeleteModal = (props) => {
    const {isOpen, onClose, loading, removefield, fieldId = '', fieldName, forComment} = props;

    return (
        <ModalR isOpen={isOpen} onClose={() => onClose(false)} backdropColor={true} blured={true} scrollable={true} shadowedContainerStyle={{ width: '536px', height: '420px' }}>
            <div className={cls.content}>
                <div className={cls.closeIcon}>
                    <div onClick={() => onClose(false)} style={{ cursor: 'pointer', backgroundColor: 'transparent', border: 0 }}>
                        <img alt="" src={closeIcon} />
                    </div>
                </div>
                <img alt={''} className={cls.shredderImg} src={shredder} />
                <p className={cls.deleteHeading}> {`Delete ${fieldName}`} </p>
                <p className={cls.deleteMessage} style={forComment? {width: "80%", margin: "auto"}: {}}>{`Are you sure you want to delete this ${fieldName}?`}</p>
                {!forComment && <p className={cls.message}> {'This action is irreversible.'}</p>}
                <div className={cls.deleteButton}>
                    <Button lighter clicked={() => onClose(false)} title={`Don't Delete ${forComment ? "" : fieldName}`} icon={<img alt="" src={closeIconPurple} />} style={forComment? {width: "190px", height: "56px"}: {}}/>
                    <Button loading={loading} clicked={() => removefield(fieldId)} icon={<img alt="" src={deleteWhiteIcon} />}title={`Delete ${fieldName}`} style={forComment? {height: "56px"}: {}}/>
                </div>
            </div>
        </ModalR>
    );
};

export default DeleteModal;