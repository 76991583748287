import {useDispatch, useSelector} from 'react-redux';
import {
    setOnBoardingModalsVisibility,
} from '../../../reduxStore/actions/modalsActions';
import './OnBoardingModals.scss';
import PreviewOrganisationalChart from './XII_PreviewOrganisationalChart';

const OnboardingModals = (props) => {
    const dispatch = useDispatch();
    const {onClose} = props;

    const {onBoardingModalsVisibility} = useSelector(store => store.modalsReducer);

    const {
        twelvthModalVisible,
    } = onBoardingModalsVisibility;

    const toggleNoModal = () => {
        dispatch(setOnBoardingModalsVisibility({}));
    };
    const onCancel = () => {
        onClose();
    };

    return (
        <>
            {/* TwelvthModal Modal Preview Organizational Chart */}
            {twelvthModalVisible && (
                <PreviewOrganisationalChart
                    hasTutorial
                    modalVisible={twelvthModalVisible}
                    closeButtonAction={onCancel}
                    modalWrapperStyle={{width: '100%', height: '100%'}}
                    shadowedContainerStyle={{
                        margin: '20% 10% 20% 10%',
                        minWidth: '1110px',
                        minHeight: '500px',
                        maxHeight: '90vh',
                    }}
                    childrenWrapperStyle={{margin: '0 2% 0 10%', minHeight: '725px', maxHeight: '90%'}}
                    onCancel={() => {
                        toggleNoModal();
                    }}
                />
            )}
        </>
    );
};

export default OnboardingModals;
