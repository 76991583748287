import cls from './MessageContainer.module.scss';
import classNames from 'classnames';
import {errorInfo} from "../../../../assets/images/new-icons";

const MessageContainer = (props) => {
    const { message, extraClassName, style, hideErrorIcon } = props;

    return (
        <div style={style}>
            <p className={classNames(cls.errorMessage, extraClassName)}>
                {!hideErrorIcon && (
                    <span style={{ verticalAlign: 'middle', marginRight: '4px' }}>
                        <img alt="" src={errorInfo} />
                    </span>
                )}
                {message}
            </p>
        </div>
    );
};


export default MessageContainer;
