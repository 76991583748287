import { Observable } from 'rxjs';
import { store } from '../reduxStore/store';
import { URLS } from './API_CONSTANTS';
import { axiosInstance } from './axiosInstance';

export const shareFeedbackOrAskHelp = (supportData) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = new FormData();

    data.append('type', supportData.type);
    data.append('description', supportData.description);
    if(supportData.screenshot) data.append('attachment', supportData.screenshot);
    if(supportData.hear_back) data.append('hear_back', "1");
    if(supportData.task_id) data.append('task_id', supportData.task_id);
    if(supportData.procedure_step_id) data.append('procedure_step_id', supportData.procedure_step_id);

    const config = {
        ...URLS.support.shareFeedbackOrAskHelp(last_access_token),
        data: data,
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data && response?.data?.data) {
                    const support = response?.data?.data;

                    subscriber.next(support);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const getSupportRequestById = (supportId) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const config = {
        ...URLS.support.getSupportRequestById(last_access_token, supportId),
        data: supportId,
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data && response?.data?.data) {
                    const support = response?.data?.data;

                    subscriber.next(support);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error.data);
            })
    );
};
