import 'rc-datetime-picker/dist/picker.css';
import {Button, ModalR} from '../index';
import {closeIconPurple, tickCirclePurple, warningIcon} from '../../../assets/images/new-icons';
import cls from './ConfirmationModal.module.scss';

const ConfirmationModal = ({resultsMessage, resultsDescription, isYes, isNo, isOpen, greenText='Yes', redText='No'}) => {
    return (
        <ModalR isOpen={isOpen}
            shadowedContainerClassname={cls.modal} scrollable={true} blured={true}>
            <div className={cls.content}>
                <img className={cls.warningIcon} alt={''} src={warningIcon}/>
                <p className={cls.resultMessage}>{resultsMessage}</p>
                <p className={cls.warningDetail}>{resultsDescription}</p>
                <div style={{display: "flex", flexDirection: "row", gap: "10px"}}>
                  <Button clicked={isYes} lighter title={greenText}
                    icon={<img alt="" src={tickCirclePurple}/>} className={cls.closeButton}/>
                  <Button clicked={isNo} lighter title={redText}
                    icon={<img alt="" src={closeIconPurple}/>} className={cls.closeButton}/>
                </div>
            </div>
        </ModalR>
    );
};

export default ConfirmationModal;
