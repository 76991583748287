import React from 'react';
import classNames from 'classnames';
import {useCombobox, useMultipleSelection} from 'downshift';
import {ComponentProps, forwardRef, useEffect, useState} from 'react';
import {Checked, Chevron, CloseXCircle, DropDownArrow} from '../../../../assets/images/icons';
import cls from './MultiSelectWithCheckboxesAndNestedItems.module.scss';
import {SearchIconGrey} from "../../../../assets/images/new-icons";

const R = require('ramda');

type SimpleItem = { value: string; label: string; icon?: string };
type ComplexItem = SimpleItem[];
export type SelectWithCheckboxesAndNestedItemsProps = {
    /** list of items to chose from */
    items: SimpleItem[];
    groupedBy?: keyof SimpleItem;
    /**displayed as label above Control */
    title?: string;
    placeholder?: string;
    /** sets an id to the related elements as required by aria listbox rules. Falls back to `title` if none is provided */
    id?: string;
    /** adds a "*"-Indicator to the end of the title that turns red in case of an error*/
    hasMandatoryIndicator?: boolean;
    /** id's of the items that need to be selected initially*/
    initialSelectedValues: SimpleItem[];
    errorMessage?: string;
    onChange?: (selectedItem?: SimpleItem) => void;
    onInputChange?: (e?: string) => void;
    noTags?: boolean;
    inputValue: string;
    triggerType?: 'chevron' | 'downArrow' | 'eyeglass' | 'eyeglassGrey';
    /** prop to pass className to Input */
    inputClassName?: string;
    /** prop to pass className to Input */
    inputContainerClassName: string;
    /** prop to pass className to MenuOverlayList */
    molclassname?: string;
    /** prop to pass className to MenuOverlayListItem */
    moliclassname?: string;
    inputStyle: Record<string, unknown>;
    menuOverlayStyle: Record<string, unknown>;
    menuOverlayListItemStyle: Record<string, unknown>;
} & ComponentProps<'div'>;
const groupBy = (propName: string, array: SimpleItem[]): ComplexItem => R.valuesIn(R.groupBy(R.prop(propName))(R.sortWith([R.ascend(R.prop(propName))])(array)));

const SelectWithCheckboxesAndNestedItems = (props: SelectWithCheckboxesAndNestedItemsProps) => {
    const {
        title,
        hasMandatoryIndicator,
        id = title?.replace(/ /g, '-'),
        triggerType,
        items,
        groupedBy,
        initialSelectedValues,
        onChange,
        onInputChange,
        errorMessage,
        placeholder,
        inputValue,
        children,
        className,
        inputClassName,
        molclassname,
        moliclassname,
        inputStyle,
        inputContainerClassName,
        menuOverlayStyle,
        menuOverlayListItemStyle,
        noTags,
        ...rest
    } = props;
    const [searchFilteredItems, setSearchFilteredItems] = useState(items);
    const [grouped] = useState(groupBy(groupedBy ?? '', items));
    const {
        getSelectedItemProps,
        getDropdownProps,
        addSelectedItem,
        removeSelectedItem,
        selectedItems,
        setSelectedItems,
    } = useMultipleSelection<SimpleItem>({
        initialSelectedItems: items?.filter(i => initialSelectedValues?.filter(e => e?.value == i?.value)?.length > 0),
    });

    useEffect(() => {
        onChange?.(selectedItems[0]);
    }, [selectedItems]);

    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        openMenu,
        selectItem
    } = useCombobox<SimpleItem | null>({
        items: searchFilteredItems,
        id,

        stateReducer: (state, actionAndChanges) => {
            const {type, changes} = actionAndChanges;

            switch (type) {
                //clear the input when clicking outside or toggle button
                case useCombobox.stateChangeTypes.ToggleButtonClick:
                case useCombobox.stateChangeTypes.InputBlur:
                    return {
                        ...changes,
                        //inputValue: '',
                    };
                case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick: {
                    if (state?.highlightedIndex > -1) {
                        setSelectedItems([items[state?.highlightedIndex]]);
                    }

                    return {
                        ...state,
                        isOpen: false, //keep menu open after selection
                        highlightedIndex: state.highlightedIndex,
                    };
                }
            }

            return changes;
        },
        onStateChange: ({type, selectedItem}) => {
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    if (selectedItem) {
                        if (selectedItems.includes(selectedItem)) {
                            removeSelectedItem(selectedItem);
                        } else {
                            addSelectedItem(selectedItem);
                        }
                        selectItem(null);
                    }
                    break;
                default:
                    break;
            }
        },
        onInputValueChange: ({inputValue}) => {
            setSearchFilteredItems(inputValue ? items?.filter(item => item?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())) : items);
        },
    });

    return (
        <div className={classNames(cls.host, className)} {...rest}>
            <Label {...getLabelProps()} hasMandatoryIndicator={hasMandatoryIndicator}>
                {title}
            </Label>
            {!noTags && (
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    {selectedItems.map((selectedItem, index) => (
                        <Tag
                            hasCloseButton
                            key={`selected-item${index}`}
                            {...getSelectedItemProps({selectedItem, index})}
                            onClick={() => {
                                removeSelectedItem(selectedItem);
                            }}
                        >
                            {selectedItem?.icon && <img alt="item-icon" className={cls.itemLeftIcon} src={selectedItem?.icon}/>}
                            {selectedItem.label}
                        </Tag>
                    ))}
                </div>
            )}
            <InputContainer {...getComboboxProps()}
                            triggerType={triggerType} className={inputContainerClassName} isActive={isOpen} hasError={errorMessage != null}>
                <Input
                    {...getInputProps({
                        value: inputValue ? inputValue : selectedItems.length > 0 ? selectedItems[0]?.label : initialSelectedValues.length > 0 ? initialSelectedValues[0].label : '',
                        placeholder,
                        'aria-invalid': errorMessage != null || undefined,
                        onFocus: () => {
                            if (!isOpen) {
                                openMenu();
                            }
                        },
                        onChange: (e) => {
                            onInputChange?.(e.target.value);
                        },
                    })}
                    className={inputClassName}
                    style={inputStyle}
                />
                <ToggleMenuInputButton {...getToggleButtonProps({...getDropdownProps({preventKeyAction: isOpen})})} isOpen={isOpen}
                                       triggerType={triggerType}>
                    {children}
                </ToggleMenuInputButton>
            </InputContainer>
            <MenuOverlayList {...getMenuProps()} className={molclassname} style={menuOverlayStyle}>
                {isOpen &&
                    grouped.map((its: any, idx: number) => {
                        return (
                            <CollapsibleHeader
                                isVisible={its.filter((item: SimpleItem) => searchFilteredItems.includes(item))?.length > 0}
                                searchFilteredItems={searchFilteredItems}
                                label={its?.[0]?.department_name ?? 'No Department'}
                                items={its}
                                isChecked={its.some(it => selectedItems.some(item => item.value === it.value))}
                                selectSource={items}
                                moliclassname={moliclassname}
                                style={menuOverlayListItemStyle}
                                key={`${its?.length}${idx}`}
                                highlightedIndex={highlightedIndex}
                                selectedItems={selectedItems}
                                getItemProps={getItemProps}
                            />
                        );
                    })}
            </MenuOverlayList>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
    );
};

export type LabelProps = { hasMandatoryIndicator?: boolean } & ComponentProps<'label'>;
export const Label = (props: LabelProps) => {
    const {hasMandatoryIndicator, children, className, style, ...rest} = props;

    return (
        <label className={classNames(cls.customLabel, className)} style={style} {...rest}>
            {children}
            {hasMandatoryIndicator && <span style={{color: 'var(--app-color-error)'}}>*</span>}
        </label>
    );
};
export type TagProps = { hasCloseButton?: boolean } & ComponentProps<'div'>;
export const Tag = forwardRef<HTMLDivElement, TagProps>((props, ref) => {
    const {children, style = {}, className = '', hasCloseButton, ...rest} = props;

    return (
        <div ref={ref} tabIndex={hasCloseButton ? 0 : undefined} className={classNames(cls.hostCustomTagDefault, className)}
             style={style} {...rest}>
            {children}
            {hasCloseButton && <CloseXCircle className={cls.customTagCloseIcon}/>}
        </div>
    );
});
export const ErrorMessage = (props: ComponentProps<'p'>) => {
    return <p className={cls.customErrorMessage} {...props} />;
};
export type ToggleMenuInputButtonProps =
    { isOpen: boolean; triggerType?: 'chevron' | 'downArrow' | 'eyeglassGrey' }
    & ComponentProps<'button'>;
export const ToggleMenuInputButton = forwardRef<HTMLButtonElement, ToggleMenuInputButtonProps>((props, ref) => {
    const {children, isOpen, className, triggerType, ...rest} = props;

    return (
        <>
            {triggerType ? (
                <button ref={ref} type="button" aria-label="toggle-menu" className={classNames(cls.customToggleMenuInputButton, className)}
                        app-variant={isOpen ? 'open' : 'closed'} app-icon={triggerType ? triggerType : ''} {...rest}>
                    {children}
                    {triggerType === 'eyeglassGrey' && <SearchIconGrey className={cls.customToggleMenuInputButtonIcon}/>}
                    {triggerType === 'chevron' && <Chevron className={cls.customToggleMenuInputButtonIcon}/>}
                    {triggerType === 'downArrow' && <DropDownArrow className={cls.customToggleMenuInputButtonIcon}/>}
                </button>
            ) : (
                children
            )}
        </>
    );
});
export const InputContainer = forwardRef<HTMLDivElement, {
    triggerType?: string;
    isActive: boolean;
    hasError: boolean
} & ComponentProps<'div'>>((props, ref) => {
    const {isActive, hasError, className, style, triggerType, ...rest} = props;

    return <div ref={ref} {...rest} className={classNames(cls.customInputContainer, className)} style={{...style}}
                app-trigger-type={triggerType} app-state={isActive ? 'active' : hasError ? 'error' : ''}/>;
});
export const Input = forwardRef<HTMLInputElement, ComponentProps<'input'>>((props, ref) => {
    const {className, ...rest} = props;

    return <input ref={ref} className={classNames(cls.customInput, className)} {...rest} />;
});
export const MenuOverlayList = forwardRef<HTMLUListElement, ComponentProps<'ul'>>((props, ref) => {
    const {className, style, ...rest} = props;

    return <ul ref={ref} {...rest} className={classNames(cls.customMenuOverlayList, className)} style={{...style}}/>;
});
export type MenuOverlayListItemProps = {
    isVisible: boolean;
    isHighlighted?: boolean;
    isSelected?: boolean;
} & ComponentProps<'li'>;
export const MenuOverlayListItem = forwardRef<HTMLLIElement, MenuOverlayListItemProps>((props, ref) => {
    const {itemType, children, className, isSelected, isHighlighted, isVisible, style, ...rest} = props;

    if (!isVisible) return null;

    return (
        <li ref={ref} className={classNames(cls.customMenuOverlayListItem, className)}
            app-variant={isSelected ? 'selected' : isHighlighted ? 'highlighted' : ''}
            app-item-type={itemType ? itemType : ''}
            app-item-display={itemType ? itemType : ''} style={style} {...rest}>
            <span className={cls.customMenuOverlayListItemChild}>{children}</span>
            <span>{isSelected && <Checked className={cls.customMenuOverlayListItemNormalCheckedMark}/>}</span>
        </li>
    );
});

const CollapsibleHeader = (props: SimpleItem & any) => {
    const {
        label,
        items,
        selectSource,
        isVisible,
        isChecked,
        searchFilteredItems,
        moliclassname,
        menuOverlayListItemStyle,
        highlightedIndex,
        selectedItems,
        getItemProps,
    } = props;
    const [open, setOpen] = useState(isChecked);

    return (
        <div className={cls.collapsibleHeaderContainer} app-variant={open ? 'open' : 'closed'} app-display={isVisible ? 'flex' : 'none'}>
            <button
                app-variant={isChecked ? 'selected' : ''}
                className={cls.collapsibleSingleHeaderButton}
                onClick={() => {
                    setOpen(!open);
                }}
            >
                <span className={cls.collapsibleHeaderText}>{label}</span>
                <Chevron className={cls.collapsibleHeaderChevron}/>
            </button>
            {open &&
                items?.map((item: any) => (
                    <MenuOverlayListItem
                        itemType={'normal'}
                        isVisible={items.filter((tItem: SimpleItem) => searchFilteredItems.includes(tItem))?.length > 0}
                        className={moliclassname}
                        style={menuOverlayListItemStyle}
                        key={`${item?.value}${selectSource?.indexOf(item)}`}
                        isHighlighted={highlightedIndex === selectSource?.indexOf(item)}
                        isSelected={selectedItems.includes(item)}
                        {...getItemProps({item: item, index: selectSource?.indexOf(item)})}
                    >
                        <div style={{marginRight: '25px'}}>{item.icon}</div>
                        {item.label}
                    </MenuOverlayListItem>
                ))}
        </div>
    );
};

export default SelectWithCheckboxesAndNestedItems;
