import { memo, useCallback, useEffect, useRef, useState } from 'react';
import ResizeObserver from 'react-resize-observer';
import { useNavigate } from 'react-router-dom';
import './chartStyles.scss';
import II_Managerials from './II_Managerials';
import II_NonManagerials from './II_NonManagerials';
import cls from './I_Managerials.module.scss';
import { useSelector } from 'react-redux';

const I_Managerials = memo((props) => {
    const {chartPreview} = useSelector(store => store.modalsReducer);
    const navigate = useNavigate();
    const mainCont_I_Ref = useRef();
    const labelCont_I_Ref = useRef();
    const chCont_I_Ref = useRef();
    const { children, name, sub_name, id, childrenLength,
        usersLength, verticalOffset, onContainerDimsChange, centerOfParent,
        directUnderGeneralManager, lastElement, lastElementCenter, firstElement, firstElementCenter, onlyItem,childNumber, ...rest } = props;
    const [managerialCh, setManagerialCh] = useState([...(children?.filter(i => [1, true].indexOf(i?.is_managerial) > -1) ?? [])]);
    const [nonManagerialCh, setNonManagerialCh] = useState([...(children?.filter(i => [0, false].indexOf(i?.is_managerial) > -1) ?? [])]);
    const [manContainersDim, setManContainersDim] = useState([]);
    const [aContainerDim, setAContainerDim] = useState({ colWidth: 0, colHeight: 0, left: 0 });

    useEffect(() => {
        if (onContainerDimsChange) {
            onContainerDimsChange({
                width: mainCont_I_Ref.current?.clientWidth,
                height:
					Math.max(
					    Math.max.apply(
					        Math,
					        manContainersDim.map(i => +i.height)
					    ) + 150,
					    +mainCont_I_Ref.current?.clientHeight
					) ?? mainCont_I_Ref.current?.clientHeight,
                offsetLeft: mainCont_I_Ref.current?.offsetLeft,
            });
        }
        if (lastElement && lastElementCenter) {
            lastElementCenter(+mainCont_I_Ref.current?.clientWidth / 2 + 50 + 20 + 5 + 15);
        }
        if (firstElement && firstElementCenter) {
            firstElementCenter(+mainCont_I_Ref.current?.clientWidth / 2 + 50 + 5 + 15);
        }
    }, [aContainerDim, manContainersDim]);

    const settingState = (type, value) => {
        switch (type) {
            case 'setManagerialCh':
                setManagerialCh(value);
                break;
            case 'setNonManagerialCh':
                setNonManagerialCh(value);
                break;
            default:
                return;
        }
    };

    useEffect(() => {
        const managerials = [...(children?.filter(i => [1, true].indexOf(i.is_managerial) > -1) ?? [])];
        const nonManagerials = [...(children?.filter(i => [0, false].indexOf(i.is_managerial) > -1) ?? [])];

        settingState('setManagerialCh', managerials);
        settingState('setNonManagerialCh', nonManagerials);
        if (onContainerDimsChange) {
            onContainerDimsChange({
                width: mainCont_I_Ref.current?.clientWidth,
                height: mainCont_I_Ref.current?.clientHeight,
                offsetLeft: mainCont_I_Ref.current?.offsetLeft,
            });
        }
    }, [children]);
    const onPositionPressed = (positionId) => {
        !chartPreview && navigate({ pathname: `/role/${positionId}`});
    };
    const findType = useCallback(() => {
        if (onlyItem) return 'center';
        if (childNumber === childrenLength -1) return 'right';
        if (childNumber === 0) return 'left';
        else return 'center'
    }, [centerOfParent, aContainerDim, onlyItem]);
    const detChildPos = useCallback(
        (idx) => {
            let total = nonManagerialCh.length + managerialCh.length;

            if (total >=3){
                if (idx === 0 && nonManagerialCh.length === 0) return 'left'
                if (idx === managerialCh.length - 1) return 'right'
                if (idx === 0 && managerialCh.length > 1 && nonManagerialCh.length > 0) return 'left'
                else return 'center'
            }
            if (total === 1) return 'center'
            if (idx === 0 && nonManagerialCh.length === 0) return 'left';
            else return 'right';
        },
        [nonManagerialCh, managerialCh]
    );

    return (
        <div className={cls.host} {...rest} app-i-item-position={findType()}
            app-i-only-item={onlyItem ? 'true' : 'false'} style={{ minHeight: `${verticalOffset}px` }} ref={mainCont_I_Ref}>
            <ResizeObserver
                onResize={(rect) => {
                    setAContainerDim({ colWidth: rect.width, colHeight: rect.height, left: rect.left });
                }}
            />
            <div className={cls.target}>
                <div
                    ref={labelCont_I_Ref}
                    className={cls.cardContainerBase}
                    onClick={() => {
                        onPositionPressed(id);
                    }}
                    app-org-chart-preview={chartPreview === true ? 'true' : 'false'}
                >
                    <p>
                        <span className={cls.companyInfoTitle}>{name}</span>
                        <span>{sub_name}</span>
                    </p>
                </div>
            </div>
            <div
                id={'II_Manag_ChildrenContRef'}
                ref={chCont_I_Ref}
                className={cls.childrenContainer}
                app-both-children-types={nonManagerialCh?.length > 0 && managerialCh?.length > 0 ? 'true' : 'false'}
                app-managerials-only={nonManagerialCh?.length == 0 && managerialCh?.length > 1 ? 'true' : 'false'}
                app-non-managerials-only={nonManagerialCh?.length > 0 && managerialCh?.length == 0 ? 'true' : 'false'}
                app-only-one-managerial={nonManagerialCh?.length == 0 && managerialCh?.length == 1 ? 'true' : 'false'}
            >
                {nonManagerialCh?.length > 0 && (
                    <II_NonManagerials
                        nmPositions={{ children: nonManagerialCh }}
                        onlyNMPos={managerialCh?.length == 0}
                        showPositionInfo={(positionId) => {navigate({ pathname: `/role/${positionId}`});}}
                        parentsDims={{
                            width: labelCont_I_Ref?.current?.clientWidth,
                            height: labelCont_I_Ref?.current?.clientHeight,
                            offsetLeft: labelCont_I_Ref?.current?.offsetLeft,
                            offsetTop: labelCont_I_Ref?.current?.offsetTop,
                        }}
                        onContainerDimsChange={({ width, height }) => {
                            setManContainersDim(c => [...c.filter(el => el.id != 'iinmn'), { id: 'iinmn', height: height + 70, width: width }]);
                        }}
                    />
                )}
                <div className={cls.II_Managerials_Children_Container}
                        app-both-siblings-types={nonManagerialCh?.length > 0 && managerialCh?.length > 1 ? 'true' : 'false'}
                    >
                    {managerialCh?.length > 0 &&
                        managerialCh?.map((i, idx) => {
                            return (
                                <II_Managerials
                                    key={name}
                                    data={i}
                                    render_index={idx}
                                    onContMan_II_ChildChange={({ width, height, offsetLeft }) => {
                                                                        setManContainersDim(c => [...c.filter(el => el.id != i.id && managerialCh.map(m => m.id).indexOf(i.id) > -1), { id: i.id, height: height, width: width, offsetLeft: offsetLeft }]);
                                                                    }}
                                    showPositionInfo={(positionId) => {
                                                                        !chartPreview && navigate({ pathname: `/role/${positionId}`});
                                                                    }}
                                                                    // centerOfParent={Number(+labelCont_I_Ref?.current?.offsetLeft) ? +labelCont_I_Ref?.current?.offsetLeft : 0}
                                    positioning={detChildPos(idx)}
                                    lastElement={idx == managerialCh?.length - 1}
                                    lastElementCenter={(lastElem) => {
                                                                        chCont_I_Ref.current.style.setProperty('--app-i-right-offset', `${lastElem}px`);
                                                                    }}
                                    firstElement={idx == 0}
                                    firstElementCenter={(lastElem) => {
                                        chCont_I_Ref.current.style.setProperty('--app-i-left-offset', `${lastElem}px`);
                                    }}
                                />
                            );
                        })
                    }
                </div>
            </div>
            <div style={{ flex: 1 }} />
        </div>
    );
});

export default I_Managerials;
