import { Observable } from 'rxjs';
import { store } from '../reduxStore/store';
import { URLS } from './API_CONSTANTS';
import { axiosInstance } from './axiosInstance';
import { setCountriesList } from '../reduxStore/actions/modalsActions';

export const getAllCountries = () => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const config = {
        ...URLS.consants.getCountries(last_access_token),
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data && response?.data?.data) {
                    const formated = response?.data?.data.map((country) => {
                        return [country.name, country.iso.toLowerCase(), `${country.phonecode}`];
                    });

                    store.dispatch(setCountriesList(formated));
                    subscriber.next();
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error.data);
            })
    );
};
