export const authDefault = {
    sessionSettings: {
        rememberMe: false,
        expirationDate: '',
        last_access_token: '',
        last_expires_in: 0,
        last_refresh_token: '',
        groupAdministrationMenu: false,
    },
    authenticatedUser: {
        id: '',
        email: '',
        profile_id: '',
        company_id: '',
        company_name: '',
        departments: [],
        first_name: '',
        is_admin: true,
        last_name: '',
        photo: null,
        positions: [],
        timezone: '',
        permissions: [],
        roles: [],
    },
    lastKnownRoute: '',
};

