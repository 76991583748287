import { DialogContent, DialogOverlay } from '@reach/dialog';
import '@reach/dialog/styles.css';
import classNames from 'classnames';
import React from 'react';
import cls from './ModalR.module.scss';
import { CloseIcon } from '../../../../assets/images/new-icons';

export default function ModalR(props) {
    const { isOpen, onClose, onContentPress, id, hasCloseIcon, shadowedContainerClassname } = props;
    const close = () => {
        onClose && onClose();
    };

    return (
        <>
            <DialogOverlay dangerouslyBypassFocusLock={props.dangerouslyBypassFocusLock || false} className={cls.transparent} isOpen={isOpen} onDismiss={close} id={id} style={{ ...props.modalWrapperStyle }}>
                <DialogContent onClick={onContentPress} className={classNames(cls.host, shadowedContainerClassname)} app-variant-opened={isOpen ? 'true' : 'false'} style={{ ...props.shadowedContainerStyle }} aria-label="Warning">
                    {hasCloseIcon && (
                        <div className={cls.closeContainer} onClick={close}>
                            <CloseIcon onClick={close} />
                        </div>
                    )}
                    {props.children}
                </DialogContent>
            </DialogOverlay>
        </>
    );
}
