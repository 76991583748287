import moment from 'moment';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import 'rc-datetime-picker/dist/picker.css';
import { useEffect, useRef, useState } from 'react';
import AutosizeInput from 'react-input-autosize';
import { Calendar } from '../../../assets/images/icons';
import cls from './RCDateTimePicker.module.scss';

export const RCDateTimePicker = (props) => {
    const { defaultValue, onDateChanged, error } = props;
    const dateTimePickerRef = useRef(null);
    const containerRef = useRef();

    const [selectedDate, setSelectedDate] = useState(defaultValue);
    const handleClickOutside = (event) => {
        if (containerRef?.current && !containerRef?.current.contains(event.target)) {
            dateTimePickerRef?.current?.togglePicker(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });
    const dateChangeHandler = (e) => {
        if (moment(e)?.isValid()) {
            setSelectedDate(moment(e).format('DD MMMM YYYY'));
            onDateChanged?.(moment(e).format('DD MMMM YYYY'));
        }
    };

    return (
        <>
            <div ref={containerRef} className={cls.host}>
                <DatetimePickerTrigger
                    ref={dateTimePickerRef}
                    showTimePicker={false}
                    dayFormat={'DD MMMM YYYY'}
                    moment={moment(selectedDate, 'DD MMMM YYYY')?.isValid() ? moment(selectedDate, 'DD MMMM YYYY') : moment()}
                    onChange={dateChangeHandler}
                    closeOnSelectDay
                    className={cls.dateTimePickerTrigger}
                >
                    <AutosizeInput readOnly name="Date" value={selectedDate ?? ''} placeholderIsMinWidth={true} placeholder={' select date '} inputClassName={cls.dateAutosizeInput} />
                    <Calendar style={{ padding: '10px' }} />
                </DatetimePickerTrigger>
            </div>

            <div>{error?.length > 0 && <p className={cls.errorGeneralText}>{error} </p>}</div>
        </>
    );
};
