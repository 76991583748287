import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './appFiles/reduxStore/store';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { PermissionProvider } from "./appFiles/components/common";

const suspenseStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    fontFamily: 'var(--app-text-secondary-font)',
    fontSize: '16px',
    color: 'var(--alvanda-dark-purple)',
};

const app = (
    <React.Fragment>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <Suspense
                    fallback={<p>{`Loading...`}</p>}
                    style={suspenseStyle}
                >
                    <PermissionProvider>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </PermissionProvider>
                </Suspense>
            </PersistGate>
        </Provider>
    </React.Fragment>
);

ReactDOM.render(app, document.getElementById('root'));
