export const positionInfoDefault = {
    name: '',
    results: '',
    strategic: '',
    tactical: '',
    standards: '',
    resources: '',
    accountability: '',
    success: '',
    failure: '',
    id: '',
    parent_id: null,
    parent_position: {id: '', name: '', is_department_root: false},
    department_id: null,
    is_managerial: '0',
    can_invite_colleagues_into_their_team: '0',
    can_remove_colleagues_from_their_team: '0',
    is_department_root: false,
    is_protected: false,
    children: [],
    users: [],
    shared_users: [],
};

export const flowDefault = {
    serverFeatureNotReadyYet: false,
    onBoardingCompleted: false,
    onCompanyMenuUpdate: false, //For sync
    onAccountMenuUpdate: false, //For sync
    onGroupsUpdate: false, //For sync
    companyData: {
        company_standards: [],
        core_values: [],
        departments_count: 3,
        display_name: '',
        employees_no: '',
        founding_date: null,
        id: '',
        industry: null,
        logo: null,
        mission_text: '',
        name: '',
        onboarding_status: false,
        positions_count: 0,
        procedures_count: 0,
        tasks_count: 0,
        components_count: 0,
        slug: '',
        updated_by: {},
        users_count: 1,
        vision_text: '',
        billing_info: {},
    },
    myGroups: [],
    selectedGroup: {},
    companyDepartments: [],
    companyPositionsArray: [],
    inviteColleguesList: [{ email: '', first_name: '', last_name: '', position: '' }],
    positionInfo: {...positionInfoDefault},
    duplicatePositionInfo: { valid: false, name: '', department_id: '', parent_id: '', is_managerial: 0 },
    companyOrgChart: {},
    groupOrgChart: {},
    allTasks: [],
    allCompletedTasks: { data: [], links: [], meta: [] },
    allCompletedTasksPaginated: [],
    companyUsersArray: [],
    companyProcesses: [],
    selectedProcess: { name: '', description: '', id: '', is_pinned: false, user_id: '', registered: false },
    selectedProStep: {
        isNewStep: false,
        id: '',
        name: '',
        guidance: '',
        duration: 0,
        rank: null,
        is_start: false,
        is_final: false,
        procedure_id: '',
        options: {
            is_import_in_next: false,
            is_start_next_process: false,
            allows_search_components: false,
        },
        parent_steps: [],
        child_steps: [],
        child_processes: [],
        component_blueprints: [],
    },
    begunProcess: { processTask: '', steps: [], isStarted: false },
    allComponents: [],
    newComponentBlueprint: { componentName: '', componentIcon: '', fields: [] },
    selectedBlueprintComponent: { id: '', name: '', icon: '', fields: [] },
    components: [],
};
