import React from 'react';
import { RenderTitle } from '../../common';
import TextEditor from '../../common/TextEditor/TextEditor';
import { accountability, consequencesFailure, consequencesSuccess, desiredResult, resorces, strategicWork, tacticalWork } from './tooltips';

const renderSection = (title, tooltipText, uniqueIdentifier, data, onChangeCallback, savePressed, setSavePressed, hasPermission) => {
	return hasPermission || data?.length > 0 ? (
		<div>
			<RenderTitle title={title} style={{ marginTop: '16px' }} tooltipText={tooltipText} />
			<TextEditor savePressed={savePressed} setSavePressed={setSavePressed} uniqueIdentifier={uniqueIdentifier} data={data} onChangeCallback={onChangeCallback} disabled={!hasPermission}  />
		</div>
	) : null;
};

const RoleEditors = ({ savePressed, setSavePressed, positionInfo, onSetPositionInfo, hasPermission }) => {
	return (
		<div>
			{renderSection('Desired Result', desiredResult, 'roleInformation_result', positionInfo?.results, data => onSetPositionInfo({ results: data }), savePressed, setSavePressed, hasPermission)}

			{renderSection('Strategic Work', strategicWork, 'roleInformation_strategic', positionInfo?.strategic, data => onSetPositionInfo({ strategic: data }), savePressed, setSavePressed, hasPermission)}

			{renderSection('Tactical Work', tacticalWork, 'roleInformation_tactical', positionInfo?.tactical, data => onSetPositionInfo({ tactical: data }), savePressed, setSavePressed, hasPermission)}

			{renderSection(
				'Position-Specific Standards',
				null,
				'roleInformation_standards',
				positionInfo?.standards,
				data => onSetPositionInfo({ standards: data }),
				savePressed,
				setSavePressed,
				hasPermission
			)}

			{renderSection('Resources', resorces, 'roleInformation_resources', positionInfo?.resources, data => onSetPositionInfo({ resources: data }), savePressed, setSavePressed, hasPermission)}

			{renderSection('Accountability', accountability, 'roleInformation_accountability', positionInfo?.accountability, data => onSetPositionInfo({ accountability: data }), savePressed, setSavePressed, hasPermission)}

			{renderSection('Consequences for Success', consequencesSuccess, 'roleInformation_success', positionInfo?.success, data => onSetPositionInfo({ success: data }), savePressed, setSavePressed, hasPermission)}

			{renderSection('Consequences for Failure', consequencesFailure, 'roleInformation_failure', positionInfo?.failure, data => onSetPositionInfo({ failure: data }), savePressed, setSavePressed, hasPermission)}
		</div>
	);
};

export default RoleEditors;
