//import qs from 'querystring';
import queryString from 'query-string';

const qsSimple = require('qs');

import { Observable } from 'rxjs';
import {
    setCompanyData,
    setGroupOrgChartData,
    setCompanyPositionsArray,
    setPositionInfo, setMyGroups, setSelectedGroup,
} from '../reduxStore/actions/flowActions';
import { store } from '../reduxStore/store';
import { URLS } from './API_CONSTANTS';
import { axiosInstance } from './axiosInstance';
import { positionInfoDefault } from "../reduxStore/reducers/default/flowDefault";
import {normalizeUserData} from "./user.service";
// for all api from collection that involves company internal setup (as company group policy- folders Company/ positions/ departments )

export const getGroupCompanyDetails = (companyId = null) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const group_id = store.getState().flowReducer?.selectedGroup?.value ?? null;

    const config = {
        ...URLS.groups.getCompanyDetails(last_access_token, group_id, companyId),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data && response?.data?.data) {
                    subscriber.next(response?.data?.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const getGroupAllDivisions = () => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const group_id = store.getState().flowReducer?.selectedGroup?.value ?? null;

    const config = {
        ...URLS.groups.getAllDivisions(last_access_token, group_id),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data && response?.data?.data) {
                    subscriber.next(response?.data?.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const getAllGroupCompanies = (page, per_page, filters={}) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const group_id = store.getState().flowReducer?.selectedGroup?.value ?? null;

    const queryParams = {
        per_page: per_page,
        page: page,
        ...filters,
    };

    const cleanParams = params =>
        Object.fromEntries(Object.entries(params).filter(([_, v]) => v != null));

    const cleanedParams = cleanParams(queryParams);

    const params = queryString.stringify(cleanedParams, {
        skipNull: true,
        skipEmptyString: true,
    });
    const config = {
        ...URLS.groups.getCompanies(last_access_token, group_id, params),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data && response?.data?.data) {
                    let data = response?.data;

                    subscriber.next(data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const exportCompaniesCSV = (filters={}) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const queryParams = {
        ...filters,
    };

    const cleanParams = params =>
        Object.fromEntries(Object.entries(params).filter(([_, v]) => v != null));

    const cleanedParams = cleanParams(queryParams);

    const params = queryString.stringify(cleanedParams, {
        skipNull: true,
        skipEmptyString: true,
    });
    const config = {
        ...URLS.company.exportCompaniesCSV(last_access_token, params),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data) {
                    subscriber.next(response?.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const getGroupFilteredUsers = (companyId = null, sentData, sortBy = ['created_at'], order = 'desc') => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const group_id = store.getState().flowReducer?.selectedGroup?.value ?? null;

    const query = queryString.stringify(
        { sort: order, sort_by: sortBy },
        {
            skipNull: true,
            skipEmptyString: true,
        }
    );

    const config = {
        ...URLS.groups.getGroupFilteredUsers(last_access_token, group_id, companyId, query),
        data: sentData,
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status == 200 && response?.data?.data) {
                    const users = [
                        ...(response?.data?.data?.map(user_info => ({
                            ...normalizeUserData(user_info),
                        })) ?? []),
                    ];

                    subscriber.next(users);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const getAllCompanyPositionsAsArray = (has_users_only = false) => {
    const {
        sessionSettings: {last_access_token},
    } = store.getState().authReducer;
    const config = {
        ...URLS.positions.getAllPositionsAsArray(last_access_token, has_users_only ? "?has_users_only=true" : ""),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data && response?.data?.data) {
                    const posi = [
                        ...(response?.data?.data?.map(i => ({
                            label: i.name,
                            value: i.id,
                        })) ?? []),
                    ].sort((a, b) => (a?.label?.toUpperCase() < b?.label?.toUpperCase() ? -1 : a?.label?.toUpperCase() > b?.label?.toUpperCase() ? 1 : 0));

                    store.dispatch(setCompanyPositionsArray([{ label: 'None of the above', value: '0' }, ...posi]));
                    subscriber.next(posi);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const getInvitableCompanyPositionsAsArray = () => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.positions.getInvitablePositionsAsArray(last_access_token),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data && response?.data?.data) {
                    const posi = [
                        ...(response?.data?.data?.map(i => ({
                            label: i.name,
                            value: i.id,
                        })) ?? []),
                    ].sort((a, b) => (a?.label?.toUpperCase() < b?.label?.toUpperCase() ? -1 : a?.label?.toUpperCase() > b?.label?.toUpperCase() ? 1 : 0));

                    subscriber.next([{ label: 'None of the above', value: '0' }, ...posi]);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const setGroupCompanyLogo = (companyId, logoFile) => {
    const group_id = store.getState().flowReducer?.selectedGroup?.value ?? null;
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = new FormData();

    data.append('logo', logoFile);
    const config = {
        ...URLS.groups.updateGroupCompanyLogo(last_access_token, group_id, companyId),
        data: data,
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data) {
                    subscriber.next(response.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const createGroup = (groupName) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.groups.createGroup(last_access_token),
        data: {name: groupName},
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 201 && response?.data) {
                    subscriber.next(response.data.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const updateGroup = (groupName) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const group_id = store.getState().flowReducer?.selectedGroup?.value ?? null;
    const config = {
        ...URLS.groups.updateGroup(last_access_token, group_id),
        data: {name: groupName},
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data) {
                    subscriber.next(response.data.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const createCompanyForGroup = (companyData, group_id) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    group_id = group_id ?? store.getState().flowReducer?.selectedGroup?.value ?? null;

    const config = {
        ...URLS.groups.createGroupCompany(last_access_token, group_id),
        data: companyData,
        handlerEnabled: true,
        forwardAllErrors: true,
        refreshGroupChart: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 201 && response?.data) {
                    subscriber.next(response.data.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const createDivision = (divisionName) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const group_id = store.getState().flowReducer?.selectedGroup?.value ?? null;

    const config = {
        ...URLS.groups.createDivision(last_access_token, group_id),
        data: {name: divisionName},
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 201 && response?.data) {
                    subscriber.next(response.data.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const updateGroupDivision = (divisionId, ndValue, color) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const group_id = store.getState().flowReducer?.selectedGroup?.value ?? null;

    const data = qsSimple.stringify({ name: ndValue, color: color });
    const defaultConfig = { ...URLS.groups.updateDivision(last_access_token, group_id, divisionId) };
    const config = {
        ...defaultConfig,
        headers: { ...defaultConfig.headers },
        data: data,
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data && response?.data?.data) {
                    // store.dispatch(someAction(payload));
                    subscriber.next(response.data.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const deleteGroupDivision = (department) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const group_id = store.getState().flowReducer?.selectedGroup?.value ?? null;

    const defaultConfig = { ...URLS.groups.deleteDivision(last_access_token, group_id, department.id) };
    const config = {
        ...defaultConfig,
        headers: { ...defaultConfig.headers },
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data) {
                    subscriber.next(response.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const updateGroupCompanyDetails = (companyId, companyData) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const group_id = store.getState().flowReducer?.selectedGroup?.value ?? null;

    const config = {
        ...URLS.groups.updateGroupCompanyDetails(last_access_token, group_id, companyId),
        data: companyData,
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data) {
                    subscriber.next(response.data.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const getCompanySMTPProvider = () => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.company.getCompanySMTPProvider(last_access_token),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data && response?.data?.data) {
                    subscriber.next(response?.data?.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const setCompanySMTPProvider = (customMailData) => {
    const data = {
        mail_configuration: customMailData,
    };

    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const config = {
        ...URLS.company.updateCompanySMTPProvider(last_access_token),
        data: data,
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if ((response?.status === 200 || response?.status === 201) && response?.data) {
                    subscriber.next(response.data.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const testCompanySMTPProvider = (customMailData) => {
    const data = {
        mail_configuration: customMailData,
    };

    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const config = {
        ...URLS.company.testCompanySMTPProvider(last_access_token),
        data: data,
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if ((response?.status === 200 || response?.status === 201) && response?.data) {
                    subscriber.next(response.data.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const sendOnboardingComplete = () => {
    const {
        companyData: { id: companyId },
    } = store.getState().flowReducer;

    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.company.completeOnBoarding(last_access_token, companyId),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data) {
                    store.dispatch(setCompanyData({ ...response?.data?.data, onboarding_status: true }));
                    subscriber.next(response?.data?.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const getMyGroups = () => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const group_id = store.getState().flowReducer?.selectedGroup?.value ?? null;

    const config = {
        ...URLS.groups.getMyGroups(last_access_token),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status == 200 && response?.data && response?.data?.data) {
                    let groups = response.data.data?.map(i => ({ label: i.name, value: i.id }));

                    store.dispatch(setMyGroups(groups));
                    //Select Default Group
                    if (!group_id || !groups.some(group => group.value === group_id)) {
                        store.dispatch(setSelectedGroup(groups[0]));
                    }
                    subscriber.next(response.data.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const createNewCompanyDepartment = (ndValue, render_index, color) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = new FormData();

    data.append('name', ndValue);
    data.append('render_index', render_index);
    data.append('color', color);
    const defaultConfig = { ...URLS.departments.createNewDepartment(last_access_token) };
    const config = {
        ...defaultConfig,
        headers: { ...defaultConfig.headers },
        data: data,
        handlerEnabled: true,
        refreshOrgChart: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status == 200 && response?.data && response?.data?.data) {
                    // store.dispatch(someAction(payload));
                    subscriber.next(response.data.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const updateCompanyDepartment = (ndValue, depId, color) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = qsSimple.stringify({ name: ndValue, color: color });
    const defaultConfig = { ...URLS.departments.updateDepartmentById(last_access_token, depId) };
    const config = {
        ...defaultConfig,
        headers: { ...defaultConfig.headers },
        data: data,
        handlerEnabled: true,
        refreshOrgChart: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data && response?.data?.data) {
                    // store.dispatch(someAction(payload));
                    subscriber.next(response.data.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const updateCompanyDepartmentsOrder = (depOrderArray) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    var data = JSON.stringify(depOrderArray);
    const defaultConfig = { ...URLS.departments.updateDepartmentsOrder(last_access_token) };
    const config = {
        ...defaultConfig,
        headers: { ...defaultConfig.headers },
        data: data,
        handlerEnabled: true,
        refreshOrgChart: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status == 200 && response?.data && response?.data?.data) {
                    const newOrdered = response.data.data?.sort((a, b) =>
                        (a.render_index < b.render_index ? -1 : a.render_index > b.render_index ? 1 : 0));

                    subscriber.next(newOrdered);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const getGroupOrgChartPositions = () => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const selectedGroup = store.getState().flowReducer?.selectedGroup ?? null;
    const group_id = selectedGroup?.value ?? null;
    const group_name = selectedGroup?.label ?? null;

    const config = {
        ...URLS.groups.getGroupOrgChart(last_access_token, group_id),
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data && response?.data?.data) {
                    let chart = {
                        "id": group_id,
                        "name": group_name,
                        "is_department_root": false,
                        "division": null,
                        "children": [
                            ...response?.data?.data || [], // Merge data
                            ...response?.data?.non_assigned || [], // Merge non_assigned
                        ],
                    };

                    //Same code is copied in axiosInstance
                    store.dispatch(setGroupOrgChartData(chart));
                    // store.dispatch(setSelectedGroup({ ...response?.data?.data, registered: true }));
                    subscriber.next(chart);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                store.dispatch(setGroupOrgChartData([]));//Reset chart data
                subscriber.error(error);
            })
    );
};

export const importInvitationsFromExcelFile = (excelFile) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = new FormData();

    data.append('file', excelFile);

    const config = {
        ...URLS.invitations.importInvitation(last_access_token, data),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data && response?.data?.data) {
                    subscriber.next(response.data.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const createInvitation = (invitationData) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = new FormData();

    data.append('email', invitationData.email);
    data.append('first_name', invitationData.first_name);
    data.append('last_name', invitationData.last_name);
    if (invitationData.position_id && invitationData.position_id !== "0") data.append('position_id', invitationData.position_id);
    const config = {
        ...URLS.invitations.createInvitation(last_access_token, data),
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data && response?.data?.data) {
                    const { first_name, last_name, email, position_id } = response.data.data;

                    // store.dispatch(setGroupOrgChartData(response?.data?.data));
                    subscriber.next({ first_name, last_name, email, position_id });
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const getSentInvitations = (page, sort) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const query = queryString.stringify(
        { per_page: 10, page: page, dir: sort.order, sort_by: sort.type },
        {
            skipNull: true,
            skipEmptyString: true,
        }
    );

    const config = {
        ...URLS.invitations.getInvitations(last_access_token, query),
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data && response?.data?.data) {
                    subscriber.next(response.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const closeInvitationById = (invitation_id) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const config = {
        ...URLS.invitations.deleteInvitationById(last_access_token, invitation_id),
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data) {
                    subscriber.next(response.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const resendInvitationById = (invitation_id) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const config = {
        ...URLS.invitations.resendAnInvitation(last_access_token, invitation_id),
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data) {
                    subscriber.next(response.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const getAllCompanyDepartmentPositions = (department) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const defaultConfig = { ...URLS.departments.getAllDepartmentPositions(last_access_token, department.id) };
    const config = {
        ...defaultConfig,
        headers: { ...defaultConfig.headers },
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status == 200 && response?.data) {
                    // store.dispatch(someAction(payload));
                    subscriber.next(response.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const getCompanyDepartmentManagerialPositions = (department_id) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const defaultConfig = { ...URLS.departments.getDepartmentManagerialPositions(last_access_token, department_id) };
    const config = {
        ...defaultConfig,
        headers: { ...defaultConfig.headers },
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status == 200 && response?.data?.data) {
                    // store.dispatch(someAction(payload));
                    subscriber.next(response.data?.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const getCompanyAllDepartmentManagerialPositions = () => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const defaultConfig = { ...URLS.departments.getCompanyAllDepartmentManagerialPositions(last_access_token) };
    const config = {
        ...defaultConfig,
        headers: { ...defaultConfig.headers },
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status == 200 && response?.data?.data) {
                    // store.dispatch(someAction(payload));
                    subscriber.next(response.data?.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
//positions

export const getAllPositions = (companyId = null) => {
    const params = queryString.stringify(
        { company_id: companyId },
        {
            skipNull: true,
            skipEmptyString: true,
        }
    );

    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;

    const config = {
        ...URLS.positions.getAllPositions(last_access_token, params),

        handlerEnabled: true,
        forwardAllErrors: true,
        refreshOrgChart: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data && response?.data?.data) {
                    const pos = response?.data?.data;

                    pos.length === 1 &&
                        store.dispatch(
                            setPositionInfo({
                                name: pos?.name,
                                results: pos?.results,
                                strategic: pos?.strategic,
                                tactical: pos?.tactical,
                                standards: pos?.standards,
                                resources: pos?.resources,
                                accountability: pos?.accountability,
                                success: pos?.success,
                                failure: pos?.failure,
                                id: pos?.id,
                                parent_id: pos?.parent_position?.id ? pos.parent_position.id : pos.parent_id,
                                parent_position: pos?.parent_position,
                                department_id: pos.department_id,
                                is_managerial: pos.is_managerial,
                                can_invite_colleagues_into_their_team: pos.can_invite_colleagues_into_their_team,
                                can_remove_colleagues_from_their_team: pos.can_remove_colleagues_from_their_team,
                                users: pos.users,
                                shared_users: pos.shared_users,
                                children: pos.children,
                            })
                        );
                    subscriber.next(pos);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const createNewPosition = (positionData) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    var data = new FormData();

    data.append('name', positionData.name);
    data.append('parent_id', positionData.parent_id);
    data.append('department_id', positionData.department_id);
    data.append('is_managerial', positionData.is_managerial);
    data.append('can_invite_colleagues_into_their_team', positionData.can_invite_colleagues_into_their_team);
    data.append('can_remove_colleagues_from_their_team', positionData.can_remove_colleagues_from_their_team);
    const config = {
        ...URLS.positions.createNewPosition(last_access_token),
        data: data,
        handlerEnabled: true,
        forwardAllErrors: true,
        refreshOrgChart: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data && response?.data?.data) {
                    const pos = response?.data?.data;

                    store.dispatch(
                        setPositionInfo({
                            name: pos?.name || '',
                            results: pos?.results || '',
                            strategic: pos?.strategic || '',
                            tactical: pos?.tactical || '',
                            standards: pos?.standards || '',
                            resources: pos?.resources || '',
                            accountability: pos?.accountability || '',
                            success: pos?.success || '',
                            failure: pos?.failure || '',
                            id: pos?.id,
                            parent_id: pos?.parent_position?.id ? pos.parent_position.id : pos.parent_id,
                            parent_position: pos?.parent_position,
                            department_id: pos.department_id,
                            is_managerial: pos.is_managerial,
                            can_invite_colleagues_into_their_team: pos.can_invite_colleagues_into_their_team,
                            can_remove_colleagues_from_their_team: pos.can_remove_colleagues_from_their_team,
                            users: pos.profiles,
                            shared_users: pos.shared_profiles,
                            children: pos.children,
                        })
                    );
                    subscriber.next(pos);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const deletePosition_AndKeepChildren = (positionId) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.positions.deletePositionAndKeepChildren(last_access_token, positionId),
        handlerEnabled: true,
        //withModalOutput: true,
        refreshOrgChart: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data) {
                    store.dispatch(setPositionInfo({...positionInfoDefault}));
                    subscriber.next(response?.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const deletePosition_AndItsChildren = (positionId) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.positions.deletePositionAndItsChildren(last_access_token, positionId),
        handlerEnabled: true,
        //withModalOutput: true,
        refreshOrgChart: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data) {
                    store.dispatch(setPositionInfo({...positionInfoDefault}));
                    subscriber.next('success');
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const updatePosition = (positionId) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const { positionInfo } = store.getState().flowReducer;

    const data = {
        name: positionInfo.name,
        results: positionInfo.results, //.replace('~', '\n'),
        strategic: positionInfo.strategic, //.replace('~', '\n'),
        tactical: positionInfo.tactical, //.replace('~', '\n'),
        standards: positionInfo.standards, //.replace('~', '\n'),
        resources: positionInfo.resources, //.replace('~', '\n'),
        accountability: positionInfo.accountability, //.replace('~', '\n'),
        success: positionInfo.success, //.replace('~', '\n'),
        failure: positionInfo.failure, //.replace('~', '\n')
        is_managerial: positionInfo.is_managerial,
        parent_id: positionInfo.parent_id,
        department_id: positionInfo.department_id,
        can_invite_colleagues_into_their_team: positionInfo.is_managerial && positionInfo.can_invite_colleagues_into_their_team ? 1 : 0,
        can_remove_colleagues_from_their_team: positionInfo.is_managerial && positionInfo.can_remove_colleagues_from_their_team ? 1 : 0,
    };
    const config = {
        ...URLS.positions.updatePositionById(last_access_token, positionId),
        data: data,
        handlerEnabled: true,
        refreshOrgChart: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response.status == '200' && response?.data) {
                    store.dispatch(setPositionInfo({...positionInfoDefault}));
                    subscriber.next('success');
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const getPositionById = (positionId) => {
    const {
        sessionSettings: {last_access_token},
    } = store.getState().authReducer;

    const config = {
        ...URLS.positions.getPositionById(last_access_token, positionId),
        handlerEnabled: true,
    };

    return new Observable(async (subscriber) => {
        try {
            const response = await axiosInstance(config);

            if (response.status === 200 && response.data) {
                const pos = response.data.data;
                let position_info = {
                    name: pos?.name || '',
                    results: pos?.results || '',
                    strategic: pos?.strategic || '',
                    tactical: pos?.tactical || '',
                    standards: pos?.standards || '',
                    resources: pos?.resources || '',
                    accountability: pos?.accountability || '',
                    success: pos?.success || '',
                    failure: pos?.failure || '',
                    id: pos?.id,
                    parent_id: pos?.parent_position?.id ? pos.parent_position.id : pos.parent_id,
                    parent_position: pos.parent_position,
                    department_id: pos.department_id,
                    is_managerial: pos.is_managerial,
                    is_department_root: pos.is_department_root,
                    is_protected: pos.is_protected,
                    children: pos.children,
                    users: pos.profiles,
                    shared_users: pos.shared_profiles,
                    can_invite_colleagues_into_their_team: pos.can_invite_colleagues_into_their_team,
                    can_remove_colleagues_from_their_team: pos.can_remove_colleagues_from_their_team,
                    is_managed_by_current_user: pos.is_managed_by_current_user,
                };

                //Needed for getting parent `is_department_root` field Maybe in future it's better to ask BE to provide this variable in main response
                if (position_info.parent_id) {
                    const newResponse = await axiosInstance({
                        ...URLS.positions.getPositionById(last_access_token, position_info.parent_id),
                        handlerEnabled: true,
                    });

                    if (newResponse.status === 200 && newResponse.data) {
                        const parentResponse = newResponse.data.data;

                        position_info.parent_position = {
                            ...position_info.parent_position,
                            is_department_root: parentResponse.is_department_root,
                        };
                    }
                }
                store.dispatch(setPositionInfo(position_info));
                subscriber.next(pos);
                subscriber.complete();
            }
        } catch (error) {
            subscriber.error(error);
        }
    });
};
export const getSpecificPositionUsers = (positionId) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const config = {
        ...URLS.positions.getPositionUsers(last_access_token, positionId),
        handlerEnabled: true,
        //withModalOutput: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response.status === '200' && response?.data) {
                    subscriber.next(response?.data?.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const duplicateSpecificPosition = (positionId, name, parent_id = '') => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    var data = new FormData();

    data.append('name', name);
    parent_id.length > 0 && data.append('parent_id', parent_id);
    const config = {
        ...URLS.positions.duplicatePosition(last_access_token, positionId),
        data: data,
        handlerEnabled: true,
        //withModalOutput: true,
        refreshOrgChart: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data?.data) {
                    subscriber.next(response?.data?.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const addingUserToPosition = (positionId, selectedToAdd) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = new FormData();

    for (let i = 0; i < selectedToAdd.length; i++) {
        data.append('user_id[]', selectedToAdd[i].value);
    }

    const config = {
        ...URLS.positions.addUserToPosition(last_access_token, positionId, data),
        handlerEnabled: true,
        forwardAllErrors: true,
        retrieveAuthenticatedUser: true};

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response.data.data) {
                    subscriber.next(response.data.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const removeUserFromPosition = (positionId, userId) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = qsSimple.stringify({ user_id: [userId] });

    const config = {
        ...URLS.positions.deleteUserFromPosition(last_access_token, positionId, data),
        handlerEnabled: true,
        forwardAllErrors: true,
        retrieveAuthenticatedUser: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200) {
                    subscriber.next(response);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const sharingUserToPosition = (positionId, userIds) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = new FormData();

    data.append('user_ids', [userIds]);
    const config = {
        ...URLS.positions.shareUserToPosition(last_access_token, positionId, { user_ids: userIds }),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                subscriber.next(response?.data?.message);
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};
export const unsharingUserFromPosition = (positionId, userIds = []) => {
    const last_access_token = store.getState().authReducer?.sessionSettings?.last_access_token ?? null;
    const data = qsSimple.stringify({ user_ids: [...userIds] });
    const config = {
        ...URLS.positions.unshareUserFromPosition(last_access_token, positionId, data),
        handlerEnabled: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.status === 200 && response?.data) {
                    getPositionById(positionId).subscribe({
                        complete: () => {
                            subscriber.next(response.data);
                            subscriber.complete();
                        },
                    });
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};


export const deactivateCompany = (company_id) => {
    const {
        sessionSettings: { last_access_token},
    } = store.getState().authReducer;

    const config = {
        ...URLS.company.deactivateCompany(last_access_token, company_id),
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data) {
                    subscriber.next(response.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const pauseCompanySubscription = (company_id, resumeAt) => {
    const {
        sessionSettings: { last_access_token},
    } = store.getState().authReducer;

    const data = qsSimple.stringify({ resumes_at: resumeAt, immediately: true });

    const config = {
        ...URLS.company.pauseSubscription(last_access_token, company_id, data),
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data) {
                    subscriber.next(response.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const resumeCompanySubscription = (company_id) => {
    const {
        sessionSettings: { last_access_token},
    } = store.getState().authReducer;

    const config = {
        ...URLS.company.resumeSubscription(last_access_token, company_id),
        handlerEnabled: true,
        forwardAllErrors: true,
    };

    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data) {
                    subscriber.next(response.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};

export const purchaseSubscription = (company_id, service_details) => {
    const {
        sessionSettings: { last_access_token},
    } = store.getState().authReducer;

    const data = qsSimple.stringify(service_details);

    const config = {
        ...URLS.company.purchaseSubscription(last_access_token, company_id, data),
        handlerEnabled: true,
        forwardAllErrors: true,
    };


    return new Observable(subscriber =>
        axiosInstance(config)
            .then((response) => {
                if (response?.data) {
                    subscriber.next(response.data);
                    subscriber.complete();
                }
            })
            .catch((error) => {
                subscriber.error(error);
            })
    );
};


