import React from 'react';
import { Page, Text, View, Document, Image  } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import { styles } from './RolePDFStyles';

import logo from '../../../assets/images/branding/alvandaLogoText.png';
import { uncachedUrl } from '../../../shared/utility';

function displayCompanyInitials(name) {
    const words = name.split(' ');

    if (words.length === 1) {
        // If there is only one word, return the first two letters
        return words[0].substring(0, 2).toUpperCase();
    } else {
        // If there are two or more words, return the initials of each word
        const initials = words.map(word => word[0].toUpperCase());
        return initials.join('');
    }
}

const RolePDF = ({ positionInfo, companyInfo}) => {
    return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                <View style={styles.headerStrip} fixed></View>
                <View>
                    <View id={'header'}>
                        <View style={styles.header}>
                            <View style={styles.headerContent}>
                                <Text style={styles.roleTitle}>{positionInfo.name}</Text>
                                <Text style={styles.companyTitle}>{companyInfo.name}</Text>
                            </View>
                            <View style={styles.logoContainer}>
                                {companyInfo.logo ? (
                                    <Image src={uncachedUrl(companyInfo.logo)} style={styles.logo} />
                                ) : (
                                    <Text style={styles.companyLogo}>{displayCompanyInitials(companyInfo.display_name)}</Text>
                                )}
                            </View>
                        </View>
                    </View>
                    <View id={'content'} style={styles.pageContent}>
                        <View id={'roleDetails'} style={styles.roleDetails}>
                            <View style={styles.row}>
                                <Text style={{paddingRight: 46}}>Department:</Text>
                                <Text style={ styles.roleInfo }>{positionInfo?.department ?? 'No Department' }</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={{paddingRight: 25}}>Parent Position: </Text>
                                <Text style={ styles.roleInfo }>{positionInfo.parent_position?.name ?? 'No Parent Position' }</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={{paddingRight: 2}}>Managerial position: </Text>
                                <Text style={ styles.roleInfo }>{positionInfo.is_managerial ? 'Yes' : 'No'}</Text>
                            </View>
                        </View>
                        <View id={'policies'} style={styles.policies}>
                            {positionInfo.results && (
                                <View style={styles.policy}>
                                    <View style={styles.policyHeading}>
                                        <Text style={styles.policyTitle}>Desired Result</Text>
                                    </View>
                                    <View style={styles.policyDescription}>
                                        <Html style={styles.policyDescription}>{positionInfo.results}</Html>
                                    </View>
                                </View>
                            )}
                            {positionInfo.strategic && (
                                <View style={styles.policy} wrap={false}>
                                    <View style={styles.policyHeading}>
                                        <Text style={styles.policyTitle}>Strategic Work</Text>
                                    </View>
                                    <View style={styles.policyDescription}>
                                        <Html style={styles.policyDescription}>{positionInfo.strategic}</Html>
                                    </View>
                                </View>
                            )}
                            {positionInfo.tactical && (
                                <View style={styles.policy} wrap={false}>
                                    <View style={styles.policyHeading}>
                                        <Text style={styles.policyTitle}>Tactical Work</Text>
                                    </View>
                                    <View style={styles.policyDescription}>
                                        <Html style={styles.policyDescription}>{positionInfo.tactical}</Html>
                                    </View>
                                </View>
                            )}
                            {positionInfo.standards && (
                                <View style={styles.policy} wrap={false}>
                                    <View style={styles.policyHeading}>
                                        <Text style={styles.policyTitle}>Position Specific Standards</Text>
                                    </View>
                                    <View style={styles.policyDescription}>
                                        <Html style={styles.policyDescription}>{positionInfo.standards}</Html>
                                    </View>
                                </View>
                            )}
                            {positionInfo.resources && (
                                <View style={styles.policy} wrap={false}>
                                    <View style={styles.policyHeading}>
                                        <Text style={styles.policyTitle}>Resources</Text>
                                    </View>
                                    <View style={styles.policyDescription}>
                                        <Html style={styles.policyDescription}>{positionInfo.resources}</Html>
                                    </View>
                                </View>
                            )}
                            {positionInfo.accountability && (
                                <View style={styles.policy} wrap={false}>
                                    <View style={styles.policyHeading}>
                                        <Text style={styles.policyTitle}>Accountability</Text>
                                    </View>
                                    <View style={styles.policyDescription}>
                                        <Html style={styles.policyDescription}>{positionInfo.accountability}</Html>
                                    </View>
                                </View>
                            )}
                            {positionInfo.success && (
                                <View style={styles.policy} wrap={false}>
                                    <View style={styles.policyHeading}>
                                        <Text style={styles.policyTitle}>Consequences for Success</Text>
                                    </View>
                                    <View style={styles.policyDescription}>
                                        <Html style={styles.policyDescription}>{positionInfo.success}</Html>
                                    </View>
                                </View>
                            )}
                            {positionInfo.failure && (
                                <View style={styles.policy} wrap={false}>
                                    <View style={styles.policyHeading}>
                                        <Text style={styles.policyTitle}>Consequences for Failure</Text>
                                    </View>
                                    <View style={styles.policyDescription}>
                                        <Html style={styles.policyDescription}>{positionInfo.failure}</Html>
                                    </View>
                                </View>
                            )}

                            <View style={styles.policy} wrap={false}>
                                <View style={styles.policyHeading}>
                                    <Text style={styles.policyTitle}>People in this position</Text>
                                </View>
                                <View style={styles.policyDescription}>
                                    {positionInfo.users?.map((people, key) => (
                                        <Html style={styles.policyDescription}>{key+1+'. '+people.first_name + ' ' + people.last_name}</Html>
                                    ))}
                                </View>
                            </View>
                            <View style={styles.policy} wrap={false}>
                                <View style={styles.policyHeading}>
                                    <Text style={styles.policyTitle}>Shared Resources</Text>
                                </View>
                                <View style={styles.policyDescription}>
                                    {positionInfo?.shared_users?.length > 0 ? positionInfo?.shared_users?.map((people, key) => (
                                        <Html style={styles.policyDescription}>{key+1+'. '+people.first_name + ' ' + people.last_name}</Html>
                                    )) : (
                                        <Text>No shared resource</Text>
                                    )}
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.footer} fixed>
                    <View>
                        <View>
                        <Text style={styles.pagination} render={({ pageNumber, totalPages }) => (
                            `${pageNumber}`
                        )} />
                        </View>
                        <View style={styles.footerText}>
                            <Text>
                                Position Overview downloaded from Alvanda.com - The Ultimate Business Process Optimization Platform.
                            </Text>
                            <Image src={logo} style={{ width: 60, objectFit: 'contain' }} />
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
}

export default RolePDF;
