import {
    ADD_COMPONENT,
    ADD_NEW_STATUS,
    ADD_NEW_TASK,
    DELETE_COMPONENT,
    DELETE_COMPONENT_BLUEPRINT,
    DELETE_TASK,
    DELETE_USER,
    ACTIVATE_USER,
    EDIT_TASK,
    REMOVE_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT,
    RESET_SELECTED_PROCESS_STEP,
    SET_BEGUN_PROCESS_TASK,
    SET_BLUEPRINT_COMPONENTS,
    SET_COMPANY_DATA,
    SET_COMPANY_DEPARTMENTS,
    SET_COMPANY_ORG_CHART_DATA,
    SET_MY_GROUPS,
    SET_SELECTED_GROUP,
    SET_GROUP_ORG_CHART_DATA,
    SET_COMPANY_POSITIONS_ARRAY,
    SET_COMPANY_PROCESSES,
    SET_COMPLETED_TASKS,
    SET_COMPONENTS,
    SET_DUPLICATE_POSITION_INFO,
    SET_INVITE_COLLEAGUES_LIST,
    SET_NEW_COMPONENT_BLUEPRINT,
    SET_ONBOARDING_COMPLETED,
    SET_POSITION_INFO,
    SET_SELECTED_COMPONENT_BLUEPRINT,
    SET_SELECTED_PROCESS,
    SET_SELECTED_PROCESS_STEP,
    SET_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT,
    SET_SELECTED_PROCESS_STEP_OPTIONS,
    SET_SERVER_FEATURE_NOT_READY_YET,
    SET_STATUTES,
    SET_TASKS,
    SET_USERS_ARRAY,
    CHANGE_TASK_PROCESS,
    RESET_FLOW_REDUCER,
    UPDATE_USER,
    SET_COMPANY_MENU_SYNC,
    SET_ACCOUNT_MENU_SYNC, SET_GROUP_SYNC,
} from '../actions/types';
import { appDefaultReducer } from './defaultReducer';

const INITIAL_STATE = appDefaultReducer.flowReducer;

export default function flowReducer(state = INITIAL_STATE, action) {
    const { type, payload, is_resumed } = action;

    const updatedElements = state.companyUsersArray.map((element) => {


        if (payload && element.id === payload.id) {
            // Update the desired element
            return {
                ...element,
                ...payload,
            };
        }

        return element;
    });

    switch (type) {
        case SET_INVITE_COLLEAGUES_LIST:
            return { ...state, inviteColleguesList: [...payload] };
        case SET_COMPANY_DATA:
            return { ...state, companyData: { ...state.companyData, ...payload } };
        case SET_COMPANY_MENU_SYNC:
            return { ...state, onCompanyMenuUpdate: !state.onCompanyMenuUpdate };
        case SET_ACCOUNT_MENU_SYNC:
            return { ...state, onAccountMenuUpdate: !state.onAccountMenuUpdate };
        case SET_GROUP_SYNC:
            return { ...state, onGroupsUpdate: !state.onGroupsUpdate };
        case SET_MY_GROUPS:
            return { ...state, myGroups: payload };
        case SET_GROUP_ORG_CHART_DATA:
            return { ...state, groupOrgChart: payload };
        case SET_SELECTED_GROUP:
            return { ...state, selectedGroup: payload };
        case SET_COMPANY_DEPARTMENTS:
            return { ...state, companyDepartments: payload };
        case SET_COMPANY_POSITIONS_ARRAY:
            return { ...state, companyPositionsArray: payload };
        case SET_POSITION_INFO:
            return { ...state, positionInfo: { ...state.positionInfo, ...payload } };
        case SET_DUPLICATE_POSITION_INFO:
            return { ...state, duplicatePositionInfo: payload };
        case SET_USERS_ARRAY:
            return { ...state, companyUsersArray: payload };
        case SET_ONBOARDING_COMPLETED:
            return { ...state, onBoardingCompleted: payload };
        case SET_COMPANY_ORG_CHART_DATA:
            return { ...state, companyOrgChart: payload };
        case SET_COMPANY_PROCESSES:
            if (action.page === 1) {
                return { ...state, companyProcesses: payload };
            } else
                return {
                    ...state,
                    companyProcesses: state.companyProcesses.concat(payload),
                };
        case SET_SELECTED_PROCESS:
            return { ...state, selectedProcess: payload };
        case SET_SELECTED_PROCESS_STEP:
            return { ...state, selectedProStep: { ...state.selectedProStep, ...(payload ?? {}) } };
        case SET_SELECTED_PROCESS_STEP_OPTIONS:
            return {
                ...state,
                selectedProStep: { ...state.selectedProStep, options: { ...state.selectedProStep.options, ...payload } },
            };
        case SET_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT:
            return {
                ...state,
                selectedProStep: {
                    ...state.selectedProStep,
                    component_blueprints: [...state.selectedProStep.component_blueprints.filter(i => i.id != payload?.id), payload],
                },
            };
        case REMOVE_SELECTED_PROCESS_STEP_COMPONENT_BLUEPRINT: {
            return {
                ...state,
                selectedProStep: {
                    ...state.selectedProStep,
                    component_blueprints: [...state.selectedProStep.component_blueprints.filter(i => i.id != payload)],
                },
            };
        }
        case RESET_SELECTED_PROCESS_STEP:
            return { ...state, selectedProStep: { ...INITIAL_STATE.selectedProStep, isNewStep: true } };

        case SET_NEW_COMPONENT_BLUEPRINT: {
            return { ...state, newComponentBlueprint: { ...state.newComponentBlueprint, ...payload } };
        }
        case SET_SELECTED_COMPONENT_BLUEPRINT: {
            return { ...state, selectedBlueprintComponent: payload };
        }
        case SET_BEGUN_PROCESS_TASK:
            return { ...state, begunProcess: { ...state.begunProcess, processTask: payload, isStarted: true, is_resumed } };
        case CHANGE_TASK_PROCESS:
            return {
                ...state,
                begunProcess: {
                    ...state.begunProcess,
                    processTask: { ...state.begunProcess.processTask, process: payload },
                },
            };
        case SET_TASKS:
            return { ...state, allTasks: payload };
        case EDIT_TASK:
            return { ...state, allTasks: [...state.allTasks, payload] };
        case DELETE_TASK:
            return { ...state, allTasks: state.allTasks.filter((item, _index) => item.id !== payload) };
        case ADD_NEW_TASK:
            return { ...state, allTasks: [...state.allTasks, payload] };
        case SET_COMPLETED_TASKS:
            if (action.page === 1) {
                return { ...state, allCompletedTasks: payload, allCompletedTasksPaginated: payload.data };
            } else
                return {
                    ...state,
                    allCompletedTasks: payload,
                    allCompletedTasksPaginated: state.allCompletedTasksPaginated.concat(payload.data),
                };
        case SET_STATUTES:
            return { ...state, allStatutes: payload };
        case DELETE_USER:
            return { ...state, companyUsersArray: state.companyUsersArray.filter(item => item.id !== payload) };
        case ACTIVATE_USER:
            return {...state, companyUsersArray: [...state.companyUsersArray, payload]}
        case UPDATE_USER:
            return { ...state, companyUsersArray: updatedElements };
        case SET_COMPONENTS:


            if (action.page === 1) {
                return { ...state, components: payload };
            } else
                return {
                    ...state,
                    components: [...state.components.concat(payload)],
                };
        case SET_BLUEPRINT_COMPONENTS: {
            if (action.page === 1) {
                return { ...state, allComponents: payload.data };
            } else
                return {
                    ...state,
                    allComponents: [...state.allComponents.concat(payload.data)],
                };
        }
        case DELETE_COMPONENT_BLUEPRINT:
            return {
                ...state,
                allComponents: state.allComponents.filter((item, _index) => item.id !== payload),
            };
        case DELETE_COMPONENT:
            return {
                ...state,
                components: state.components.filter((item, _index) => item.id !== payload),
            };
        case ADD_COMPONENT:
            return {
                ...state,
                components: [...state.components, payload],
            };
        case ADD_NEW_STATUS:
            return { ...state, allStatutes: [...state.allStatutes, payload] };
        case SET_SERVER_FEATURE_NOT_READY_YET:
            return { ...state, serverFeatureNotReadyYet: payload };
        case RESET_FLOW_REDUCER: {
            const onboardingCompleted = state.onBoardingCompleted ?? state.companyData.onboarding_status === true;

            return {
                ...state,
                ...INITIAL_STATE,
                companyData: { ...INITIAL_STATE.companyData, onboarding_status: false, billing_info: {}, updated_by: {} },
                onBoardingCompleted: onboardingCompleted,
            };
        }
        default:
            return state;
    }
}
