import { memo, useCallback, useEffect, useRef, useState } from 'react';
import ResizeObserver from 'react-resize-observer';
import { useNavigate } from 'react-router-dom';
import './chartStyles.scss';
import III_Managerials from './III_Managerials';
import III_NonManagerials from './III_NonManagerials';
import cls from './II_Managerials.module.scss';
import { useSelector } from 'react-redux';
const II_Managerials = memo((props) => {
    const {chartPreview} = useSelector(store => store.modalsReducer);
    const navigate = useNavigate();
    const mainCont_II_Ref = useRef();
    const labelCont_II_Ref = useRef();
    const chCont_II_Ref = useRef();
    const { data, showPositionInfo, onContMan_II_ChildChange, positioning, firstElement, firstElementCenter, lastElement, lastElementCenter, ...rest } = props;

    const [mg_II_Ch, setMg_II_Ch] = useState([...(data?.children?.filter(i => i.is_managerial == 1 || i.is_managerial == true) ?? [])]);
    const [nonMg_II_Ch, setNonMg_II_Ch] = useState([...(data?.children?.filter(i => i.is_managerial == 0 || i.is_managerial == false) ?? [])]);

    const [colDim, setColDim] = useState({ colWidth: 0, colHeight: 0, left: 0 });

    const [, /* manCont_II_Dim */ setManCont_II_Dim] = useState([]);
    const [nmanCont_III_Dim, setNManCont_III_Dim] = useState([]);

    useEffect(() => {
        if (onContMan_II_ChildChange) {
            onContMan_II_ChildChange({
                width: mainCont_II_Ref.current?.clientWidth,
                height: mainCont_II_Ref.current?.clientHeight,
                offsetLeft: mainCont_II_Ref.current?.offsetLeft,
            });
        }
        if (lastElement && lastElementCenter) {
            lastElementCenter(+mainCont_II_Ref.current?.clientWidth / 2 + 10);
        }
        if (firstElement && firstElementCenter) {
            firstElementCenter(+mainCont_II_Ref.current?.clientWidth / 2 + 10);
        }
    }, [colDim, nmanCont_III_Dim]);
    const settingState = (type, value) => {
        switch (type) {
            case 'setManagerialCh':
                setMg_II_Ch(value);
                break;
            case 'setNonManagerialCh':
                setNonMg_II_Ch(value);
                break;
            default:
                return;
        }
    };

    useEffect(() => {
        const managerials = [...(data?.children?.filter(i => i.is_managerial == 1 || i.is_managerial == true) ?? [])];
        const nonManagerials = [...(data?.children.filter(i => i.is_managerial == 0 || i.is_managerial == false) ?? [])];

        settingState('setManagerialCh', managerials);
        settingState('setNonManagerialCh', nonManagerials);
    }, [data?.children]);
    const detChildPos = useCallback(
        (idx) => {
            let total = nonMg_II_Ch.length + mg_II_Ch.length;
            if (total >=3){
                if (idx === 0 && nonMg_II_Ch.length === 0) return 'left'
                if (idx === mg_II_Ch.length - 1) return 'right'
                if (idx === 0 && mg_II_Ch.length > 1 && nonMg_II_Ch.length > 0) return 'left'
                else return 'center'
            }
            if (total === 1) return 'center'
            if (idx === 0 && nonMg_II_Ch.length === 0) return 'left';
            else return 'right';
        },
        [nonMg_II_Ch, mg_II_Ch]
    );

    return (
        <div key={props.id} className={cls.host} app-ii-item-position={positioning} {...rest} ref={mainCont_II_Ref}>
            <ResizeObserver
                onResize={(rect) => {
                    setColDim(c => ({ ...c, colWidth: rect.width, colHeight: rect.height, left: rect.left }));
                }}
            />
            <div className={cls.target} ref={labelCont_II_Ref}>
                <div
                    className={cls.cardContainerBase}
                    onClick={() => {
                        showPositionInfo(data.id);
                    }}
                    app-org-chart-preview={chartPreview === true ? 'true' : 'false'}
                    app-managerials={mg_II_Ch?.length > 0 ? 'true' : 'false'}
                >
                    <p>
                        <span className={cls.companyInfoTitle}>{data.name}</span>
                        <span>{data.sub_name}</span>
                    </p>
                </div>
            </div>
            <div className={cls.childrenContainer}>
                <div
                    ref={chCont_II_Ref}
                    className={cls.childrenContainer}
                    app-both-children-types={nonMg_II_Ch?.length > 0 && mg_II_Ch?.length > 0 ? 'true' : 'false'}
                    app-managerials-only={nonMg_II_Ch?.length == 0 && mg_II_Ch?.length > 1 ? 'true' : 'false'}
                    app-non-managerials-only={nonMg_II_Ch?.length > 0 && mg_II_Ch?.length == 0 ? 'true' : 'false'}
                    app-only-one-managerial={nonMg_II_Ch?.length == 0 && mg_II_Ch?.length == 1 ? 'true' : 'false'}
                >
                    {nonMg_II_Ch?.length > 0 && (
                        <III_NonManagerials
                            key={data.name}
                            nmPositions={{ children: nonMg_II_Ch }}
                            onlyNMPos={mg_II_Ch?.length == 0}
                            onContNMan_III_ChildChange={({ width, _height, offsetLeft, _offsetParent }) => {
                                setNManCont_III_Dim({ id: 'nManCont_III_Dim', width: width, offsetLeft: offsetLeft });
                            }}
                            showPositionInfo={(positionId) => {!chartPreview && navigate({ pathname: `/role/${positionId}`});
                            }}
                            parentsDims={{
                                width: labelCont_II_Ref?.current?.clientWidth,
                                height: labelCont_II_Ref?.current?.clientHeight,
                                offsetLeft: labelCont_II_Ref?.current?.offsetLeft,
                                offsetTop: labelCont_II_Ref?.current?.offsetTop,
                            }}
                        />
                    )}
                    <div className={cls.III_Managerials_Children_Container}
                        app-both-siblings-types={nonMg_II_Ch?.length > 0 && mg_II_Ch?.length > 1 ? 'true' : 'false'}
                    >
                    {mg_II_Ch?.length > 0 &&
						mg_II_Ch?.map((i, idx) => {
						    return (
                                <III_Managerials
                                    key={i.id}
                                    data={i}
                                    render_index={idx}
                                    onContMan_III_ChildChange={({ width, _height, offsetLeft, _offsetParent }) => {
                                        setManCont_II_Dim(c => [...c.filter(el => el.id != i.id && mg_II_Ch.map(m => m.id).indexOf(i.id) > -1), { id: i.id, width: width, offsetLeft: offsetLeft }]);
                                    }}

                                    showPositionInfo={(positionId) => {!chartPreview && navigate({ pathname: `/role/${positionId}`});}}
                                    positioning={detChildPos(idx)}
                                    lastElement={idx == mg_II_Ch?.length - 1}
                                    lastElementCenter={(lastElem) => {
                                        chCont_II_Ref.current.style.setProperty('--app-ii-right-offset', `${lastElem}px`);
                                    }}
                                    firstElement={idx == 0}
                                    firstElementCenter={(lastElem) => {
                                        chCont_II_Ref.current.style.setProperty('--app-ii-left-offset', `${lastElem}px`);
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default II_Managerials;
