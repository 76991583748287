import React, {useEffect, useRef} from 'react';
import { useCombobox } from 'downshift';
import { ComponentProps, ForwardedRef, forwardRef, useState } from 'react';
import classNames from 'classnames';
import {
    ErrorMessage, Label, ToggleMenuButton,
    MenuOverlayListItem, MenuOverlayListContainer, Input,
} from './SelectComponents';
import { SearchIconGrey } from '../../../../assets/images/new-icons';
import cls from './SelectWithSearchInMenuList.module.scss';
import { XSign } from '../../../../assets/images/icons';
import Loader from "react-loader-spinner";
import {isSafari} from "react-device-detect";
import {isEqual} from "lodash";

type Item = { flag?: JSX.Element; value: string; label: string; icon?: SVGElement; symbol?: string };
export type SelectProps = {
    defaultSelected: any;
    /** list of items to chose from */
    items: Item[];
    /**displayed as label above Control */
    title: string;
    placeholder?: string;
    /** sets an id to the related elements as required by aria listbox rules. Falls back to `title` if none is provided */
    id?: string;
    /** adds a "*"-Indicator to the end of the title that turns red in case of an error*/
    hasMandatoryIndicator?: boolean;
    errorMessage?: string;
    onChange?: (selectedItem?: Item | null) => void;
    onClear?: () => void;
	clearControlsClassName: string;
    toggleLeftIcon?: string;
    hasToggleIcon?: boolean;
    hideImgIcon?: boolean;
    triggerType?: string;
    itemType?: 'checkboxes' | 'normal' | 'radio' | 'no-item-icons';
    checkboxEmplacement?: 'left' | 'right';
    iconValue?: boolean;
    iconCustomClass?: string;
    isClearable?: boolean;
    menuButtonPlaceholder: string;
    /** prop to make country phone dial code dropdown */
    isPhoneSelect?: boolean;
    isCurrencySelect?: boolean;
    /** prop to pass className to toggle menu input button */
    tmiClassName: string;
    /** prop to pass className to MenuOverlayList */
    molclassname: string;
    /** prop to pass className to MenuOverlayListItem */
    moliclassname: string;
    molcontainerclass?: string;
    placeholderClassName?: string;
    searchPlaceholder?: string;
    toggleBtnChildrenClass?: string;
    hideSearchInput?: boolean;
    noDataMessage?: string;
    error: boolean;
    disable?: boolean;
    loading?: boolean;
} & Omit<ComponentProps<'div'>, 'onChange'>;

// eslint-disable-next-line react/display-name
const SelectWithSearchInMenuList = forwardRef<HTMLDivElement, SelectProps>((props: SelectProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
        items,
        title,
        defaultSelected,
        hasToggleIcon,
        hideImgIcon,
        id = title?.replace(/ /g, '-'),
        placeholder,
        searchPlaceholder,
        hasMandatoryIndicator,
        errorMessage,
        disable,
        onChange,
        onClear,
        className,
        iconValue,
        iconCustomClass,
        isClearable,
        tmiClassName,
        molclassname,
        molcontainerclass,
        clearControlsClassName,
        moliclassname,
        placeholderClassName,
        toggleBtnChildrenClass,
        isPhoneSelect,
        isCurrencySelect,
        triggerType,
        itemType,
        checkboxEmplacement,
        hideSearchInput,
        noDataMessage,
        loading,
        ...rest
    } = props;

    const [searchFilteredItems, setSearchFilteredItems] = useState(items);
    const [searchValue, setSearchValue] = useState('');
    const {
        getLabelProps,
        isOpen,
        getMenuProps,
        getInputProps,
        getItemProps,
        getToggleButtonProps,
        highlightedIndex,
        selectedItem,
        selectItem,
    } = useCombobox({
        items: searchFilteredItems,
        id,
        defaultSelectedItem: defaultSelected,
        itemToString: item => item?.label ?? '',
        onSelectedItemChange: (event) => { setSearchValue(''); onChange?.(event.selectedItem);},
    });

    const selectedValuesRef = useRef();

    useEffect(() => {
        // to avoid unnecessary rendering used this logic
        if (!isEqual(selectedValuesRef.current, defaultSelected)){
            selectItem(defaultSelected);
            selectedValuesRef.current = defaultSelected;
        }
    }, [defaultSelected]);

    useEffect(() => {
        setSearchFilteredItems(items);
    }, [items]);

    useEffect(() => {
        if (isOpen) {
            setSearchFilteredItems(items);
        } else {
            setSearchValue("");
        }
    }, [isOpen]);

    return (
        <div ref={ref} className={classNames(cls.host, className)} {...rest}>
            <Label {...getLabelProps()} hasMandatoryIndicator={hasMandatoryIndicator}>
                {title}
            </Label>
            <ToggleMenuButton
                className={classNames(cls.toggleMenu, tmiClassName)}
                hasToggleIcon={!triggerType && hasToggleIcon}
                triggerType={triggerType? triggerType : ''}
                isOpen={isOpen}
                disabled={disable}
                toggleBtnClass={toggleBtnChildrenClass}
                {...getToggleButtonProps()}
            >
                {isPhoneSelect && (
                    <div className={cls.inputFlagContainer}>
                        <span className={cls.countryFlagIcon}>
                            {selectedItem?.flag}
                        </span>
                        <span className={cls.countryDialNumber}>{selectedItem?.value}</span>
                    </div>
                )}
                {isCurrencySelect && (
                    <div className={cls.inputFlagContainer}>
                        <span className={cls.countryFlagIcon}>
                            {selectedItem?.symbol}
                        </span>
                        <span className={cls.countryDialNumber}>{selectedItem?.value}</span>
                    </div>
                )}
                {iconValue && defaultSelected?.length > 0 ?
                    <img alt={''} style={{ marginRight: 'auto', width: '18px', height: '18px' }}
                        src={defaultSelected} /> : (selectedItem?.label?.length > 0 || selectedItem?.name?.length > 0) ? (selectedItem?.label || selectedItem.name) :
                        <p className={placeholderClassName} style={{ color: "#858593" }}>{placeholder}</p>
                }
                {isClearable && selectedItem?.label && (
                    <div
                        className={clearControlsClassName}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            selectItem(null);
                            onClear?.();
                        }}
                    >
                        <XSign className={cls.toggleMenuControlsIcons} />
                    </div>
                )}
            </ToggleMenuButton>
            <MenuOverlayListContainer {...getMenuProps()} className={classNames(cls.menuOverlayList, molclassname)}>
                {isOpen && (!noDataMessage || !hideSearchInput) && (
                    <div className={cls.dropdownSearchInputContainer}>
                        <SearchIconGrey />
                        <Input
                            autoFocus
                            type='text'
                            className={cls.input}
                            placeholder={searchPlaceholder}
                            {...getInputProps({
                                value: searchValue,
                                onChange: (e) => {
                                    setSearchValue(e.target.value);
                                    const inputValue = e?.target?.value?.toLowerCase();

                                    const filteredItems = items?.filter(item => {
                                        const labelContainsInput = item?.label?.toLowerCase()?.includes(inputValue);
                                        // value Filter is for Currency Code e.g PKR Or Phone Code e.g +92
                                        const valueContainsInput = ((isCurrencySelect || isPhoneSelect) && item?.value?.toLowerCase()?.includes(inputValue));

                                        return labelContainsInput || valueContainsInput;
                                    });

                                    setSearchFilteredItems(inputValue ? filteredItems : items);
                                },
                            })}
                        />
                    </div>
                )}
                {isOpen && (
                    <ul className={molcontainerclass} {...getMenuProps()}>
                        {loading ? (
                            <Loader type={isSafari ? 'Watch' : 'ThreeDots'} color={'var(--app-color-gray-dark)'}
                                secondaryColor="lightgray" radius={22} height={22} width={'22px'}/>
                        ) : (
                            <>
                                {noDataMessage ? (
                                    <div className={cls.noDataStyle}>{noDataMessage}</div>
                                ) : (
                                    <>
                                        {searchFilteredItems?.map((item, index) => (
                                            <MenuOverlayListItem
                                                itemType={itemType ? itemType : 'no-item-icons'}
                                                checkboxEmplacement={checkboxEmplacement}isVisible={true}
                                                className={classNames(cls.menuOverlayListItem, moliclassname)}
                                                key={`${item}${index}`}
                                                isHighlighted={highlightedIndex === index}
                                                isSelected={selectedItem?.value == item?.value}
                                                {...getItemProps({ item, index })}
                                                style={{
                                                    cursor: 'pointer',
                                                    color: 'var(--app-color-gray-light)',
                                                    backgroundColor: selectedItem?.value == item?.value ? 'var(--app-color-gray-lighter)' : highlightedIndex === index ? '#F1F1F1' : 'transparent',
                                                }}
                                            >
                                                {(isPhoneSelect || isCurrencySelect) ? (
                                                    <div className={cls.dropdownItemFlagContainer}>
                                                        {isCurrencySelect && (
                                                            <span className={cls.currencySymbolIcon}>
                                                                {item?.symbol}
                                                            </span>
                                                        )}
                                                        {isPhoneSelect && (
                                                            <span className={cls.countryFlagIcon}>
                                                                {item?.flag}
                                                            </span>
                                                        )}
                                                        <span className={cls.countryDialNumber}>{item?.value}</span>
                                                        {item.label}
                                                    </div>
                                                ) : (
                                                    <div className={cls.itemContainer}>
                                                        {(item?.icon && typeof item?.icon !== 'string' && !hideImgIcon) && <span className={classNames(cls.itemIcon, iconCustomClass)}>{item.icon}</span>}
                                                        {(item?.icon && typeof item?.icon === 'string' && !hideImgIcon) &&
                                                        <img className={cls.itemLeftImgIcon} alt="item-icon" src={item?.icon}/>}
                                                        {item.label}
                                                    </div>
                                                )}
                                            </MenuOverlayListItem>
                                        ))}
                                        {searchValue?.length > 0 && items?.length > 0 && searchFilteredItems?.length === 0 && (
                                            <div className={cls.noResultStyle}>No results found</div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </ul>
                )}
            </MenuOverlayListContainer>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
    );
});

export default SelectWithSearchInMenuList;
