import classNames from 'classnames';
import {useCombobox, useMultipleSelection} from 'downshift';
import React, {ComponentProps, ForwardedRef, forwardRef, useEffect, useState} from 'react';
import cls from './MultiselectWithSearchR.module.scss';
import {
    ErrorMessage,
    Input,
    InputContainer,
    Item,
    Label,
    MenuOverlayList,
    MenuOverlayListItem,
    RenderTags,
    TagProps,
    ToggleMenuInputButton,
    ToggleMenuInputButtonProps
} from './SelectComponents';

export type MultiSelectWithSearchProps = {
    /** list of items to chose from */
    items: Item[];
    /**displayed as label above Control */
    title?: string;
    placeholder?: string;
    /** sets an id to the related elements as required by aria listbox rules. Falls back to `title` if none is provided */
    id?: string;
    /** adds a "*"-Indicator to the end of the title that turns red in case of an error*/
    hasMandatoryIndicator?: boolean;
    /** id's of the items that need to be selected initially*/
    initialSelectedItems: Item[];
    errorMessage?: string;
    onChange?: (selectedItems?: Item[]) => void;

    inputStyle?: Record<string, unknown>;
    menuOverlayStyle?: Record<string, unknown>;
    menuOverlayListItemStyle?: Record<string, unknown>;
    noTags?: boolean;
    labelsTag: boolean;
    tagEmplacement: 'top' | 'bottom' | 'inlined';
    tagType?: TagProps['tagType'];
    tagCloseButtonType?: TagProps['closeButtonType'];
    triggerType?: ToggleMenuInputButtonProps['triggerType'];
    itemType?: 'checkboxes' | 'normal' | 'radio' | 'no-item-icons';
    checkboxEmplacement?: 'left' | 'right';
    inputValue?: string;
    leftIconItemContainerClassName?: string;
    menuOverlayListItemChildClassName?: string;
    /** prop to pass className to Input */
    inputContainerClassname?: string;
    inputClassName?: string;
    /** prop to pass className to MenuOverlayList */
    molclassname?: string;
    /** prop to pass className to MenuOverlayListItem */
    moliclassname?: string;
} & ComponentProps<'div'>;

const MultiSelectWithSearch = forwardRef<HTMLDivElement, MultiSelectWithSearchProps>((props: MultiSelectWithSearchProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
        title,
        id = title?.replace(/ /g, '-'),
        noTags,
        labelsTag,
        tagType,
        tagCloseButtonType,
        tagEmplacement,
        /* inputValue, */ triggerType,
        itemType,
        checkboxEmplacement,
        items,
        initialSelectedItems,
        children,
        placeholder,
        hasMandatoryIndicator,
        errorMessage,
        onChange,
        inputStyle,
        menuOverlayStyle,
        menuOverlayListItemStyle,
        className,
        leftIconItemContainerClassName,
        menuOverlayListItemChildClassName,
        inputContainerClassname,
        inputClassName,
        molclassname,
        moliclassname,
        ...rest
    } = props;
    const [searchFilteredItems, setSearchFilteredItems] = useState(items);
    const [localInputValue, setLocalInputValue] = useState('');
    const {getSelectedItemProps, getDropdownProps, addSelectedItem, removeSelectedItem, selectedItems} = useMultipleSelection<Item>({
        initialSelectedItems: items?.filter(i => initialSelectedItems?.filter(e => e?.value == i?.value)?.length > 0),
        itemToString: item => (item ? item.value : localInputValue),
    });

    useEffect(() => {
        onChange?.(selectedItems);
    }, [selectedItems]);
    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        openMenu,
        selectItem
    } = useCombobox<Item | null>({
        items: searchFilteredItems,
        id,
        itemToString: () => localInputValue,
        stateReducer: (state, actionAndChanges) => {
            const {type, changes} = actionAndChanges;

            switch (type) {
                //clear the input when clicking outside or toggle button
                case useCombobox.stateChangeTypes.ToggleButtonClick:
                case useCombobox.stateChangeTypes.InputBlur:
                    return {
                        ...changes,
                        inputValue: '',
                        //isOpen: true,
                    };
                case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    return {
                        ...changes,
                        isOpen: true, //keep menu open after selection
                        highlightedIndex: state.highlightedIndex,
                    };
            }

            return changes;
        },
        onStateChange: ({type, selectedItem}) => {
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    if (selectedItem) {
                        if (selectedItems.includes(selectedItem)) removeSelectedItem(selectedItem);
                        else addSelectedItem(selectedItem);
                        selectItem(null);
                    }
                    break;
                default:
                    break;
            }
        },
        onInputValueChange: ({inputValue}) => {
            setSearchFilteredItems(inputValue ? items?.filter(item => item?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())) : items);
            setLocalInputValue(inputValue ?? '');
        },
    });

    useEffect(() => setSearchFilteredItems(items), [items]);

    return (
        <div
            ref={ref}
            className={classNames(cls.host, className)}
            {...rest}
            onClick={() => {
                if (!isOpen) openMenu();
            }}
        >
            <Label {...getLabelProps()} hasMandatoryIndicator={hasMandatoryIndicator}>
                {title}
            </Label>
            <div className={cls.inputAndTagsWrapper} app-tag-emplacement={tagEmplacement}>
                {!noTags && (
                    <RenderTags
                        labelsTag={labelsTag}
                        selectedItems={selectedItems}
                        getSelectedItemProps={getSelectedItemProps}
                        removeSelectedItem={removeSelectedItem}
                        hasCloseButton={typeof tagCloseButtonType == 'string' && tagCloseButtonType?.length > -1}
                        closeButtonType={tagCloseButtonType}
                        tagType={tagType}
                    />
                )}
                <InputContainer
                    {...getComboboxProps()}
                    app-renedered-elements={tagEmplacement == 'inlined' ? 'toggle-only' : 'input-toggle'}
                    className={classNames(cls.inputContainer, inputContainerClassname)}
                    isActive={isOpen}
                    hasError={errorMessage != null}
                    hasResponsiveHeight={!noTags}
                >
                    <Input
                        className={inputClassName}
                        style={inputStyle}
                        {...getInputProps({
                            placeholder,
                            'aria-invalid': errorMessage != null || undefined,
                            style: {display: tagEmplacement == 'inlined' ? 'none' : 'initial'},
                            onFocus: () => {
                                if (!isOpen) openMenu();
                            },
                            ...getDropdownProps({preventKeyAction: isOpen}),
                        })}
                    />
                    <ToggleMenuInputButton {...getToggleButtonProps(getDropdownProps({preventKeyAction: isOpen}))} isOpen={isOpen}
                                           triggerType={triggerType}>
                        {children}
                    </ToggleMenuInputButton>
                </InputContainer>
            </div>

            {/* {!noTags && tagEmplacement == 'bottom' && false && <RenderTags selectedItems={selectedItems}
                    getSelectedItemProps={getSelectedItemProps}
                    removeSelectedItem={removeSelectedItem}
                    hasCloseButton={tagCloseButtonType && typeof tagCloseButtonType == 'string' && tagCloseButtonType?.length > -1}
                    closeButtonType={tagCloseButtonType}
                    tagType={tagType}
                />} */}
            <MenuOverlayList {...getMenuProps()} className={molclassname} style={menuOverlayStyle}>
                {isOpen &&
                    searchFilteredItems?.map((item, index) => (
                        <MenuOverlayListItem
                            itemType={itemType ? itemType : 'normal'}
                            checkboxEmplacement={checkboxEmplacement}
                            isVisible={true}
                            className={moliclassname}
                            style={menuOverlayListItemStyle}
                            key={`${item?.value}${items?.indexOf(item)}`}
                            isHighlighted={highlightedIndex === index}
                            isSelected={selectedItems.includes(item)}
                            icon={item?.icon ?? undefined}
                            leftIconItemContainerClassName={leftIconItemContainerClassName}
                            menuOverlayListItemChildClassName={menuOverlayListItemChildClassName}
                            {...getItemProps({item, index})}
                        >
                            {item.label}
                            <span style={{
                                color: 'var(--alvanda-medium-purple)',
                                alignSelf: 'start',
                            }}>{item?.sublabel}</span>
                        </MenuOverlayListItem>
                    ))}
            </MenuOverlayList>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </div>
    );
});

export default MultiSelectWithSearch;
