import {memo, useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import {getCompanyOrgChartPositions} from '../../../services/company.service';
import {first} from 'rxjs/operators';
import './chartStyles.scss';
import cls from './CompanyOrganisationalChart.module.scss';
import I_Managerials from './I_Managerials';
import I_NonManagerials from './I_NonManagerials';
import Loader from "react-loader-spinner";
import {isSafari} from "react-device-detect";
import { setChartPreview } from '../../../reduxStore/actions/modalsActions';

const R = require('ramda');
const CompanyOrganisationalChart = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {preview = false, chartPreview, setChartPreview} = props;
    const {companyOrgChart} = useSelector(store => store.flowReducer);
    const [departments, setDepartments] = useState([]);
    const [directPositions, setDirectPositions] = useState([]);
    const [depContainersDim, setDepContainersDim] = useState([]);
    const [loading, setLoading] = useState(false);
    const [verticalOffset, setVerticalOffset] = useState(0);
    const firstChdRowWidth = useRef();
    const gManContainerRef = useRef();
    const [, setDeps] = useState([]);
    const [generalManager, setGeneralManager] = useState({});
    const setRenderedDepartments = dps => setDeps([...dps, {name: 'last'}]);

    useEffect(()=>{
        setChartPreview(preview);
    }, [preview]);
    useEffect(() => {
        const positionId = new URLSearchParams(location?.search).get('positionId');

        if (positionId) {
            navigate({pathname: `/role/${positionId}`});
        }
        if (gManContainerRef?.current?.tagName?.toLowerCase() === 'div') {
            setTimeout(
                () =>
                    gManContainerRef?.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center',
                    }),
                50
            );
        }
    }, [location]);

    useEffect(() => {
        if (departments?.length > 0) setRenderedDepartments(departments);
    }, [departments]);

    useLayoutEffect(() => {
        setLoading(true);
        getCompanyOrgChartPositions().pipe(first()).subscribe({
            complete: () => {
                setLoading(false);
            },
        });
    }, []);

    const settingVertOff = (value) => {
        setVerticalOffset(value);
    };

    useEffect(() => {
        settingVertOff(depContainersDim.reduce((a, c) => (c.height > a ? (a = c.height) : a), 0) ?? 0);
    }, [depContainersDim]);
    useEffect(() => {
        chartRecomputeData();
    }, [companyOrgChart]);

    const chartRecomputeData = useCallback(() => {
        if (companyOrgChart == {} || Object.entries(companyOrgChart)?.length == 0) {
            setDepartments([]);
            setDirectPositions([]);
            setGeneralManager({});
        }
        Object.entries(companyOrgChart).map(([_key, _value], _i) => {
            if (companyOrgChart['children']?.length > 0) {
                companyOrgChart['children'].map((it) => {
                    if (it['is_department_root'] === true) {
                        const departments = R.sortBy(R.path(['department', 'render_index']))
                        (R.reject(R.propEq('department', R.isNil), it['children']));
                        const positions = [...it['children'].filter(i => i.department == null)];

                        setDepartments([...departments]);
                        setDirectPositions(positions);
                        setGeneralManager(it);

                        return null;
                    }

                    return null;
                });

                return null;
            }
        });
        // window?.scrollTo(window?.innerWidth, 50);
    }, [companyOrgChart]);
    const RenderBottomNamings = memo(
        (props) => {
            const {name, verticalOffset} = props;

            return (
                <div className={cls.bottomDepartmentsContainer} style={{'--app-margin-top': `${verticalOffset}px,`}}>
                    <div className={cls.bottomMarginContainer}/>
                    <div className={cls.departmentNameContainer}>
                        <p>{name}</p>
                    </div>
                </div>
            );
        },
        (props, nextProps) => props?.verticalOffset != nextProps?.verticalOffset
    );
    const onPositionPressed = (positionId) => {
        !chartPreview && navigate({pathname: `/role/${positionId}`});
    };

    if(preview && loading) {
        return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100vw'}}>
            <Loader type={isSafari ? 'Watch' : 'ThreeDots'} color={'var(--app-color-gray-dark)'} height={"120px"} width={"120px"} />
        </div>;
    }

    return (
        <>
            {loading ? <Loader type={isSafari ? 'Watch' : 'ThreeDots'} color={'var(--app-color-gray-dark)'} radius={50} style={{marginTop: '80px'}} /> :
                <div id={'chart'} style={{margin: '20px, 10px 0px,10px', padding: '10px 0 50px 10px', display: 'inline-block'}}>
                    <div style={{overflow: 'visible', justifyContent: 'space-between'}}>
                        <div className={cls.topRows}>
                            <div
                                className={cls.cardContainerBase}
                                onClick={() => {
                                    onPositionPressed(companyOrgChart?.id);
                                }}
                                app-card-type="chairman"
                                app-org-chart-preview={chartPreview === true ? 'true' : 'false'}
                            >
                                <p>
                                    {companyOrgChart.name} <span
                                        style={{color: (companyOrgChart?.users?.length ?? 0) === 0 && '#EB5757'}}> ({companyOrgChart?.users?.length ?? 0}) </span>
                                </p>
                            </div>
                        </div>
                        <div className={cls.topRows}>
                            <div
                                ref={gManContainerRef}
                                onClick={() => {
                                    onPositionPressed(generalManager?.id);
                                }}
                                className={cls.cardContainerBase}
                                app-card-type="general"
                                app-org-chart-preview={chartPreview === true ? 'true' : 'false'}
                                app-org-depts={departments.length > 0 ? 'true' : 'false'}
                            >
                                <p>
                                    {generalManager.name} <span
                                        style={{color: (generalManager?.users?.length ?? 0) === 0 && '#EB5757'}}> ({generalManager?.users?.length ?? 0}) </span>
                                </p>
                            </div>
                        </div>
                        <div id="cont" ref={firstChdRowWidth} className={cls.childrenWrapper}>
                            <div
                                className={cls.childrenContainer}
                                app-both-children-types={directPositions?.length > 0 && departments?.length > 0 ? 'true' : 'false'}
                                app-managerials-only={directPositions?.length == 0 && departments?.length > 1 ? 'true' : 'false'}
                                app-non-managerials-only={directPositions?.length > 0 && departments?.length == 0 ? 'true' : 'false'}
                                app-only-one-managerial={directPositions?.length == 0 && departments?.length == 1 ? 'true' : 'false'}
                            >
                                {directPositions?.length > 0 && (
                                    <I_NonManagerials
                                        data={{children: directPositions}}
                                        showPositionInfo={(positionId) => {
                                            !chartPreview &&
                                            navigate({pathname: `/role/${positionId}`});
                                        }}
                                        onContainerDimsChange={({width, height}) => {
                                            setDepContainersDim(c => [...c.filter(el => el.id != 'gmnmdp'),
                                                {id: 'gmnmdp', height: height + 70, width: width}]);
                                        }}
                                        onlyNMPos={departments?.length == 0}
                                    />
                                )}
                                {departments?.length > 0 &&
                                    departments?.map((i, idx) => {
                                        return (
                                            <div key={i?.name + idx} className={cls.departmentsContainer}>
                                                <I_Managerials
                                                    name={i.name}
                                                    sub_name={i.sub_name}
                                                    id={i.id}
                                                    childNumber={idx}
                                                    verticalOffset={verticalOffset}
                                                    onContainerDimsChange={({width, height}) => {
                                                        setDepContainersDim(c => [...c.filter(el => el.id != i.id), {
                                                            id: i.id,
                                                            height: height,
                                                            width: width,
                                                        }]);
                                                    }}
                                                    // parentContDim={{ width: gManContainerRef?.current?.clientWidth, offsetLeft: gManContainerRef?.current?.offsetLeft }}
                                                    centerOfParent={+gManContainerRef?.current?.offsetLeft + gManContainerRef?.current?.clientWidth / 2 ?? 0}
                                                    directUnderGeneralManager={true}
                                                    lastElement={idx == departments?.length - 1}
                                                    lastElementCenter={(lastElem) => {
                                                        firstChdRowWidth.current.style.setProperty('--app-right-offset', `${lastElem}px`);
                                                    }}
                                                    firstElement={idx == 0}
                                                    firstElementCenter={(lastElem) => {
                                                        firstChdRowWidth.current.style.setProperty('--app-left-offset', `${lastElem}px`);
                                                    }}
                                                    onlyItem={departments.length === 1}
                                                    childrenLength={departments.length}
                                                    usersLength={i.users.length}
                                                >
                                                    {i.children}
                                                </I_Managerials>
                                                <RenderBottomNamings name={i?.department?.name} verticalOffset={verticalOffset}/>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setChartPreview: value => dispatch(setChartPreview(value)),
    };
};

const mapStateToProps = (store) => {
    const {chartPreview} = store.modalsReducer;

    return {
        chartPreview,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyOrganisationalChart);
