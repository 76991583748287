import { Page, Text, View, Document, Font, StyleSheet  } from '@react-pdf/renderer';
import spline_bold from '../../../assets/fonts/SplineSans-Bold.ttf';
import spline_semibold from '../../../assets/fonts/SplineSans-SemiBold.ttf';
import spline_medium from '../../../assets/fonts/SplineSans-Medium.ttf';
import spline_regular from '../../../assets/fonts/SplineSans-Regular.ttf';
import spline_light from '../../../assets/fonts/SplineSans-Light.ttf';
import rubik_bold from '../../../assets/fonts/Rubik-Bold.ttf';
import rubik_semibold from '../../../assets/fonts/Rubik-SemiBold.ttf';
import rubik_medium from '../../../assets/fonts/Rubik-Medium.ttf';
import rubik_regular from '../../../assets/fonts/Rubik-Regular.ttf';
import rubik_light from '../../../assets/fonts/Rubik-Light.ttf';
Font.register(
    {
        family: 'rubik', 
        fonts: [
            { src: rubik_bold, fontWeight: 800 },
            { src: rubik_semibold, fontWeight: 700 },
            { src: rubik_medium, fontWeight: 600 },
            { src: rubik_regular, fontWeight: 400 },
            { src: rubik_light, fontWeight: 200 },
        ]
    });
Font.register(
    {
        family: 'spline-sans', 
        fonts: [
            { src: spline_light, fontWeight: 200 },
            { src: spline_regular, fontWeight: 400 },
            { src: spline_medium, fontWeight: 600 },
            { src: spline_semibold, fontWeight: 700 },
            { src: spline_bold, fontWeight: 800 },
        ]
    }
);
// Create styles
export const styles = StyleSheet.create({
    page: {
      backgroundColor: '#fff',
      fontFamily: 'rubik',
      fontWeight: 400,
      padding: '15 0 30'
    },
    headerStrip: {
        height: 27,
        width: '100%',
        backgroundColor: '#3030AD',
        border: 'none',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0
    },
    header: {
        fontFamily: 'spline-sans',
        height: 100,
        width: '100%',
        backgroundColor: '#3030AD',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        color: '#fff',
        border: "none",
        padding: '20 30'
    },
    headerContent: {
        width: '78%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        textAlign: 'left',
        gap: '30px',
        alignItems: 'center',
        paddingLeft: 18
    },
    logo: {
        width: 90, 
        height: 90, 
        objectFit: 'cover',
        borderRadius: '50%'
    },
    roleTitle: {
       fontSize: 20,
       width: 230,
       fontWeight: 400,
    },
    companyTitle: {
        fontSize: 12,
        width: 190,
        fontWeight: 400,

     },
    logoContainer: {
        height: 90,
        width: 90,
        position: 'absolute',
        right: '7%',
        left: 'auto',
        bottom: '-50%',
        borderRadius: '50%',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #3030AD',
        color: '#3030AD',
        fontSize: 30,
        fontWeight: 700
    },

    row: {
        display: 'flex',
        flexDirection: 'row',
        gap: 20,
        fontSize: 12,
        marginTop: 15
    },
    roleInfo: {
        fontFamily: 'spline-sans',
        fontWeight: 600
    },
    pageContent: {
        padding: '3 48 20'
    },
    roleDetails: {
        fontWeight: 400,
        fontSize: 16    
    },
    policies: {
        fontSize: 12,
        fontWeight: 400
    },
    policy: {
        marginTop: 20
    },
    policyHeading: {
        borderBottom: '1px solid #AFAFE9',
        padding: '7 0',
        margin: '7 0',
    },
    policyTitle: {
        fontSize: 14, 
        fontWeight: 400 
    },
    policyDescription: {
        fontSize: 11,
        fontWeight: 400,
        lineHeight: 1.5,
    },
    fontMedium: {
        fontWeight: 600
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: 40,
        backgroundColor: '#F7F7FD',
        fontSize: 7,
        paddingTop: 13,
        color: '#0B0B28',
        paddingLeft: 48
    },
    pagination: {
        width: 30,
        height: 20,
        backgroundColor: '#3030AD',
        position: 'absolute',
        color: '#fff',
        bottom: 4,
        left: 'auto',
        right: 18,
        fontSize: 11,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        paddingTop: 3
    },
    footerText: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        alignContent: 'center'
    }
  });