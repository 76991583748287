import { PinnedIcon, NotPinnedIcon } from '../../../assets/images/new-icons';
import Spinner from '../UI/Spinner/Spinner';
import cls from './PinnedProcessCard.module.scss';
import { stepsIcon, clockIcon } from '../../../assets/images/new-icons';
import { eye } from '../../../assets/images/new-icons';
import TaskModal from "../../SideMenuEntries/TaskComps/TaskModal/TaskModal.js";

const PinnedProcessCard = (props) => {
    const { withPinning, processPinned, index, processingPinning, processTime, processTitle, processSteps, processDescription, onClick, unpinProcessClicked, pinProcessClicked, style,onPreview, showPreview = false } = props;

    return (
        <div className={cls.pinnedCardHost} onClick={onClick} style={{ ...style }}>
            <div className={cls.topContainer}>
                <p className={cls.processCardTitle}>{processTitle}</p>
            </div>
            <div className={cls.descriptionTextContainer}>
                <p style={{ color: !processDescription && '#AFAFE9' }} className={cls.processCardDescription}>
                    <div dangerouslySetInnerHTML={{__html: processDescription ? processDescription : 'No description available.'}}/>
                </p>
            </div>
            <div className={cls.bottomContainer}>
                <div className={cls.left} >
                    <div className={cls.firstItemcContainer}>
                        <img src={clockIcon} alt={''} />
                        <p>{processTime}</p>
                    </div>
                    <div className={cls.secondItemcContainer}>
                        <img src={stepsIcon} alt={''} />
                        <p>{processSteps}</p>
                    </div>
                </div>
                <div className={cls.right}>
                    {showPreview &&
                        <div style={{height: "20px", width: "20px", marginRight: "10px"}}>
                            <img onClick={(e)=>{
                                e.stopPropagation();
                                onPreview();}}
                            src={eye} alt={'PreviewIcon'}/>
                        </div>}
                    {withPinning && (
                        <div
                            className={cls.pinContainer}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                processPinned ? unpinProcessClicked() : pinProcessClicked();
                            }}
                        >
                            {processingPinning === index ? (
                                <span className={cls.pinLoadingContainer}>
                                    <Spinner size={20} type={'ThreeDots'} />
                                </span>
                            ) : processPinned ? (
                                <PinnedIcon/>
                            ) : (
                                <NotPinnedIcon/>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PinnedProcessCard;
